import { trim } from '@util/string/Functions';
const formatVisibility = (visibility) => {
    if (visibility == false || visibility == 'public')
        return 'public';
    if (visibility == true || visibility == 'private')
        return 'private';
};
const formatVisibilityBoolean = (visibility) => {
    if (visibility == false || visibility == 'public')
        return true;
    if (visibility == true || visibility == 'private')
        return false;
};
const buildUserEditRequest = (form, user) => {
    let count = 0;
    const body = {};
    if (form.name != user.name) {
        body.name = trim(form.name);
        count++;
    }
    if (formatVisibility(form.visibility) != user.visibility) {
        body.visibility = formatVisibilityBoolean(form.visibility);
        count++;
    }
    return { body, count };
};
export { buildUserEditRequest };
