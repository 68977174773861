import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Title from 'antd/lib/typography/Title';
import useBreakpoints from '@hooks/useBreakpoints';
import { Col, Row } from 'antd';
import { useAppSelector } from '@hooks/useStore';
import { FeatureCard } from '@components/cards/feature_card/FeatureCard';
export const EntityHero = (props) => {
    const { title, features, banner } = props;
    const breakpoints = useBreakpoints();
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const isSmall = breakpoints.isSmall;
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: { position: 'relative', marginBottom: isSmall ? 24 : 40, width: '100%' }, children: [_jsx("img", { alt: "example", style: { objectFit: 'cover', maxHeight: 480, width: '100%', marginBottom: isSmall ? 24 : 40, minHeight: 230 }, src: banner, loading: "eager" }), _jsxs("div", { style: { width: '100%', maxWidth: 1260, margin: 'auto' }, children: [_jsx(Title, { style: { marginBottom: isSmall ? 24 : 40, textAlign: 'center', fontSize: isSmall ? 24 : 30, paddingLeft: 16, paddingRight: 16 }, children: title }), features && features.length > 0 && !isMobile && (_jsx("div", { style: { padding: !isMobile ? 16 : 0 }, children: _jsx(Row, { gutter: 24, children: features.map((feature, index) => (_jsx(Col, { span: 8, children: _jsx(FeatureCard, Object.assign({}, feature), index) }, `landing-feature-${index}`))) }) })), features && features.length > 0 && isMobile && (_jsx("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: isDark ? '#141414' : 'white',
                            margin: !isMobile ? 16 : 0,
                            borderRadius: 4,
                        }, children: features.map((feature, index) => (_jsx(FeatureCard, Object.assign({ mode: 'vertical' }, feature), index))) }))] })] }));
};
