import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Progress } from 'antd';
import { Colors } from '@util/color/Colors';
import SVG from '@components/assets/SVG';
import { DeleteOutlined } from '@ant-design/icons';
import ImagePlayButton from '@bundle/assets/media/video/icon-play-round-white.svg';
import useBreakpoints from '@hooks/useBreakpoints';
import { FileComponent } from './FileComponent';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SocketContext } from 'src/SocketComponent';
import strings from '@resources/localization';
const ListItem = (props) => {
    var _a, _b, _c, _d, _e;
    const { file, onClose, items, index, maxFiles, filetype, isEditing, fileStyle, showAdd, uploadArea, cancelUpload, iconFitMode = 'topright', uploadType = 'sync', } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [progress, setProgress] = useState({});
    const [complete, setComplete] = useState({});
    const gallery = {
        [1]: {
            [0]: '1 / 7',
        },
        [2]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
        },
        [3]: {
            [0]: '1 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
            [2]: '1 / 4',
            [3]: '4 / 7',
        },
        [5]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
            [2]: '1 / 3',
            [3]: '3 / 5',
            [4]: '5 / 7',
        },
    };
    const galleryAdd = {
        [1]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
        },
        [2]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 7',
        },
        [3]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 3',
            [2]: '3 / 5',
            [3]: '5 / 7',
        },
    };
    const socket = useContext(SocketContext);
    useEffect(() => {
        var _a, _b, _c;
        const lastMessage = socket.lastJsonMessage;
        if (((_a = lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.channel) === null || _a === void 0 ? void 0 : _a.id) == 'asset.convert') {
            const message = socket.lastJsonMessage;
            if ((_b = message === null || message === void 0 ? void 0 : message.body) === null || _b === void 0 ? void 0 : _b.progress) {
                setProgress(Object.assign(Object.assign({}, progress), { [message.body.conversionPk]: message.body.progress }));
                if (((_c = message === null || message === void 0 ? void 0 : message.body) === null || _c === void 0 ? void 0 : _c.state) == 'complete') {
                    setComplete(Object.assign(Object.assign({}, complete), { [message.body.conversionPk]: true }));
                }
            }
        }
    }, [socket.lastJsonMessage]);
    useEffect(() => {
        var _a;
        if ((_a = file === null || file === void 0 ? void 0 : file.conversion) === null || _a === void 0 ? void 0 : _a.publicKey) {
            socket.sendJsonMessage({
                channel: 'asset.convert',
                body: {
                    conversionPk: file.conversion.publicKey,
                },
            });
        }
    }, [file]);
    const url = filetype == 'video' && !file.preview ? window.URL.createObjectURL(file.file) : filetype == 'video' && file.preview ? file.preview : undefined;
    const uploadSuccess = uploadType == 'async' && file.status == 'success' && ((_a = file.conversion) === null || _a === void 0 ? void 0 : _a.publicKey) && complete[(_b = file.conversion) === null || _b === void 0 ? void 0 : _b.publicKey];
    return (_jsxs("div", { style: Object.assign(Object.assign({ position: 'relative' }, (items && {
            gridColumn: filetype == 'attachment'
                ? '1 / 7'
                : showAdd
                    ? galleryAdd[items && items.length > maxFiles ? maxFiles : items && items.length][index]
                    : gallery[items && items.length > maxFiles ? maxFiles : items && items.length][index],
        })), (items &&
            items.length == 1 &&
            !showAdd && {
            display: filetype != 'image' ? 'block' : 'flex',
            alignItems: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) == 'circle' ? 'center' : 'inherit',
            width: (fileStyle === null || fileStyle === void 0 ? void 0 : fileStyle.width) || '100%',
            justifyContent: 'center',
        })), children: [filetype != 'attachment' &&
                !isEditing &&
                (!file.status || file.status == 'success') &&
                (!file.conversion || complete[(_c = file.conversion) === null || _c === void 0 ? void 0 : _c.publicKey]) && (_jsxs(_Fragment, { children: [_jsx("div", { style: Object.assign({ width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', zIndex: 1, background: 'rgba(0,0,0,0.4)' }, fileStyle) }), _jsx("div", { style: Object.assign(Object.assign({ position: 'absolute' }, ((uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) == 'circle' && { marginRight: 'auto', marginLeft: 'auto' })), ((uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && { right: 8, top: 8, display: 'flex', alignItems: 'center' })), children: _jsx(Button, { onClick: () => onClose(file), shape: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !isMobile ? 'default' : 'circle', icon: _jsx(DeleteOutlined, { style: { display: 'flex', justifyContent: 'center' } }), style: Object.assign({ zIndex: 2, background: Colors('OPTION_POLL_BACKGROUND') }, ((uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !isMobile && { display: 'flex', alignItems: 'center' })), children: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !isMobile && strings.general.delete }) })] })), filetype == 'image' && (items.length > 1 || showAdd) && (_jsxs("div", { style: { position: 'relative' }, children: [file.status == 'uploading' && (_jsx("div", { style: {
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0,0,0,0.6)',
                        }, children: _jsx(Progress, { className: "turingo", type: "circle", percent: file.percent }) })), _jsx("img", { alt: "example", style: Object.assign(Object.assign({ width: '100%', objectPosition: 'center center' }, fileStyle), { height: (fileStyle === null || fileStyle === void 0 ? void 0 : fileStyle.height) || 256, objectFit: (fileStyle === null || fileStyle === void 0 ? void 0 : fileStyle.objectFit) || 'cover', borderRadius: 4 }), src: file.preview })] })), filetype == 'image' && items.length == 1 && !showAdd && (_jsxs(_Fragment, { children: [(uploadType == 'sync' || (file.status == 'success' && !uploadSuccess) || !file.file) && (_jsx("div", { style: { position: 'relative' }, children: _jsx("img", { style: Object.assign(Object.assign({ width: '100%', objectFit: 'cover', borderRadius: 4 }, fileStyle), { height: (fileStyle === null || fileStyle === void 0 ? void 0 : fileStyle.height) || '100%' }), src: file.preview }) })), uploadType == 'async' && file.status == 'uploading' && (_jsxs("div", { style: {
                            padding: 16,
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'var(--neutral-3)',
                            borderRadius: 4,
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 175,
                        }, children: [_jsxs(Paragraph, { style: { marginBottom: 16 }, children: [`${strings.general.processing} ${file.percent}%`, " "] }), _jsx(Progress, { showInfo: false, type: "line", percent: file.percent, strokeWidth: 6, success: { strokeColor: 'var(--ant-primary-color)' }, strokeColor: { from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' } }), _jsx(Button, { onClick: cancelUpload, children: strings.general.cancel })] }))] })), filetype == 'video' && (_jsxs(_Fragment, { children: [(uploadType == 'sync' || uploadSuccess) && (_jsxs("div", { children: [_jsx("div", { style: { width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', zIndex: 1, background: 'rgba(0,0,0,0.4)' } }), _jsx(SVG, { style: {
                                    width: '56px',
                                    height: '56px',
                                    top: 'calc(50% - 28px)',
                                    left: 'calc(50% - 28px)',
                                    position: 'absolute',
                                    zIndex: 1,
                                }, content: ImagePlayButton }), _jsx("div", { style: Object.assign({ position: 'relative' }, (!isMobile && { maxHeight: 572, overflow: 'hidden' })), children: _jsx("video", { style: {
                                        width: '100%',
                                        height: '100%',
                                        objectPosition: 'center center',
                                        borderRadius: 4,
                                    }, src: url }) })] })), uploadType == 'async' && file.status == 'uploading' && !uploadSuccess && (_jsx(_Fragment, { children: _jsx("div", { style: {
                                padding: 16,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                justifyContent: 'center',
                                backgroundColor: 'var(--neutral-3)',
                                borderRadius: 4,
                                alignItems: 'center',
                                height: 345,
                            }, children: _jsxs("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    alignItems: 'center',
                                }, children: [_jsxs(Paragraph, { style: { marginBottom: 16 }, children: [strings.general.uploading, " ", file.percent, "%"] }), _jsx(Progress, { showInfo: false, type: "line", percent: file.percent || 0, strokeWidth: 6, success: { strokeColor: 'var(--ant-primary-color)' }, strokeColor: { from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' } }), _jsx(Button, { onClick: cancelUpload, children: strings.general.cancel })] }) }) })), uploadType == 'async' && file.status == 'success' && !uploadSuccess && (_jsx(_Fragment, { children: _jsxs("div", { style: {
                                padding: 16,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                justifyContent: 'center',
                                backgroundColor: 'var(--neutral-3)',
                                borderRadius: 4,
                                alignItems: 'center',
                                height: 345,
                            }, children: [_jsxs("div", { style: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                        alignItems: 'center',
                                    }, children: [_jsxs(Paragraph, { style: { marginBottom: 16 }, children: [strings.general.processing, " ", progress[(_d = file.conversion) === null || _d === void 0 ? void 0 : _d.publicKey], "%"] }), _jsx(Progress, { showInfo: false, type: "line", percent: progress[(_e = file.conversion) === null || _e === void 0 ? void 0 : _e.publicKey] || 0, strokeWidth: 6, success: { strokeColor: 'var(--ant-primary-color)' }, strokeColor: { from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' } })] }), _jsx(Alert, { style: { width: '100%' }, showIcon: true, message: strings.general.videoProcessing })] }) }))] })), filetype == 'attachment' && _jsx(FileComponent, { isEditing: isEditing, file: file, onRemove: () => onClose(file) }, `attachment#${index}`)] }));
};
export { ListItem };
