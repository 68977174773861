import strings from '@resources/localization';
const LanguageMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            next(action);
            const state = store === null || store === void 0 ? void 0 : store.getState();
            strings.setLanguage(state.ui.language);
            return;
        }
    }
    return next(action);
};
export { LanguageMiddleware };
