import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import { isEntityLanding } from '@util/router/ReverseRouter';
import { Anchor } from 'antd';
import React, { useRef } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Colors } from '@util/color/Colors';
const { Link } = Anchor;
export const EntityAnchor = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const isClassroomLanding = isEntityLanding('courses');
    const handleClick = (e, link) => {
        e.preventDefault();
    };
    const certRef = useRef();
    return (_jsxs(Anchor, { onClick: handleClick, style: { background: Colors('BACKGROUND'), boxShadow: isDark ? '0px 1px 0px #595959' : '0px 2px 8px #F0F1F2' }, offsetTop: 64, targetOffset: 180, children: [_jsx(Link, { href: "#description", title: strings.general.description }), _jsx(Link, { href: "#details", title: strings.general.detail }), _jsx(Link, { href: "#mentors", title: isClassroomLanding ? strings.general.mentors : strings.general.participants }), _jsx(Link, { href: "#lessons", title: isClassroomLanding ? strings.screens.boards.course.post.title.plural : strings.screens.boards.forum.post.title.plural }), isClassroomLanding && _jsx(Link, { href: "#audience", title: strings.general.audience }), isClassroomLanding && _jsx(Link, { href: "#abilities", title: strings.general.abilities }), isClassroomLanding && _jsx(Link, { href: "#certificate", title: strings.general.certificate.title.singular }), _jsx(Link, { href: "#reviews", title: strings.rates.title })] }));
};
