import { compile } from 'path-to-regexp';
import { keysOf } from '../object/keysOf';
const createReverseRouter = (source) => {
    const result = {};
    keysOf(source).forEach((key) => {
        const { route } = source[key];
        result[key] = compile(route);
    });
    return result;
};
const isEntityLanding = (kind) => {
    const path = window.location.pathname.split('/');
    return path.length == 3 && path[1] == kind;
};
const isPlayer = (kind) => {
    const path = window.location.pathname.split('/');
    return path.length == 4 && path[1] == kind;
};
export { createReverseRouter, isEntityLanding, isPlayer };
