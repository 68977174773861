import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Divider, List, Skeleton } from 'antd';
import ClassNames from '@util/style/ClassNames';
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon';
import useBreakpoints from '@hooks/useBreakpoints';
export function TuringoList(props) {
    const { items, onClick, onClickValueName = 'publicKey', valueName = 'name', loading = false, exceptValues = [], itemRender, emptyText, showArrow: showArrowOrFunction = true, itemKind: itemKindOrFunction = 'list', showDivider = true, } = props;
    const isMobile = useBreakpoints().isMobile;
    return (_jsxs(_Fragment, { children: [(!items || loading) && (_jsxs("div", { style: { padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }, children: [_jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null })] })), items && !loading && items.length == 0 && (_jsxs("div", { className: "turingo-text", style: { padding: isMobile ? '0 16px' : '0 24px', color: 'var(--secondary-text)' }, children: [emptyText ? emptyText : 'Sin items', ' '] })), items &&
                !loading &&
                items.map((i, index) => {
                    if (exceptValues.includes(i.item ? i.item[onClickValueName] : i[onClickValueName]))
                        return;
                    const showsArrow = typeof showArrowOrFunction === 'boolean' ? showArrowOrFunction : showArrowOrFunction(i.item);
                    const itemKind = typeof itemKindOrFunction === 'string' ? itemKindOrFunction : itemKindOrFunction(i.item);
                    return (_jsxs(_Fragment, { children: [itemKind == 'list' && (_jsx(List.Item, { onClick: () => onClick(i.item ? i.item[onClickValueName] : i[onClickValueName], i.item), className: ClassNames('turingo-list-item', 'hover-svg'), actions: showsArrow && [
                                    _jsx("span", { style: { marginRight: 16 }, children: _jsx(ArrowRightIcon, {}) }, "list-loadmore-edit"),
                                ], children: _jsxs("span", { style: { paddingLeft: isMobile ? 16 : 24, paddingRight: isMobile ? 16 : 24, maxWidth: 'calc(100% - 52px)', width: '100%' }, className: 'turingo-list-item-text', children: [i.icon && i.icon, !itemRender && (i.item ? i.item[valueName] : i[valueName]), itemRender && (i.item ? itemRender(i.item) : itemRender(i))] }, i.item ? i.item[onClickValueName] : i[onClickValueName]) }, i.item ? i.item[onClickValueName] : i[onClickValueName])), itemKind == 'button' && (_jsxs(Button, { type: "primary", block: true, disabled: true, style: { marginLeft: isMobile ? 16 : 24, marginRight: isMobile ? 16 : 24, maxWidth: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 48px)' }, children: [i.icon && i.icon, !itemRender && (i.item ? i.item[valueName] : i[valueName]), itemRender && (i.item ? itemRender(i.item) : itemRender(i))] }, i.item ? i.item[onClickValueName] : i[onClickValueName])), showDivider && items.length - 1 != index && (_jsx(Divider, { style: {
                                    marginTop: 8,
                                    marginBottom: 8,
                                    marginLeft: 16,
                                    marginRight: 16,
                                    width: 'calc(100% - 32px)',
                                    minWidth: 'calc(100% - 32px)',
                                } }))] }));
                })] }));
}
