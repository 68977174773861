import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactPlayer from '@lib/react-player';
import style from './PreviewPost.scss';
import { PreviewCard } from '../../components/preview_card/PreviewCard';
const PreviewPost = (props) => {
    var _a, _b;
    const { linkData } = props;
    if (linkData.type == 'video' || (linkData.type == 'video.other' && ((_a = linkData.video) === null || _a === void 0 ? void 0 : _a.url))) {
        return (_jsx("div", { className: style.playerWrapper, children: _jsx(ReactPlayer, { className: style.reactPlayer, controls: true, width: '100%', height: '100%', url: (_b = linkData.video) === null || _b === void 0 ? void 0 : _b.url }) }));
    }
    else {
        return _jsx(PreviewCard, { data: linkData, isClickeable: true });
    }
};
export { PreviewPost };
