const formatErrorDomain = (domain, subdomain) => `${domain}.${subdomain}`;
class ApplicationError extends Error {
    constructor(domain, subdomain, name, data, description, crash = false) {
        const id = formatErrorDomain(domain, subdomain);
        super(`${id} [Application Error]: ${name}` + (description ? `\n  ${description}` : ''));
        this.name = name;
        this._isa = 'turingo.application.error';
        this._id = id;
        this._description = description;
        this._catastrophic = crash;
    }
    get isa() {
        return this._isa;
    }
    get id() {
        return this._id;
    }
}
ApplicationError.ISA = 'turingo.application.error';
ApplicationError.Prefix = 'Application Error';
function declareError(domain, subdomain, name, description, crash = false) {
    const factory = (data) => new ApplicationError(domain, subdomain, name, data, description, crash);
    factory.isa = 'turingo.application.error.factory';
    factory.id = formatErrorDomain(domain, subdomain);
    return factory;
}
function isApplicationError(error) {
    return error.isa === ApplicationError.ISA;
}
function isApplicationErrorDeclarator(f) {
    return typeof f === 'function' && f.isa === 'turingo.application.error.factory';
}
function isErrorOf(instance, original) {
    return instance.id === original.id;
}
function isReduxErrorOf(error, original) {
    return error.message.startsWith(original.id);
}
export { ApplicationError as ApplicationError, declareError, isApplicationError, isErrorOf, isReduxErrorOf };
