import { communityMemberDetails, userDetails, userEdit } from '@state/actions';
import { createSlice } from '@reduxjs/toolkit';
const defaultState = {
    items: {},
    createState: {
        loading: false,
    },
    listState: {
        loading: false,
    },
};
const userSlice = createSlice({
    name: 'user',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: undefined, state: {
                    loading: true,
                } });
        });
        builder.addCase(userDetails.rejected, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: undefined, state: {
                    loading: false,
                    error: payload,
                } });
        });
        builder.addCase(userDetails.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: payload.data, state: {
                    loading: false,
                } });
        });
        builder.addCase(communityMemberDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: undefined, state: {
                    loading: true,
                } });
        });
        builder.addCase(communityMemberDetails.rejected, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: undefined, state: {
                    loading: false,
                    error: payload,
                } });
        });
        builder.addCase(communityMemberDetails.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.u_pk]), { item: payload.data, state: {
                    loading: false,
                } });
        });
        builder.addCase(userEdit.pending, (state, { meta }) => {
            var _a;
            if ((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.extraParams.u_pk]), { state: {
                        editing: true,
                        loading: false,
                    } });
            }
        });
        builder.addCase(userEdit.rejected, (state, { payload, meta }) => {
            var _a;
            if ((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = Object.assign(Object.assign({}, state.items[meta.arg.extraParams.u_pk]), { state: {
                        editing: false,
                        loading: false,
                        error: payload,
                    } });
            }
        });
        builder.addCase(userEdit.fulfilled, (state, { payload, meta }) => {
            var _a;
            if ((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = {
                    item: payload.data,
                    state: {
                        editing: false,
                        loading: false,
                    },
                };
            }
        });
    },
});
export { userSlice };
