import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '@hooks/useStore';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { Router } from '@Router';
import Style from './BoardCard.scss';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { WatchButton } from '@components/buttons/watch_button/WatchButton';
import { useContext } from 'react';
import { API } from '@api/API';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { BoardCardHeader } from './components/header/BoardCardHeader';
import { BoardCardBody } from './components/body/BoardCardBody';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
import { BoardContext } from '@screens/community/boards/media/MediaDetail';
export const BoardCard = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { item, withProgress, style, hideAction, showCertificate, isOnboarding = false } = props;
    const { cm_pk = 'turingo' } = useParams();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const isStory = item.kind == 'story' || (item.kind == 'forum' && (cm_pk == 'alena' || cm_pk == 'usm'));
    const progress = item.progress;
    const courseLastLesson = progress === null || progress === void 0 ? void 0 : progress.lessonLastPublicKey;
    const hasAccess = (_b = (_a = item === null || item === void 0 ? void 0 : item.access) === null || _a === void 0 ? void 0 : _a.posts) === null || _b === void 0 ? void 0 : _b.includes('view');
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const boardContext = useContext(BoardContext);
    const actionStyle = isMobile ? Style.btnContainerSimple : isStory ? Style.btnContainerSimple : Style.btnContainerDouble;
    const history = useHistory();
    const watchNow = () => {
        if (!courseLastLesson)
            return '';
        if (isOnboarding)
            return Router.Path.community_settings_post_detail({ cm_pk, section: 'onboarding', item_pk: item.publicKey, p_pk: courseLastLesson });
        return Router.Path[`${item.kind}_post_details`]({ cm_pk, b_pk: item.publicKey, p_pk: courseLastLesson });
    };
    const onPlay = () => {
        void API.communityBoardStart({ urlParams: { cm_pk, b_pk: item.publicKey } }).then((result) => {
            if (boardContext === null || boardContext === void 0 ? void 0 : boardContext.isOnboarding) {
                return history.push(Router.Path.community_settings_post_detail({
                    cm_pk: cm_pk,
                    section: 'onboarding',
                    item_pk: item.publicKey,
                    p_pk: result.data.currentContentPk,
                }));
            }
            else {
                history.push(Router.Path[`${item.kind}_post_details`]({ cm_pk: cm_pk, b_pk: item.publicKey, p_pk: result.data.currentContentPk }));
            }
        });
    };
    const showProgressBar = withProgress && (progress === null || progress === void 0 ? void 0 : progress.lessonOverallCount) > 0;
    const cardComponent = (_jsxs(Card, { onClick: isStory ? onPlay : undefined, style: Object.assign(Object.assign(Object.assign({}, style), (!style && { margin: 'auto' })), { borderRadius: 4, height: '100%', width: '100%' }), bodyStyle: { padding: 0, height: '100%', display: 'flex', flexDirection: 'column' }, hoverable: true, children: [_jsx(BoardCardHeader, { item: item, showProgressBar: showProgressBar }), _jsxs("div", { style: { padding: 16, display: 'flex', flexDirection: 'column', flexGrow: 1 }, children: [!showProgressBar && !isOnboarding && (_jsxs(TuringoTag, { kind: "tag", color: item.product ? 'purple' : 'cyan', style: { marginBottom: 8, width: 'min-content' }, children: [!item.product && ((_c = strings.screens.boards[item.kind].list) === null || _c === void 0 ? void 0 : _c.card.free), item.product && hasAccess && strings.general.purshased, item.product &&
                                !hasAccess &&
                                new Intl.NumberFormat('es-CL', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                }).format((_d = item.product) === null || _d === void 0 ? void 0 : _d.price), ' ', ((_e = item.product) === null || _e === void 0 ? void 0 : _e.priceOriginal) && !hasAccess && (_jsx("s", { style: { opacity: 0.8 }, children: new Intl.NumberFormat('es-CL', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                }).format((_f = item.product) === null || _f === void 0 ? void 0 : _f.priceOriginal) }))] })), _jsx(Paragraph, { style: { height: 60, margin: 0, fontSize: 20, fontWeight: 500, marginBottom: 16 }, ellipsis: { rows: 2 }, children: ((_g = item.course) === null || _g === void 0 ? void 0 : _g.title) || item.name }), _jsx(BoardCardBody, { item: item, isOnboarding: isOnboarding, showCertificate: showCertificate })] }), !hideAction && _jsx(Divider, { style: { margin: 0 } }), !hideAction && (_jsxs("div", { className: actionStyle, children: [!isStory && (_jsx(LinkButton, { className: isMobile ? Style.btnItemMobile : undefined, width: '100%', href: isOnboarding
                            ? Router.Path.community_settings_detail({ cm_pk, section: 'onboarding', item_pk: item.publicKey })
                            : Router.Path[`${item.kind}_details`]({ b_pk: item.publicKey, cm_pk }), children: strings.screens.boards[item.kind].list.card.details })), _jsx(WatchButton, { entity: item, position: "card" })] }))] }));
    return (_jsx(ConditionalWrap, { condition: !isStory, wrap: (children) => (_jsx(Link, { onClick: () => {
                void API.analyticsAction({ bodyParams: { action: 'btn.course', sender: props.from } });
            }, to: isLogged
                ? !isStory
                    ? isOnboarding
                        ? Router.Path.community_settings_detail({ cm_pk, section: 'onboarding', item_pk: item.publicKey })
                        : Router.Path[`${item.kind}_details`]({ b_pk: item.publicKey, cm_pk })
                    : watchNow()
                : isStory
                    ? { pathname: `/signup`, state: { b_pk: item.publicKey, cm_pk } }
                    : Router.Path[`${item.kind}_details`]({ b_pk: item.publicKey, cm_pk }), children: children })), children: cardComponent }));
};
