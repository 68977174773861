import { API } from '@api/API';
import { ReduxRequest } from '@api/requests/AsyncRequests';
import { createAction } from '@reduxjs/toolkit';
export const openAuthExpiredModal = createAction('auth/expired');
export const showErrorAction = createAction('error/active');
export const setErrorAction = createAction('error/set');
export const hideErrorAction = createAction('error/inactive');
export const authLogin = ReduxRequest('auth/login', API.authLogin);
export const authSignup = ReduxRequest('auth/singup', API.authSignup);
export const authSocial = ReduxRequest('/auth/oauth', API.authSocial);
export const authValidate = ReduxRequest('auth/validate', API.authValidate);
export const authValidateStatus = ReduxRequest('auth/validate/status', API.authValidateStatus);
export const authRevalidate = ReduxRequest('auth/resendValidationEmail', API.authRevalidate);
export const authLogout = createAction('auth/logout');
export const authAddAccount = ReduxRequest('auth/add', API.authAddAccount);
export const authRemoveAccount = ReduxRequest('auth/remove', API.authRemoveAccount);
export const authAccounts = ReduxRequest('auth/list', API.oAuthAccounts);
export const authAddEmail = ReduxRequest('auth/social_email', API.oAuthEmailAccounts);
export const userSelf = ReduxRequest('user/self', API.userSelf);
export const userDelete = ReduxRequest('user/delete', API.userDelete);
export const authRecover = ReduxRequest('auth/recover', API.authRecover);
export const authRecoverConfirm = ReduxRequest('auth/recoverConfirm', API.authRecoverConfirm);
export const authUpdateEmail = ReduxRequest('auth/email', API.authUpdateEmail);
export const authUpdatePassword = ReduxRequest('auth/password', API.authUpdatePassword);
export const countries = ReduxRequest('/countries', API.countries);
export const billingProvider = ReduxRequest('billing/provider', API.billingProvider);
export const billingCartCreate = ReduxRequest('billing/cart/create', API.billingCartCreate);
export const billingPaypalPaymentCreate = ReduxRequest('billing/paypal/start', API.billingCheckoutPaypalStart);
export const billingPaypalPaymentComplete = ReduxRequest('billing/paypal/complete', API.billingCheckoutPaypalConfirm);
export const billingDLocalPaymentComplete = ReduxRequest('billing/dlocal/complete', API.billingCheckoutDLocal);
export const billingDLocalGoPaymentStart = ReduxRequest('billing/dlocalgo/start', API.billingCheckoutDLocalGoStart);
export const billingDLocalGoPaymentConfirm = ReduxRequest('billing/dlocalgo/confirm', API.billingCheckoutDLocalGoConfirm);
export const billingDLocalGoPaymentCancel = ReduxRequest('billing/dlocalgo/cancel', API.billingCheckoutDLocalGoCancel);
export const billingRedeem = ReduxRequest('billing/redeem', API.billingLicenseRedeem);
export const help = ReduxRequest('help', API.help);
export const demo = ReduxRequest('demo', API.demo);
export const communityDetails = ReduxRequest('community/get', API.communityDetails);
export const communityMembers = ReduxRequest('community/members', API.communityMembers);
export const communityMemberDelete = ReduxRequest('community/member/delete', API.communityMemberDelete);
export const communityMemberTeamEdit = ReduxRequest('community/member/team/edit', API.communityMemberTeamEdit);
export const communityInvitationDelete = ReduxRequest('community/invitation/delete', API.communityInvitationDelete);
export const communityInvitationCreate = ReduxRequest('community/invitation/create', API.communityInvitationCreate);
export const communityInvitationEdit = ReduxRequest('community/invitation/edit', API.communityInvitationEdit);
export const communityInvitationLink = ReduxRequest('community/invitation/link', API.communityInvitationLink);
export const communityInvitationEmail = ReduxRequest('community/invitation/email', API.communityInvitationEmail);
export const communityInvitation = ReduxRequest('community/invitation/get', API.communityInvitation);
export const communityPosts = ReduxRequest('community/posts', API.communityPosts);
export const communityList = ReduxRequest('community/list', API.communityList);
export const communityTeams = ReduxRequest('community/teams', API.communityTeams);
export const communityTeamDetail = ReduxRequest('community/team/detail', API.communityTeamDetail);
export const communityTeamMembers = ReduxRequest('community/team/members', API.communityTeamMembers);
export const communityTeamMemberAdd = ReduxRequest('community/team/members/add', API.communityTeamMemberAdd);
export const communityTeamMemberDelete = ReduxRequest('community/team/members/delete', API.communityTeamMemberDelete);
export const communityMemberDetails = ReduxRequest('community/team/member', API.communityMemberDetails);
export const communityMemberSchema = ReduxRequest('community/team/schema', API.communityMemberSchema);
export const communityMemberSchemaUpdate = ReduxRequest('community/team/schema/update', API.communityMemberSchemaUpdate);
export const communityTeamCreate = ReduxRequest('community/team/create', API.communityTeamCreate);
export const communityTeamEdit = ReduxRequest('community/team/edit', API.communityTeamEdit);
export const communityExit = ReduxRequest('community/exit', API.communityExit);
export const communityPluginGet = ReduxRequest('community/plugin/get', API.communityPluginGet);
export const communityPluginConnect = ReduxRequest('community/plugin/connect', API.communityPluginConnect);
export const communityPluginDisconnect = ReduxRequest('community/plugin/disconnect', API.communityPluginDisconnect);
export const communityEventCreate = ReduxRequest('community/event/create', API.communityEventCreate);
export const communityEventUpdate = ReduxRequest('community/event/update', API.communityEventUpdate);
export const communityEventDelete = ReduxRequest('community/event/delete', API.communityEventDelete);
export const communityEventList = ReduxRequest('community/event/list', API.communityEventList);
export const communityEventDetails = ReduxRequest('community/event/detail', API.communityEventDetails);
export const communityEventJoin = ReduxRequest('community/event/join', API.communityEventJoin);
export const communityEventStart = ReduxRequest('community/event/start', API.communityEventStart);
export const communityEventAttendanceAccept = ReduxRequest('community/event/attendance/accept', API.communityEventAttendanceAccept);
export const communityEventAttendanceReject = ReduxRequest('community/event/attendance/reject', API.communityEventAttendanceReject);
export const communityEventAttendanceList = ReduxRequest('community/event/attendance/list', API.communityEventAttendanceList);
export const communityTeamDelete = ReduxRequest('community/team/delete', API.communityTeamDelete);
export const communityEdit = ReduxRequest('community/edit', API.communityEdit);
export const communityBoardCreate = ReduxRequest('community/board/create', API.communityBoardCreate);
export const communityBoardEdit = ReduxRequest('community/board/edit', API.communityBoardEdit);
export const communityBoardDelete = ReduxRequest('community/board/delete', API.communityBoardDelete);
export const communityBoardMigrate = ReduxRequest('community/board/migrate', API.communityBoardMigrate);
export const communityBoardIndex = ReduxRequest('community/boards', API.communityBoardIndex);
export const communityBoardDetail = ReduxRequest('community/board/detail', API.communityBoardDetails);
export const communityChatCreate = ReduxRequest('community/chat/create', API.communityChatCreate);
export const communityChatExists = ReduxRequest('community/chat/exists', API.communityChatExists);
export const communityChatAccept = ReduxRequest('community/chat/accept', API.communityChatAccept);
export const communityChatGet = ReduxRequest('community/chat/get', API.communityChatGet);
export const communityChatList = ReduxRequest('community/chat/list', API.communityChatList);
export const communityChatSilence = ReduxRequest('community/chat/silence', API.communityChatSilence);
export const communityChatMessages = ReduxRequest('community/chat/messages', API.communityChatMessages);
export const communityDirectory = ReduxRequest('community/directory', API.communityDirectory);
export const communityCertificateList = ReduxRequest('community/certificate/list', API.communityCertificateList);
export const communityCertificateCreate = ReduxRequest('community/certificate/create', API.communityCertificateCreate);
export const communityCertificateUpdate = ReduxRequest('community/certificate/update', API.communityCertificateUpdate);
export const communityCertificateDelete = ReduxRequest('community/certificate/delete', API.communityCertificateDelete);
export const communityBoardPropertyCreate = ReduxRequest('community/board/property/create', API.communityBoardPropertyCreate);
export const communityBoardPropertyEdit = ReduxRequest('community/board/property/edit', API.communityBoardPropertyEdit);
export const communityBoardPropertyOrder = ReduxRequest('community/board/property/order', API.communityBoardPropertyOrder);
export const communityBoardPropertyDelete = ReduxRequest('community/board/property/delete', API.communityBoardPropertyDelete);
export const communityBoardMentorCreate = ReduxRequest('community/board/mentor/create', API.communityBoardMentorCreate);
export const communityBoardMentorEdit = ReduxRequest('community/board/mentor/edit', API.communityBoardMentorEdit);
export const communityBoardMentorOrder = ReduxRequest('community/board/mentor/order', API.communityBoardMentorOrder);
export const communityBoardMentorDelete = ReduxRequest('community/board/mentor/delete', API.communityBoardMentorDelete);
export const boardList = ReduxRequest('board/list', API.boardList);
export const boardDiscussion = ReduxRequest('board/discussion', API.boardList);
export const boardMembers = ReduxRequest('board/members', API.boardMembers);
export const boardDetails = ReduxRequest('board/get', API.boardDetails);
export const boardOrder = ReduxRequest('board/order', API.boardOrder);
export const boardProgress = ReduxRequest('board/progress', API.boardProgress);
export const analyticsProgress = ReduxRequest('analytics/progress', API.analyticsProgress);
export const userDetails = ReduxRequest('user/get', API.userDetails);
export const userEdit = ReduxRequest('user/edit', API.userEdit);
export const citySearch = ReduxRequest('city/search', API.citySearch);
export const rateList = ReduxRequest('rates/list', API.postList);
export const rateCreate = ReduxRequest('rate/create', API.postCreate);
export const rateLessonCreate = ReduxRequest('rateLesson/create', API.commentCreate);
export const rateDelete = ReduxRequest('rate/delete', API.postDelete);
export const rateEdit = ReduxRequest('rate/edit', API.postEdit);
export const postEdit = ReduxRequest('post/edit', API.postEdit);
export const postCreate = ReduxRequest('post/create', API.postCreate);
export const postDelete = ReduxRequest('post/delete', API.postDelete);
export const postPin = ReduxRequest('post/pin', API.postPin);
export const postUnpin = ReduxRequest('post/unpin', API.postUnpin);
export const postOrder = ReduxRequest('post/order', API.postOrder);
export const postAttachmentCreate = ReduxRequest('post/attachment/create', API.postAttachmentCreate);
export const postAttachmentDelete = ReduxRequest('post/attachment/delete', API.postAttachmentDelete);
export const postLinkCreate = ReduxRequest('post/link/create', API.postLinkCreate);
export const postLinkDelete = ReduxRequest('post/link/delete', API.postLinkDelete);
export const postLinkEdit = ReduxRequest('post/link/edit', API.postLinkEdit);
export const quizCreate = ReduxRequest('quiz/create', API.quizCreate);
export const quizEdit = ReduxRequest('quiz/edit', API.quizEdit);
export const answerCreate = ReduxRequest('answers/create', API.answerCreate);
export const answerDetails = ReduxRequest('answers/details', API.answerDetails);
export const postDetails = ReduxRequest('post/details', API.postDetails);
export const postRefresh = ReduxRequest('post/refresh', API.postDetails);
export const postAdminDetails = ReduxRequest('post/details/admin', API.postAdminDetails);
export const postList = ReduxRequest('posts/list', API.postList);
export const postListMore = ReduxRequest('post/more', API.postList);
export const postReaction = ReduxRequest('post/reaction', API.postReaction);
export const postManyReaction = ReduxRequest('post/manyreaction', API.postManyReaction);
export const postVote = ReduxRequest('post/vote', API.postReaction);
export const postReactionDelete = ReduxRequest('post/reactionDelete', API.postReactionDelete);
export const postReactionList = ReduxRequest('post/reactionList', API.postReactionList);
export const userMentions = ReduxRequest('user/mentions', API.userMentions);
export const postReport = ReduxRequest('post/report', API.postReport);
export const chatReport = ReduxRequest('chat/report', API.chatReport);
export const chatMessageReport = ReduxRequest('chat/message/report', API.chatMessageReport);
export const nextLesson = ReduxRequest('post/next', API.nextLesson);
export const completeLesson = ReduxRequest('post/complete', API.completeLesson);
export const commentCreate = ReduxRequest('comment/create', API.commentCreate);
export const commentDelete = ReduxRequest('comment/delete', API.commentDelete);
export const commentEdit = ReduxRequest('comment/edit', API.commentEdit);
export const commentList = ReduxRequest('comment/list', API.commentList);
export const commentSection = ReduxRequest('comment/section', API.commentList);
export const replyCreate = ReduxRequest('reply/create', API.replyCreate);
export const replyList = ReduxRequest('reply/section', API.replyList);
export const getLastNotifications = ReduxRequest('/notification', API.getLastNotifications);
export const markNotificationsAsSeen = ReduxRequest('/notification/mark/seen', API.markNotificationsAsSeen);
export const markNotificationAsOpened = ReduxRequest('/notification/mark/opened', API.markNotificationAsOpened);
export const cartDetails = ReduxRequest('/billing/cart', API.billingCartGet);
export const debugEventEmail = ReduxRequest('/debug/event', API.debugEventEmail);
export const debugChatEmail = ReduxRequest('/debug/chat', API.debugChatEmail);
export const debugDigestEmail = ReduxRequest('/debug/digest', API.debugDigestEmail);
