import { instanceOfLogin } from '@api/model/responses/Account/Account';
import { createSlice } from '@reduxjs/toolkit';
import { authSocial } from '@state/actions';
const defaultState = {
    password_create: false,
    password: false,
    email: false,
    chat: false,
    email_create: false,
    delete_account: false,
    share: {
        active: false,
    },
    loader: { status: false, error: null },
    logout: false,
    conflict_modal: false,
    conflict_accounts: {
        userEmail: undefined,
        token: '',
    },
    see_more: false,
};
const modalSlice = createSlice({
    name: 'modal',
    initialState: defaultState,
    reducers: {
        modalAction: (state, action) => {
            if (action.payload.modal == 'share') {
                state[action.payload.modal] = {
                    active: action.payload.active,
                    link: action.payload.link,
                    kind: action.payload.kind,
                    e_kind: action.payload.e_kind,
                    referenceCommunity: action.payload.referenceCommunity,
                    referenceBoard: action.payload.referenceBoard,
                    referencePost: action.payload.referencePost,
                    referenceUser: action.payload.referenceUser,
                };
            }
            else {
                state[action.payload.modal] = action.payload.state;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authSocial.pending, (state) => {
            state.loader.status = true;
        });
        builder.addCase(authSocial.rejected, (state, action) => {
            state.loader.error = action.payload;
            state.loader.status = false;
        });
        builder.addCase(authSocial.fulfilled, (state, action) => {
            state.loader.status = false;
            if (!instanceOfLogin(action.payload.data)) {
                state.conflict_modal = true;
                state.conflict_accounts.token = action.payload.data.token;
                state.conflict_accounts.userEmail = action.payload.data.userEmail;
            }
        });
    },
});
export const { modalAction } = modalSlice.actions;
export { modalSlice };
