import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Card, Col } from 'antd';
import React from 'react';
import style from './FeaturesCard.scss';
export const FeaturesCard = (props) => {
    const { title, items, id } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsx(Col, { span: 24, children: _jsx(Card, { id: id, title: title, bordered: false, children: items === null || items === void 0 ? void 0 : items.map((item, index) => {
                if (item)
                    return (_jsxs("div", { className: style.libox, children: [" ", _jsxs("div", { className: style.text, style: { fontSize: isMobile ? 16 : 14 }, children: [" ", item] })] }, index));
            }) }) }));
};
