import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { CatalogueItem1 } from '@components/icons/CatalogueItem1';
import { CatalogueItem2 } from '@components/icons/CatalogueItem2';
import { CatalogueItem3 } from '@components/icons/CatalogueItem3';
import { CatalogueItem4 } from '@components/icons/CatalogueItem4';
import { CatalogueItem5 } from '@components/icons/CatalogueItem5';
import { FormContext } from 'antd/es/form/context';
import { Form } from 'antd';
export const CatalogueIcons = {
    catalog_icon_1: CatalogueItem1,
    catalog_icon_2: CatalogueItem2,
    catalog_icon_3: CatalogueItem3,
    catalog_icon_4: CatalogueItem4,
    catalog_icon_5: CatalogueItem5,
};
const IconSelector = (props) => {
    const { name } = props;
    const { form } = useContext(FormContext);
    const icon = Form.useWatch(name, form);
    const selectIcon = (value) => {
        form.setFieldValue(name, value);
    };
    const icons = [
        { icon: CatalogueItem1, value: 'catalog_icon_1' },
        { icon: CatalogueItem2, value: 'catalog_icon_2' },
        { icon: CatalogueItem3, value: 'catalog_icon_3' },
        { icon: CatalogueItem4, value: 'catalog_icon_4' },
        { icon: CatalogueItem5, value: 'catalog_icon_5' },
    ];
    return (_jsx("div", { style: { display: 'flex' }, children: icons.map((item, index) => {
            return (_jsx("div", { style: { position: 'relative' }, children: _jsx("div", { onClick: () => selectIcon(item.value), style: {
                        padding: 8,
                        borderRadius: 4,
                        marginRight: 16,
                        cursor: 'pointer',
                        color: icon == item.value ? 'var(--ant-primary-color)' : 'var(--text)',
                        border: icon == item.value ? '1px solid var(--ant-primary-color)' : '1px solid var(--text)',
                    }, children: _jsx(item.icon, { filled: icon == item.value, style: { fontSize: 24, height: 24, width: 24, display: 'flex' } }) }) }, index));
        }) }));
};
export { IconSelector };
