import { ErrorAuthTokenExpired } from '@api/core/Client';
import { isReduxErrorOf } from '@typings/error/Error';
import { authLogout } from '@state/actions/index';
import { API } from '@api/API';
const AuthenticationMiddleware = (store) => (next) => (action) => {
    if (action.type.endsWith('/rejected')) {
        const failure = action;
        if (isReduxErrorOf(failure.error, ErrorAuthTokenExpired)) {
            store.dispatch(authLogout({ reason: 'auto.expiration' }));
            void API.analyticsAction({ bodyParams: { action: 'account.logout', sender: 'system' } });
        }
    }
    return next(action);
};
export { AuthenticationMiddleware };
