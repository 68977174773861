import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert, Button, Form } from 'antd';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
import { FormPasswordItem } from '@components/form/FormPasswordItem/FormPasswordItem';
import strings from '@resources/localization';
import { authSignup } from '@state/actions';
import { cleanAuth } from '@state/reducers/Auth/AuthReducer';
import { trim } from '@util/string/Functions';
export const setErrorType = (kindError) => {
    switch (kindError) {
        case 'routing.arguments': {
            return strings.screens.auth.signup.error.missingParams;
        }
        case 'application.account.email.used': {
            return strings.screens.auth.signup.error.email;
        }
        case 'application.many.requests': {
            return 'Demasiadas peticiones, intente mas tarde';
        }
        default: {
            return strings.screens.auth.signup.error.fetch;
        }
    }
};
const SignupForm = (props) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [form] = Form.useForm();
    const onFinish = (values) => {
        var _a, _b, _c;
        void dispatch(authSignup({
            bodyParams: Object.assign(Object.assign({ email: values.email, password: values.password, name: trim(values.name) }, (((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.b_pk) && { action: [{ kind: 'follow', entityPk: location.state.b_pk }] })), (((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.product) && { action: [{ kind: 'redeem', license: (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.product.license }] })),
            options: {
                then: {
                    action: props.then,
                },
            },
        }));
    };
    const isLoading = useAppSelector((state) => state.auth.loading);
    const error = useAppSelector((state) => state.auth.error);
    const kindError = useAppSelector((state) => { var _a; return (_a = state.auth.errorKind) === null || _a === void 0 ? void 0 : _a.error; });
    useEffect(() => {
        dispatch(cleanAuth());
    }, []);
    return (_jsxs(Form, { form: form, layout: 'vertical', name: "normal_login", className: "login-form", onFinish: onFinish, children: [_jsx(FormEmailItem, { name: 'email', initialValue: props.email, label: strings.forms.email.signup_label, validations: ['format', 'required'], placeholder: strings.forms.email.placeholder }), _jsx(FormPasswordItem, { name: 'password', label: strings.forms.password.signup_label, validations: ['required'] }), error && _jsx(Alert, { showIcon: true, style: { marginBottom: 10 }, message: setErrorType(kindError), type: "error", closable: true }), _jsxs("div", { style: { fontSize: 12, textAlign: 'center' }, children: [_jsx("span", { style: { marginRight: 3, fontSize: 12 }, children: strings.screens.auth.signup.policies.previousMessage }), _jsx(Link, { style: { fontSize: 12, marginRight: 3 }, to: '/privacy', children: strings.screens.auth.signup.policies.privacy }), _jsx("span", { style: { marginRight: 3, fontSize: 12 }, children: strings.screens.auth.signup.policies.and }), _jsx(Link, { style: { marginRight: 3, fontSize: 12 }, to: '/terms', children: strings.screens.auth.signup.policies.conditions })] }), _jsx(Form.Item, { style: { textAlign: 'center', marginTop: 20 }, children: _jsx(Button, { block: true, loading: isLoading, type: "primary", htmlType: "submit", children: strings.screens.auth.signup.action }) })] }));
};
export { SignupForm };
