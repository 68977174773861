import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { CreatePostContext } from '../../PostCreateProvider';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { Form } from 'antd';
import { FormSelect } from '@components/form/FormSelectValue/FormSelectModalItem';
const TopicSelectionScreen = () => {
    const { boards, form } = useContext(CreatePostContext);
    const board = form.getFieldValue(['form', 'turingo', 'board']);
    const topics = Form.useWatch(['form', 'turingo', 'topics'], form);
    const board_item = boards.find((value) => value.publicKey == board);
    return (_jsxs("div", { children: [_jsx(Paragraph, { style: { marginBottom: 16 }, className: "turingo-text", children: strings.screens.boards.discussion.post.topic.description }), _jsx(FormSelect, { defaultValue: topics, items: board_item === null || board_item === void 0 ? void 0 : board_item.topics, valueField: "publicKey", nameField: "name", maxSelection: 10, afterAction: (selection) => {
                    form.setFieldValue(['form', 'turingo', 'topics'], selection);
                } })] }));
};
export { TopicSelectionScreen };
