import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SVG from '@components/assets/SVG';
import { useAppSelector } from '@hooks/useStore';
import FeatureStyle from './FeatureCard.scss';
import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import useBreakpoints from '@hooks/useBreakpoints';
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton';
export const FeatureCard = (props) => {
    const { svg, title, description, Icon, kind, mode = 'horizontal', style, action } = props;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall || breakpoints.md;
    const isMobile = breakpoints.xxs;
    if (mode === 'horizontal') {
        return (_jsxs(Card, { bordered: false, style: Object.assign({ height: '100%', borderRadius: 4 }, style), bodyStyle: { padding: 16 }, children: [_jsxs("div", { children: [svg && _jsx(SVG, { content: svg, className: FeatureStyle.icon }), kind == 'iconpark' && _jsx(Icon, { size: isSmall ? '42' : isMobile ? '28' : '56', fill: 'var(--cyan-6)' }), kind == 'antd' && _jsx(Icon, { style: { fontSize: isSmall ? 42 : isMobile ? 28 : 56, color: 'var(--cyan-6)' } }), _jsx("p", { className: FeatureStyle.title, style: { marginTop: kind == 'iconpark' || kind == 'svg' ? 12 : 20, fontSize: isMobile ? 18 : 16 }, children: title }), _jsx("p", { style: { marginBottom: 0, fontSize: isMobile ? 16 : 14, lineHeight: '22px' }, className: FeatureStyle.subtitle, children: description })] }), action && action.href && action.title && (_jsxs(ExpandableButton, { type: "primary", href: action.href, style: { marginTop: 16 }, children: [action.title, ' '] }))] }));
    }
    else {
        return (_jsxs("div", { style: { display: 'flex', flexDirection: 'row', padding: 16, alignItems: 'center' }, children: [_jsxs("div", { style: { display: 'flex', marginRight: 16 }, children: [svg && _jsx(SVG, { content: svg, className: FeatureStyle.icon }), kind == 'iconpark' && _jsx(Icon, { size: isSmall ? '42' : isMobile ? '28' : '56', fill: isDark ? '#58D1C9' : '#13C2C2' }), kind == 'antd' && _jsx(Icon, { style: { fontSize: isSmall ? 42 : isMobile ? 28 : 56, color: isDark ? '#58D1C9' : '#13C2C2' } })] }), _jsxs("div", { style: { overflow: 'hidden', alignSelf: 'center', textAlign: 'start' }, children: [_jsx(Title, { style: { marginBottom: 8, fontSize: isMobile ? 18 : 16, }, children: title }), _jsx("p", { style: { marginBottom: 0, fontSize: isMobile ? 16 : 14, lineHeight: '22px' }, children: description })] })] }));
    }
};
