import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { AvatarTuringo } from '@components/avatar/Avatar';
import useBreakpoints from '@hooks/useBreakpoints';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { CreatePostContext } from '../../../../PostCreateProvider';
import { DownOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { CrossPostingContext } from '../../CreationScreen';
import { Down } from '@icon-park/react';
import style from './Header.scss';
import { MaskSelectorModal } from '@components/content/masks/MaskSelectorModal';
import { useAppSelector } from '@hooks/useStore';
const PostCreationHeader = (props) => {
    var _a, _b;
    const breakpoints = useBreakpoints();
    const { consumer } = useContext(CrossPostingContext);
    const { mask } = useContext(CreatePostContext);
    const [showMasks, setShowMasks] = useState(false);
    const { boards, setScreen, form, editable } = useContext(CreatePostContext);
    const { cm_pk, b_pk } = useParams();
    const board_selected = form.getFieldValue(['form', 'turingo', 'board']);
    const board_item = boards.find((value) => value.publicKey == board_selected);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    const isAdmin = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.includes('edit');
    return (_jsxs("div", { style: Object.assign({ display: 'flex' }, props.style), children: [showMasks && (_jsx(MaskSelectorModal, { kind: "post", currentMask: mask.get, setCurrentMask: mask.set, open: showMasks, onCancel: () => setShowMasks(false) })), _jsxs("div", { style: { position: 'relative', marginRight: 8 }, children: [_jsx(AvatarTuringo, { size: 24, style: {
                            height: 48,
                            width: 48,
                            lineHeight: '48px',
                            marginRight: 8,
                            marginBottom: 4,
                        }, name: mask.get.name, avatar: mask.get.avatarUrl }), !editable && isAdmin && (_jsx("div", { className: style.mask_button, onClick: () => setShowMasks(true), children: _jsx(Down, { style: { display: 'flex' }, theme: "outline", size: "16", strokeLinejoin: "bevel" }) }))] }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }, children: [_jsx(Paragraph, { strong: true, className: 'turingo-text', style: {
                            lineHeight: '18px',
                            wordBreak: 'break-word',
                        }, ellipsis: { rows: 1 }, children: mask.get.name }), consumer == 'turingo' && boards.length > 1 && !editable && !b_pk && (_jsx(Button, { style: {
                            fontSize: breakpoints.isMobile ? 14 : 12,
                            height: 24,
                            lineHeight: '12px',
                            padding: '1px 8px 1px 0px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            borderRadius: 4,
                            alignItems: 'center',
                        }, onClick: () => setScreen('board_selection'), icon: _jsx(DownOutlined, { style: {
                                marginLeft: 4,
                                fontSize: 10,
                                display: 'flex',
                            } }), children: (board_item === null || board_item === void 0 ? void 0 : board_item.name) || strings.screens.community.publicIn })), consumer == 'turingo' && (boards.length == 1 || editable || b_pk) && (_jsx("div", { style: {
                            fontSize: 14,
                        }, children: `${strings.screens.community.publicIn} ${board_item === null || board_item === void 0 ? void 0 : board_item.name}` }))] })] }));
};
export { PostCreationHeader };
