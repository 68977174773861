import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { setCurrentLanguage } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { FooterSocialIcons } from './FooterSocialIcons';
import style from './Footer.scss';
import { SwitchTheme } from '@components/content/switch_theme/SwitchTheme';
import { Radio } from 'antd';
import { API } from '@api/API';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
export const FooterBar = (props) => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const theme = useAppSelector((state) => state.ui.theme);
    const language_selected = useAppSelector((state) => state.ui.language);
    const setLanguage = (language) => {
        strings.setLanguage(language);
        dispatch(setCurrentLanguage(language));
    };
    const optionsWithDisabled = [
        { label: strings.screens.settings.sections.language.options.es, value: 'es' },
        { label: strings.screens.settings.sections.language.options.en, value: 'en' },
    ];
    const onChange = (e) => {
        setLanguage(e.target.value);
    };
    return (_jsx("div", { style: { padding: 0, zIndex: 10, width: '100%', justifyContent: 'center', backgroundColor: 'var(--background)' }, children: _jsxs("div", { className: style.footer, children: [_jsxs("div", { className: style.first, children: [!isMobile && (_jsx("div", { style: { width: props.error ? 175 : 200 }, children: _jsx(FooterSocialIcons, { error: props.error }) })), _jsx("div", { style: { marginBottom: isMobile ? 16 : 0 }, children: _jsx(SwitchTheme, {}) })] }), _jsxs("div", { className: style.second, children: [isMobile && (_jsx("div", { style: { width: 200 }, children: _jsx(FooterSocialIcons, { error: props.error }) })), _jsxs("div", { className: style.links, children: [!isMobile && _jsxs("p", { style: { marginRight: 16, marginBottom: 0 }, children: ["TURINGO\u00AE ", new Date().getFullYear().toString()] }), !props.error && (_jsx(LinkButton, { type: 'text', href: '/aboutUs', style: { marginRight: isMobile ? 0 : 16, marginBottom: !isMobile ? 0 : 16 }, children: strings.navigation.usermenu.options.aboutus })), !props.error && (_jsx(LinkButton, { type: 'text', href: '/help', style: { marginRight: isMobile ? 0 : 16, marginBottom: !isMobile ? 0 : 16 }, onClick: () => {
                                        void API.analyticsAction({ bodyParams: { action: 'support.faq', sender: 'main.nav.footer' } });
                                    }, children: strings.navigation.usermenu.options.faq })), _jsx(LinkButton, { type: 'text', externalHref: 'https://blog.turingo.com/', target: '_blank', style: { marginRight: isMobile ? 0 : 16, marginBottom: !isMobile ? 0 : 16 }, rel: "noreferrer", children: strings.navigation.usermenu.options.blog }), !props.error && (_jsx(LinkButton, { type: 'text', href: '/terms', style: { marginRight: isMobile ? 0 : 16, marginBottom: !isMobile ? 0 : 16 }, children: strings.navigation.usermenu.options.terms })), !props.error && (_jsx(LinkButton, { type: 'text', href: '/privacy', style: { marginRight: isMobile ? 0 : 16, marginBottom: !isMobile ? 0 : 16 }, children: strings.navigation.usermenu.options.privacy })), isMobile && _jsxs("p", { style: { marginTop: 8, marginBottom: 16 }, children: ["TURINGO\u00AE ", new Date().getFullYear().toString()] })] }), _jsx(Radio.Group, { style: { marginBottom: isMobile ? 32 : 0 }, onChange: onChange, value: language_selected, optionType: "button", children: optionsWithDisabled.map((language) => (_jsx(Radio.Button, { value: language.value, children: language.label }, language.value))) })] })] }) }));
};
