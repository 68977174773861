var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function trim(s) {
    const newValue = (s || '').replace(/\s+/g, ' ');
    return newValue.replace(/^\s+|\s+$/g, '');
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const validateEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(String(email).toLowerCase());
};
export const getFirstURL = (input) => {
    const scheme = '(?:https)';
    const hostname = '(\\:\\/\\/(?:www.|[a-zA-ZÀ-ž.]+)[a-zA-ZÀ-ž0-9\\-\\.]+\\.(?:[\\w]{1,}))?';
    const port = '(:[0-9][0-9]{0,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])?';
    const path = '([a-zA-ZÀ-ž0-9\\-_\\.\\/\\+]+)?';
    const query = '(?:\\?$|[^\\s"]*)?';
    const hash = '(?:#[^\\s"]*)?';
    const regex = new RegExp(`(${scheme}${hostname}${port}${path}${query}${hash})`, 'gim');
    const out = regex.exec(input);
    if (out) {
        return out[0];
    }
    else {
        return undefined;
    }
};
export function formatMB(numMB) {
    if (numMB < 1000) {
        return numMB.toString() + ' MB';
    }
    else {
        return (numMB / 1000).toFixed(2) + ' GB';
    }
}
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export function insert(text, replacements) {
    if (!replacements || replacements.length <= 0 || text.length <= 0)
        return text;
    replacements.sort((a, b) => a.offset - b.offset);
    let copy = '';
    let pivot = 0;
    for (const replacement of replacements) {
        const afterText = text.substring(replacement.offset, replacement.offset + replacement.length);
        copy += text.substring(pivot, replacement.offset);
        if (replacement.text)
            copy += `{${replacement.text}@${afterText}}`;
        pivot = replacement.offset + (replacement.length || 0);
    }
    if (pivot <= text.length) {
        copy += text.substring(pivot, undefined);
    }
    return copy;
}
export function insertMention(text, replacements, lastReplacement) {
    if (!replacements || replacements.length <= 0 || text.length <= 0)
        return text;
    replacements.sort((a, b) => a.offset - b.offset);
    let copy = '';
    let pivot = 0;
    for (const replacement of replacements) {
        copy += text.substring(pivot, replacement.offset);
        if (replacement.kind == 'link' && replacement.text)
            copy += `<span id='${replacement.kind}' data-id='${replacement.id}'>${replacement.text}</span>`;
        if (replacement.kind == 'mention' && replacement.text)
            copy += `<span id='${replacement.kind}' data-id='${replacement.id}'>${replacement.text}</span>${(lastReplacement === null || lastReplacement === void 0 ? void 0 : lastReplacement.id) == replacement.id ? ' ' : ''}`;
        pivot = replacement.offset + (replacement.length || 0);
    }
    if (pivot <= text.length) {
        copy += text.substring(pivot, undefined);
    }
    return copy;
}
const intoPairs = (xs) => xs.slice(1).map((x, i) => [xs[i], x]);
const breakAt = (places, str) => intoPairs([0, ...places, str.length]).map(([a, b]) => str.substring(a, b));
const breakWhere = (words, str) => breakAt(words.reduce((a, { offset, length }) => [...a, offset, offset + length], []), str);
export const createNodes = (links, str) => {
    const sortedLinks = links.slice(0).sort(({ offset: o1 }, { offset: o2 }) => o1 - o2);
    return breakWhere(sortedLinks, str)
        .map((s, i) => (i % 2 == 0 ? { data: s, type: 'text' } : { data: s, type: 'mention', publicKey: sortedLinks[(i - 1) / 2].publicKey }))
        .filter(({ data }) => data.length > 0);
};
function reverseString(str) {
    return str.split('').reverse().join('');
}
export function getCurrentLine(input, cursorPosition) {
    return input.substring(0, cursorPosition).split('\n').length;
}
export function getLastLink(input, cursorPosition, debug) {
    if (cursorPosition === undefined || cursorPosition < 0)
        return { link: undefined, range: undefined };
    const regex = /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_\+.~#?&//=]*?)(?!.*[\r\n]*.*\1)(?!\n)(?!\s)$/gi;
    const prefix = input.substring(0, cursorPosition);
    const linkRegex = regex.exec(prefix);
    if (linkRegex) {
        const link = linkRegex[0];
        const start = regex.lastIndex - linkRegex[0].length;
        const end = regex.lastIndex - 1 + linkRegex[0].length;
        return { link, range: [start, end] };
    }
    return { link: undefined, range: undefined };
}
export function getLastMention(input, lastMentionCursor, cursorPosition, debug) {
    if (cursorPosition === undefined || cursorPosition < 0)
        return { word: undefined, range: undefined, valid: false };
    const regex = /^[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ ]+[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]@/;
    const newRegex = /(\B@([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{2,15}(\s?))([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{1,15}(\s?)){1,2}\b)(?!.*[\r\n]*.*\1)(?!\n)/;
    const prefix = input.substring(lastMentionCursor, cursorPosition);
    const mentions = newRegex.exec(prefix);
    if (mentions) {
        const word = mentions[0];
        const start = newRegex.lastIndex - mentions[0].length + prefix.length + lastMentionCursor;
        const end = newRegex.lastIndex - 1 + mentions[0].length + lastMentionCursor;
        return { word, range: [start, end], valid: end == cursorPosition - 1 && word.length > 3 };
    }
    return { word: undefined, range: undefined, valid: false };
}
export function getActiveToken(input, cursorPosition) {
    if (cursorPosition === undefined || cursorPosition < 0)
        return { word: undefined, range: undefined };
    const words = [];
    input.split(/[\s\n]/).forEach((word, index) => {
        const previous = words[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
        words.push({ word, range: [start, end] });
    });
    return words.find(({ range }) => range[0] <= cursorPosition && range[1] >= cursorPosition);
}
export function dynamicTabName(input, notifications) {
    if (!notifications || notifications <= 0)
        return input;
    return `(${notifications}) ` + input;
}
export function clientEncryptJWK(jwk, message) {
    return __awaiter(this, void 0, void 0, function* () {
        const publicKey = yield window.crypto.subtle.importKey('jwk', jwk, { name: 'RSA-OAEP', hash: 'SHA-256' }, true, ['encrypt']);
        const encrypted = yield window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, publicKey, new TextEncoder().encode(message));
        const encryptedBase64 = window.btoa(ab2str(encrypted));
        return encryptedBase64.replace(/(.{64})/g, '$1\n');
    });
}
function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}
export function ensureHttp(link) {
    const httpRegex = /^(http:\/\/|https:\/\/)/i;
    if (httpRegex.test(link)) {
        return link;
    }
    return `http://${link}`;
}
