import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Progress } from './Progress';
import strings from '@resources/localization';
const ProgressStep = (props) => {
    const { total, progress, kind, colorBackground, colorText } = props;
    const notSeen = total - progress;
    const getProgressText = () => {
        if (notSeen == 0)
            return strings.formatString(strings.screens.boards[kind].list.card.progress.completed, progress, total);
        if (notSeen > 0 && notSeen != total)
            return strings.formatString(strings.screens.boards[kind].list.card.progress.inprogress, progress, total);
        return strings.formatString(strings.screens.boards[kind].list.card.progress.inprogress, progress, total);
    };
    return (_jsxs("div", { style: { backgroundColor: colorBackground, paddingLeft: 16, paddingRight: 16, paddingBottom: 12, paddingTop: 8 }, children: [_jsxs(Paragraph, { style: { color: colorText, fontSize: 12, marginBottom: 4 }, children: [" ", getProgressText()] }), _jsxs(Row, { children: [[...Array(progress).fill(undefined)].map((value, index) => (_jsx(Col, { flex: 'auto', children: _jsx("div", { style: { padding: 1 }, children: _jsx(Progress, { seen: true, color: colorText }) }) }, `p${index}`))), [...Array(notSeen).fill(undefined)].map((value, index) => (_jsx(Col, { flex: 'auto', children: _jsx("div", { style: { padding: 1 }, children: _jsx(Progress, { color: colorText }) }) }, `nS${index}`)))] })] }));
};
export { ProgressStep };
