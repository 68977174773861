var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { API } from '@api/API';
import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
export function DebounceCommunitySelect(_a) {
    var props = __rest(_a, []);
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const { cm_pk, item_pk } = useParams();
    useEffect(() => {
        setFetching(true);
        void API.communityList({}).then((response) => {
            const data = response.data
                .filter((board) => board.publicKey != item_pk)
                .map((team) => {
                return { label: team.name, value: team.publicKey };
            });
            setOptions(data);
            setFetching(false);
        });
    }, []);
    if (fetching) {
        return (_jsx("div", { style: { padding: 24, textAlign: 'center' }, children: _jsx(Spin, {}) }));
    }
    return _jsx(Select, Object.assign({ labelInValue: true, notFoundContent: fetching ? _jsx(Spin, { size: "small" }) : null }, props, { options: options }));
}
