import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarTuringo } from '@components/avatar/Avatar';
import style from './AutoComplete.scss';
import { Skeleton } from 'antd';
import { createPortal } from 'react-dom';
export const Autocomplete = (props) => {
    const { items, handleSelection, top, left, loading, height, width } = props;
    if (loading)
        return createPortal(_jsx("div", { className: style.autocompletePanel, style: { top, height: height, left, width }, children: _jsxs("ul", { className: style.autocompleteItems, children: [_jsx("li", { className: style.autocompleteSkeleton, children: _jsx(Skeleton, { avatar: true, active: true, title: true, paragraph: null }) }, '#s1'), _jsx("li", { className: style.autocompleteSkeleton, children: _jsx(Skeleton, { avatar: true, active: true, title: true, paragraph: null }) }, '#s2'), _jsx("li", { className: style.autocompleteSkeleton, children: _jsx(Skeleton, { avatar: true, active: true, title: true, paragraph: null }) }, '#s3'), _jsx("li", { className: style.autocompleteSkeleton, children: _jsx(Skeleton, { avatar: true, active: true, title: true, paragraph: null }) }, '#s4'), _jsx("li", { className: style.autocompleteSkeleton, children: _jsx(Skeleton, { avatar: true, active: true, title: true, paragraph: null }) }, '#s5')] }) }), document.body);
    return createPortal(_jsx("div", { className: style.autocompletePanel, style: { top, height: height, left, width }, children: items.length > 0 && (_jsx("ul", { className: style.autocompleteItems, children: items.map((hit) => (_jsx("li", { className: style.autocompleteItem, children: _jsx("button", { style: { background: 'none', border: 'none', cursor: 'pointer' }, onClick: () => handleSelection(hit), children: _jsx(Item, { mention: hit }) }) }, hit.publicKey))) })) }), document.body);
};
const Item = (props) => {
    const { mention } = props;
    return (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(AvatarTuringo, { size: 16, style: {
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    lineHeight: '40px',
                    marginRight: 8,
                }, name: mention.name, avatar: mention.avatarUrl }), _jsxs("div", { style: { lineHeight: '22px' }, children: [_jsx("div", { style: { fontSize: 14, fontWeight: 400, lineHeight: '22px', textAlign: 'start' }, children: mention.name }), _jsx("div", { style: { fontSize: 12, fontWeight: 400, lineHeight: '22px', textAlign: 'start' }, children: mention.job })] })] }));
};
