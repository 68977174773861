var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { debounce } from 'lodash';
import { Select, Spin } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { FormContext } from 'antd/es/form/context';
const FormDeferredItem = (props) => {
    const { onSearchDeferred, minimumCharacters = 3, debounceTimeMs = 750, value } = props, rest = __rest(props, ["onSearchDeferred", "minimumCharacters", "debounceTimeMs", "value"]);
    const [cache, setCache] = useState();
    const [loading, setLoading] = useState(false);
    const { form } = useContext(FormContext);
    const onSearchDebounced = useMemo(() => {
        const search = (term) => {
            void onSearchDeferred(term)
                .then((remoteResults) => {
                if (remoteResults === 'wait')
                    return;
                setCache(remoteResults);
                setLoading(false);
            })
                .catch((error) => {
                setLoading(false);
            });
        };
        return debounce(search, debounceTimeMs);
    }, [onSearchDeferred, debounceTimeMs]);
    const onSelect = (value) => {
        form.setFieldsValue({ [props.name]: value.value });
    };
    const onClear = () => {
        form.setFieldsValue({ [props.name]: undefined });
    };
    return (_jsx(Select, Object.assign({ labelInValue: true, showSearch: true, dropdownStyle: { zIndex: 2000 }, allowClear: true, loading: loading, defaultValue: value, onSelect: onSelect, onClear: onClear, options: cache, filterOption: false, notFoundContent: loading ? _jsx(Spin, { size: "small" }) : null, onSearch: (term) => {
            if (!term || term.length < minimumCharacters)
                return;
            setLoading(true);
            onSearchDebounced(term);
            if (props.onSearch)
                props.onSearch(term);
        }, value: value }, rest)));
};
export { FormDeferredItem };
