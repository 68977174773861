import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SocketContext } from 'src/SocketComponent';
import { CreatePostContext } from '../../PostCreateProvider';
import { AlanEditor } from './components/AlanEditor';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
const AlanScreen = (props) => {
    const [value, setValue] = useState('');
    const socket = useContext(SocketContext);
    const { form, alanState } = useContext(CreatePostContext);
    const alanText = Form.useWatch(['form', 'turingo', 'alan'], form);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    useEffect(() => {
        var _a;
        const lastMessage = socket.lastJsonMessage;
        if (((_a = lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.channel) === null || _a === void 0 ? void 0 : _a.id) == 'ai.chat.autocomplete') {
            const errorMessage = socket.lastJsonMessage;
            if (!(errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.error)) {
                const message = socket.lastJsonMessage;
                if (message.body.state == 'start') {
                    form.setFieldValue(['form', 'turingo', 'alan'], '');
                }
                if (message.body.state == 'running') {
                    form.setFieldValue(['form', 'turingo', 'alan'], alanText + message.body.content);
                }
            }
        }
    }, [socket.lastJsonMessage]);
    return (_jsxs("div", { children: [_jsx(Paragraph, { style: { marginBottom: 16 }, className: "turingo-text", children: strings.screens.boards.discussion.post.alan.description }), _jsx(AlanEditor, { maxLength: 250, showCount: true, maxHeight: isMobile ? 143 : 81, minHeight: isMobile ? 143 : 81, value: value, placeholder: strings.screens.boards.discussion.post.alan.fieldText.placeholder, bordered: true, onAlanState: alanState.set, onChange: setValue }), _jsx(Input.TextArea, { style: {
                    backgroundColor: alanState.get.state != 'inactive' ? 'var(--background)' : 'var(--neutral-2)',
                    color: alanState.get.state != 'inactive' ? 'var(--neutral-13)' : 'var(--neutral-6)',
                    marginTop: 16,
                    maxHeight: isMobile ? '35vh' : 'unset',
                    minHeight: isMobile ? '35vh' : 106,
                    height: isMobile ? '35vh' : 'unset',
                }, value: alanText, onChange: (e) => form.setFieldValue(['form', 'turingo', 'alan'], e.target.value), disabled: alanState.get.state == 'inactive' || alanState.get.state == 'running' || alanState.get.state == 'stoping', autoComplete: "off", placeholder: strings.screens.boards.discussion.post.alan.fieldResult.placeholder })] }));
};
export { AlanScreen };
