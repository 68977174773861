import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const RatioImage = (props) => {
    const { src, maxWidth, style } = props;
    return (_jsxs("div", { style: Object.assign({ paddingTop: '56.2%', position: 'relative', maxWidth: maxWidth }, style), children: [src && (_jsx("img", { alt: "example", style: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    borderRadius: 4,
                }, src: src, loading: "eager" })), !src && (_jsxs("div", { style: Object.assign({ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', borderRadius: 4, justifyContent: 'center', backgroundColor: 'var(--neutral-3)' }, style), children: [props.iconAnt && _jsx(props.iconAnt, { style: { fontSize: 56, alignSelf: 'center', color: 'var(--neutral-7)' } }), props.iconPark && (_jsx(props.iconPark, { style: {
                            display: 'flex',
                            alignItems: 'center'
                        }, theme: "outline", size: 56, fill: 'var(--neutral-7)' }))] }))] }));
};
export { RatioImage };
