import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { markNotificationsAsSeen } from '@state/actions';
import { NotificationItem } from './NotificationItem';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import { Check } from '@icon-park/react';
import strings from '@resources/localization';
import { Link } from 'react-router-dom';
import { Router } from '@Router';
const NotificationPanel = (props) => {
    const { onClose } = props;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const language = useAppSelector((state) => state.ui.language);
    const dispatch = useAppDispatch();
    const { general } = useAppSelector((state) => state.notification);
    const notificationCommunity = general.notifications.filter((n) => {
        var _a, _b, _c;
        if (n.sender == 'system')
            return n;
        if (((_a = n.predicate) === null || _a === void 0 ? void 0 : _a.communityPk) == currentCommunity) {
            if (((_b = n.predicate) === null || _b === void 0 ? void 0 : _b.kind) == 'post' && ((_c = n.predicate) === null || _c === void 0 ? void 0 : _c.publicKey.startsWith('lesson'))) {
                return;
            }
            else {
                return n;
            }
        }
    });
    const markAsSeen = () => {
        void dispatch(markNotificationsAsSeen({
            urlParams: {
                notificationId: 'all',
            },
            bodyParams: {
                communityPk: currentCommunity,
            },
        }));
        onClose();
    };
    return (_jsxs("div", { children: [_jsx("div", { className: 'scrollStyle', style: { width: 424, maxHeight: 500, overflow: 'auto', paddingBottom: 16 }, children: _jsxs("div", { style: { display: 'grid', gridTemplateColumns: '1fr', rowGap: 8, padding: 16 }, children: [notificationCommunity.map((notification, index) => {
                            return (_jsx(NotificationItem, { onClose: markAsSeen, language: language, dispatch: dispatch, history: history, item: notification }, index));
                        }), notificationCommunity.length == 0 && (_jsx(AlertDashed, { icon: _jsx(Check, { style: { display: 'flex' } }), message: strings.notifications.empty }))] }) }), notificationCommunity.length != 0 && (_jsx("div", { style: { padding: '16px 0px' }, children: _jsx(Link, { style: { margin: 16 }, to: Router.Path.notifications({}), onClick: onClose, children: strings.notifications.showMore }) }))] }));
};
export { NotificationPanel };
