import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import Meta from 'react-meta-tags';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Card, Tabs } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { hasError } from '@util/StateHandler';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { CommunityScreenSkeleton } from '@components/skeletons/CommunityScreenSkeleton';
import { communityEventList, communityPosts } from '@state/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkBlockedScroll } from '@hooks/useBlockScroll';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import { ListPost } from '@components/content/posts/list_post/ListPosts';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
const { TabPane } = Tabs;
export const CommunityHighlights = (props) => {
    var _a, _b, _c;
    const { cm_pk } = useParams();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const posts = useAppSelector((state) => { var _a; return (_a = state.posts.all['feed']) === null || _a === void 0 ? void 0 : _a.items; });
    const postState = useAppSelector((state) => { var _a; return (_a = state.posts.all['feed']) === null || _a === void 0 ? void 0 : _a.listState; });
    const notifications = useContext(NotificationContext);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    useEffect(() => {
        void dispatch(communityPosts({
            urlParams: {
                cm_pk,
            },
            searchParams: {
                pin: 'all',
            },
            extraParams: {
                section: 'highlights'
            },
            headerParams: {
                page: 'first',
            },
        }));
        void dispatch(communityEventList({
            urlParams: { cm_pk },
        }));
    }, [cm_pk]);
    if (!community || ((_a = community.state) === null || _a === void 0 ? void 0 : _a.loading) || !postState || posts.loading) {
        return _jsx(CommunityScreenSkeleton, {});
    }
    else if (hasError([community.state, posts])) {
        return _jsx(CommunityScreenSkeleton, {});
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName((_b = community.item) === null || _b === void 0 ? void 0 : _b.name, notifications) }), _jsx("meta", { name: "description", content: (_c = community.item) === null || _c === void 0 ? void 0 : _c.description })] }), _jsx(ContentLayout, { left: _jsx("div", { style: { display: 'flex', justifyContent: 'center' }, children: _jsx("div", { style: { maxWidth: !isMobile ? 614 : undefined, width: '100%' }, children: _jsx(InfiniteScroll, { dataLength: Object.keys(posts).length, next: () => {
                                    if (!checkBlockedScroll()) {
                                        if (!isLogged) {
                                            dispatch(modalAction({ modal: 'see_more', state: true }));
                                        }
                                        else {
                                            void dispatch(communityPosts({
                                                urlParams: {
                                                    cm_pk,
                                                },
                                                headerParams: {
                                                    page: postState === null || postState === void 0 ? void 0 : postState.pagination,
                                                },
                                                extraParams: {
                                                    section: 'highlights',
                                                },
                                                searchParams: {
                                                    pin: 'all',
                                                },
                                            }));
                                        }
                                    }
                                }, hasMore: (postState.pagination && postState.pagination != 'first') || postState.loading, loader: !isLogged ? null : _jsx(PostSkeleton, {}), endMessage: _jsx(Card, { children: _jsx(AlertDashed, { showIcon: false, message: strings.screens.boards.discussion.details.no_more }) }), children: _jsx(ListPost, { items: posts }) }) }) }), right: null })] }));
    }
};
