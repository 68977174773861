export function byteArrayFromBase64(base64, minimumBits = 0) {
    const bytes = minimumBits >= 8 ? new Array(Math.ceil(minimumBits / 8)).fill(0) : new Array();
    if (base64.length > 0)
        Array.from(atob(base64)).forEach((x, i) => (bytes[i] = x.charCodeAt(0)));
    return Uint8Array.from(bytes);
}
export function byteArrayCopy(byteArray) {
    return Uint8Array.from(byteArray);
}
export function byteArrayGetBit(byteArray, bitIndex) {
    const byte = Math.floor(bitIndex / 8);
    const offs = bitIndex % 8;
    if (byte >= byteArray.length)
        return 0;
    return ((byteArray[byte] || 0) & (128 >> offs)) !== 0 ? 1 : 0;
}
export function byteArraySetBit(byteArray, bitIndex, value) {
    const byte = Math.floor(bitIndex / 8);
    const offs = bitIndex % 8;
    if (byte >= byteArray.length)
        return;
    switch (value) {
        case 'flip':
            byteArray[byte] = byteArray[byte] ^ (128 >> offs);
            break;
        case 1:
            byteArray[byte] = byteArray[byte] | (128 >> offs);
            break;
        case 0:
            byteArray[byte] = byteArray[byte] & ~(128 >> offs);
            break;
    }
    return byteArray;
}
export function* byteArrayMapBits(byteArray, from = 0, upto = -1) {
    upto = upto >= 0 ? upto : byteArray.length * 8;
    upto = upto < byteArray.length * 8 ? upto : byteArray.length * 8;
    for (let bit = from; bit < upto; bit++) {
        yield (byteArray[Math.floor(bit / 8)] & (0b10000000 >> bit % 8)) !== 0 ? { bit: 1, bitCount: upto } : { bit: 0, bitCount: upto };
    }
}
export function byteArrayToString(array, byteSeparator) {
    if (array.length <= 0)
        return '';
    let result = '';
    array.forEach((byte, index) => {
        result += byte.toString(2).padStart(8, '0');
        if (byteSeparator && index < array.length - 1)
            result += byteSeparator;
    });
    return result;
}
