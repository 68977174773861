function fileBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            }
            else {
                const view = new Uint8Array(reader.result);
                resolve(view.toString());
            }
        };
        reader.onerror = (error) => reject(error);
    });
}
export { fileBase64 };
