const formatSeconds = (seconds, format = 'hh?:mm:ss') => {
    if (seconds < 0 || seconds === undefined || seconds === null) {
        return '00:00';
    }
    const t = {
        h: Math.floor(seconds / 3600),
        m: Math.floor((seconds / 60) % 60),
        s: Math.floor(seconds % 60),
    };
    if (t.h <= 0 && format === 'hh?:mm:ss') {
        format = 'mm:ss';
    }
    switch (format) {
        case 'hh?:mm:ss':
        case 'hh:mm:ss':
            return `${t.h.toFixed(0).padStart(2, '0')}:${t.m.toFixed(0).padStart(2, '0')}:${t.s.toFixed(0).padStart(2, '0')}`;
        case 'hh:mm':
            return `${t.h.toFixed(0).padStart(2, '0')}:${t.m.toFixed(0).padStart(2, '0')}`;
        case 'mm:ss': {
            const minutes = t.h * 60 + t.m;
            return `${minutes.toFixed(0).padStart(2, '0')}:${t.s.toFixed(0).padStart(2, '0')}`;
        }
    }
};
const encodeTimeSeconds = (time) => {
    if (isNaN(time))
        return '0';
    const date = new Date(null);
    date.setSeconds(time);
    const [hours, minutes, seconds] = date.toISOString().substr(11, 8).split(':');
    return `${hours}h${minutes}m${seconds}s`;
};
const decodeTimeSeconds = (timeExpression, regex) => {
    const [hours, minutes, seconds] = timeExpression.split(regex).filter((value) => value != '');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
};
export { formatSeconds, encodeTimeSeconds, decodeTimeSeconds };
