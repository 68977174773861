import update from 'immutability-helper';
export const Migration001 = (state) => {
    return update(state, {
        auth: {
            authState: {
                $set: state.auth.authToken !== undefined ? 'auth.normal' : 'unauth.visitor',
            },
        },
    });
};
