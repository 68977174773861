import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useEffect } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useBreakpoints from '@hooks/useBreakpoints';
import { Card, Col, Divider, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { SignupForm } from './SignupForm';
import { Social } from '@components/content/social/Social';
import { Router } from '@Router';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import { API } from '@api/API';
import { RedirectComponent } from '../redirect/RedirectComponent';
const Signup = () => {
    var _a, _b;
    const location = useLocation();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall;
    const isMobile = breakpoints.isMobile;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const to = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.to;
    if (isLogged) {
        return _jsx(RedirectComponent, { to: to });
    }
    else {
        return (_jsxs(Row, { justify: "center", align: "middle", style: { minHeight: '80vh' }, children: [_jsxs(Meta, { children: [_jsx("title", { children: strings.screens.auth.signup.browserTitle }), _jsx("meta", { name: "description", content: strings.screens.auth.signup.browserDescription })] }), _jsx(Col, { xs: 24, md: 18, lg: 12, style: { maxWidth: 600, marginTop: 24, marginBottom: 24 }, children: _jsxs(Card, { bodyStyle: { padding: isSmall ? 16 : 24 }, children: [_jsx(GoBackButton, { type: "default", showText: false, style: { marginBottom: 24 } }), _jsxs("div", { style: { maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }, children: [_jsx(Paragraph, { style: {
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            marginBottom: 40,
                                            fontSize: isMobile ? 24 : 30,
                                            lineHeight: isMobile ? '32px' : '38px',
                                        }, children: strings.screens.auth.signup.free }), _jsx(Social, { kind: "signup" }), _jsx(Divider, { plain: true, children: strings.general.email_option }), _jsx(SignupForm, { email: (_b = location.state) === null || _b === void 0 ? void 0 : _b.email }), _jsx(Divider, { style: { margin: '16px 0' } }), _jsxs("div", { style: { width: '100%', textAlign: 'center' }, children: [_jsx("span", { style: { marginRight: 3 }, children: strings.screens.auth.signup.goLogin.ask }), _jsx(Link, { onClick: () => {
                                                    void API.analyticsAction({ bodyParams: { action: 'btn.login', sender: 'main.signup' } });
                                                }, to: { pathname: Router.Path.login({}), state: location.state }, children: strings.screens.auth.signup.goLogin.action })] })] })] }) })] }));
    }
};
export { Signup };
