import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { Dropdown, Space } from 'antd';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
import { Down } from '@icon-park/react';
const SettingItem = (props) => {
    var _a;
    const { buttonAlign = 'flex-end', gap = 16, style } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const items = isMobile ? (_a = props.action) === null || _a === void 0 ? void 0 : _a.reverse() : props.action;
    return (_jsxs("div", { style: Object.assign(Object.assign({}, (!isMobile && { display: 'flex', justifyContent: 'space-between', alignItems: 'center' })), style), children: [_jsxs("div", { style: { marginRight: isMobile ? 0 : 24 }, children: [_jsxs("div", { style: { display: 'flex', marginBottom: isMobile ? 16 : 0, alignItems: isMobile ? 'start' : 'center' }, children: [props.icon && (_jsx("div", { style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 16,
                                    height: 24,
                                    minWidth: 16,
                                    fontSize: 16,
                                    marginRight: 8,
                                }, children: _jsx(props.icon, {}) })), _jsx(Paragraph, { style: { marginBottom: 0, fontWeight: props.description ? 500 : 400 }, children: props.title })] }), props.description && (_jsx(Paragraph, { style: Object.assign({ marginTop: isMobile ? 0 : gap, marginBottom: isMobile ? gap : 0 }, props.descriptionStyle), children: props.description }))] }), _jsx("div", { style: { display: 'grid', gap: 16, gridAutoFlow: isMobile ? 'row' : 'column', alignSelf: buttonAlign }, children: items === null || items === void 0 ? void 0 : items.map((item, i) => {
                    if (item.dropdownItems) {
                        return (_jsx(Dropdown, { menu: item.dropdownItems, trigger: ['click'], children: _jsx(TuringoButton, { disabled: item.disabled, icon: item.icon ? _jsx(item.icon, {}) : undefined, type: item.btnType || 'primary', children: _jsxs(Space, { children: [item.title, _jsx(Down, {})] }) }, `btn_${i}`) }, `dropdown_${i}`));
                    }
                    return (_jsx(TuringoButton, { expandInMobile: true, loading: item.loading, disabled: item.disabled, icon: item.icon ? _jsx(item.icon, {}) : undefined, type: item.btnType || 'primary', onClick: item.onClick, children: item.title }, i));
                }) })] }));
};
export { SettingItem };
