import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import style from './style.scss';
import { PlusOutlined } from '@ant-design/icons';
export const TopicAddButton = (props) => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: { marginRight: 4, height: 'fit-content' }, className: props.disabled ? (isMobile ? style.itemAddMobileDisabled : style.itemAddDisabled) : isMobile ? style.itemAddMobile : style.itemAdd, onClick: props.disabled ? undefined : props.onClick, children: [_jsx(PlusOutlined, { style: { fontSize: isMobile ? 14 : 10 } }), " ", props.title] }));
};
