var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
const LinkeableCard = (props) => {
    const { children, href, linkStyle } = props, otherProps = __rest(props, ["children", "href", "linkStyle"]);
    if (!props.href) {
        return _jsx(Card, Object.assign({}, otherProps, { children: children }));
    }
    return (_jsx(Link, { to: href, style: linkStyle, children: _jsx(Card, Object.assign({}, otherProps, { children: children })) }));
};
export { LinkeableCard };
