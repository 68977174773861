import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '@hooks/useStore';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown, Form, Menu } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CommunityEditModal } from '@components/content/entity/community_header/components/community_edit_modal/CommunityEditModal';
import style from './CommunityHeader.scss';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { EntityName } from './components/EntityName';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { addWebp } from '@util/browser/addWebp';
import { tableColorBanner } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { MoreOne } from '@icon-park/react';
import { CommunityExitModal } from './components/community_exit_modal/CommunityExitModal';
import { useState } from 'react';
const CommunityHeader = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { entity, showBreadcrumb, showAvatar, isHome } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == ((_a = entity === null || entity === void 0 ? void 0 : entity.item) === null || _a === void 0 ? void 0 : _a.publicKey);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const [visibleExitModal, setVisibleExitModal] = useState(false);
    const [editForm] = Form.useForm();
    const openEditModal = () => {
        setVisibleEditModal(true);
    };
    const isAdmin = (_c = (_b = entity === null || entity === void 0 ? void 0 : entity.item) === null || _b === void 0 ? void 0 : _b.access) === null || _c === void 0 ? void 0 : _c.includes('edit');
    const menuItems = [];
    const menu = () => _jsx(Menu, { items: menuItems });
    if (!entity.item)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { style: { background: 'var(--background)', paddingBottom: 16, borderRadius: 4 }, children: [_jsx(CommunityEditModal, { entity: entity, open: visibleEditModal, setVisibility: setVisibleEditModal, formInstance: editForm }), _jsx(CommunityExitModal, { open: visibleExitModal, onCancel: () => setVisibleExitModal(false) }), showBreadcrumb && (_jsx("div", { style: { padding: isMobile ? 16 : '16px 24px' }, children: _jsx(Breadcrumb, { children: _jsx(Breadcrumb.Item, { children: _jsx(Link, { to: '/', children: strings.screens.profile.title }) }, 'profile') }) })), _jsxs("div", { className: style.imgContainer, children: [entity.item.bannerUrl && (_jsx("div", { style: { paddingTop: '40%', position: 'relative' }, children: _jsx("img", { alt: "example", style: {
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                borderTopLeftRadius: isHome ? 4 : 0,
                                borderTopRightRadius: isHome ? 4 : 0,
                            }, src: addWebp((_d = entity.item) === null || _d === void 0 ? void 0 : _d.bannerUrl), loading: "lazy" }) })), !entity.item.bannerUrl && (_jsx("div", { style: { paddingTop: '40%', position: 'relative' }, children: _jsx("div", { style: {
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                background: tableColorBanner[(_g = (_f = (_e = entity.item) === null || _e === void 0 ? void 0 : _e.ui) === null || _f === void 0 ? void 0 : _f.style) === null || _g === void 0 ? void 0 : _g.color],
                            } }) })), showAvatar && (_jsx(AvatarTuringo, { style: { border: ' 4px solid var(--hover)' }, color: (_k = (_j = (_h = entity.item) === null || _h === void 0 ? void 0 : _h.ui) === null || _j === void 0 ? void 0 : _j.style) === null || _k === void 0 ? void 0 : _k.color, className: style.profileImg, size: breakpoints.xxs || breakpoints.xs ? 32 : 64, avatar: entity.item.logoUrl, name: entity.item.name }))] }), showAvatar && (_jsxs(_Fragment, { children: [_jsx("div", { className: style.infoContainer, children: _jsxs("div", { className: style.editContainer, children: [(isLoggedUser || isAdmin) && !isHome && (_jsx(Button, { size: "large", icon: _jsx(EditOutlined, {}), onClick: openEditModal, children: isMobile ? '' : strings.general.edit })), isHome && currentEntity && menuItems.length > 0 && (_jsx(Dropdown, { trigger: ['click'], overlay: menu, placement: "bottomRight", children: _jsx(Button, { style: { padding: '4px 8px' }, children: _jsx(MoreOne, { style: { cursor: 'pointer', display: 'flex', alignItems: 'center' }, size: "20" }) }) }))] }) }), _jsx(EntityName, Object.assign({}, entity.item))] })), !showAvatar && (_jsx(_Fragment, { children: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', padding: '24px 21px 24px 0px' }, children: [_jsx(EntityName, Object.assign({}, entity.item)), _jsx("div", { children: (isLoggedUser || isAdmin) && (_jsx(Button, { size: "large", icon: _jsx(EditOutlined, {}), onClick: openEditModal, children: isMobile ? '' : strings.general.edit })) })] }) })), "        "] }));
};
export { CommunityHeader };
