export function filterArchived(categories) {
    return categories
        .filter((category) => !category.archived)
        .map((category) => (Object.assign(Object.assign({}, category), { properties: category.properties.filter((property) => !property.archived) })));
}
export const addCategoryIdToProperties = (categories) => {
    if (!categories)
        return undefined;
    return categories.map((category) => {
        const updatedProperties = category.properties.map((property) => (Object.assign(Object.assign({}, property), { categoryId: category.id, placement: category.placement })));
        return Object.assign(Object.assign({}, category), { properties: updatedProperties });
    });
};
export const separateByPlacement = (categories) => {
    return categories.reduce((acc, category) => {
        const { placement } = category;
        if (placement == 'primary') {
            if (!acc['center']) {
                acc['center'] = [];
            }
            acc['center'].push(category);
        }
        else if (placement == 'blocked') {
            if (!acc['right']) {
                acc['right'] = [];
            }
            acc['right'].push(category);
        }
        else {
            if (!acc[placement]) {
                acc[placement] = [];
            }
            acc[placement].push(category);
        }
        return acc;
    }, {});
};
export function filterCategoriesAndProperties(categories) {
    const result = [];
    categories.forEach((category) => {
        if (category.archived) {
            result.push(category);
        }
        else {
            category.properties.forEach((property) => {
                if (property.archived) {
                    result.push(Object.assign(Object.assign({}, property), { placement: category.placement, categoryId: category.id }));
                }
            });
        }
    });
    return result;
}
export const formatPlacement = (placement) => {
    if (placement == 'primary') {
        return 'center';
    }
    else if (placement == 'blocked') {
        return 'right';
    }
    else {
        return placement;
    }
};
export const formatResponse = (schema, archived) => {
    const schemaCopy = JSON.parse(JSON.stringify(schema));
    const result = [];
    Object.keys(schemaCopy).forEach((placement) => {
        schemaCopy[placement].forEach((category) => {
            result.push(category);
        });
    });
    archived.forEach((archive) => {
        if (!('categoryId' in archive)) {
            result.push(archive);
        }
    });
    archived.forEach((archive) => {
        if ('categoryId' in archive) {
            const category = result.find((category) => category.id == archive.categoryId);
            if (category) {
                category.properties.push(archive);
            }
        }
    });
    return result;
};
export const extractCategories = (data) => {
    const categories = [...((data === null || data === void 0 ? void 0 : data.center) || []), ...((data === null || data === void 0 ? void 0 : data.right) || [])];
    return categories.map((category) => ({
        value: category.id,
        label: category.title,
        title: category.placement,
    }));
};
export function getOrderedProperties(sections) {
    if (!sections)
        return undefined;
    let allProperties = [];
    sections.forEach((section) => {
        allProperties = allProperties.concat(section.properties);
    });
    return allProperties.sort((a, b) => a.orderEditor - b.orderEditor).filter((property) => !property.archived);
}
export function getAllPropertiesOrderedByOrderEditor(config) {
    const allProperties = [];
    function collectProperties(sections) {
        sections === null || sections === void 0 ? void 0 : sections.forEach((section) => {
            var _a;
            (_a = section.properties) === null || _a === void 0 ? void 0 : _a.forEach((property) => {
                allProperties.push(property);
            });
        });
    }
    collectProperties(config === null || config === void 0 ? void 0 : config.center);
    collectProperties(config === null || config === void 0 ? void 0 : config.right);
    allProperties.sort((a, b) => a.orderEditor - b.orderEditor);
    return allProperties;
}
export function getAllPropertiesOrderedByOnboardingOrder(config) {
    const allProperties = [];
    function collectProperties(sections) {
        sections === null || sections === void 0 ? void 0 : sections.forEach((section) => {
            var _a;
            (_a = section.properties) === null || _a === void 0 ? void 0 : _a.forEach((property) => {
                if (property.onboarding) {
                    allProperties.push(property);
                }
            });
        });
    }
    collectProperties(config === null || config === void 0 ? void 0 : config.center);
    collectProperties(config === null || config === void 0 ? void 0 : config.right);
    allProperties.sort((a, b) => a.orderOnboarding - b.orderOnboarding);
    return allProperties;
}
export function exceedLimit(schema) {
    const allProperties = [];
    function collectProperties(sections) {
        sections === null || sections === void 0 ? void 0 : sections.forEach((section) => {
            var _a;
            (_a = section.properties) === null || _a === void 0 ? void 0 : _a.forEach((property) => {
                allProperties.push(property);
            });
        });
    }
    collectProperties(schema === null || schema === void 0 ? void 0 : schema.categories);
    return allProperties.length >= schema.metadata.propertyLimit;
}
export function schemaOnboarding(schema) {
    const allProperties = [];
    function collectProperties(sections) {
        sections === null || sections === void 0 ? void 0 : sections.forEach((section) => {
            var _a;
            (_a = section.properties) === null || _a === void 0 ? void 0 : _a.forEach((property) => {
                if (property.onboarding) {
                    allProperties.push(property);
                }
            });
        });
    }
    collectProperties(schema === null || schema === void 0 ? void 0 : schema.categories);
    allProperties.sort((a, b) => a.orderOnboarding - b.orderOnboarding);
    return allProperties;
}
export function updateOrderEditor(data, propertyId, newOrder) {
    let allProperties = [];
    for (const section of [data === null || data === void 0 ? void 0 : data.center, data === null || data === void 0 ? void 0 : data.right]) {
        if (section) {
            for (const category of section) {
                allProperties = allProperties.concat(category.properties);
            }
        }
    }
    const property = allProperties.find((prop) => prop.id === propertyId);
    if (!property) {
        throw new Error('Property not found');
    }
    const editorProperties = getAllPropertiesOrderedByOrderEditor(data).filter((prop) => !prop.highlight);
    const oldIndex = editorProperties.findIndex((prop) => prop.id === propertyId);
    editorProperties.splice(oldIndex, 1);
    editorProperties.splice(newOrder, 0, property);
    editorProperties.forEach((prop, index) => {
        prop.orderEditor = index;
    });
    allProperties.sort((a, b) => a.orderProfile - b.orderProfile);
    const updatedData = { center: [], right: [] };
    for (const section of [data.center, data.right]) {
        if (section) {
            const updatedSection = section.map((category) => {
                const updatedCategory = Object.assign(Object.assign({}, category), { properties: [] });
                for (const prop of allProperties) {
                    const inForm = editorProperties.find((p) => p.id === prop.id);
                    if (inForm) {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(inForm);
                        }
                    }
                    else {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(prop);
                        }
                    }
                }
                return updatedCategory;
            });
            if (section === data.center) {
                updatedData.center = updatedSection;
            }
            else {
                updatedData.right = updatedSection;
            }
        }
    }
    return updatedData;
}
export function updateOrderOnboarding(data, propertyId, newOrder) {
    let allProperties = [];
    for (const section of [data === null || data === void 0 ? void 0 : data.center, data === null || data === void 0 ? void 0 : data.right]) {
        if (section) {
            for (const category of section) {
                allProperties = allProperties.concat(category.properties);
            }
        }
    }
    const property = allProperties.find((prop) => prop.id === propertyId);
    if (!property) {
        throw new Error('Property not found');
    }
    const onboardingProperties = getAllPropertiesOrderedByOnboardingOrder(data).filter((prop) => !prop.highlight);
    const oldIndex = onboardingProperties.findIndex((prop) => prop.id === propertyId);
    onboardingProperties.splice(oldIndex, 1);
    onboardingProperties.splice(newOrder, 0, property);
    onboardingProperties.forEach((prop, index) => {
        prop.orderOnboarding = index;
    });
    allProperties.sort((a, b) => a.orderProfile - b.orderProfile);
    const updatedData = { center: [], right: [] };
    for (const section of [data.center, data.right]) {
        if (section) {
            const updatedSection = section.map((category) => {
                const updatedCategory = Object.assign(Object.assign({}, category), { properties: [] });
                for (const prop of allProperties) {
                    const inBoarding = onboardingProperties.find((p) => p.id === prop.id);
                    if (inBoarding) {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(inBoarding);
                        }
                    }
                    else {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(prop);
                        }
                    }
                }
                return updatedCategory;
            });
            if (section === data.center) {
                updatedData.center = updatedSection;
            }
            else {
                updatedData.right = updatedSection;
            }
        }
    }
    return updatedData;
}
