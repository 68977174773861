var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from 'antd';
const { Text } = Typography;
import Style from './LinkUnderline.scss';
const LinkUnderline = (props) => {
    const { onClick, type, children, href, style } = props, otherProps = __rest(props, ["onClick", "type", "children", "href", "style"]);
    if (onClick) {
        return (_jsx("div", Object.assign({ onClick: onClick, style: style, className: Style.text }, otherProps, { children: children })));
    }
    else if (href) {
        return (_jsx("a", Object.assign({ href: href, style: style, className: Style.text }, otherProps, { children: children })));
    }
    else {
        return (_jsx(Text, Object.assign({ style: style }, otherProps, { children: children })));
    }
};
export { LinkUnderline };
