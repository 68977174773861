var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { arrayIdentical } from '@util/array/equality';
import { fileBase64 } from '@util/files/Base64';
import React from 'react';
const ImageIdPrefix = 'canvas:img:';
function isPreloadFont(obj) {
    if (typeof obj !== 'object')
        return false;
    const candidate = obj;
    return candidate.source !== undefined && candidate.fontFamily !== undefined;
}
function fontURL(url) {
    if (url.startsWith('url'))
        return url;
    return `url(${url})`;
}
class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.canvas = undefined;
        this.componentDidMount = () => {
            this.draw();
        };
        this.addLoadImage = (imageId, imageURL) => __awaiter(this, void 0, void 0, function* () {
            const image = new Image();
            image.id = ImageIdPrefix + imageId;
            image.loading = 'eager';
            image.onerror = this.onImageFail;
            image.onload = this.onImageLoad;
            if (typeof imageURL == 'string') {
                image.crossOrigin = 'anonymous';
                image.src = imageURL;
            }
            else {
                imageURL = yield fileBase64(imageURL);
                image.crossOrigin = 'anonymous';
                image.src = imageURL;
            }
            const item = { id: imageId, src: imageURL, concrete: image, state: 'new' };
            this.storeImages[imageId] = item;
        });
        this.addLoadFont = (fontId, source) => {
            let font;
            let src;
            if (typeof source === 'string') {
                font = new FontFace(fontId, fontURL(source));
                src = source;
            }
            else if (isPreloadFont(source)) {
                font = new FontFace(source.fontFamily, fontURL(source.source), source.descriptors);
                src = source.source;
            }
            else {
                throw `Expected a font URL or font metadata, received a ${typeof source} instead`;
            }
            const stored = {
                id: fontId,
                src: src,
                concrete: font,
                state: 'new',
            };
            this.storeFonts[fontId] = stored;
            switch (font.status) {
                case 'loading':
                    stored.state = 'new';
                    font.loaded.then(() => this.onFontEvent('loaded', fontId)).catch((e) => this.onFontEvent('failed', fontId, e));
                    break;
                case 'unloaded':
                    stored.state = 'new';
                    font.load()
                        .then(() => this.onFontEvent('loaded', fontId))
                        .catch((e) => this.onFontEvent('failed', fontId, e));
                    break;
                case 'loaded':
                    stored.state = 'loaded';
                    this.onFontEvent('loaded', fontId);
                    break;
                case 'error':
                    stored.state = 'failed';
                    this.onFontEvent('failed', fontId);
                    break;
            }
        };
        this.onFontEvent = (state, fontId, error) => {
            this.storeFonts[fontId].state = state;
            if (this.props.onFontLoad)
                this.props.onFontLoad(fontId);
            if (state === 'failed')
                console.error(`Failed loading font '${fontId}' at '${this.storeFonts[fontId].src}' due to ${(error === null || error === void 0 ? void 0 : error.message) || 'some reason'}`);
            if (this.state.render !== 'failed') {
                this.setState({ render: state === 'loaded' ? this.state.render + 1 : 'failed' });
            }
        };
        this.onImageEvent = (state, event) => {
            const image = event.target;
            const imageId = image.id.substr(ImageIdPrefix.length);
            this.storeImages[imageId].state = state;
            if (this.props.onImageLoad)
                this.props.onImageLoad(imageId);
            if (state === 'failed')
                console.error(`Failed loading image '${imageId}' at '${this.storeImages[imageId].src}'`);
            if (this.state.render !== 'failed') {
                this.setState({ render: state === 'loaded' ? this.state.render + 1 : 'failed' });
            }
        };
        this.onImageFail = (event) => this.onImageEvent('failed', event);
        this.onImageLoad = (event) => this.onImageEvent('loaded', event);
        this.didStoreLoad = (store) => {
            for (const value of Object.values(store)) {
                if (value.state !== 'loaded')
                    return false;
            }
            return true;
        };
        this.draw = () => {
            if (!this.canvas)
                return 'No canvas';
            if (!this.props.onDraw)
                return 'No onDraw';
            if (!this.didStoreLoad(this.storeImages))
                return 'Need Images';
            if (!this.didStoreLoad(this.storeFonts))
                return 'Need Fonts';
            if (this.state.render === 'failed')
                return 'Load failed';
            const ctx = this.canvas.getContext('2d', Object.assign({ alpha: true }, this.props.canvasOptions));
            const imageTable = {};
            Object.values(this.storeImages).forEach((item) => (imageTable[item.id] = item.concrete));
            const fontTable = {};
            Object.values(this.storeFonts).forEach((item) => (fontTable[item.id] = item.concrete));
            this.props.onDraw(ctx, { width: this.canvas.width, height: this.canvas.height }, imageTable, fontTable);
            if (this.props.onDrawComplete)
                this.props.onDrawComplete(this.canvas);
            return true;
        };
        this.render = () => {
            const _a = this.props, { canvasOptions, preloadImages, preloadFonts, onImageLoad, onFontLoad, onDraw, onDrawComplete } = _a, canvasProps = __rest(_a, ["canvasOptions", "preloadImages", "preloadFonts", "onImageLoad", "onFontLoad", "onDraw", "onDrawComplete"]);
            const didDraw = this.draw();
            return _jsx("canvas", Object.assign({}, canvasProps, { ref: (c) => (this.canvas = c), style: { backgroundColor: 'white' } }));
        };
        this.storeImages = {};
        this.storeFonts = {};
        this.state = { render: 0 };
        try {
            if (props.preloadImages) {
                for (const [imageId, image] of Object.entries(props.preloadImages)) {
                    if (image) {
                        void this.addLoadImage(imageId, image);
                    }
                }
            }
            if (props.preloadFonts) {
                for (const [fontId, fontURLOrRequest] of Object.entries(props.preloadFonts)) {
                    this.addLoadFont(fontId, fontURLOrRequest);
                }
            }
        }
        catch (error) {
            console.error(error);
            this.state = { render: 'failed' };
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!arrayIdentical(Object.values(prevProps.preloadImages), Object.values(this.props.preloadImages))) {
            {
                try {
                    if (this.props.preloadImages) {
                        for (const [imageId, image] of Object.entries(this.props.preloadImages)) {
                            if (image) {
                                void this.addLoadImage(imageId, image);
                            }
                        }
                    }
                }
                catch (error) {
                    console.error(error);
                    this.setState({ render: 'failed' });
                }
            }
        }
    }
}
export { Canvas };
