import { compact } from 'lodash';
const ClassNames = (...classes) => {
    if (!classes || classes.length === 0) {
        return '';
    }
    if (classes instanceof Array) {
        return compact(classes).join(' ');
    }
    return classes;
};
export default ClassNames;
