import { byteArrayFromBase64 } from '@util/array/bytearray';
function progressBitmapParameters(parametersString) {
    const params = {};
    parametersString.split('&').forEach((p) => {
        const [key, value] = p.split('=');
        switch (key) {
            case 'spb':
                params.secondsPerBit = parseInt(value);
                break;
        }
    });
    return params;
}
function progressBitmapFromString(encoded, secondsTotal) {
    if (!encoded || encoded.length <= 0) {
        return {
            secondsPerBit: 5,
            bitmap: byteArrayFromBase64('', Math.floor(secondsTotal / 5)),
        };
    }
    const [parametersString, progressString] = encoded.split(':');
    const parameters = progressBitmapParameters(parametersString);
    const uint8array = byteArrayFromBase64(progressString, Math.floor(secondsTotal / parameters.secondsPerBit));
    return Object.assign(Object.assign({}, parameters), { bitmap: uint8array });
}
export { progressBitmapFromString };
