var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router';
import { Card, message, Input, Button } from 'antd';
import JSPDF from '@lib/jspdf';
import { API } from '@api/API';
import { getRequest, useRequestState } from '@api/requests/AsyncRequests';
import { DownloadOutlined } from '@ant-design/icons';
import { Certificate } from '@components/content/certificate/Certificate';
import { useState } from 'react';
import strings from '@resources/localization';
import { clipboardWriteText } from '@util/browser/Copy';
import { useAppSelector } from '@hooks/useStore';
import useBreakpoints from '@hooks/useBreakpoints';
import { CertificateConstants } from '@resources/constants/CertificateConstants';
const CertificatePublicScreen = () => {
    var _a, _b, _c, _d, _e;
    const { pk } = useParams();
    const [certificate, setCertificate] = useRequestState();
    void getRequest(API.getCertificate, setCertificate)({ urlParams: { pk } });
    const [canvas, setCanvas] = useState();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const handleCertificateDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!canvas)
            return;
        const certImage = document.createElement('a');
        certImage.download = 'TuringoCertificate.png';
        certImage.href = canvas.toDataURL('image/png');
        const imgData = canvas.toDataURL('image/png');
        const pdf = new (yield JSPDF.loadPromise()).jsPDF('l', 'px', [752, 581]);
        pdf.addImage(imgData, 'JPEG', 0, 0, 752, 581);
        pdf.save(`Turingo Certificate ${pk}.pdf`);
        void API.analyticsAction({
            bodyParams: { action: 'certificate.save', sender: 'modal.certificate', shareMethod: 'download', referenceCertificate: certificate.data.publicKey },
        });
    });
    const shareCertificate = () => {
        clipboardWriteText(`${window.location.origin.toString()}/cert/${pk}`);
        void message.destroy();
        void message.success({
            content: strings.modals.share.message,
            style: {
                marginTop: '90vh',
            },
        });
        void API.analyticsAction({
            bodyParams: { action: 'certificate.share', sender: 'modal.certificate', shareMethod: 'link', referenceCertificate: certificate.data.publicKey },
        });
    };
    return (_jsx("div", { style: { width: '100%', display: 'flex', justifyContent: 'center' }, children: _jsxs(Card, { title: 'Certificado', style: { maxWidth: 800, marginBottom: 24 }, bodyStyle: { padding: 40 }, children: [certificate.loading && (_jsx("div", { style: {
                        position: 'relative',
                        paddingBottom: '80%',
                        borderRadius: '4px 4px 0 0',
                        width: CertificateConstants.width,
                        overflow: 'hidden',
                        maxWidth: 700,
                    }, children: _jsx("div", { className: "turingo-skeleton", style: { position: 'absolute', width: '100%', height: '100%' } }) })), certificate.data && (_jsx(Certificate, { pk: certificate.data.merit.publicKey, granteeName: certificate.data.user.name, courseTitle: certificate.data.board.name, mentors: (_b = (_a = certificate.data.board.partners) === null || _a === void 0 ? void 0 : _a.mentor) === null || _b === void 0 ? void 0 : _b.map((m) => m.name), grantDate: certificate.data.dateEarned, logo: (_c = certificate.data.merit.document) === null || _c === void 0 ? void 0 : _c.logoUrl, background: (_e = (_d = certificate.data.merit) === null || _d === void 0 ? void 0 : _d.document) === null || _e === void 0 ? void 0 : _e.backgroundUrl, signer: certificate.data.merit.signer, onRenderComplete: (canvas) => setCanvas(canvas) })), certificate.data && certificate.data.user.publicKey == (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey)
                    ? [
                        _jsxs("div", { style: { marginTop: 16, display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '3fr 1fr', gap: 8 }, children: [_jsx(Input.Search, { readOnly: true, value: `${window.location.origin.toString()}/cert/${pk}`, onSearch: shareCertificate, onClick: shareCertificate, placeholder: "url", enterButton: strings.modals.share.action, style: { width: '100%' } }), _jsxs(Button, { type: 'primary', onClick: handleCertificateDownload, block: true, children: [_jsx(DownloadOutlined, {}), " ", strings.screens.boards.course.certificate.download.action] }, "download")] }, 'footer'),
                    ]
                    : null] }) }));
};
export { CertificatePublicScreen };
