import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import ReactPlayer from '@lib/react-player';
import { Waypoint } from 'react-waypoint';
import style from './VideoPost.scss';
const VideoPost = (props) => {
    const { attachments } = props;
    const [shouldPlay, updatePlayState] = useState(false);
    const handleExitViewport = function () {
        updatePlayState(false);
    };
    return (_jsx(Waypoint, { scrollableAncestor: window, onLeave: handleExitViewport, children: _jsx("div", { className: style.playerWrapper, children: _jsx(ReactPlayer, { className: style.reactPlayer, playing: shouldPlay, style: { display: 'flex', maxHeight: 680 }, controls: true, width: '100%', url: attachments[0].url, onPlay: () => updatePlayState(true), onPause: () => updatePlayState(false) }) }) }));
};
export { VideoPost };
