import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import style from './Carrousel.scss';
import { useAppSelector } from '@hooks/useStore';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
export const Carrousel = (props) => {
    const { showArrows = true, slidesToShow } = props;
    const [page, setPage] = useState(0);
    const breakpoints = useBreakpoints();
    const [lastSlide, setLastSlide] = useState(0);
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    useEffect(() => {
        if (props.children[1]) {
            setLastSlide(props.children[1].length - slides);
        }
        else {
            setLastSlide(props.children.length - slides);
        }
    }, [props.children]);
    const isMobile = breakpoints.isMobile;
    const sliderRef = useRef(null);
    const nextSlide = () => {
        sliderRef.current.slickNext();
    };
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };
    const afterChangeHandler = (currentSlide, nextSlide) => {
        setPage(nextSlide);
    };
    const slides = slidesToShow ? slidesToShow : isMobile ? 1 : 3;
    const slideDots = (dots) => {
        return (_jsxs("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                padding: isMobile ? 0 : 10,
            }, children: [isMobile && showArrows && (_jsx("div", { style: Object.assign(Object.assign({}, (page == 0 && { visibility: 'hidden' })), { marginRight: 12 }), children: _jsx(Button, { onClick: prevSlide, shape: "circle", icon: _jsx(LeftOutlined, {}) }) })), _jsxs("ul", { style: { margin: '0px', paddingInlineStart: 0, alignSelf: 'center' }, children: [" ", dots, " "] }), isMobile && showArrows && (_jsx("div", { style: Object.assign(Object.assign({}, (page >= lastSlide && { visibility: 'hidden' })), { marginLeft: 12 }), children: _jsx(Button, { onClick: nextSlide, shape: "circle", icon: _jsx(RightOutlined, {}) }) }))] }));
    };
    const dot = (i) => {
        return (_jsx("div", { style: {
                opacity: 0.3,
                width: 24,
                height: 4,
                borderRadius: 100,
            } }));
    };
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: slides,
        slidesToScroll: slides,
        arrows: false,
        appendDots: slideDots,
        customPaging: dot,
    };
    return (_jsxs("div", { className: 'turingoCarousel', style: { display: 'flex', justifyContent: 'center' }, children: [!isMobile && showArrows && (_jsx("div", { style: Object.assign({ alignSelf: 'center', paddingBottom: 70 }, (page == 0 && { visibility: 'hidden' })), children: _jsx(Button, { onClick: prevSlide, shape: "circle", icon: _jsx(LeftOutlined, {}) }) })), _jsx("div", { style: { maxWidth: 1284, marginBottom: 70 }, children: _jsx(Slider, Object.assign({ ref: sliderRef }, settings, { beforeChange: afterChangeHandler, className: !showArrows ? style.maxWidth : '', children: props.children })) }), !isMobile && showArrows && (_jsx("div", { style: Object.assign({ alignSelf: 'center', paddingBottom: 70 }, (page >= lastSlide && { visibility: 'hidden' })), children: _jsx(Button, { onClick: nextSlide, shape: "circle", icon: _jsx(RightOutlined, {}) }) }))] }));
};
