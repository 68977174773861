import { useCallback, useEffect, useRef } from 'react';
import { authURL, STATES } from './utils';
const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    return `left=${left},top=${top},width=${width},height=${height}`;
};
export const generateRandomString = (length = 20) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
export function useOAuth({ provider, redirectUri, clientId, onSuccess, onError, state = '', closePopupMessage = 'User closed the popup' }) {
    const popupRef = useRef(null);
    const popUpIntervalRef = useRef(null);
    let error = true;
    const receiveMessage = useCallback((event) => {
        const savedState = localStorage.getItem(STATES[provider]);
        if (event.origin === window.location.origin) {
            if (event.data.errorMessage && event.data.from === 'Turingo') {
                if (event.data.state !== savedState) {
                    popupRef.current && popupRef.current.close();
                    return;
                }
                onError && onError(event.data);
                popupRef.current && popupRef.current.close();
            }
            else if (event.data.code && event.data.from === 'Turingo') {
                if (event.data.state !== savedState) {
                    popupRef.current && popupRef.current.close();
                    return;
                }
                error = false;
                onSuccess && onSuccess(event.data.code);
                popupRef.current && popupRef.current.close();
                return;
            }
        }
    }, [onError, onSuccess]);
    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);
        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, [receiveMessage]);
    const getUrl = () => {
        const generatedState = state || generateRandomString();
        localStorage.setItem(STATES[provider], generatedState);
        return authURL(provider, clientId, redirectUri, generatedState);
    };
    const socialLogin = () => {
        var _a;
        (_a = popupRef.current) === null || _a === void 0 ? void 0 : _a.close();
        popupRef.current = window.open(getUrl(), '_blank', getPopupPositionProperties({ width: 600, height: 600 }));
        if (popUpIntervalRef.current) {
            window.clearInterval(popUpIntervalRef.current);
            popUpIntervalRef.current = null;
        }
        popUpIntervalRef.current = window.setInterval(() => {
            try {
                if (popupRef.current && popupRef.current.closed) {
                    window.clearInterval(popUpIntervalRef.current);
                    popUpIntervalRef.current = null;
                    console.log('error', error);
                    if (onError && error) {
                        onError({
                            error: 'user_closed_popup',
                            errorMessage: closePopupMessage,
                        });
                    }
                }
            }
            catch (error) {
                console.error(error);
                if (onError) {
                    onError({
                        error: 'user_closed_popup',
                        errorMessage: closePopupMessage,
                    });
                }
                window.clearInterval(popUpIntervalRef.current);
                popUpIntervalRef.current = null;
            }
        }, 1000);
    };
    return socialLogin;
}
