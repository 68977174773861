import { createSlice } from '@reduxjs/toolkit';
import { commentCreate, commentDelete, commentEdit, commentList, commentSection, postCreate, postDetails, postAdminDetails, postList, postReaction, postReactionDelete, replyCreate, replyList, communityPosts, postDelete, postEdit, postPin, postUnpin, postVote, rateLessonCreate, postReactionList, postOrder, postManyReaction, quizCreate, postAttachmentCreate, postLinkCreate, postAttachmentDelete, postLinkDelete, postLinkEdit, quizEdit, postRefresh, } from '@state/actions';
const defaultState = {
    all: {},
    detail: {},
};
const postsSlice = createSlice({
    name: 'posts',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postDetails.pending, (state, { meta }) => {
            if (!state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: {
                                items: {},
                                listState: { loading: false, error: undefined },
                            },
                        },
                    },
                };
            }
            else {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    item: undefined,
                    state: {
                        loading: true,
                        error: undefined,
                    },
                    childrens: {
                        items: {},
                        listState: { loading: false, error: undefined },
                    },
                };
            }
        });
        builder.addCase(postDetails.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            };
        });
        builder.addCase(postDetails.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: payload === null || payload === void 0 ? void 0 : payload.data,
                state: {
                    loading: false,
                    error: undefined,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            };
        });
        builder.addCase(postRefresh.pending, (state, { meta }) => {
            var _a, _b;
            if ((_b = (_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { state: {
                        loading: false,
                        editing: true,
                        error: undefined,
                    } });
            }
        });
        builder.addCase(postRefresh.fulfilled, (state, { payload, meta }) => {
            var _a, _b;
            if ((_b = (_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { item: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item), { interactions: payload.data.interactions }), state: {
                        loading: false,
                        editing: false,
                        error: undefined,
                    } });
            }
        });
        builder.addCase(postAdminDetails.pending, (state, { meta }) => {
            if (!state.detail[meta.arg.urlParams.b_pk]) {
                state.detail[meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: {
                                items: {},
                                listState: { loading: false, error: undefined },
                            },
                        },
                    },
                };
            }
            else {
                state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    item: undefined,
                    state: {
                        loading: true,
                        error: undefined,
                    },
                    childrens: {
                        items: {},
                        listState: { loading: false, error: undefined },
                    },
                };
            }
        });
        builder.addCase(postAdminDetails.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            };
        });
        builder.addCase(postAdminDetails.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: payload === null || payload === void 0 ? void 0 : payload.data,
                state: {
                    loading: false,
                    error: undefined,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            };
        });
        builder.addCase(postReactionList.fulfilled, (state, { payload, meta }) => {
            var _a, _b, _c, _d, _e, _f;
            if ((_c = (_b = (_a = state.all) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[meta.arg.urlParams.target_pk]) {
                state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk] = Object.assign(Object.assign({}, state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]), { item: Object.assign(Object.assign(Object.assign(Object.assign({}, state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), (meta.arg.searchParams.kind == 'react' && payload.total && { totalReactions: payload.total })), (meta.arg.searchParams.kind == 'pin_read' && payload.total && { totalReads: payload.total })), (meta.arg.searchParams.kind == 'pin_view' && payload.total && { totalViews: payload.total })) });
            }
            if ((_f = (_e = (_d = state.detail) === null || _d === void 0 ? void 0 : _d[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _e === void 0 ? void 0 : _e.items) === null || _f === void 0 ? void 0 : _f[meta.arg.urlParams.target_pk]) {
                state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk] = Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]), { item: Object.assign(Object.assign(Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), (meta.arg.searchParams.kind == 'react' && payload.total && { totalReactions: payload.total })), (meta.arg.searchParams.kind == 'pin_read' && payload.total && { totalReads: payload.total })), (meta.arg.searchParams.kind == 'pin_view' && payload.total && { totalViews: payload.total })) });
            }
        });
        builder.addCase(postList.pending, (state, { meta }) => {
            var _a, _b, _c, _d;
            if (((_a = meta.arg.headerParams) === null || _a === void 0 ? void 0 : _a.page) && ((_b = meta.arg.headerParams) === null || _b === void 0 ? void 0 : _b.page) != 'first') {
                state.all[meta.arg.urlParams.b_pk] = {
                    items: state.all[meta.arg.urlParams.b_pk] ? state.all[meta.arg.urlParams.b_pk].items : {},
                    listState: Object.assign({ loading: true }, (((_c = meta.arg.searchParams) === null || _c === void 0 ? void 0 : _c.topic) && { topic: (_d = meta.arg.searchParams) === null || _d === void 0 ? void 0 : _d.topic })),
                    saved: {},
                };
            }
            else {
                state.all[meta.arg.urlParams.b_pk] = {
                    items: {},
                    listState: {
                        loading: true,
                    },
                    saved: {},
                };
            }
        });
        builder.addCase(postList.rejected, (state, { meta, payload }) => {
            var _a, _b;
            state.all[meta.arg.urlParams.b_pk] = {
                items: {},
                listState: Object.assign({ loading: false, error: payload }, (((_a = meta.arg.searchParams) === null || _a === void 0 ? void 0 : _a.topic) && { topic: (_b = meta.arg.searchParams) === null || _b === void 0 ? void 0 : _b.topic })),
                saved: {},
            };
        });
        builder.addCase(postOrder.fulfilled, (state, { payload, meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: Object.assign({}, payload.data.posts.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: state.all[meta.arg.urlParams.b_pk].items[v.publicKey].item, loading: false } })), {})),
                listState: {
                    loading: false,
                },
                saved: {},
            };
        });
        builder.addCase(postOrder.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: Object.assign({}, state.all[meta.arg.urlParams.b_pk].items),
                listState: {
                    loading: false,
                },
                saved: {},
            };
        });
        builder.addCase(postOrder.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: Object.assign({}, state.all[meta.arg.urlParams.b_pk].items),
                listState: {
                    loading: false,
                    error: payload,
                },
                saved: {},
            };
        });
        builder.addCase(postList.fulfilled, (state, { payload, meta }) => {
            var _a, _b;
            state.all[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk]), { items: Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk].items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})), listState: Object.assign({ loading: false, pagination: payload.pagination }, (((_a = meta.arg.searchParams) === null || _a === void 0 ? void 0 : _a.topic) && { topic: (_b = meta.arg.searchParams) === null || _b === void 0 ? void 0 : _b.topic })), saved: {} });
        });
        builder.addCase(communityPosts.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f;
            if (((_a = meta.arg.headerParams) === null || _a === void 0 ? void 0 : _a.page) && ((_b = meta.arg.headerParams) === null || _b === void 0 ? void 0 : _b.page) != 'first') {
                state.all['feed'] = {
                    items: state.all['feed'] ? state.all['feed'].items : {},
                    listState: Object.assign({ loading: true }, (((_c = meta.arg.searchParams) === null || _c === void 0 ? void 0 : _c.topic) && { topic: (_d = meta.arg.searchParams) === null || _d === void 0 ? void 0 : _d.topic })),
                    saved: {},
                };
            }
            else {
                state.all['feed'] = {
                    items: {},
                    listState: Object.assign({ loading: true }, (((_e = meta.arg.searchParams) === null || _e === void 0 ? void 0 : _e.topic) && { topic: (_f = meta.arg.searchParams) === null || _f === void 0 ? void 0 : _f.topic })),
                    saved: {},
                };
            }
        });
        builder.addCase(communityPosts.rejected, (state, { meta, payload }) => {
            var _a, _b;
            state.all['feed'] = {
                items: {},
                listState: Object.assign({ loading: false, error: payload }, (((_a = meta.arg.searchParams) === null || _a === void 0 ? void 0 : _a.topic) && { topic: (_b = meta.arg.searchParams) === null || _b === void 0 ? void 0 : _b.topic })),
                saved: {},
            };
        });
        builder.addCase(communityPosts.fulfilled, (state, { payload, meta }) => {
            var _a, _b;
            state.all['feed'] = Object.assign(Object.assign({}, state.all['feed']), { items: Object.assign(Object.assign({}, state.all['feed'].items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})), listState: Object.assign({ loading: false, pagination: payload.pagination }, (((_a = meta.arg.searchParams) === null || _a === void 0 ? void 0 : _a.topic) && { topic: (_b = meta.arg.searchParams) === null || _b === void 0 ? void 0 : _b.topic })), saved: {} });
        });
        builder.addCase(commentList.pending, (state, { meta }) => {
            if (!state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) {
                state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { listState: { loading: true } }),
                        },
                    },
                };
            }
            else {
                if (!state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]) {
                    state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        item: undefined,
                        state: {
                            loading: true,
                            error: undefined,
                        },
                        childrens: Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { listState: { loading: true } }),
                    };
                }
                else {
                    state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { listState: { loading: true } }) });
                }
            }
        });
        builder.addCase(commentList.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { listState: { loading: true, error: payload } }) });
        });
        builder.addCase(commentList.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: {
                    items: Object.assign(Object.assign({}, state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(commentSection.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e;
            if (!state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: false,
                                error: undefined,
                            },
                            childrens: {
                                items: Object.assign({}, (((_b = (_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _a === void 0 ? void 0 : _a.items[meta.arg.urlParams.p_pk].childrens) === null || _b === void 0 ? void 0 : _b.items) && Object.assign({}, (_c = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.p_pk].childrens.items))),
                                listState: { loading: true },
                            },
                        },
                    },
                };
            }
            else {
                if (!state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]) {
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        item: undefined,
                        state: {
                            loading: true,
                            error: undefined,
                        },
                        childrens: {
                            items: Object.assign({}, (((_d = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens) === null || _d === void 0 ? void 0 : _d.items) && Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens.items))),
                            listState: { loading: true },
                        },
                    };
                }
                else {
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: {
                            items: Object.assign({}, (((_e = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens) === null || _e === void 0 ? void 0 : _e.items) && Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens.items))),
                            listState: { loading: true },
                        } });
                }
            }
        });
        builder.addCase(commentSection.rejected, (state, { meta, payload }) => {
            var _a, _b, _c;
            if ((_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _a === void 0 ? void 0 : _a.items[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, (_b = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.p_pk]), { childrens: Object.assign(Object.assign({}, (_c = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.p_pk].childrens), { listState: { loading: true, error: payload } }) });
            }
        });
        builder.addCase(commentSection.fulfilled, (state, { payload, meta }) => {
            var _a;
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: {
                    items: Object.assign(Object.assign({}, (_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]) === null || _a === void 0 ? void 0 : _a.childrens.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: {
                            item: v,
                            loading: false,
                            childrens: { items: {}, listState: { loading: false }, createState: { loading: false } },
                        } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(commentCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                        .childrens.items), createState: { loading: true } }) });
        });
        builder.addCase(commentCreate.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                        .childrens.items), createState: { loading: false, error: payload } }) });
        });
        builder.addCase(commentCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]), { item: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item), { totalComments: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item
                        .totalComments + 1 }), childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens), { items: Object.assign({ [payload.data.publicKey]: {
                            childrens: {
                                items: undefined,
                                listState: {
                                    loading: false,
                                },
                                createState: {
                                    loading: false,
                                },
                            },
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                        .childrens.items), createState: { loading: false } }) });
        });
        builder.addCase(commentDelete.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.listState), { loading: true });
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: true,
                };
            }
        });
        builder.addCase(commentDelete.rejected, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.listState), { loading: false, error: payload });
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                    error: payload,
                };
            }
        });
        builder.addCase(commentDelete.fulfilled, (state, { meta }) => {
            let numberOfReplies = 0;
            if (meta.arg.extraParams.parents.length == 1) {
                numberOfReplies +=
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.c_pk].item.totalComments;
            }
            delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                .childrens.items[meta.arg.urlParams.c_pk];
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].item.totalComments -= 1 + numberOfReplies;
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                .childrens.listState), { loading: false });
            if (meta.arg.extraParams.parents.length == 2) {
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk];
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].item.totalComments -= 1;
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                };
            }
        });
        builder.addCase(postDelete.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState), { loading: true });
            }
            else if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = {
                    loading: true,
                };
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: true,
                };
            }
        });
        builder.addCase(postDelete.rejected, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState), { loading: false, error: payload });
            }
            else if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = {
                    loading: false,
                    error: payload,
                };
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                    error: payload,
                };
            }
        });
        builder.addCase(postDelete.fulfilled, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk];
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState), { loading: false });
            }
            else if (meta.arg.extraParams.parents.length == 1) {
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk];
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].item.totalComments -= 1;
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.listState = {
                    loading: false,
                };
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk];
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].item.totalComments -= 1;
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                };
            }
        });
        builder.addCase(commentEdit.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.c_pk]), { state: {
                        loading: true,
                    } });
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk]), { state: {
                        loading: true,
                    } });
            }
        });
        builder.addCase(commentEdit.rejected, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.c_pk]), { state: {
                        loading: false,
                    } });
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk]), { state: {
                        loading: false,
                    } });
            }
        });
        builder.addCase(commentEdit.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens = {
                    items: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items), { [payload.data.publicKey]: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[payload.data.publicKey]), { item: payload.data, state: {
                                loading: false,
                            } }) }),
                    listState: { loading: false },
                };
            }
            else if (meta.arg.extraParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens = {
                    items: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.items), { [payload.data.publicKey]: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[payload.data.publicKey].item), { item: payload.data, state: {
                                loading: false,
                            } }) }),
                    listState: { loading: false },
                };
            }
        });
        builder.addCase(postPin.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: true,
                },
            };
        });
        builder.addCase(postPin.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items), { [meta.arg.urlParams.p_pk]: {
                    item: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item), { pin: meta.arg.bodyParams.mandatory ? 'mandatory' : 'normal' }),
                    state: {
                        loading: false,
                    },
                } });
        });
        builder.addCase(postUnpin.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: true,
                },
            };
        });
        builder.addCase(postUnpin.fulfilled, (state, { meta }) => {
            if (meta.arg.extraParams.highlight) {
                delete state[meta.arg.extraParams.scope]['feed'].items[meta.arg.urlParams.p_pk];
            }
            else {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items), { [meta.arg.urlParams.p_pk]: {
                        item: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                            .item), { pin: undefined, totalReads: 0, totalViews: 0 }),
                        state: {
                            loading: false,
                        },
                    } });
            }
        });
        builder.addCase(postEdit.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: false,
                    editing: true,
                },
            };
        });
        builder.addCase(postEdit.rejected, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: false,
                    editing: false,
                },
            };
        });
        builder.addCase(postEdit.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items), { [payload.data.publicKey]: {
                    item: Object.assign(Object.assign({}, payload.data), { board: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[payload.data.publicKey]
                            .item.board }),
                    state: {
                        loading: false,
                        editing: false,
                    },
                } });
        });
        builder.addCase(quizEdit.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk] = {
                item: state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk].item,
                state: {
                    loading: true,
                },
            };
        });
        builder.addCase(quizEdit.rejected, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk] = {
                item: state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk].item,
                state: {
                    loading: false,
                },
            };
        });
        builder.addCase(quizEdit.fulfilled, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk].items = Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk].items), { [payload.data.publicKey]: {
                    item: Object.assign({}, payload.data),
                    state: {
                        loading: false,
                    },
                } });
        });
        builder.addCase(postManyReaction.fulfilled, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
            for (const reference of meta.arg.bodyParams.postReferences) {
                if ((_c = (_b = (_a = state[meta.arg.extraParams.scope]) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[reference.postPk]) {
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk].items[reference.postPk].item = Object.assign(Object.assign({}, (_f = (_e = (_d = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk]) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[reference.postPk]) === null || _f === void 0 ? void 0 : _f.item), { totalViews: ((_l = (_k = (_j = (_h = (_g = state[meta.arg.extraParams.scope]) === null || _g === void 0 ? void 0 : _g[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]) === null || _h === void 0 ? void 0 : _h.items) === null || _j === void 0 ? void 0 : _j[reference.postPk]) === null || _k === void 0 ? void 0 : _k.item) === null || _l === void 0 ? void 0 : _l.totalViews)
                            ? ((_p = (_o = (_m = state[meta.arg.extraParams.scope]) === null || _m === void 0 ? void 0 : _m[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]) === null || _o === void 0 ? void 0 : _o.items) === null || _p === void 0 ? void 0 : _p[reference.postPk].item.totalViews) + 1
                            : 1, interactions: Object.assign(Object.assign({}, (_s = (_r = (_q = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk].items) === null || _q === void 0 ? void 0 : _q[reference.postPk]) === null || _r === void 0 ? void 0 : _r.item) === null || _s === void 0 ? void 0 : _s.interactions), { pinView: true }) });
                }
            }
        });
        builder.addCase(postReaction.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            if (!meta.arg.bodyParams.parents) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), (meta.arg.bodyParams.kind == 'react' && {
                    totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                        .item.totalReactions + 1,
                })), (meta.arg.bodyParams.kind == 'pin_read' && {
                    totalReads: ((_d = (_c = (_b = (_a = state[meta.arg.extraParams.scope]) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[meta.arg.urlParams.target_pk]) === null || _d === void 0 ? void 0 : _d.item.totalReads)
                        ? ((_g = (_f = (_e = state[meta.arg.extraParams.scope]) === null || _e === void 0 ? void 0 : _e[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _f === void 0 ? void 0 : _f.items) === null || _g === void 0 ? void 0 : _g[meta.arg.urlParams.target_pk].item.totalReads) + 1
                        : 1,
                })), (meta.arg.bodyParams.kind == 'pin_view' && {
                    totalViews: ((_m = (_l = (_k = (_j = (_h = state[meta.arg.extraParams.scope]) === null || _h === void 0 ? void 0 : _h[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _j === void 0 ? void 0 : _j.items) === null || _k === void 0 ? void 0 : _k[meta.arg.urlParams.target_pk]) === null || _l === void 0 ? void 0 : _l.item) === null || _m === void 0 ? void 0 : _m.totalViews)
                        ? ((_q = (_p = (_o = state[meta.arg.extraParams.scope]) === null || _o === void 0 ? void 0 : _o[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) === null || _p === void 0 ? void 0 : _p.items) === null || _q === void 0 ? void 0 : _q[meta.arg.urlParams.target_pk].item.totalViews) + 1
                        : 1,
                })), { interactions: Object.assign(Object.assign(Object.assign(Object.assign({}, (_t = (_s = (_r = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items) === null || _r === void 0 ? void 0 : _r[meta.arg.urlParams.target_pk]) === null || _s === void 0 ? void 0 : _s.item) === null || _t === void 0 ? void 0 : _t.interactions), (meta.arg.bodyParams.kind == 'react' && { react: meta.arg.bodyParams.content })), (meta.arg.bodyParams.kind == 'pin_read' && { pinRead: true })), (meta.arg.bodyParams.kind == 'pin_view' && { pinView: true })) });
            }
            else if (meta.arg.bodyParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1, reaction: {
                        content: meta.arg.bodyParams.content,
                    } });
            }
            else if (meta.arg.bodyParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1, reaction: {
                        content: meta.arg.bodyParams.content,
                    } });
            }
        });
        builder.addCase(postReaction.rejected, (state, { meta }) => {
            var _a, _b, _c;
            if (!meta.arg.bodyParams.parents) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), (meta.arg.bodyParams.kind == 'react' && {
                    totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                        .item.totalReactions - 1,
                })), { interactions: Object.assign(Object.assign(Object.assign(Object.assign({}, (_c = (_b = (_a = state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items) === null || _a === void 0 ? void 0 : _a[meta.arg.urlParams.target_pk]) === null || _b === void 0 ? void 0 : _b.item) === null || _c === void 0 ? void 0 : _c.interactions), (meta.arg.bodyParams.kind == 'react' && { react: null })), (meta.arg.bodyParams.kind == 'pin_read' && { pinRead: null })), (meta.arg.bodyParams.kind == 'pin_view' && { pinView: null })) });
            }
            else if (meta.arg.bodyParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1, reaction: null });
            }
            else if (meta.arg.bodyParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1, reaction: null });
            }
        });
        builder.addCase(postVote.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), { poll: payload.data.poll });
        });
        builder.addCase(postReactionDelete.pending, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item
                        .totalReactions - 1, interactions: Object.assign(Object.assign(Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                        .item.interactions), (meta.arg.bodyParams.kind == 'react' && { react: null })), (meta.arg.bodyParams.kind == 'pin_read' && { pinRead: null })), (meta.arg.bodyParams.kind == 'pin_view' && { pinView: null })) });
            }
            else if (meta.arg.bodyParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1, reaction: undefined });
            }
            else if (meta.arg.bodyParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1, reaction: undefined });
            }
        });
        builder.addCase(postReactionDelete.rejected, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item
                        .totalReactions + 1, interactions: Object.assign(Object.assign(Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                        .item.interactions), (meta.arg.bodyParams.kind == 'react' && { react: meta.arg.bodyParams.content })), (meta.arg.bodyParams.kind == 'pin_read' && { pinRead: true })), (meta.arg.bodyParams.kind == 'pin_view' && { pinView: true })) });
            }
            else if (meta.arg.bodyParams.parents.length == 1) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1, reaction: {
                        content: meta.arg.bodyParams.content,
                    } });
            }
            else if (meta.arg.bodyParams.parents.length == 2) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                    .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item), { totalReactions: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1, reaction: {
                        content: meta.arg.bodyParams.content,
                    } });
            }
        });
        builder.addCase(rateLessonCreate.fulfilled, (state, { meta }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.lessonRate = {
                rate: meta.arg.bodyParams.rate,
                content: meta.arg.bodyParams.content,
            };
        });
        builder.addCase(postCreate.pending, (state, { meta }) => {
            var _a, _b;
            state.all[((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.feed) ? 'feed' : meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[((_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.feed) ? 'feed' : meta.arg.urlParams.b_pk]), { createState: { loading: true } });
        });
        builder.addCase(postCreate.rejected, (state, { meta, payload }) => {
            var _a, _b;
            state.all[((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.feed) ? 'feed' : meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[((_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.feed) ? 'feed' : meta.arg.urlParams.b_pk]), { createState: { loading: false, error: payload } });
        });
        builder.addCase(postCreate.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c, _d, _e;
            state.all[((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.feed) ? 'feed' : meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[((_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.feed) ? 'feed' : meta.arg.urlParams.b_pk]), { items: Object.assign(Object.assign(Object.assign({}, ((!meta.arg.extraParams.position || meta.arg.extraParams.position == 'first') && {
                    [payload.data.publicKey]: {
                        item: Object.assign(Object.assign({}, payload.data), (!((_c = meta.arg.extraParams) === null || _c === void 0 ? void 0 : _c.feed) && { board: undefined })),
                        state: {
                            loading: false,
                        },
                    },
                })), state.all[((_d = meta.arg.extraParams) === null || _d === void 0 ? void 0 : _d.feed) ? 'feed' : meta.arg.urlParams.b_pk].items), (meta.arg.extraParams.position == 'last' && {
                    [payload.data.publicKey]: {
                        item: Object.assign(Object.assign({}, payload.data), (!((_e = meta.arg.extraParams) === null || _e === void 0 ? void 0 : _e.feed) && { board: undefined })),
                        state: {
                            loading: false,
                        },
                    },
                })), createState: { loading: false } });
        });
        builder.addCase(quizCreate.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk]), { createState: { loading: true } });
        });
        builder.addCase(quizCreate.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk]), { createState: { loading: false, error: payload } });
        });
        builder.addCase(quizCreate.fulfilled, (state, { meta, payload }) => {
            var _a, _b;
            state.all[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.all[meta.arg.urlParams.b_pk]), { items: Object.assign(Object.assign(Object.assign({}, ((!meta.arg.extraParams.position || meta.arg.extraParams.position == 'first') && {
                    [payload.data.publicKey]: {
                        item: Object.assign(Object.assign({}, payload.data), (!((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.feed) && { board: undefined })),
                        state: {
                            loading: false,
                        },
                    },
                })), state.all[meta.arg.urlParams.b_pk].items), (meta.arg.extraParams.position == 'last' && {
                    [payload.data.publicKey]: {
                        item: Object.assign(Object.assign({}, payload.data), (!((_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.feed) && { board: undefined })),
                        state: {
                            loading: false,
                        },
                    },
                })), createState: { loading: false } });
        });
        builder.addCase(replyList.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), listState: { loading: true } }) });
        });
        builder.addCase(replyList.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), listState: { loading: false, error: payload } }) });
        });
        builder.addCase(replyList.fulfilled, (state, { payload, meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign(Object.assign({}, payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: {
                            item: v,
                            loading: false,
                            childrens: { items: {}, listState: { loading: false }, createState: { loading: false } },
                        } })), {})), state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), listState: { loading: false, pagination: payload.pagination } }) });
        });
        builder.addCase(replyCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), createState: { loading: true } }) });
        });
        builder.addCase(replyCreate.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), createState: { loading: false, error: payload } }) });
        });
        builder.addCase(replyCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].item.totalComments += 1;
            if (meta.arg.bodyParams.parents[0] != meta.arg.urlParams.c_pk) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]].childrens.items[meta.arg.urlParams.c_pk].item.totalComments += 1;
            }
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[meta.arg.urlParams.c_pk] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                .items[meta.arg.urlParams.c_pk]), { childrens: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                    .childrens.items[meta.arg.urlParams.c_pk].childrens), { items: Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }), createState: { loading: false } }) });
        });
        builder.addCase(postAttachmentCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.assets.attachments = payload.data;
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false;
        });
        builder.addCase(postAttachmentCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true;
        });
        builder.addCase(postLinkCreate.fulfilled, (state, { meta, payload }) => {
            if (state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links) {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links = [
                    ...state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links,
                    ...payload.data,
                ];
            }
            else {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links = payload.data;
            }
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false;
        });
        builder.addCase(postLinkCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true;
        });
        builder.addCase(postLinkEdit.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links[meta.arg.extraParams.index] = Object.assign(Object.assign({}, state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links[meta.arg.extraParams.index]), { title: meta.arg.bodyParams.title, url: meta.arg.bodyParams.url });
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false;
        });
        builder.addCase(postLinkEdit.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true;
        });
        builder.addCase(postAttachmentDelete.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.assets.attachments.splice(meta.arg.extraParams.index, 1);
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false;
        });
        builder.addCase(postAttachmentDelete.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true;
        });
        builder.addCase(postLinkDelete.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links.splice(meta.arg.extraParams.index, 1);
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false;
        });
        builder.addCase(postLinkDelete.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true;
        });
    },
});
export { postsSlice };
