import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileTextOutlined, FileZipOutlined, FilePdfOutlined, FileWordOutlined, FileOutlined, FileExcelOutlined, FilePptOutlined } from '@ant-design/icons';
import { formatBytes } from '@util/string/Functions';
import { Colors } from '@util/color/Colors';
import { Button, Spin } from 'antd';
import { Download } from '@icon-park/react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { useState } from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon';
import { ArrowLeftIcon } from '@components/icons/ArrowLeftIcon';
import useBreakpoints from '@hooks/useBreakpoints';
export const GetTypeIcon = (type, style = { fontSize: 24, marginRight: 16, color: Colors('ICON') }) => {
    return type == 'application/pdf' ? (_jsx(FilePdfOutlined, { style: style })) : type == 'application/vnd.ms-excel' ||
        type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        type == 'application/msexcel' ||
        type == 'application/x-msexcel' ||
        type == 'application/x-ms-excel' ? (_jsx(FileExcelOutlined, { style: style })) : type == 'application/vnd.ms-powerpoint' || type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ? (_jsx(FilePptOutlined, { style: style })) : type == 'application/msword' || type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (_jsx(FileWordOutlined, { style: style })) : type == 'text/plain' || type == 'text/csv' ? (_jsx(FileTextOutlined, { style: style })) : type == 'application/x-rar-compressed' || type == 'application/zip' ? (_jsx(FileZipOutlined, { style: style })) : (_jsx(FileOutlined, { style: style }));
};
export const AttachmentComponent = (props) => {
    const { attachment, showPreview = true } = props;
    const type = attachment.mimeType;
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const [numPages, setNumPages] = useState(1);
    const breakpoints = useBreakpoints();
    const [page, setPage] = useState(1);
    function onDocumentLoadSuccess(pdf) {
        setNumPages(pdf === null || pdf === void 0 ? void 0 : pdf.numPages);
    }
    const hasNextPage = page < numPages;
    const hasPreviousPage = page > 1;
    const nextPage = () => {
        if (hasNextPage)
            setPage(page + 1);
    };
    const previousPage = () => {
        if (hasPreviousPage)
            setPage(page - 1);
    };
    if (type == 'application/pdf' && showPreview) {
        return (_jsxs("div", { children: [_jsxs("div", { style: { backgroundColor: 'black', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative' }, children: [_jsxs("div", { style: { alignSelf: 'center', display: 'flex', alignItems: 'center' }, children: [_jsx(Button, { className: hasPreviousPage ? 'hover-svg' : 'disabled-svg', icon: _jsx(ArrowLeftIcon, { height: 14, width: 14, color: "white", style: { display: 'flex' } }), type: "text", disabled: !hasPreviousPage, onClick: previousPage }), _jsx(Paragraph, { className: "turingo-text", style: { color: 'white' }, children: `${page}/${numPages}` }), _jsx(Button, { className: hasNextPage ? 'hover-svg' : 'disabled-svg', icon: _jsx(ArrowRightIcon, { color: "white", style: { display: 'flex' } }), type: "text", disabled: !hasNextPage, onClick: nextPage })] }), _jsx("div", { style: { position: 'absolute', top: 0, right: 0 }, children: _jsx(Button, { onClick: () => {
                                    !isLogged && dispatch(modalAction({ modal: 'see_more', state: true }));
                                }, type: "text", style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, href: isLogged ? attachment.url : undefined, icon: _jsx(Download, { style: { display: 'flex', color: 'white' }, theme: "outline", size: "16" }) }) })] }), _jsx("div", { style: { padding: 16, backgroundColor: 'var(--neutral-8)' }, children: _jsx("div", { style: { maxHeight: 600, maxWidth: !breakpoints.isMobile ? 584 : '100vw', overflowY: 'auto', boxShadow: '0px 3px 14px 0px' }, children: _jsx(SizeMe, { children: ({ size }) => (_jsx(Document, { loading: _jsx("div", { style: { justifyContent: 'center', display: 'flex', padding: 24, backgroundColor: 'white' }, children: _jsx(Spin, {}) }), file: attachment.url, onLoadSuccess: onDocumentLoadSuccess, children: _jsx(Page, { pageNumber: page, renderAnnotationLayer: false, renderTextLayer: false, width: size.width ? size.width : 1 }) })) }) }) })] }));
    }
    return (_jsxs("div", { style: {
            backgroundColor: Colors('POSTED_POLL_BACKGROUND'),
            minHeight: 68,
            padding: 16,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center',
        }, children: [GetTypeIcon(type), _jsxs("div", { style: { display: 'flex', flexDirection: 'column', paddingRight: 50, flexGrow: 1, wordBreak: 'break-all' }, children: [_jsx("div", { style: { fontWeight: 500, lineHeight: '22px' }, children: attachment.name }), attachment.size && _jsx("div", { style: { fontSize: 12, lineHeight: '20px' }, children: formatBytes(attachment.size) })] }), _jsx("div", { children: _jsx(Button, { onClick: () => {
                        !isLogged && dispatch(modalAction({ modal: 'see_more', state: true }));
                    }, style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, href: isLogged ? attachment.url : undefined, shape: "circle", icon: _jsx(Download, { style: { display: 'flex' }, theme: "outline", size: "16" }) }) })] }));
};
