import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Tooltip } from 'antd';
import ClassNames from '@util/style/ClassNames';
import Style from './ButtonPost.scss';
import { ChartHistogramTwo, PictureOne, VideoTwo } from '@icon-park/react';
import { FileTextOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
const ButtonPost = (props) => {
    const { kind, disabled, onClick, hideTitle, style } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsx(Tooltip, { title: !disabled && strings.screens.boards.discussion.post.kind[kind].placeholder, children: _jsxs("div", { onClick: !disabled && onClick, style: Object.assign({ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'start' : 'center', fontSize: isMobile ? 16 : 14 }, style), className: ClassNames(Style.button, disabled ? Style.disabled_color : Style[`color_${kind}`]), children: [_jsxs("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: isMobile ? 14 : 12,
                    }, className: ClassNames(Style.circle, disabled ? Style.disabled_background : Style[`background_${kind}`]), children: [kind == 'image' && _jsx(PictureOne, { style: { display: 'flex', alignItems: 'center' } }), kind == 'video' && _jsx(VideoTwo, { style: { display: 'flex', alignItems: 'center' } }), kind == 'poll' && _jsx(ChartHistogramTwo, { style: { display: 'flex', alignItems: 'center' } }), kind == 'attachment' && _jsx(FileTextOutlined, { style: { display: 'flex', alignItems: 'center' } })] }), kind == 'image' && !isMobile && !hideTitle && (_jsx(Paragraph, { style: { marginLeft: 4, color: 'inherit' }, children: strings.screens.boards.discussion.post.kind.image.name })), kind == 'video' && !isMobile && !hideTitle && (_jsx(Paragraph, { style: { marginLeft: 4, color: 'inherit' }, children: strings.screens.boards.discussion.post.kind.video.name })), kind == 'poll' && !isMobile && !hideTitle && (_jsx(Paragraph, { style: { marginLeft: 4, color: 'inherit' }, children: strings.screens.boards.discussion.post.kind.poll.name })), kind == 'attachment' && !isMobile && !hideTitle && (_jsx(Paragraph, { style: { marginLeft: 4, color: 'inherit' }, children: strings.screens.boards.discussion.post.kind.attachment.name }))] }) }));
};
export { ButtonPost };
