import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import strings from '@resources/localization';
import { postPin } from '@state/actions';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Switch } from 'antd';
import { Check, Close } from '@icon-park/react';
export const PinModal = (props) => {
    var _a, _b, _c;
    const { cm_pk, b_pk, p_pk } = useParams();
    const { open, onCancel, target } = props;
    const dispatch = useAppDispatch();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isWhatsAppActive = ((_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.plugins) === null || _b === void 0 ? void 0 : _b.whatsapp) === null || _c === void 0 ? void 0 : _c.state) == 'active';
    const [mandatory, setMandatory] = useState(true);
    const [sendEmail, setSendEmail] = useState(true);
    const [sendWhatsapp, setSendWhatsapp] = useState(isWhatsAppActive ? true : false);
    const scope = p_pk ? 'detail' : 'all';
    const isFeed = !b_pk;
    const postState = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[target.publicKey]) === null || _b === void 0 ? void 0 : _b.state; });
    const onPin = () => {
        void dispatch(postPin({
            urlParams: { cm_pk, b_pk: target.boardPk, p_pk: target.publicKey },
            bodyParams: {
                mandatory: mandatory,
                sendEmail: sendEmail,
                sendWhatsapp: sendWhatsapp,
            },
            extraParams: {
                scope: scope,
                feed: isFeed,
            },
            options: {
                alerts: {
                    success: 'La publicación ha sido destacada',
                    error: 'Error',
                },
                then: {
                    action: onCancel,
                },
            },
        }));
    };
    return (_jsxs(TuringoModal, { onCancel: onCancel, open: open, title: strings.screens.markasread.actions.highlight, width: 572, footer: [
            _jsx(FooterModal, { primary: {
                    action: onPin,
                    customText: strings.screens.markasread.actions.highlight,
                    loading: postState === null || postState === void 0 ? void 0 : postState.loading,
                }, secondary: {
                    action: onCancel,
                    customText: strings.general.cancel,
                } }, 'footer_modal'),
        ], children: [_jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.markasread.pin_modal.description }), _jsxs("div", { style: { marginBottom: 16, display: 'flex', justifyContent: 'space-between' }, children: [_jsxs("span", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("p", { style: { fontWeight: 500, marginBottom: 0 }, children: strings.screens.markasread.pin_modal.email.title }), _jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.markasread.pin_modal.email.description })] }), _jsx(Switch, { defaultChecked: true, onChange: (checked) => {
                            setSendEmail(checked);
                        }, style: { marginLeft: 16 }, checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }) })] }), isWhatsAppActive && (_jsxs("div", { style: { marginBottom: 16, display: 'flex', justifyContent: 'space-between' }, children: [_jsxs("span", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("p", { style: { fontWeight: 500, marginBottom: 0 }, children: strings.screens.markasread.pin_modal.whatsapp.title }), _jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.markasread.pin_modal.whatsapp.description })] }), _jsx(Switch, { defaultChecked: true, onChange: (checked) => {
                            setSendWhatsapp(checked);
                        }, style: { marginLeft: 16 }, checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }) })] })), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsxs("span", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("p", { style: { fontWeight: 500, marginBottom: 0 }, children: strings.screens.markasread.pin_modal.read.title }), _jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.markasread.pin_modal.read.description })] }), _jsx(Switch, { defaultChecked: true, onChange: (checked) => {
                            setMandatory(checked);
                        }, style: { marginLeft: 16 }, checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }) })] })] }));
};
