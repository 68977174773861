import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { userEdit } from '@state/actions';
import { FooterModal } from './components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import WhatsappVideo from '@bundle/video/whatsapp.mp4';
import ReactPlayer from 'react-player';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
export const WhatsAppModal = () => {
    var _a, _b;
    const cm_pk = useAppSelector((state) => state.community.currentCommunity);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const toProfile = () => {
        history.push(Router.Path.user_details({ cm_pk: cm_pk, u_pk: currentEntity.publicKey }));
    };
    const onSubmit = (redirect) => {
        dispatch(userEdit(Object.assign({ urlParams: {
                cm_pk: cm_pk,
            }, bodyParams: {
                onboardTourWhatsapp: false,
            }, extraParams: {
                u_pk: currentEntity.publicKey,
            } }, (redirect && {
            options: {
                then: { action: toProfile },
            },
        }))));
    };
    return (_jsxs(TuringoModal, { padding: "0px", closable: false, width: 572, open: (_b = (_a = currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.onboard) === null || _a === void 0 ? void 0 : _a.tour) === null || _b === void 0 ? void 0 : _b.whatsapp, footer: [
            _jsx(FooterModal, { style: { padding: isMobile ? '0px 16px 16px 16px' : '0px 24px 16px 16px' }, primary: {
                    action: () => {
                        onSubmit(false);
                    },
                    customText: 'Ok',
                }, secondary: {
                    action: () => {
                        onSubmit(true);
                    },
                    customText: strings.modals.whatsapp.toProfile,
                } }, 'footer_modal'),
        ], children: [_jsx(ReactPlayer, { playing: true, muted: true, controls: true, width: '100%', height: '100%', url: WhatsappVideo }), _jsxs("div", { style: { padding: isMobile ? '16px 16px 0px 16px' : '16px 24px 0px 24px' }, children: [_jsx("p", { style: { marginBottom: 8 }, className: "turingo-title", children: strings.modals.whatsapp.title }), _jsx("p", { className: "turingo-text", children: strings.modals.whatsapp.description })] })] }));
};
