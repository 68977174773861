import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch } from '@hooks/useStore';
import { authSocial } from '@state/actions/index';
import { cleanAuth } from '@state/reducers/Auth/AuthReducer';
import { SocialButton } from '@components/buttons/social_button/SocialButton';
import { useOAuth } from '@hooks/oauth';
import { useLocation, useParams } from 'react-router';
export const linkedInLogin = (onSuccess, onError) => {
    return useOAuth({
        provider: 'linkedin',
        clientId: OAUTH_LINKEDIN_ID,
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: onSuccess,
        onError: onError,
    });
};
export const GoogleLogin = (onSuccess, onError) => {
    return useOAuth({
        provider: 'google',
        clientId: OAUTH_GOOGLE_ID,
        redirectUri: `${window.location.origin}/google`,
        onSuccess: onSuccess,
        onError: onError,
    });
};
export const FacebookLogin = (onSuccess, onError) => {
    return useOAuth({
        provider: 'facebook',
        clientId: OAUTH_FACEBOOK_ID,
        redirectUri: `${window.location.origin}/facebook`,
        onSuccess: onSuccess,
        onError: onError,
    });
};
export const Social = (props) => {
    const { kind } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { cm_pk } = useParams();
    useEffect(() => {
        dispatch(cleanAuth());
    }, []);
    const onSuccessLinkedIn = (code) => {
        var _a, _b;
        void dispatch(authSocial({
            urlParams: { provider: 'linkedin' },
            bodyParams: {
                token: code,
                redirectUri: `${window.location.origin}/linkedin`,
                action: ((_a = location.state) === null || _a === void 0 ? void 0 : _a.token)
                    ? [
                        {
                            kind: 'join:private',
                            communityPk: cm_pk,
                            invitationToken: (_b = location.state) === null || _b === void 0 ? void 0 : _b.token,
                        },
                    ]
                    : undefined,
            },
        }));
    };
    const onSuccessFacebook = (code) => {
        var _a, _b;
        void dispatch(authSocial({
            urlParams: { provider: 'facebook' },
            bodyParams: {
                token: code,
                redirectUri: `${window.location.origin}/facebook`,
                action: ((_a = location.state) === null || _a === void 0 ? void 0 : _a.token)
                    ? [
                        {
                            kind: 'join:private',
                            communityPk: cm_pk,
                            invitationToken: (_b = location.state) === null || _b === void 0 ? void 0 : _b.token,
                        },
                    ]
                    : undefined,
            },
        }));
    };
    const onSuccessGoogle = (code) => {
        var _a, _b;
        void dispatch(authSocial({
            urlParams: { provider: 'google' },
            bodyParams: {
                token: code,
                redirectUri: `${window.location.origin}/google`,
                action: ((_a = location.state) === null || _a === void 0 ? void 0 : _a.token)
                    ? [
                        {
                            kind: 'join:private',
                            communityPk: cm_pk,
                            invitationToken: (_b = location.state) === null || _b === void 0 ? void 0 : _b.token,
                        },
                    ]
                    : undefined,
            },
        }));
    };
    const onError = (error) => {
        console.log(error);
    };
    const linkedIn = linkedInLogin(onSuccessLinkedIn, onError);
    const google = GoogleLogin(onSuccessGoogle, onError);
    const facebook = FacebookLogin(onSuccessFacebook, onError);
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx(SocialButton, { provider: "google", kind: kind, onClick: google }), _jsx(SocialButton, { provider: "facebook", kind: kind, onClick: facebook }), _jsx(SocialButton, { provider: "linkedin", kind: kind, onClick: linkedIn })] }) }));
};
