import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Colors } from '@util/color/Colors';
import { useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Close } from '@icon-park/react';
export const AlertActions = (props) => {
    const { message, onOk, onCancel, style, bodyStyle, banner, closable = true } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const [visible, setVisible] = useState(true);
    if (!visible)
        return _jsx(_Fragment, {});
    return (_jsx("div", { style: Object.assign({ background: Colors('ALERT_BLUE_BACKGROUND'), border: banner ? 'none' : `1px solid ${Colors('ALERT_BLUE_BORDER')}`, borderRadius: banner ? 0 : 2, padding: '16px 16px 16px 16px', position: 'relative' }, style), children: _jsxs("div", { style: Object.assign({ display: 'flex', alignItems: isMobile || !banner ? 'start' : 'center', flexDirection: isMobile || !banner ? 'column' : 'row', justifyContent: isMobile || !banner ? 'start' : 'space-between' }, bodyStyle), children: [_jsx("div", { style: { marginRight: isMobile ? 32 : 0, marginLeft: banner && !isMobile ? 32 : 0 }, children: message }), _jsxs("div", { style: {
                        marginRight: banner && !isMobile ? 32 : 0,
                        width: isMobile || !banner ? '100%' : 'initial',
                        display: !isMobile && !banner ? 'flex' : 'grid',
                        flexWrap: 'nowrap',
                        justifyContent: !isMobile && !banner ? 'center' : 'unset',
                        alignItems: 'center',
                        gap: 16,
                        marginTop: !isMobile && !banner ? 16 : 0,
                    }, children: [onOk, onCancel, closable && (_jsx(Close, { onClick: () => setVisible(false), style: {
                                cursor: 'pointer',
                                color: isDark ? 'var(--NEUTRAL-9)' : 'rgba(0, 0, 0, 0.85)',
                                fontSize: 14,
                                position: isMobile || banner || !banner ? 'absolute' : 'initial',
                                marginLeft: 12,
                                zIndex: 9,
                                right: 16,
                                top: 16,
                            } }))] })] }) }));
};
