import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Colors } from '@util/color/Colors';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import style from './PreviewCard.scss';
const PreviewContent = (props) => {
    var _a, _b, _c;
    const { data, isClickeable } = props;
    return (_jsxs("div", { className: isClickeable ? style.previewCard : null, children: [data.type != 'noOG' && ((_a = data.image) === null || _a === void 0 ? void 0 : _a.url) && !((_b = data.image) === null || _b === void 0 ? void 0 : _b.url.includes('lookaside.fbsbx.com')) && (_jsx("div", { style: { position: 'relative', paddingBottom: '66.6%' }, children: _jsx("div", { style: {
                        position: 'absolute',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${(_c = data.image) === null || _c === void 0 ? void 0 : _c.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    } }) })), _jsxs("div", { style: { padding: '8px 16px', backgroundColor: Colors('POSTED_POLL_BACKGROUND') }, children: [_jsx("div", { className: style.description, children: new URL(data.url).host.replace('www.', '') }), _jsx("div", { className: style.title, children: data.title ? data.title : new URL(data.url).host }), _jsx(Paragraph, { className: style.description, ellipsis: {
                            rows: 2,
                        }, children: data.description })] })] }));
};
export const PreviewCard = (props) => {
    const { data, isClickeable } = props;
    if (isClickeable)
        return (_jsx("a", { href: data.url, target: '_blank', rel: "noreferrer", children: _jsx(PreviewContent, Object.assign({}, props)) }));
    else
        return _jsx(PreviewContent, Object.assign({}, props));
};
