import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PinIcon } from '@components/icons/PinIcon';
import strings from '@resources/localization';
const PinHeader = () => {
    return (_jsxs("div", { style: {
            height: 30,
            backgroundColor: 'var(--ant-primary-1)',
            width: '100%',
            borderRadius: '4px 4px 0px 0px',
            color: 'var(--ant-primary-6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 24px',
        }, children: [_jsx(PinIcon, { color: "var(--ant-primary-6)", style: { marginRight: 4 } }), " ", strings.screens.markasread.title] }));
};
export { PinHeader };
