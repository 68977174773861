import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Canvas } from '@components/content/canvas/Canvas';
import ImageSignatureBernardo from '@bundle/assets/certificate/signature/scan-signature-bernardo.png';
import ImageSignatureIvanVera from '@bundle/assets/certificate/signature/scan-signature-ivan.png';
import ImageTuringoIso from '@bundle/assets/branding/isotype.png';
import RobotoFont from '@bundle/fonts/Roboto.ttf';
import { CertificateConstants as Constants } from '@resources/constants/CertificateConstants';
import { drawSignature, drawText, getLines, handleMentors, parseDate } from '../free/FreeCertificate';
import strings from '@resources/localization';
import { Router } from '@Router';
const SignatureCertificate = (props) => {
    const preloads = {
        signatureIvan: ImageSignatureIvanVera,
        signatureBernardo: ImageSignatureBernardo,
        logo: ImageTuringoIso,
    };
    const lbn = { x: 0.29, y: 0.17, w: 0, h: 0.35 };
    const width = Constants.width * Constants.scale;
    const height = Constants.height * Constants.scale;
    const labels = [
        { style: 'title', text: 'Turingo certifica que' },
        { style: 'name', text: props.granteeName },
        { style: 'title', text: strings.certificate.preambleCourse },
        { style: 'content', text: props.courseTitle },
        { style: 'title', text: strings.certificate.preambleTeacher },
    ];
    const handledMentors = handleMentors(props.mentors);
    handledMentors.forEach((line) => {
        labels.push({ style: handledMentors.length > 1 ? 'multiline-content' : 'content', text: line });
    });
    labels.push({ style: 'title', text: parseDate(props.grantDate) });
    const certificateURL = Router.Path.certificate({ pk: props.pk || '0000-0000-0000' });
    return (_jsx(_Fragment, { children: _jsx(Canvas, { width: width, height: height, preloadImages: preloads, preloadFonts: { Roboto: RobotoFont }, onDrawComplete: props.onRenderComplete, onDraw: (ctx, { width: w, height: h }, images, fonts) => {
                ctx.font = `16pt ${fonts.Roboto.family}`;
                console;
                ctx.fillStyle = '#111645';
                ctx.fillRect(0, 0, w, h);
                ctx.fillStyle = '#ffffff';
                ctx.beginPath();
                ctx.moveTo(w - 10, h - 10);
                ctx.arcTo(10, h - 10, 10, 10, 4);
                ctx.arcTo(10, 10, w - 10, 10, 4);
                ctx.arcTo(w - 10, 10, w - 10, h - 10, 4);
                ctx.arcTo(w - 10, h - 10, 10, h - 10, 4);
                ctx.fill();
                ctx.fillStyle = '#111645';
                ctx.fillRect(100, 0, 130, 260);
                ctx.beginPath();
                ctx.arc(165, 265, 65, 0, 2 * Math.PI, false);
                ctx.fill();
                ctx.fillStyle = '#ffffff';
                ctx.beginPath();
                ctx.arc(165, 262, 50, 0, 2 * Math.PI, false);
                ctx.fill();
                if (images.logo) {
                    ctx.drawImage(images.logo, 165 - 55, 262 - 55, 110, 110);
                }
                labels.forEach((label, index) => {
                    if (label.style == 'content') {
                        const newLines = getLines(ctx, label.text, 200);
                        labels.splice(index, 1);
                        newLines.forEach((line, i) => {
                            labels.splice(index + i, 0, Object.assign(Object.assign({}, label), { text: line }));
                        });
                    }
                });
                labels.forEach((label, index) => {
                    const vOffset = lbn.y + (lbn.h * index) / labels.length - (index === 0 ? 0.01 : 0);
                    const hOffset = lbn.x + lbn.w / 2.0;
                    drawText(ctx, label.style, label.text, w * hOffset, h * vOffset, 'left', '#0E162C');
                });
                const signatures = [
                    {
                        name: strings.screens.boards.course.certificate.signatures.bernardo.name,
                        image: images.signatureBernardo,
                        position: strings.screens.boards.course.certificate.signatures.bernardo.position,
                        notLine: true,
                    },
                    {
                        name: strings.screens.boards.course.certificate.signatures.ivan.name,
                        image: images.signatureIvan,
                        position: strings.screens.boards.course.certificate.signatures.ivan.position,
                        notLine: true,
                    },
                ];
                signatures.forEach((signature, index) => {
                    drawSignature(ctx, signature, index);
                });
                drawText(ctx, 'footer', 'Verifica la autenticidad de este certificado en', 345, 815, 'left', '#8C8C8C');
                drawText(ctx, 'footer', certificateURL, 345, 830, 'left', '#0E162C');
            } }) }));
};
export { SignatureCertificate };
