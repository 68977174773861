import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { BackTop } from 'antd';
import strings from '@resources/localization';
import { cleanAuth } from '@state/reducers/Auth/AuthReducer';
import LandingB2BBody from './LandingB2BBody';
import useBreakpoints from '@hooks/useBreakpoints';
import { useHistory } from 'react-router';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { API } from '@api/API';
const LandingB2B = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const userState = useAppSelector((state) => state.auth.authState);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const breakpoints = useBreakpoints();
    const isMedium = breakpoints.md || breakpoints.mdl;
    const isLarge = breakpoints.lg || breakpoints.xl || breakpoints.xxl;
    const paddingSize = isMedium ? 16 : isLarge ? 24 : 16;
    if (userState === 'unauth.closed' || userState === 'unauth.expired' || userState === 'unauth.rejected') {
        dispatch(cleanAuth());
    }
    return (_jsxs("div", { style: { width: '100%', backgroundColor: isDark ? '#000000' : '#FAFAFA', position: 'relative', overflow: 'hidden' }, children: [_jsx(BackTop, { style: { zIndex: 100 } }), _jsxs(Meta, { children: [_jsx("title", { children: strings.screens.landingb2b.browserTitle }), _jsx("meta", { name: "description", content: strings.screens.landingb2b.browserDescription })] }), _jsx("div", { style: {
                    position: 'absolute',
                    top: -800,
                    width: 1600,
                    height: 1700,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    borderRadius: '50%',
                    background: isDark ? '#141414' : '#F5F5F5',
                    zIndex: 0,
                } }), _jsxs("div", { children: [_jsx("div", { style: { paddingTop: breakpoints.isMobile ? 56 : 80, paddingBottom: breakpoints.isMobile ? 56 : 80, zIndex: 1, position: 'relative' }, children: _jsxs("div", { style: {
                                display: 'flex',
                                paddingLeft: paddingSize,
                                paddingRight: paddingSize,
                                justifyContent: 'center',
                                textAlign: 'center',
                                flexDirection: 'column',
                            }, children: [_jsxs("div", { style: { fontSize: 38, lineHeight: '46px', fontWeight: 500, maxWidth: 840, alignSelf: 'center', marginBottom: 16 }, children: [_jsx("div", { style: { color: '#4cc1be', display: 'inline' }, children: strings.screens.landingb2b.headline[0] }), ' ', strings.screens.landingb2b.headline[1]] }), _jsx("p", { style: { fontSize: breakpoints.isSmall ? 18 : 20, lineHeight: '24px', maxWidth: 840, marginBottom: 16, alignSelf: 'center' }, children: strings.screens.landingb2b.subheadline }), _jsx(LinkButton, { externalHref: 'https://calendly.com/turingo/demo-request', block: breakpoints.isMobile, style: Object.assign(Object.assign({}, (!breakpoints.isMobile && { width: 'fit-content' })), { alignSelf: 'center' }), type: "primary", onClick: () => {
                                        void API.analyticsAction({ bodyParams: { action: 'btn.demo', sender: 'main.landing.b2b' } });
                                    }, children: strings.screens.landingb2b.action })] }) }, "a"), _jsx(LandingB2BBody, { theme: theme })] })] }));
};
export { LandingB2B };
