import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Divider, Drawer } from 'antd';
import { Close, HamburgerButton } from '@icon-park/react';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useEffect, useState } from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SidebarItems } from '@components/navegation/sider/Siderbar/SidebarItems';
import { SidebarCommunities } from '@components/navegation/sider/Siderbar/SidebarCommunities';
import { communityList } from '@state/actions';
import { AdminSidebarItems } from '@components/navegation/sider/AdminSidebar/AdminSidebarItems';
import useBreakpoints from '@hooks/useBreakpoints';
const NewSideBar = () => {
    var _a, _b, _c;
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const isMobile = useBreakpoints().isMobile;
    const currentUser = useAppSelector((state) => state.auth.currentEntity);
    const communities = useAppSelector((state) => state.auth.communities);
    useEffect(() => {
        void dispatch(communityList({}));
    }, []);
    const isAdminPanel = location.pathname.split('/')[2] == 'settings';
    const showCommunitiesPanel = currentUser && communities && !communities.loading && ((_a = communities.items) === null || _a === void 0 ? void 0 : _a.length) > 1 && !isAdminPanel;
    if (!community || !community.item)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsx(HamburgerButton, { style: { display: 'flex', marginRight: 16 }, onClick: () => {
                    setVisible(true);
                }, theme: "outline", size: "24", fill: isDark ? '#fff' : '#333' }), _jsxs(Drawer, { headerStyle: { display: 'none' }, bodyStyle: {
                    padding: 0,
                    display: 'grid',
                    gridTemplateColumns: isAdminPanel || !showCommunitiesPanel ? 'inherit' : isMobile ? '72px calc(100% - 72px)' : '64px calc(100% - 64px)',
                }, title: null, placement: "left", closable: true, onClose: () => {
                    setVisible(false);
                }, open: visible, children: [showCommunitiesPanel && (_jsx(SidebarCommunities, { kind: "drawer", onChange: () => setVisible(false), communities: communities.items })), _jsxs("div", { children: [_jsx("div", { style: { padding: 16, marginLeft: 'auto', width: 'fit-content' }, children: _jsx(Close, { onClick: () => setVisible(false), style: { fontSize: 16, color: isDark ? '#fff' : '#333' } }) }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', paddingLeft: 16 }, children: [_jsx(AvatarTuringo, { color: (_c = (_b = community.item.ui) === null || _b === void 0 ? void 0 : _b.style) === null || _c === void 0 ? void 0 : _c.color, avatar: community.item.logoUrl, size: 20, width: 40, height: 40, style: { marginRight: 8, lineHeight: '40px' }, name: community.item.name }), _jsx(Paragraph, { strong: true, style: { fontSize: 18, marginBottom: 0 }, children: community.item.name })] }), _jsx(Divider, {}), _jsxs("div", { style: { overflowY: 'auto', maxHeight: 'calc(100dvh - 125px)' }, children: [!isAdminPanel && _jsx(SidebarItems, { afterClick: () => setVisible(false), hideCollapse: true, mode: 'inline', collapsed: false }), isAdminPanel && _jsx(AdminSidebarItems, { isDrawer: true, afterClick: () => setVisible(false), hideCollapse: true, mode: 'inline', collapsed: false })] })] })] })] }));
};
export { NewSideBar };
