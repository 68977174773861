import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Alert } from 'antd';
import { useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
const VideoBlockedScreen = (props) => {
    const theme = useAppSelector((state) => state.ui.theme);
    return (_jsx("div", { style: {
            background: theme == 'dark' ? 'var(--neutral-3)' : 'var(--neutral-4)',
            height: 198,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }, children: _jsx(Alert, { showIcon: true, message: strings.screens.evaluation.quiz.lessonNotAvailable }) }));
};
export { VideoBlockedScreen };
