import strings from '@resources/localization';
import { localizeNaturalNumber } from './Number';
const localizeSeconds = (seconds, compact = false) => {
    if (seconds < 60) {
        return localizeNaturalNumber(compact ? strings.general.time.secondsCompact : strings.general.time.seconds, seconds);
    }
    const totalMinutes = Math.floor(seconds / 60);
    const t = {
        hours: Math.floor(totalMinutes / 60),
        minutes: totalMinutes % 60,
        seconds: seconds % 60,
    };
    if (t.hours > 0) {
        const formattedHours = localizeNaturalNumber(compact ? strings.general.time.hoursCompact : strings.general.time.hours, t.hours);
        const formattedMinutes = localizeNaturalNumber(compact ? strings.general.time.minutesCompact : strings.general.time.minutes, t.minutes);
        return strings.formatString(strings.general.time.hhmm, formattedHours, formattedMinutes);
    }
    else {
        const formattedMinutes = localizeNaturalNumber(compact ? strings.general.time.minutesCompact : strings.general.time.minutes, t.minutes);
        const formattedSeconds = localizeNaturalNumber(compact ? strings.general.time.secondsCompact : strings.general.time.seconds, t.seconds);
        return strings.formatString(strings.general.time.hhmm, formattedMinutes, formattedSeconds);
    }
};
export { localizeSeconds };
