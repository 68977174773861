import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Link } from 'react-router-dom';
import { Drawer, Menu, Popover } from 'antd';
import { InfoCircleOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { FacebookOne, Family, Instagram, KeyboardOne, Lock, Logout, Mail, Message, Twitter, Youtube } from '@icon-park/react';
import strings from '@resources/localization';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { AvatarTuringo } from '@components/avatar/Avatar';
import style from './UserMenu.scss';
import { Router } from '@Router';
import { API } from '@api/API';
import { useState } from 'react';
const UserMenu = () => {
    const dispatch = useAppDispatch();
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [visible, setVisible] = useState(false);
    const openLogoutModal = () => {
        setVisible(false);
        dispatch(modalAction({ modal: 'logout', state: true }));
        void API.analyticsModal({ bodyParams: { modal: 'modal.main.logout', state: 'open' } });
    };
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const menu = [
        ...(currentCommunity
            ? [
                {
                    label: (_jsx(Link, { to: Router.Path.user_details({ u_pk: currentEntity.publicKey, cm_pk: currentCommunity }), children: strings.navigation.usermenu.options.profile })),
                    key: 'menu-item-profile',
                    icon: _jsx(UserOutlined, { style: { fontSize: 20 } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
            ]
            : []),
        {
            label: _jsxs(Link, { to: { pathname: '/settings' }, children: [" ", strings.navigation.usermenu.options.settings] }),
            key: 'menu-item-settings',
            icon: _jsx(SettingOutlined, { style: { fontSize: 20 } }),
            onClick: () => {
                setMenuActive(false);
                setVisible(false);
            },
        },
        {
            type: 'divider',
            key: 'menu-item-divider#1',
        },
        {
            label: _jsxs(Link, { to: { pathname: '/terms' }, children: [" ", strings.navigation.usermenu.options.terms] }),
            key: 'menu-item-terms',
            icon: _jsx(InfoCircleOutlined, { style: { fontSize: 20 } }),
            onClick: () => {
                setMenuActive(false);
                setVisible(false);
            },
        },
        {
            label: _jsxs(Link, { to: { pathname: '/privacy' }, children: [" ", strings.navigation.usermenu.options.privacy] }),
            key: 'menu-item-privacy',
            icon: _jsx(Lock, { size: "20", style: { fontSize: 20 } }),
            onClick: () => {
                setMenuActive(false);
                setVisible(false);
            },
        },
        ...(currentEntity.privilige == 'superadmin'
            ? [
                {
                    label: _jsxs(Link, { to: { pathname: '/debug' }, children: [" ", 'Debug'] }),
                    key: 'menu-item-debug',
                    icon: _jsx(KeyboardOne, { size: "20", style: { fontSize: 20 } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
            ]
            : []),
        ...(isMobile
            ? [
                {
                    type: 'divider',
                    key: 'menu-item-divider#2',
                },
                {
                    label: (_jsx("a", { href: APP_MANIFEST.sites.owned.blog.url, target: '_blank', rel: "noreferrer", children: strings.navigation.usermenu.options.blog })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-blog',
                    icon: _jsx(Message, { size: "20", style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: _jsxs(Link, { to: { pathname: '/help' }, children: [" ", strings.navigation.usermenu.options.faq] }),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-faq',
                    icon: _jsx(QuestionCircleOutlined, { style: { fontSize: 20 } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: _jsxs(Link, { to: { pathname: '/aboutUs' }, children: [" ", strings.navigation.usermenu.options.aboutus] }),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-aboutus',
                    icon: _jsx(Family, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    type: 'divider',
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-divider#3',
                },
                {
                    label: (_jsx("a", { href: APP_MANIFEST.sites.social.twitter.url, title: APP_MANIFEST.sites.social.twitter.network, target: '_blank', rel: "noreferrer", children: "Twitter" })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-twitter',
                    icon: _jsx(Twitter, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: (_jsx("a", { href: APP_MANIFEST.sites.social.instagram.url, title: APP_MANIFEST.sites.social.instagram.network, target: '_blank', rel: "noreferrer", children: "Instagram" })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-instagram',
                    icon: _jsx(Instagram, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: (_jsx("a", { href: APP_MANIFEST.sites.social.facebook.url, title: APP_MANIFEST.sites.social.facebook.network, target: '_blank', rel: "noreferrer", children: "Facebook" })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-facebook',
                    icon: _jsx(FacebookOne, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: (_jsx("a", { href: APP_MANIFEST.sites.social.youtube.url, title: APP_MANIFEST.sites.social.youtube.network, target: '_blank', rel: "noreferrer", children: "YouTube" })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-youtube',
                    icon: _jsx(Youtube, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
                {
                    label: (_jsx(Link, { onClick: () => {
                            void API.analyticsAction({ bodyParams: { action: 'support.contact', sender: 'main.nav.header.menu' } });
                        }, to: {
                            pathname: '/help',
                            search: '?page=contact',
                        }, children: strings.navigation.usermenu.options.contact })),
                    style: { display: 'flex', alignItems: 'center' },
                    key: 'menu-item-help',
                    icon: _jsx(Mail, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
                    onClick: () => {
                        setMenuActive(false);
                        setVisible(false);
                    },
                },
            ]
            : []),
        {
            type: 'divider',
            key: 'menu-item-divider#4',
        },
        {
            label: strings.navigation.usermenu.options.logout,
            style: { display: 'flex', alignItems: 'center' },
            key: 'menu-item-logout',
            icon: _jsx(Logout, { style: { fontSize: 20, display: 'flex', alignItems: 'center' } }),
            onClick: openLogoutModal,
        },
    ];
    const [menuActive, setMenuActive] = useState(false);
    if (isMobile)
        return (_jsxs(_Fragment, { children: [_jsxs("div", { id: 'userMenu', className: style.popoverClosed, style: { fontWeight: 500, fontSize: 16, paddingLeft: 16, display: 'flex', alignItems: 'center', height: 48 }, onClick: () => setVisible(true), children: [_jsx(AvatarTuringo, { style: { marginRight: 8, height: 40, width: 40, lineHeight: '40px', border: '1px solid var(--picture-border)' }, name: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.name, avatar: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl }), !breakpoints.xxs && !breakpoints.xs && strings.navigation.usermenu.title] }), _jsx(Drawer, { bodyStyle: { padding: 0 }, title: strings.navigation.usermenu.title, placement: "right", closable: true, onClose: () => setVisible(false), open: visible, children: _jsx(Menu, { mode: "vertical", selectedKeys: [], items: menu }) })] }));
    return (_jsx(Popover, { style: { padding: '0px 12px' }, overlayClassName: 'userMenuContent', content: _jsx("div", { style: { width: 241, maxHeight: 500, overflow: 'auto' }, children: _jsx("div", { style: { display: 'grid', gridTemplateColumns: '1fr' }, children: _jsx(Menu, { selectedKeys: [], mode: "vertical", items: menu }) }) }), trigger: "click", placement: "bottomRight", open: menuActive, onOpenChange: (open) => setMenuActive(open), arrowPointAtCenter: true, getPopupContainer: (trigger) => trigger.parentElement, children: _jsxs("div", { id: 'userMenu', className: style.popoverClosed, style: {
                display: 'inline-grid',
                gridTemplateColumns: 'auto auto auto',
                alignItems: 'center',
                fontWeight: 500,
                columnGap: 8,
                fontSize: 16,
                paddingLeft: 16,
                paddingRight: 16,
                marginTop: -2,
            }, children: [_jsx(AvatarTuringo, { style: { height: 40, width: 40, lineHeight: '40px', border: '1px solid var(--picture-border)' }, name: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.name, avatar: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl }), strings.navigation.usermenu.title] }) }));
};
export { UserMenu };
