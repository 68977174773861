import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useParams } from 'react-router';
import { boardMembers } from '@state/actions';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useEffect } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import { UserProfileCard } from '@components/content/entity/user_profile_card/UserProfileCard';
import { EntityMemberSkeleton } from '@components/skeletons/EntityMemberSkeleton';
import InfiniteCards from '@components/infinite/InfiniteCards';
export const AllMembersCard = (props) => {
    const dispatch = useAppDispatch();
    const { cm_pk, b_pk } = useParams();
    const board = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]);
    const members = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[cm_pk].boards.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.items; });
    const membersState = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[cm_pk].boards.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.listState; });
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.xxs || breakpoints.xs || breakpoints.sm;
    const isTablet = breakpoints.md || breakpoints.mdl;
    useEffect(() => {
        void dispatch(boardMembers({
            urlParams: {
                cm_pk,
                b_pk,
            },
            headerParams: {
                page: 'first',
            },
        }));
    }, []);
    return (_jsx(InfiniteCards, { dataLength: !membersState ? undefined : Object.keys(members).length, loading: membersState ? membersState.loading : true, next: () => {
            var _a, _b;
            void dispatch(boardMembers({
                urlParams: {
                    cm_pk,
                    b_pk,
                },
                headerParams: {
                    page: (_b = (_a = board.members) === null || _a === void 0 ? void 0 : _a.listState) === null || _b === void 0 ? void 0 : _b.pagination,
                },
            }));
        }, hasMore: ((membersState === null || membersState === void 0 ? void 0 : membersState.pagination) && (membersState === null || membersState === void 0 ? void 0 : membersState.pagination) != 'first') || (membersState === null || membersState === void 0 ? void 0 : membersState.loading), loader: _jsx(EntityMemberSkeleton, {}, 'loader'), children: _jsx("div", { style: {
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
                rowGap: 16,
                placeContent: 'center',
                columnGap: 16,
            }, children: members &&
                Object.keys(members).map((key, index) => {
                    return (_jsx(UserProfileCard, { options: {
                            moniker: {
                                show: true,
                                title: true,
                            },
                            clickeable: true,
                            mode: isMobile ? 'horizontal' : 'vertical',
                        }, item: members[key].item }, members[key].item.publicKey));
                }) }) }));
};
