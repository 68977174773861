import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useState } from 'react';
import { LinkButton } from '../link_button/LinkButton';
import { BuyButton } from '../buy_button/BuyButton';
import { useAppSelector } from '@hooks/useStore';
import { KindNamePlural } from '@util/string/ModelNames';
import strings from '@resources/localization';
import { useHistory, useParams } from 'react-router';
import { Router } from '@Router';
import { API } from '@api/API';
import { BoardContext } from '@screens/community/boards/media/MediaDetail';
const WatchButton = (props) => {
    var _a, _b;
    const { entity, size = 'middle', showPrice, position } = props;
    const isSuscriptor = (_b = (_a = entity === null || entity === void 0 ? void 0 : entity.access) === null || _a === void 0 ? void 0 : _a.posts) === null || _b === void 0 ? void 0 : _b.includes('view');
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const { cm_pk } = useParams();
    const { section } = useParams();
    const boardContext = useContext(BoardContext);
    const [loading, setLoading] = useState(false);
    const progress = entity.progress;
    const notStarted = !(progress === null || progress === void 0 ? void 0 : progress.lessonCompletedCount) || (progress === null || progress === void 0 ? void 0 : progress.lessonCompletedCount) == 0;
    const history = useHistory();
    const watchText = () => {
        return notStarted ? strings.screens.boards[entity.kind].list.card.watchNow : strings.screens.boards[entity.kind].list.card.continue;
    };
    const watchNow = () => {
        if (!(progress === null || progress === void 0 ? void 0 : progress.lessonLastPublicKey))
            return '';
        if (boardContext.isOnboarding) {
            return Router.Path.community_settings_post_detail({
                cm_pk: cm_pk,
                section: 'onboarding',
                item_pk: entity.publicKey,
                p_pk: progress === null || progress === void 0 ? void 0 : progress.lessonLastPublicKey,
            });
        }
        return Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: progress === null || progress === void 0 ? void 0 : progress.lessonLastPublicKey });
    };
    if (!isLogged && !entity.product) {
        return (_jsx(LinkButton, { onClick: () => {
                void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: (boardContext === null || boardContext === void 0 ? void 0 : boardContext.b_pk) ? 'course.detail.card' : 'course.card' } });
            }, block: true, size: size, type: "primary", href: { pathname: `/signup`, state: { b_pk: entity.publicKey, kind: KindNamePlural[entity.kind] } }, children: strings.screens.boards[entity.kind].list.card.watchNow }, `cb#1`));
    }
    else if (!isSuscriptor && entity.product) {
        return _jsx(BuyButton, { board: entity, size: size, showPrice: showPrice, position: position });
    }
    else if (!isSuscriptor && !entity.product) {
        return (_jsx(LinkButton, { onClick: () => {
                void API.analyticsAction({ bodyParams: { action: 'btn.play', sender: (boardContext === null || boardContext === void 0 ? void 0 : boardContext.b_pk) ? 'course.detail.card' : 'course.card' } });
                void API.communityBoardStart({ urlParams: { cm_pk, b_pk: entity.publicKey } }).then((result) => {
                    if (boardContext === null || boardContext === void 0 ? void 0 : boardContext.isOnboarding) {
                        return history.push(Router.Path.community_settings_post_detail({
                            cm_pk: cm_pk,
                            section: 'onboarding',
                            item_pk: entity.publicKey,
                            p_pk: result.data.currentContentPk,
                        }));
                    }
                    else {
                        history.push(Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: result.data.currentContentPk }));
                    }
                    setLoading(false);
                });
            }, width: '100%', block: true, size: size, type: "primary", href: watchNow(), children: strings.screens.boards[entity.kind].list.card.watchNow }, `cb#1`));
    }
    if (isSuscriptor) {
        return (_jsx(LinkButton, { onClick: () => {
                void API.analyticsAction({ bodyParams: { action: 'btn.play', sender: (boardContext === null || boardContext === void 0 ? void 0 : boardContext.b_pk) ? 'course.detail.card' : 'course.card' } });
                setLoading(true);
                void API.communityBoardStart({ urlParams: { cm_pk, b_pk: entity.publicKey } }).then((result) => {
                    if (boardContext === null || boardContext === void 0 ? void 0 : boardContext.isOnboarding) {
                        return history.push(Router.Path.community_settings_post_detail({
                            cm_pk: cm_pk,
                            section: 'onboarding',
                            item_pk: entity.publicKey,
                            p_pk: result.data.currentContentPk,
                        }));
                    }
                    else {
                        history.push(Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: result.data.currentContentPk }));
                    }
                    setLoading(false);
                });
            }, width: '100%', block: true, type: "primary", size: size, loading: loading, href: watchNow(), children: watchText() }, `cb#1`));
    }
};
export { WatchButton };
