import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { CreatePostContext } from '../../../../../PostCreateProvider';
import { Close } from '@icon-park/react';
const PreviewPoll = (props) => {
    const { form, screen, setScreen, editable } = useContext(CreatePostContext);
    const poll = Form.useWatch(['form', 'turingo', 'poll'], form);
    if (!poll)
        return _jsx(_Fragment, {});
    return (_jsx("div", { children: _jsxs("div", { style: { position: 'relative', marginTop: 16 }, children: [!editable && (_jsx(Button, { onClick: () => {
                        setScreen('poll_creation');
                    }, shape: "circle", id: "closeBtn", icon: _jsx(EditOutlined, {}), style: { position: 'absolute', zIndex: 100, right: 48, top: 8 } })), !editable && (_jsx(Button, { onClick: () => {
                        form.setFieldValue(['form', 'turingo', 'kind'], 'basic');
                        form.setFieldValue(['form', 'turingo', 'poll'], undefined);
                    }, shape: "circle", id: "closeBtn", icon: _jsx(Close, { style: { display: 'flex', justifyContent: 'center' } }), style: { position: 'absolute', zIndex: 100, right: 8, top: 8 } })), _jsxs(Card, { bodyStyle: { backgroundColor: 'var(--neutral-3)' }, children: [_jsx("p", { style: { fontWeight: 500, textAlign: 'center', overflowWrap: 'break-word' }, children: poll.question }), poll.choices.map((item, index) => {
                            return (_jsx("div", { style: {
                                    marginBottom: 8,
                                    padding: 5,
                                    textAlign: 'center',
                                    backgroundColor: 'var(--poll_option_background)',
                                    border: `1px solid var(--border-button)`,
                                    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.016)',
                                    borderRadius: 4,
                                }, children: item.answer }, `pollOptions#${index}`));
                        })] })] }) }));
};
export { PreviewPoll };
