var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DefaultEndpointOptions } from './EndpointOptions';
const normalizeParameters = (options, params) => {
    const headerParams = params;
    if (headerParams.page !== undefined) {
        options.headers['page'] = headerParams.page;
        delete headerParams.page;
    }
    if (headerParams.pageCount !== undefined) {
        options.headers['pageCount'] = headerParams.pageCount.toString();
        delete headerParams.pageCount;
    }
    if (headerParams.presignId !== undefined) {
        options.headers['presignId'] = headerParams.presignId.toString();
        delete headerParams.presignId;
    }
};
const Endpoint = (client) => (url, options = {}, defaultParameters = {}) => (parameters) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const resolvedOptions = Object.assign(Object.assign({}, DefaultEndpointOptions), options);
    const resolvedSearchParams = Object.assign(Object.assign({}, defaultParameters.searchParams), ((_a = parameters.searchParams) !== null && _a !== void 0 ? _a : {}));
    const resolvedUrlParams = Object.assign(Object.assign({}, defaultParameters.urlParams), ((_b = parameters.urlParams) !== null && _b !== void 0 ? _b : {}));
    const resolvedBodyParams = Object.assign(Object.assign({}, defaultParameters.bodyParams), ((_c = parameters.bodyParams) !== null && _c !== void 0 ? _c : {}));
    const resolvedHeaderParams = Object.assign(Object.assign({}, defaultParameters.headerParams), ((_d = parameters.headerParams) !== null && _d !== void 0 ? _d : {}));
    normalizeParameters(resolvedOptions, resolvedHeaderParams);
    return (yield client.perform(url, resolvedOptions, resolvedUrlParams, resolvedBodyParams, resolvedSearchParams));
});
export { Endpoint };
