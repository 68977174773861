import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'antd';
import ReactPhoneInput from '@lib/react-phone-input-2';
import GoogleLibPhoneNumber from '@lib/google-libphonenumber';
import 'react-phone-input-2/lib/high-res.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import { useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { useContext, useEffect, useState } from 'react';
import { FormContext } from 'antd/es/form/context';
import { FormHelpItem } from '../HelpeableWrapper/FormHelpItem';
export const FormPhoneItem = (props) => {
    const { name, defaultValue, label, help, rules } = props;
    const language = useAppSelector((state) => state.ui.language);
    const [hasError, sethasError] = useState(false);
    const [countryPhone, setCountryPhone] = useState('');
    const [dialCode, setDialCode] = useState('');
    const [phone, setPhone] = useState(defaultValue ? defaultValue : '');
    const { form } = useContext(FormContext);
    useEffect(() => {
        form === null || form === void 0 ? void 0 : form.setFieldValue(name, phone);
        void (form === null || form === void 0 ? void 0 : form.validateFields([name]));
    }, [phone]);
    const getLanguage = () => {
        if (language === 'es')
            return es;
        if (language === 'pt')
            return pt;
        return undefined;
    };
    const onChange = (value, country) => {
        setCountryPhone(country.countryCode);
        setDialCode(country.dialCode);
        setPhone(value);
    };
    const isRequired = rules === null || rules === void 0 ? void 0 : rules.some((rule) => rule.required);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "ant-col ant-form-item-label", children: _jsx("label", { className: isRequired ? 'ant-form-item-required' : '', children: label || strings.screens.profile.editModal.phone.label }) }), _jsx(ReactPhoneInput, { containerClass: hasError ? 'phoneError' : '', masks: { cl: '. .... ....' }, country: 'cl', countryCodeEditable: false, isValid: (value, country) => {
                    setCountryPhone(country.iso2);
                    return true;
                }, value: phone, onChange: onChange, localization: getLanguage() }), _jsx(GoogleLibPhoneNumber, { children: (phoneUtil) => (_jsx(FormHelpItem, { help: help, name: name, rules: [
                        {
                            validator: (rule, value) => {
                                try {
                                    let found = false;
                                    if (rules && rules.length > 0) {
                                        rules.forEach((rule) => {
                                            if (rule.required) {
                                                if (!value || value.length == 0 || value == dialCode) {
                                                    found = true;
                                                }
                                            }
                                        });
                                    }
                                    if (found) {
                                        sethasError(true);
                                        return Promise.reject(strings.general.fieldBlank);
                                    }
                                    if (!found && dialCode == value) {
                                        sethasError(false);
                                        return Promise.resolve();
                                    }
                                    if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryPhone), countryPhone)) {
                                        sethasError(true);
                                        return Promise.reject(strings.general.phoneNotValid);
                                    }
                                }
                                catch (err) {
                                    if (value.length != 0) {
                                        sethasError(true);
                                        return Promise.reject(strings.general.phoneNotValid);
                                    }
                                    else {
                                        sethasError(false);
                                        return Promise.resolve();
                                    }
                                }
                                sethasError(false);
                                return Promise.resolve();
                            },
                        },
                    ], children: _jsx(Input, { hidden: true }) })) })] }));
};
