import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ShareModal } from '@components/modals/ShareModal';
import { PasswordChangeModal } from '@components/modals/PasswordChangeModal';
import { PasswordCreateModal } from '@components/modals/PasswordCreateModal';
import { EmailCreateModal } from '@components/modals/EmailCreateModal';
import { ConflictModal } from '@components/modals/ConflictModal';
import { AuthExpiredModal } from '@components/modals/AuthExpiredModal';
import { SeeMoreModal } from '@components/modals/SeeMoreModal';
import { EmailChangeModal } from '@components/modals/EmailChangeModal';
import { LogoutModal } from '@components/modals/LogoutModal';
import { ChatModal } from '@components/modals/chat_modal/ChatModal';
import { WhatsAppModal } from '@components/modals/WhatsAppModal';
import { DeleteAccountModal } from '@components/modals/DeleteAccountModal';
const GlobalModals = () => {
    return (_jsxs(_Fragment, { children: [_jsx(DeleteAccountModal, {}), _jsx(WhatsAppModal, {}), _jsx(ShareModal, {}), _jsx(PasswordChangeModal, {}), _jsx(PasswordCreateModal, {}), _jsx(EmailCreateModal, {}), _jsx(ConflictModal, {}), _jsx(AuthExpiredModal, {}), _jsx(SeeMoreModal, {}), _jsx(EmailChangeModal, {}), _jsx(LogoutModal, {}), _jsx(ChatModal, {})] }));
};
export { GlobalModals };
