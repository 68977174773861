export function arrayIdentical(a, b, equality = (a, b) => a === b) {
    if (!a || !b)
        return false;
    if (a.length !== b.length)
        return false;
    for (let iter = 0; iter < a.length; iter++) {
        if (!equality(a[iter], b[iter]))
            return false;
    }
    return true;
}
export function arraySimilarity(a, b, equality = (a, b) => a === b) {
    if (!a || !b)
        return false;
    if (a.length !== b.length)
        return false;
    const remaining = [...b];
    for (let iterA = 0; iterA < a.length; iterA++) {
        let matched = -1;
        for (let iterB = 0; iterB < remaining.length; iterB++) {
            if (equality(a[iterA], remaining[iterB])) {
                matched = iterB;
                break;
            }
        }
        if (matched >= 0) {
            remaining.splice(matched, 1);
        }
        else {
            return false;
        }
    }
    return true;
}
