import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@state/persistance/Storage';
import { Application } from '@Application';
import './style/index.css';
import 'antd/dist/antd.variable.min.css';
import './style/color-dark.css';
import './style/ant.css';
import { useEffect } from 'react';
require('dayjs/locale/es');
require('dayjs/locale/pt');
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(localizedFormat);
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { authLogout } from '@state/actions';
import { ConfigProvider } from 'antd';
import { render } from 'react-dom';
const root = document.getElementById('root');
export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}
const RunnableApplication = () => {
    return (_jsx(Provider, { store: store, children: _jsx(PersistGate, { loading: null, persistor: persistor, children: _jsx(ConfigProvider, { children: _jsxs(BrowserRouter, { getUserConfirmation: () => {
                    }, children: [_jsx(ScrollToTop, {}), _jsx(Application, {})] }) }) }) }));
};
window.addEventListener('storage', (event) => {
    if (event.key === 'logout') {
        store.dispatch(authLogout({ reason: 'manual' }));
    }
});
render(_jsx(RunnableApplication, {}), root);
serviceWorkerRegistration.register();
