const Default = {
    authentication: 'optional.header.bearer',
    method: 'GET',
    version: '1',
    encoding: 'body.url.encoded',
    useColonParams: true,
    headers: {},
    appendUTM: true,
};
export { Default as DefaultEndpointOptions };
