import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Radio, Skeleton, Space } from 'antd';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { getRequest, useRequestState } from '@api/requests/AsyncRequests';
import { API } from '@api/API';
import { useParams } from 'react-router';
import { useAppSelector } from '@hooks/useStore';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useForm } from 'antd/lib/form/Form';
const MaskSelectorModal = (props) => {
    var _a;
    const { open, onCancel, currentMask, setCurrentMask, kind = 'post', onChange, loading } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [form] = useForm();
    const [masks, setMasks] = useRequestState();
    const { cm_pk } = useParams();
    void getRequest(API.communityMasks, setMasks)({ urlParams: { cm_pk } });
    const submitForm = (values) => {
        var _a;
        let newMask = (_a = masks === null || masks === void 0 ? void 0 : masks.data) === null || _a === void 0 ? void 0 : _a.find((m) => m.publicKey == values.mask);
        if (!newMask)
            newMask = currentEntity;
        setCurrentMask(newMask);
        if (onChange) {
            onChange(newMask.publicKey);
        }
        else {
            onCancel();
        }
    };
    const RadioSelector = (mask) => {
        var _a, _b;
        return (_jsx(Radio, { value: mask.publicKey, children: _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(AvatarTuringo, { size: 24, style: {
                            height: 48,
                            width: 48,
                            lineHeight: '48px',
                            marginRight: 8,
                        }, name: mask.name, avatar: mask.avatarUrl }), _jsxs("div", { style: { lineHeight: '16px' }, children: [_jsx("div", { className: "turingo-text", style: { display: 'flex', alignItems: 'center', fontWeight: 500 }, children: mask.name }), ((_a = mask.profile) === null || _a === void 0 ? void 0 : _a.job) && (_jsx("div", { className: "turingo-text", style: { display: 'flex', alignItems: 'center' }, children: (_b = mask.profile) === null || _b === void 0 ? void 0 : _b.job }))] })] }) }));
    };
    return (_jsxs(TuringoModal, { open: open, onCancel: onCancel, width: 600, title: strings.modals.mask.title, footer: [
            _jsx(FooterModal, { primary: {
                    disabled: masks.loading,
                    loading: loading,
                    action: form.submit,
                    customText: kind == 'post' ? strings.general.continue : 'Cambiar',
                }, secondary: kind == 'interaction' && {
                    action: onCancel,
                    customText: strings.general.cancel,
                }, back: kind == 'post' && {
                    kind: 'back',
                    action: onCancel,
                    customText: strings.general.back,
                } }, 'footer_modal'),
        ], children: [_jsx(Paragraph, { style: { marginBottom: 16 }, className: "turingo-text", children: strings.modals.mask.description }), masks.loading && (_jsxs("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' }, children: [_jsx(Skeleton, { className: "large", active: true, title: true, paragraph: null, style: { marginBottom: 24 } }), _jsx(Skeleton, { className: "large", active: true, title: true, paragraph: null })] })), !masks.loading && (_jsx(Form, { initialValues: {
                    mask: currentMask.publicKey,
                }, scrollToFirstError: true, className: 'scrollStyle', onFinish: submitForm, form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: _jsx(Form.Item, { name: ['mask'], children: _jsx(Radio.Group, { defaultValue: 'general', children: _jsxs(Space, { direction: "vertical", size: 24, children: [RadioSelector(currentEntity), (_a = masks === null || masks === void 0 ? void 0 : masks.data) === null || _a === void 0 ? void 0 : _a.map((m) => RadioSelector(m))] }) }) }) }))] }));
};
export { MaskSelectorModal };
