function get_browser() {
    const ua = navigator.userAgent;
    let tem = [];
    let M = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i.exec(ua) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
        tem = /\bOPR|Edge\/(\d+)/.exec(ua);
        if (tem != null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = /version\/(\d+)/i.exec(ua)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1],
    };
}
export const addWebp = (url, width) => {
    const browser = get_browser();
    if (browser.name == 'Safari' && parseInt(browser.version) < 16) {
        if (width)
            return `${url}?webp=false&w=${width}`;
        return url;
    }
    if (!width)
        return `${url}?webp=true`;
    return `${url}?webp=true&w=${width}`;
};
