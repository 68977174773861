import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { ChatListItem } from '@screens/community/chat/components/ChatListItem';
import { Router } from '@Router';
import { Link } from 'react-router-dom';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import { Check } from '@icon-park/react';
import { communityChatList } from '@state/actions';
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton';
import { Colors } from '@util/color/Colors';
import strings from '@resources/localization';
import { useEffect } from 'react';
const MessagesPanel = (props) => {
    var _a, _b, _c;
    const { onClose } = props;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const chats = useAppSelector((state) => { var _a; return (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.chats; });
    const { general } = useAppSelector((state) => state.notification);
    const messagesCommunity = general.messages.filter((n) => {
        var _a;
        if (n.sender == 'system')
            return n;
        if (((_a = n.predicate) === null || _a === void 0 ? void 0 : _a.communityPk) == currentCommunity) {
            return n;
        }
    });
    const dispatch = useAppDispatch();
    useEffect(() => {
        void dispatch(communityChatList({
            urlParams: {
                cm_pk: currentCommunity,
            },
            headerParams: {
                page: 'first',
            },
        }));
    }, [currentCommunity]);
    return (_jsxs("div", { children: [_jsx("div", { className: 'scrollStyle', style: { width: 424, maxHeight: 500, overflow: 'auto', paddingBottom: 16 }, children: _jsxs("div", { style: { display: 'grid', gridTemplateColumns: '1fr', rowGap: 8, padding: 16 }, children: [(chats === null || chats === void 0 ? void 0 : chats.items) &&
                            Object.keys(chats.items).map((chatPk, i) => {
                                const state = messagesCommunity
                                    .filter((u) => {
                                    const predicate = u.predicate;
                                    return predicate.roomPk == chatPk && u.state == 'new';
                                })
                                    .reduce((accumulator, object) => {
                                    return accumulator + object.unseen;
                                }, 0);
                                return (_jsx(ChatListItem, { style: Object.assign(Object.assign({ border: `1px solid var(--border)`, borderRadius: 4, color: 'var(--neutral-13)' }, (state > 0 && { backgroundColor: Colors('POSTED_POLL_BACKGROUND') })), { padding: 16 }), showOptions: true, onClose: onClose, state: state, item: chats.items[chatPk].item }, i));
                            }), (chats === null || chats === void 0 ? void 0 : chats.items) && ((_a = chats === null || chats === void 0 ? void 0 : chats.listState) === null || _a === void 0 ? void 0 : _a.loading) && _jsx(ChatListSkeleton, { nElements: 2 }), (chats === null || chats === void 0 ? void 0 : chats.items) && Object.keys(chats.items).length == 0 && !((_b = chats === null || chats === void 0 ? void 0 : chats.listState) === null || _b === void 0 ? void 0 : _b.loading) && (_jsx(AlertDashed, { icon: _jsx(Check, { style: { display: 'flex' } }), message: strings.screens.chat.empty }))] }) }), (chats === null || chats === void 0 ? void 0 : chats.items) && !((_c = chats === null || chats === void 0 ? void 0 : chats.listState) === null || _c === void 0 ? void 0 : _c.loading) && (_jsx("div", { style: { padding: '16px 0px' }, children: _jsx(Link, { style: { margin: 16 }, to: { pathname: Router.Path.community_chat({ cm_pk: currentCommunity }) }, onClick: onClose, children: strings.screens.chat.showAll }) }))] }));
};
export { MessagesPanel };
