import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from 'antd';
import strings from '@resources/localization';
import { FormHelpItem } from '../HelpeableWrapper/FormHelpItem';
const FormEmailItem = (props) => {
    const { name, label, help, validations, style, className, placeholder, tooltip, onChange, customErrors, maxLength, showCount, disabled, autoComplete = true, } = props;
    return (_jsx(FormHelpItem, { help: help, tooltip: tooltip, style: style, className: className, name: name, label: label, initialValue: props.initialValue, rules: [
            ...(validations.includes('required')
                ? [
                    {
                        required: true,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.required) || strings.forms.email.validations.required,
                    },
                ]
                : []),
            ...(validations.includes('format')
                ? [
                    {
                        type: 'email',
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.format) || strings.forms.email.validations.format,
                    },
                ]
                : []),
            ...(validations.includes('long')
                ? [
                    {
                        max: 50,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.long) || strings.forms.email.validations.long,
                    },
                ]
                : []),
            ...(validations.includes('short')
                ? [
                    {
                        min: 5,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.short) || strings.forms.email.validations.short,
                    },
                ]
                : []),
        ], children: _jsx(Input, { disabled: disabled, showCount: showCount, maxLength: maxLength, id: "email", name: autoComplete ? 'email' : null, autoComplete: autoComplete ? 'username' : null, type: "email", placeholder: placeholder, onChange: onChange }) }));
};
export { FormEmailItem };
