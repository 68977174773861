import { createSlice } from '@reduxjs/toolkit';
import { authLogin, authSignup, authSocial, authValidate, authRecover, authLogout, authAccounts, authAddAccount, authRemoveAccount, authUpdateEmail, authUpdatePassword, openAuthExpiredModal, userEdit, communityList, authValidateStatus, userSelf, userDelete, } from '@state/actions';
import { v4 } from 'uuid';
import { instanceOfLogin } from '@api/model/responses/Account/Account';
const defaultState = {
    errorKind: null,
    error: null,
    loading: false,
    recover: null,
    authState: 'unauth.visitor',
    analyticsId: v4(),
    connected_accounts: {
        data: {
            password: false,
            social: {
                facebook: null,
                google: null,
                linkedin: null,
            },
        },
        error: false,
        loading: false,
    },
    communities: { items: [], loading: false },
    showCommunityMenu: false,
    authExpiredModal: false,
};
const authSlice = createSlice({
    name: 'auth',
    initialState: defaultState,
    reducers: {
        cleanAuth: (state) => {
            state.authValidation = undefined;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.authState = 'unauth.visitor';
            state.error = null;
            state.loading = false;
            state.recover = null;
            state.authExpiredModal = false;
            state.showCommunityMenu = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(openAuthExpiredModal, (state, action) => {
            state.authExpiredModal = true;
        });
        builder.addCase(communityList.pending, (state, action) => {
            state.communities = { items: [], loading: true };
        });
        builder.addCase(communityList.rejected, (state, action) => {
            state.communities = { items: [], loading: false };
        });
        builder.addCase(communityList.fulfilled, (state, action) => {
            state.communities = { items: action.payload.data, loading: false };
        });
        builder.addCase(authUpdatePassword.fulfilled, (state, action) => {
            state.connected_accounts.data.password = true;
        });
        builder.addCase(authValidateStatus.fulfilled, (state, action) => {
            state.authValidation = action.payload.data.authValidation;
        });
        builder.addCase(authAccounts.pending, (state, action) => {
            state.connected_accounts.loading = false;
        });
        builder.addCase(authAccounts.rejected, (state, action) => {
            state.connected_accounts.loading = false;
            state.connected_accounts.error = true;
        });
        builder.addCase(authAccounts.fulfilled, (state, action) => {
            state.connected_accounts.data = action.payload.data;
            state.connected_accounts.loading = false;
            state.connected_accounts.error = false;
        });
        builder.addCase(authAddAccount.pending, (state, action) => {
            state.connected_accounts.loading = true;
        });
        builder.addCase(authAddAccount.rejected, (state, action) => {
            state.connected_accounts.loading = false;
        });
        builder.addCase(authAddAccount.fulfilled, (state, action) => {
            state.connected_accounts.data = action.payload.data;
            state.connected_accounts.loading = false;
        });
        builder.addCase(authRemoveAccount.fulfilled, (state, action) => {
            state.connected_accounts.data = action.payload.data;
        });
        builder.addCase(authLogout, (state, action) => {
            state.currentEntity = undefined;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.authValidation = undefined;
            state.analyticsId = v4();
            state.authState = action.payload.reason === 'auto.expiration' ? 'unauth.expired' : 'unauth.closed';
            state.loading = false;
        });
        builder.addCase(userDelete.fulfilled, (state) => {
            state.currentEntity = undefined;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.authValidation = undefined;
            state.analyticsId = v4();
            state.authState = 'unauth.deleted';
            state.loading = false;
        });
        builder.addCase(authLogin.pending, (state) => {
            state.authEmail = undefined;
            state.authToken = undefined;
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authLogin.rejected, (state) => {
            state.authEmail = undefined;
            state.authToken = undefined;
            state.authState = 'unauth.rejected';
            state.loading = false;
            state.error = true;
        });
        builder.addCase(authLogin.fulfilled, (state, action) => {
            state.currentEntity = action.payload.data.user;
            state.communities = {
                items: action.payload.data.user.communities,
                loading: false
            };
            state.authEmail = action.payload.data.authEmail;
            state.authValidation = action.payload.data.authValidation;
            state.authToken = action.payload.data.authToken;
            state.authState = 'auth.normal';
            state.error = null;
            state.loading = false;
        });
        builder.addCase(authRecover.rejected, (state) => {
            state.recover = true;
        });
        builder.addCase(userEdit.pending, (state, { meta, payload }) => {
            state.loading = true;
        });
        builder.addCase(userEdit.fulfilled, (state, { meta, payload }) => {
            state.currentEntity = Object.assign(Object.assign(Object.assign({}, state.currentEntity), payload.data), (payload.data.phoneNumber ? { phoneNumber: payload.data.phoneNumber } : { phoneNumber: undefined }));
            state.error = null;
            state.loading = false;
        });
        builder.addCase(userEdit.rejected, (state, { meta, payload }) => {
            state.loading = false;
        });
        builder.addCase(userSelf.fulfilled, (state, { meta, payload }) => {
            state.currentEntity = Object.assign(Object.assign({}, state.currentEntity), payload.data);
            state.error = null;
        });
        builder.addCase(authValidate.pending, (state) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(authValidate.rejected, (state) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(authValidate.fulfilled, (state) => {
            state.error = null;
            state.authValidation = 'valid';
            state.loading = false;
        });
        builder.addCase(authSocial.pending, (state) => {
            state.loading = true;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.error = null;
        });
        builder.addCase(authSocial.rejected, (state, action) => {
            state.loading = false;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.error = true;
            state.errorKind = action.payload;
        });
        builder.addCase(authSocial.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            if (instanceOfLogin(action.payload.data)) {
                state.currentEntity = action.payload.data.user;
                state.authEmail = action.payload.data.authEmail;
                state.authValidation = action.payload.data.authValidation;
                state.authToken = action.payload.data.authToken;
                state.authState = 'auth.normal';
            }
        });
        builder.addCase(authSignup.pending, (state) => {
            state.loading = true;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.error = null;
        });
        builder.addCase(authSignup.rejected, (state, action) => {
            state.loading = false;
            state.authEmail = undefined;
            state.authToken = undefined;
            state.error = true;
            state.errorKind = action.payload;
        });
        builder.addCase(authSignup.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            if (instanceOfLogin(action.payload.data)) {
                state.currentEntity = action.payload.data.user;
                state.authEmail = action.payload.data.authEmail;
                state.authValidation = action.payload.data.authValidation;
                state.authToken = action.payload.data.authToken;
                state.authState = 'auth.normal';
            }
        });
        builder.addCase(authUpdateEmail.fulfilled, (state, action) => {
            state.authEmail = action.meta.arg.bodyParams.email;
            state.authValidation = 'trial';
        });
    },
});
export const { cleanAuth } = authSlice.actions;
export { authSlice };
