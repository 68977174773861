import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { CheckOne, CloseOne } from '@icon-park/react';
import WelcomeIcon from '@bundle/assets/icons/welcome.svg';
import SVG from '@components/assets/SVG';
export const InformationModalBody = (props) => {
    const { kind, title, description, subdescription, color, titleStyle, descriptionStyle, iconSize, forceLeftTextAlign } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: { textAlign: forceLeftTextAlign ? 'unset' : isMobile ? 'center' : 'unset' }, children: [kind == 'welcome' && _jsx(SVG, { style: { width: '64px', height: '64px' }, content: WelcomeIcon }), kind == 'success' && _jsx(CheckOne, { theme: "outline", size: iconSize !== null && iconSize !== void 0 ? iconSize : '56', fill: color !== null && color !== void 0 ? color : 'var(--modal-icon-success)' }), kind == 'warning' && _jsx(ExclamationCircleOutlined, { style: { color: color !== null && color !== void 0 ? color : 'var(--modal-icon-warning)', fontSize: 56, margin: 4 } }), kind == 'error' && _jsx(CloseOne, { theme: "outline", size: "56", fill: color !== null && color !== void 0 ? color : 'var(--modal-icon-error)' }), _jsx(Paragraph, { className: "turingo-title", style: Object.assign({ marginTop: 20, marginBottom: 16 }, titleStyle), strong: true, children: title }), description && (_jsx(Paragraph, { className: "turingo-text", style: Object.assign({ marginBottom: subdescription ? 4 : 16 }, descriptionStyle), children: description })), subdescription && (_jsx(Paragraph, { className: "turingo-text", style: { marginBottom: 16 }, children: subdescription }))] }));
};
