import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Divider, Radio, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { setCurrentTheme } from '@state/reducers/UserInterface/UserInterfaceReducer';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { API } from '@api/API';
export const ThemeSelector = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const onChange = (e) => {
        dispatch(setCurrentTheme(e.target.value));
        void API.analyticsSetting({ bodyParams: { theme: e.target.value } });
    };
    return (_jsx("div", { children: _jsx(Radio.Group, { style: Object.assign({}, (isMobile && { width: '100%' })), onChange: onChange, value: theme, className: "turingo-radio-list", children: _jsxs(Space, { direction: "vertical", size: 0, children: [_jsx(Radio, { value: "light", children: strings.screens.settings.sections.theme.options.light }), _jsx(Divider, {}), _jsx(Radio, { value: "dark", children: strings.screens.settings.sections.theme.options.dark })] }) }) }));
};
