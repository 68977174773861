import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v4 } from 'uuid';
import { useCallback, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { communityChatExists } from '@state/actions';
import { Router } from '@Router';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { capitalizeFirstLetter } from '@util/string/Functions';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
import useBreakpoints from '@hooks/useBreakpoints';
import { getMoniker, getMonikerTitle } from '@components/content/posts/components/PostAuthor';
import { MoreButton } from '@components/buttons/more_button/MoreButton';
const UserProfileCard = (props) => {
    var _a;
    const { item, options } = props;
    const { mode = 'vertical', clickeable = false, moniker = { show: true, title: true }, extra } = options;
    const { cm_pk } = useParams();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const showHorizontal = isMobile || mode == 'horizontal';
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    const [localId, setLocalId] = useState(v4());
    const afterCreate = (result) => {
        setLocalId(result.data.publicKey);
    };
    const chatDetail = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.messages) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[localId]; });
    const onClick = () => {
        void dispatch(communityChatExists({
            urlParams: {
                cm_pk,
            },
            bodyParams: { users: [item.publicKey] },
            extraParams: {
                localId: localId,
                users: [
                    Object.assign(Object.assign({}, item), { roomAccess: 'member', roomState: 'invited' }),
                    Object.assign(Object.assign({}, currentEntity), { roomAccess: 'owner', roomState: 'present' }),
                ],
            },
            options: {
                then: {
                    action: afterCreate,
                },
            },
        }));
    };
    const monikerComponent = useCallback((user, maxWidth) => {
        return getMoniker(user, maxWidth, true);
    }, [item]);
    const teamWithBadge = (_a = item === null || item === void 0 ? void 0 : item.teams) === null || _a === void 0 ? void 0 : _a.find((t) => t.badge);
    if (chatDetail) {
        return _jsx(Redirect, { to: { pathname: Router.Path.community_chat({ cm_pk }), state: { roomPk: localId } } });
    }
    return (_jsx(ConditionalWrap, { condition: clickeable, wrap: (children) => (_jsx(Link, { style: { display: 'flex', color: 'var(--neutral-13)', width: '100%', height: '100%' }, to: { pathname: (item === null || item === void 0 ? void 0 : item.publicKey) ? Router.Path.user_details({ u_pk: item.publicKey, cm_pk }) : '', state: { from: location.pathname } }, children: children })), children: _jsx(Card, { hoverable: clickeable, bodyStyle: { padding: 16, height: '100%' }, bordered: true, style: { height: '100%', width: '100%' }, children: _jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: '100%',
                    overflow: 'hidden',
                }, children: [_jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: showHorizontal ? 'row' : 'column',
                            alignItems: showHorizontal ? 'center' : 'start',
                            position: 'relative',
                            width: '100%',
                        }, children: [_jsxs("div", { style: {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: showHorizontal ? 'auto' : '100%',
                                    alignItems: 'flex-start',
                                    marginRight: showHorizontal ? 16 : 0,
                                }, children: [_jsx(AvatarTuringo, { kind: 'circle', name: item.name, avatar: item.avatarUrl, size: 24, width: 64, height: 64, style: { marginBottom: showHorizontal ? 0 : 16, lineHeight: '64px', border: '3px solid var(--border)' } }), teamWithBadge && teamWithBadge.badge.title && !showHorizontal && (_jsx(TuringoTag, { kind: "tag", color: "neutral", size: "small", style: { marginBottom: 8, marginRight: 0 }, children: capitalizeFirstLetter(teamWithBadge.badge.title) }, teamWithBadge.badge.title))] }), _jsxs("div", { style: { display: 'block', width: '100%' }, children: [teamWithBadge && teamWithBadge.badge.title && showHorizontal && (_jsx(TuringoTag, { kind: "tag", color: "neutral", size: "small", style: { marginBottom: 4, marginRight: 0 }, children: capitalizeFirstLetter(teamWithBadge.badge.title) }, teamWithBadge.badge.title)), item.name && (_jsx(Paragraph, { strong: true, className: "turingo-title", style: { wordBreak: 'break-word', maxWidth: '100%', marginBottom: 4 }, ellipsis: { rows: 2 }, children: item.name })), moniker.title && getMonikerTitle(item, 'calc(100vw - 160px)'), moniker.show && monikerComponent(item, 'calc(100vw - 210px)')] })] }), extra && (_jsx("div", { style: { alignSelf: 'center' }, children: _jsx(MoreButton, { type: "default", size: 'medium', items: extra(item) }) }))] }) }) }));
};
export { UserProfileCard };
