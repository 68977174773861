var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Button, Form, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
import { FormPasswordItem } from '@components/form/FormPasswordItem/FormPasswordItem';
import strings from '@resources/localization';
import { authLogin } from '@state/actions';
import { cleanAuth } from '@state/reducers/Auth/AuthReducer';
import { Router } from '@Router';
import { API } from '@api/API';
import { clientEncryptJWK } from '@util/string/Functions';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
export const LoadingIcon = _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true });
const LoginForm = (props) => {
    const loginStrings = strings.screens.auth.login;
    const location = useLocation();
    const [form] = useForm();
    const dispatch = useAppDispatch();
    function encriptEmailAndPassword(email, password, publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const emailEncrypted = yield clientEncryptJWK(publicKey, email);
            const passwordEncrypted = yield clientEncryptJWK(publicKey, password);
            return { email: emailEncrypted, password: passwordEncrypted };
        });
    }
    const [loading, setLoading] = useState(false);
    const onSubmit = (values) => {
        const { email, password } = values;
        setLoading(true);
        void API.authPresign({})
            .then((response) => {
            void encriptEmailAndPassword(email, password, response.data.signature)
                .then((result) => {
                var _a, _b;
                void dispatch(authLogin({
                    headerParams: {
                        presignId: response.data.id,
                    },
                    bodyParams: Object.assign({ email: result.email, password: result.password }, (((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.product) && { action: [{ kind: 'redeem', license: (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.product.license }] })),
                    options: {
                        then: {
                            action: () => setLoading(false),
                            error: () => setLoading(false),
                        },
                    },
                }));
            })
                .catch(() => setLoading(false));
        })
            .catch(() => setLoading(false));
    };
    const isLoggingIn = useAppSelector((state) => state.auth.loading);
    const error = useAppSelector((state) => state.auth.error);
    useEffect(() => {
        dispatch(cleanAuth());
    }, []);
    return (_jsxs(Form, { form: form, layout: 'vertical', name: "normal_login", className: "login-form", onFinish: onSubmit, children: [_jsx(FormEmailItem, { name: 'email', label: strings.forms.email.label, validations: ['format', 'required'], placeholder: strings.screens.settings.sections.signup.changeEmail.modal.email.placeholder, initialValue: props.email }), _jsx(FormPasswordItem, { name: 'password', label: _jsxs(Row, { justify: "space-between", style: { width: '100%' }, children: [_jsx("span", { children: strings.forms.password.label }), _jsx(Link, { to: Router.Path.recover({}), children: loginStrings.forgotenPassword })] }), validations: ['required'] }), error && _jsx(Alert, { showIcon: true, style: { marginBottom: 10 }, message: strings.screens.auth.login.error, type: "error", closable: true }), _jsx(Form.Item, { style: { textAlign: 'center', marginTop: 20 }, children: _jsxs(Button, { block: true, type: "primary", onClick: form.submit, className: "login-form-button", disabled: loading || isLoggingIn, children: [loading || isLoggingIn ? _jsx(Spin, { indicator: LoadingIcon, style: { marginLeft: -8, marginRight: 4 } }) : undefined, " ", loginStrings.action] }) })] }));
};
export { LoginForm };
