import { jsx as _jsx } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { UserProfileCard } from '../user_profile_card/UserProfileCard';
export const EntityCarousel = (props) => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const { items, clickeable = true, extra, mode = items.length == 1 || isMobile ? 'horizontal' : 'vertical' } = props;
    return (_jsx("div", { style: {
            display: 'grid',
            gridTemplateColumns: mode == 'horizontal' ? 'repeat(1, minmax(0, 1fr))' : items.length == 2 ? 'repeat(2, minmax(0, 1fr))' : 'repeat(3, minmax(0, 1fr))',
            gap: 16,
            justifyContent: 'center',
        }, children: items.map((mentor, index) => {
            return (_jsx(UserProfileCard, { options: {
                    moniker: {
                        show: true,
                        title: false,
                    },
                    clickeable: clickeable,
                    mode: mode,
                    extra: extra,
                }, item: mentor }, `ent-${index}`));
        }) }));
};
