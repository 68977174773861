import Strings from '@resources/localization';
const localizeNaturalNumber = (localizable, number) => {
    if (number === 0) {
        return localizable.zero;
    }
    else if (number === 1) {
        return localizable.one;
    }
    else {
        return Strings.formatString(localizable.nary, number.toFixed());
    }
};
export { localizeNaturalNumber };
