var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { HelpeableWrapper } from './HelpeableWrapper';
const FormHelpItem = (props) => {
    const { help: formHelp, customOnChange } = props, rest = __rest(props, ["help", "customOnChange"]);
    const [help, setHelp] = useState(formHelp);
    useEffect(() => {
        setHelp(formHelp);
    }, [formHelp]);
    return (_jsx(Form.Item, Object.assign({}, rest, { help: help, children: _jsx(HelpeableWrapper, { customOnChange: customOnChange, setHelp: setHelp, help: formHelp, children: props.children }) })));
};
export { FormHelpItem };
