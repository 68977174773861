import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Image } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { Colors } from '@util/color/Colors';
import { addWebp } from '@util/browser/addWebp';
export const ImagePost = (props) => {
    const { attachments } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const imageWidth = isMobile ? 320 : 800;
    const gallery = {
        [1]: {
            [0]: { ratio: '100%', grid: '1 / 7' },
        },
        [2]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
        },
        [3]: {
            [0]: { ratio: '66.6%', grid: '1 / 7' },
            [1]: { ratio: '100%', grid: '1 / 4' },
            [2]: { ratio: '100%', grid: '4 / 7' },
        },
        [4]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
            [2]: { ratio: '100%', grid: '1 / 4' },
            [3]: { ratio: '100%', grid: '4 / 7' },
        },
        [5]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
            [2]: { ratio: '100%', grid: '1 / 3' },
            [3]: { ratio: '100%', grid: '3 / 5' },
            [4]: { ratio: '100%', grid: '5 / 7' },
        },
    };
    return (_jsx("div", { style: {
            width: '100%',
            position: 'relative',
            display: attachments.length == 1 ? 'flex' : 'grid',
            backgroundColor: attachments.length == 1 ? 'var(--image-background)' : 'transparent',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            columnGap: 8,
            rowGap: 8,
            marginBottom: 8,
        }, children: _jsx(Image.PreviewGroup, { children: attachments.map((file, index) => {
                if (attachments.length == 1) {
                    return (_jsxs("div", { style: { position: 'relative', width: '100%', height: 616 }, children: [_jsx(Image, { preview: {
                                    mask: '',
                                }, style: { display: 'none' }, width: '100%', height: 616, wrapperStyle: {
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    overflow: 'hidden',
                                }, src: addWebp(file.url, imageWidth) }), _jsx("div", { style: {
                                    cursor: 'pointer',
                                    backgroundImage: `url(${addWebp(file.url, imageWidth)})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: `contain`,
                                    backgroundPositionX: `center`,
                                    backgroundPositionY: `center`,
                                    width: '100%',
                                    height: 616,
                                } }, index)] }));
                }
                else {
                    return (_jsx(Image, { preview: {
                            mask: '',
                        }, width: '100%', wrapperStyle: {
                            position: 'relative',
                            paddingTop: gallery[attachments.length][index].ratio,
                            gridColumn: gallery[attachments.length][index].grid,
                            overflow: 'hidden',
                        }, src: addWebp(file.url, imageWidth), placeholder: _jsx("div", { style: {
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                objectFit: 'cover',
                                objectPosition: 'center center',
                                height: '100%',
                                cursor: 'pointer',
                                background: Colors('SKELETON'),
                            } }) }, index));
                    {
                    }
                }
            }) }) }));
};
