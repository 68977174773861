import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { throttle } from 'throttle-debounce';
import { ThresholdUnits, parseThreshold } from './utils/thresholds';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import strings from '@resources/localization';
export default class InfiniteCards extends Component {
    constructor(props) {
        super(props);
        this.lastScrollTop = 0;
        this.actionTriggered = false;
        this.startY = 0;
        this.currentY = 0;
        this.dragging = false;
        this.maxPullDownDistance = 0;
        this.getScrollableTarget = () => {
            if (this.props.scrollableTarget instanceof HTMLElement)
                return this.props.scrollableTarget;
            if (typeof this.props.scrollableTarget === 'string') {
                return document.getElementById(this.props.scrollableTarget);
            }
            if (this.props.scrollableTarget === null) {
                console.warn(`You are trying to pass scrollableTarget but it is null. This might
        happen because the element may not have been added to DOM yet.
        See https://github.com/ankeetmaini/react-infinite-scroll-component/issues/59 for more info.
      `);
            }
            return null;
        };
        this.onStart = (evt) => {
            if (this.lastScrollTop)
                return;
            this.dragging = true;
            if (evt instanceof MouseEvent) {
                this.startY = evt.pageY;
            }
            else if (evt instanceof TouchEvent) {
                this.startY = evt.touches[0].pageY;
            }
            this.currentY = this.startY;
            if (this._infScroll) {
                this._infScroll.style.willChange = 'transform';
                this._infScroll.style.transition = `transform 0.2s cubic-bezier(0,0,0.31,1)`;
            }
        };
        this.onMove = (evt) => {
            if (!this.dragging)
                return;
            if (evt instanceof MouseEvent) {
                this.currentY = evt.pageY;
            }
            else if (evt instanceof TouchEvent) {
                this.currentY = evt.touches[0].pageY;
            }
            if (this.currentY < this.startY)
                return;
            if (this.currentY - this.startY >= Number(this.props.pullDownToRefreshThreshold)) {
                this.setState({
                    pullToRefreshThresholdBreached: true,
                });
            }
            if (this.currentY - this.startY > this.maxPullDownDistance * 1.5)
                return;
            if (this._infScroll) {
                this._infScroll.style.overflow = 'visible';
                this._infScroll.style.transform = `translate3d(0px, ${this.currentY - this.startY}px, 0px)`;
            }
        };
        this.onEnd = () => {
            this.startY = 0;
            this.currentY = 0;
            this.dragging = false;
            if (this.state.pullToRefreshThresholdBreached) {
                this.props.refreshFunction && this.props.refreshFunction();
                this.setState({
                    pullToRefreshThresholdBreached: false,
                });
            }
            requestAnimationFrame(() => {
                if (this._infScroll) {
                    this._infScroll.style.overflow = 'auto';
                    this._infScroll.style.transform = 'none';
                    this._infScroll.style.willChange = 'unset';
                }
            });
        };
        this.onScrollListener = (event) => {
            if (typeof this.props.onScroll === 'function') {
                setTimeout(() => this.props.onScroll && this.props.onScroll(event), 0);
            }
            const target = this.props.height || this._scrollableNode
                ? event.target
                : document.documentElement.scrollTop
                    ? document.documentElement
                    : document.body;
            if (this.actionTriggered)
                return;
            const atBottom = this.props.inverse
                ? this.isElementAtTop(target, this.props.scrollThreshold)
                : this.isElementAtBottom(target, this.props.scrollThreshold);
            if (atBottom && this.props.hasMore) {
                this.actionTriggered = true;
                this.setState({ showLoader: true });
                this.props.next && this.props.next();
            }
            this.lastScrollTop = target.scrollTop;
        };
        this.state = {
            showShadow: false,
            showLoader: false,
            pullToRefreshThresholdBreached: false,
            prevDataLength: props.dataLength,
        };
        this.throttledOnScrollListener = throttle(150, this.onScrollListener).bind(this);
        this.onStart = this.onStart.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onEnd = this.onEnd.bind(this);
    }
    componentDidMount() {
        this._scrollableNode = this.getScrollableTarget();
        this.el = this.props.height ? this._infScroll : this._scrollableNode || window;
        if (this.el) {
            this.el.addEventListener('scroll', this.throttledOnScrollListener);
        }
        if (typeof this.props.initialScrollY === 'number' && this.el && this.el instanceof HTMLElement && this.el.scrollHeight > this.props.initialScrollY) {
            this.el.scrollTo(0, this.props.initialScrollY);
        }
        if (this.props.pullDownToRefresh && this.el) {
            this.el.addEventListener('touchstart', this.onStart);
            this.el.addEventListener('touchmove', this.onMove);
            this.el.addEventListener('touchend', this.onEnd);
            this.el.addEventListener('mousedown', this.onStart);
            this.el.addEventListener('mousemove', this.onMove);
            this.el.addEventListener('mouseup', this.onEnd);
            this.maxPullDownDistance =
                (this._pullDown && this._pullDown.firstChild && this._pullDown.firstChild.getBoundingClientRect().height) || 0;
            this.forceUpdate();
            if (typeof this.props.refreshFunction !== 'function') {
                throw new Error(`Mandatory prop "refreshFunction" missing.
          Pull Down To Refresh functionality will not work
          as expected. Check README.md for usage'`);
            }
        }
    }
    componentWillUnmount() {
        if (this.el) {
            this.el.removeEventListener('scroll', this.throttledOnScrollListener);
            if (this.props.pullDownToRefresh) {
                this.el.removeEventListener('touchstart', this.onStart);
                this.el.removeEventListener('touchmove', this.onMove);
                this.el.removeEventListener('touchend', this.onEnd);
                this.el.removeEventListener('mousedown', this.onStart);
                this.el.removeEventListener('mousemove', this.onMove);
                this.el.removeEventListener('mouseup', this.onEnd);
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.dataLength === prevProps.dataLength)
            return;
        this.actionTriggered = false;
        this.setState({
            showLoader: false,
        });
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const dataLengthChanged = nextProps.dataLength !== prevState.prevDataLength;
        if (dataLengthChanged) {
            return Object.assign(Object.assign({}, prevState), { prevDataLength: nextProps.dataLength });
        }
        return null;
    }
    isElementAtTop(target, scrollThreshold = 0.8) {
        const clientHeight = target === document.body || target === document.documentElement ? window.screen.availHeight : target.clientHeight;
        const threshold = parseThreshold(scrollThreshold);
        if (threshold.unit === ThresholdUnits.Pixel) {
            return target.scrollTop <= threshold.value + clientHeight - target.scrollHeight + 1;
        }
        return target.scrollTop <= threshold.value / 100 + clientHeight - target.scrollHeight + 1;
    }
    isElementAtBottom(target, scrollThreshold = 0.8) {
        const clientHeight = target === document.body || target === document.documentElement ? window.screen.availHeight : target.clientHeight;
        const threshold = parseThreshold(scrollThreshold);
        if (threshold.unit === ThresholdUnits.Pixel) {
            return target.scrollTop + clientHeight >= target.scrollHeight - threshold.value;
        }
        return target.scrollTop + clientHeight >= (threshold.value / 100) * target.scrollHeight;
    }
    render() {
        const style = Object.assign({ height: this.props.height || 'auto', width: '100%', WebkitOverflowScrolling: 'touch' }, this.props.style);
        const hasChildren = this.props.hasChildren || !!(this.props.children && this.props.children instanceof Array && this.props.children.length);
        const outerDivStyle = this.props.pullDownToRefresh && this.props.height ? { overflow: 'auto' } : {};
        return (_jsxs("div", { children: [this.props.pullDownToRefresh && (_jsx("div", { style: { position: 'relative' }, ref: (pullDown) => (this._pullDown = pullDown), children: _jsx("div", { style: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: -1 * this.maxPullDownDistance,
                        }, children: this.state.pullToRefreshThresholdBreached ? this.props.releaseToRefreshContent : this.props.pullDownToRefreshContent }) })), _jsxs("div", { style: Object.assign(Object.assign({}, outerDivStyle), style), children: [this.props.inverse && (this.props.dataLength == undefined || this.props.loading) && this.props.loader, this.props.children, !this.props.inverse && (this.props.dataLength == undefined || this.props.loading) && this.props.loader] }), this.props.dataLength == 0 && !this.props.loading && (this.props.empty ? this.props.empty : _jsx(AlertDashed, { message: strings.general.noResults, showIcon: false })), !this.props.hasMore && this.props.endMessage] }));
    }
}
