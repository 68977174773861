import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '@hooks/useStore';
import { Link } from 'react-router-dom';
import { API } from '@api/API';
import useBreakpoints from '@hooks/useBreakpoints';
import style from './Footer.scss';
import strings from '@resources/localization';
import { LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
const FooterPurchase = () => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const theme = useAppSelector((state) => state.ui.theme);
    return (_jsx("div", { style: {
            minHeight: 60,
            padding: 0,
            zIndex: 10,
            width: '100%',
            justifyContent: 'center',
            backgroundColor: theme == 'dark' ? '#262626' : 'white',
        }, children: _jsx("div", { className: style.footer, children: _jsxs("div", { className: style.secondP, children: [_jsx("div", { className: style.links, children: _jsxs("span", { style: { marginRight: isMobile ? 0 : 20, marginBottom: !isMobile ? 0 : 16 }, children: ["\u00A9 ", new Date().getFullYear().toString(), " Turingo SpA."] }) }), _jsxs("div", { className: style.secure, children: [_jsxs("span", { children: [_jsx("span", { style: { fontWeight: 400, marginRight: 5 }, children: strings.comments.form.help }), _jsx(Link, { target: "_blank", onClick: () => {
                                            void API.analyticsAction({ bodyParams: { action: 'support.contact', sender: 'main.nav.footer' } });
                                        }, to: {
                                            pathname: '/help',
                                            search: '?page=contact',
                                        }, children: strings.comments.form.contactUs })] }), _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx("div", { style: { paddingLeft: 8, paddingRight: 8, borderRight: '1px solid var(--neutral-13)' }, children: _jsx(LockOutlined, { style: { fontSize: 24 } }) }), _jsx("div", { style: { padding: 8 }, children: strings.general.safebuy }), _jsx(Tooltip, { title: strings.general.httpsinfo, children: _jsx(InfoCircleOutlined, { style: { fontSize: 14 } }) })] })] })] }) }) }));
};
export { FooterPurchase };
