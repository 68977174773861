import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Row, Tooltip } from 'antd';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { useWindowSize } from 'react-use';
const TopicComponent = (props) => {
    const { topics, postPk, style, maxDivSize = 0, offset = 80 } = props;
    const [etiquetasVisibles, setEtiquetasVisibles] = useState(topics);
    const [etiquetasOcultas, setEtiquetasOcultas] = useState([]);
    const [divSize, setDivSize] = useState(maxDivSize);
    const [change, setChange] = useState(false);
    const windowSize = useWindowSize();
    useEffect(() => {
        setChange(!change);
        setEtiquetasVisibles(topics);
    }, [topics]);
    useEffect(() => {
        const div = document.getElementById(`topics-container-${postPk}`);
        if (div) {
            if (div.offsetWidth != divSize) {
                setDivSize(div.offsetWidth);
                setEtiquetasVisibles(topics);
            }
        }
    }, [windowSize, topics, change]);
    useEffect(() => {
        if (divSize) {
            let totalSize = 0;
            const etiquetasVisibles = [];
            const etiquetasOcultas = [];
            topics.forEach((topic) => {
                const etiquetaElement = document.getElementById(`topic-${postPk}-${topic.publicKey}`);
                if (etiquetaElement) {
                    const etiquetaSize = etiquetaElement.offsetWidth;
                    if (totalSize + etiquetaSize <= divSize - offset) {
                        totalSize += etiquetaSize;
                        etiquetasVisibles.push(topic);
                    }
                    else {
                        etiquetasOcultas.push(topic);
                    }
                }
                else {
                    etiquetasOcultas.push(topic);
                }
            });
            setEtiquetasVisibles(etiquetasVisibles);
            setEtiquetasOcultas(etiquetasOcultas);
        }
    }, [topics, divSize, change]);
    return (_jsxs(Row, { style: Object.assign({ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap' }, style), id: `topics-container-${postPk}`, children: [etiquetasVisibles.map((topic, index) => {
                return (_jsx(TuringoTag, { style: { marginRight: 8 }, kind: "topic", id: `topic-${postPk}-${topic.publicKey}`, children: topic.name }, index));
            }), etiquetasOcultas.length > 0 && (_jsx(Tooltip, { trigger: ['click', 'hover'], style: { cursor: 'pointer' }, title: etiquetasOcultas.map((topic) => {
                    return _jsxs("div", { children: [" ", topic.name] }, `hidden-${postPk}-${topic.publicKey}`);
                }), children: _jsxs("span", { style: { cursor: 'pointer' }, children: ["+", etiquetasOcultas.length] }) }))] }));
};
export { TopicComponent };
