import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Post, PostContext } from '../Post';
import { useParams } from 'react-router';
const ListPostItem = (props) => {
    var _a, _b, _c, _d;
    const { cm_pk, b_pk, p_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [currentMask, setCurrentMask] = useState(currentEntity);
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isAdmin = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.includes('edit');
    const isFeed = !b_pk;
    const scope = p_pk ? 'detail' : 'all';
    return (_jsx(PostContext.Provider, { value: {
            mask: { get: currentMask, set: setCurrentMask },
            post: props.item,
            isAdmin,
            cm_pk: cm_pk,
            b_pk: (_c = props.item) === null || _c === void 0 ? void 0 : _c.boardPk,
            p_pk: (_d = props.item) === null || _d === void 0 ? void 0 : _d.publicKey,
            isFeed: isFeed,
            scope: scope,
        }, children: _jsx(Post, {}) }));
};
export { ListPostItem };
