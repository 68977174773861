import strings from '@resources/localization';
export const infoBetweenDates = (date1, date2) => {
    const diff = date2.getTime() - date1.getTime();
    if (diff <= 0)
        return undefined;
    let delta = Math.abs(diff) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = delta % 60;
    if (days == 14) {
        return strings.formatString(strings.general.votes.time.days.nary, days);
    }
    else if (days == 7) {
        return strings.formatString(strings.general.votes.time.days.nary, days);
    }
    else if (days > 1) {
        return strings.formatString(strings.general.votes.time.days.nary, days + 1);
    }
    else if (hours >= 23) {
        return strings.formatString(strings.general.votes.time.days.one, 1);
    }
    else if (hours > 1) {
        return strings.formatString(strings.general.votes.time.hours.nary, hours);
    }
    else if (hours == 1) {
        return strings.formatString(strings.general.votes.time.hours.one);
    }
    else if (minutes > 1) {
        return strings.formatString(strings.general.votes.time.minutes.nary, minutes);
    }
    else if (minutes == 1) {
        return strings.formatString(strings.general.votes.time.minutes.one);
    }
    else {
        return strings.formatString(strings.general.votes.time.minutes.zero);
    }
};
