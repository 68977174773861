import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { commentSection } from '@state/actions';
import { Divider, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { UnderlineButton } from '@components/buttons/underline_button/UnderlineButton';
import { PostCommentField } from './components/PostCommentField';
import strings from '@resources/localization';
import { Comment } from './Comment';
import useBreakpoints from '@hooks/useBreakpoints';
import { PostContext } from '../../Post';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton';
export const PostCommentSection = (props) => {
    const { childRef, kind = 'paginated' } = props;
    const { mask, p_pk, b_pk, isFeed, scope, cm_pk } = useContext(PostContext);
    const post = useAppSelector((state) => { var _a; return (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]; });
    const comments = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]) === null || _b === void 0 ? void 0 : _b.childrens; });
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    useEffect(() => {
        fetchData(false);
    }, [b_pk, p_pk, mask.get]);
    const fetchData = (pagination) => {
        var _a, _b, _c, _d;
        if (!pagination) {
            void dispatch(commentSection(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk, p_pk } }, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { searchParams: { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey } })), { headerParams: {
                    page: null,
                }, extraParams: {
                    scope: scope,
                    feed: isFeed,
                } })));
        }
        else {
            void dispatch(commentSection(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk, p_pk } }, (currentEntity.publicKey != ((_c = mask.get) === null || _c === void 0 ? void 0 : _c.publicKey) && { searchParams: { mask: (_d = mask.get) === null || _d === void 0 ? void 0 : _d.publicKey } })), { headerParams: {
                    page: comments === null || comments === void 0 ? void 0 : comments.listState.pagination,
                }, extraParams: {
                    scope: scope,
                    feed: isFeed,
                } })));
        }
    };
    return (_jsxs("div", { children: [_jsx("div", { style: { padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }, children: _jsx(Divider, { style: { margin: 0 } }) }), _jsx(PostCommentField, { ref: childRef }), kind == 'paginated' && (_jsxs("div", { children: [comments && comments.items && Object.keys(comments.items).map((key) => _jsx(Comment, { comment: comments.items[key].item }, key)), (comments === null || comments === void 0 ? void 0 : comments.listState.pagination) && !(comments === null || comments === void 0 ? void 0 : comments.listState.loading) && post.item.totalComments > 5 && post.item.totalComments % 5 != 0 && (_jsx("div", { style: { padding: breakpoints.isSmall ? 16 : 24 }, children: _jsx(UnderlineButton, { onClick: () => fetchData(true), children: strings.formatString(strings.general.seeMore, strings.comments.title.toLowerCase()) }) })), (comments === null || comments === void 0 ? void 0 : comments.listState.loading) && (_jsx("div", { style: { padding: breakpoints.isSmall ? 16 : 24, textAlign: 'center' }, children: _jsx(Spin, {}) }))] })), comments && kind == 'scrolleable' && (_jsx(InfiniteScroll, { dataLength: Object.keys(comments.items).length, next: () => {
                    fetchData(true);
                }, hasMore: ((comments === null || comments === void 0 ? void 0 : comments.listState.pagination) && (comments === null || comments === void 0 ? void 0 : comments.listState.pagination) != 'first') || (comments === null || comments === void 0 ? void 0 : comments.listState.loading), loader: _jsx(PostSkeleton, {}), children: comments && comments.items && Object.keys(comments.items).map((key) => _jsx(Comment, { comment: comments.items[key].item }, key)) }))] }));
};
