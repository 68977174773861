import { jsx as _jsx } from "react/jsx-runtime";
import { Canvas } from '@components/content/canvas/Canvas';
import ImageSignatureBernardo from '@bundle/assets/certificate/signature/scan-signature-bernardo.png';
import ImageSignatureIvanVera from '@bundle/assets/certificate/signature/scan-signature-ivan.png';
import ImageTuringoLogo from '@bundle/assets/branding/logo-lettergray.png';
import ImageTuringoBg from '@bundle/assets/certificate/placeholder/background-premium.png';
import RobotoFont from '@bundle/fonts/Roboto.ttf';
import { CertificateConstants as Constants } from '@resources/constants/CertificateConstants';
import { drawSignature, drawText, getLines, handleMentors, parseDate } from '../free/FreeCertificate';
import strings from '@resources/localization';
const PremiumCertificate = (props) => {
    var _a, _b;
    const preloadImages = {
        signatureIvan: ImageSignatureIvanVera,
        signatureBernardo: ImageSignatureBernardo,
        bgLogo: ImageTuringoBg,
        logo: props.logo ? props.logo : ImageTuringoLogo,
        signature0: props.signer ? (_a = props.signer[0]) === null || _a === void 0 ? void 0 : _a.signatureUrl : undefined,
        signature1: props.signer ? (_b = props.signer[1]) === null || _b === void 0 ? void 0 : _b.signatureUrl : undefined,
    };
    const ibn = { x: 0.35, y: 0.15 };
    const lbn = { x: 0.35, y: 0.26, w: 0, h: 0.43 };
    const width = Constants.width * Constants.scale;
    const height = Constants.height * Constants.scale;
    const labels = [
        { style: 'title', text: strings.certificate.preamblePerson },
        { style: 'name', text: props.granteeName },
        { style: 'title', text: strings.certificate.preambleCourse },
        { style: 'content', text: props.courseTitle },
        { style: 'title', text: strings.certificate.preambleTeacher },
    ];
    const handledMentors = handleMentors(props.mentors);
    handledMentors.forEach((line) => {
        labels.push({ style: handledMentors.length > 1 ? 'multiline-content' : 'content', text: line });
    });
    labels.push({ style: 'title', text: parseDate(props.grantDate) });
    return (_jsx(Canvas, { width: width, height: height, preloadImages: preloadImages, preloadFonts: { Roboto: RobotoFont }, onDrawComplete: props.onRenderComplete, onDraw: (ctx, { width: w, height: h }, images) => {
            if (images.bgLogo) {
                const ratio = images.bgLogo.width / images.bgLogo.height;
                const WIDTH = ratio * h > w ? w : ratio * h;
                ctx.drawImage(images.bgLogo, 0.5, 0.5, WIDTH, h);
            }
            if (images.logo) {
                const ratio = images.logo.width / images.logo.height;
                const HEIGHT = Constants.width * 0.06;
                const WIDTH = ratio * HEIGHT;
                ctx.drawImage(images.logo, Constants.width * 0.35, Constants.height * 0.12, WIDTH, HEIGHT);
            }
            labels.forEach((label, index) => {
                if (label.style == 'content') {
                    const newLines = getLines(ctx, label.text, Constants.width * 0.6);
                    labels.splice(index, 1);
                    newLines.forEach((line, i) => {
                        labels.splice(index + i, 0, Object.assign(Object.assign({}, label), { text: line }));
                    });
                }
                if (label.style == 'footer') {
                    const vOffset = lbn.y + (lbn.h * index) / labels.length - (index === 0 ? 0.01 : 0);
                    const hOffset = lbn.x + lbn.w / 2.0;
                    drawText(ctx, label.style, label.text, w * hOffset, h * vOffset + 130);
                }
            });
            labels.forEach((label, index) => {
                if (label.style != 'footer') {
                    const vOffset = lbn.y + (lbn.h * index) / labels.length - (index === 0 ? 0.01 : 0);
                    const hOffset = lbn.x + lbn.w / 2.0;
                    drawText(ctx, label.style, label.text, w * hOffset, h * vOffset + 60);
                }
            });
            let signatures = [];
            if (props.signer) {
                signatures = props.signer.map((sign, index) => {
                    return { name: sign.name, image: index == 0 ? images.signature0 : images.signature1, position: sign.position };
                });
            }
            else {
                signatures = [
                    {
                        name: strings.screens.boards.course.certificate.signatures.bernardo.name,
                        image: images.signatureBernardo,
                        position: strings.screens.boards.course.certificate.signatures.bernardo.position,
                    },
                    {
                        name: strings.screens.boards.course.certificate.signatures.ivan.name,
                        image: images.signatureIvan,
                        position: strings.screens.boards.course.certificate.signatures.ivan.position,
                    },
                ];
            }
            signatures.forEach((signature, index) => {
                drawSignature(ctx, signature, index, Constants.width * 0.35, Constants.height * 0.75);
            });
        } }));
};
export { PremiumCertificate };
