import { combineReducers } from 'redux';
import { authSlice } from './Auth/AuthReducer';
import { billingSlice } from './Billing/BillingReducer';
import { communitySlice } from './Community/CommunityReducer';
import { errorSlice } from './Errors/ErrorReducer';
import { modalSlice } from './Modal/ModalReducer';
import { notificationSlice } from './Notifications/NotificationReducer';
import { postsSlice } from './Posts/PostReducer';
import { userSlice } from './user/UserReducer';
import { uiSlice } from './UserInterface/UserInterfaceReducer';
const Root = combineReducers({
    auth: authSlice.reducer,
    billing: billingSlice.reducer,
    community: communitySlice.reducer,
    modal: modalSlice.reducer,
    notification: notificationSlice.reducer,
    posts: postsSlice.reducer,
    ui: uiSlice.reducer,
    user: userSlice.reducer,
    error: errorSlice.reducer,
});
export { Root as RootReducer };
