import { setErrorAction } from '@state/actions';
import { store } from '@state/persistance/Storage';
import { openAuthExpiredModal } from '@state/actions';
export class Authentication {
    constructor() {
    }
    getAnalyticsId() {
        const auth = store === null || store === void 0 ? void 0 : store.getState().auth;
        return auth.analyticsId;
    }
    getAuthToken() {
        const auth = store === null || store === void 0 ? void 0 : store.getState().auth;
        return auth.authToken;
    }
    saveAuthToken(token) {
    }
    invalidateAuthToken() {
        store.dispatch(openAuthExpiredModal());
    }
    showError(error) {
        var _a;
        const code = (_a = store === null || store === void 0 ? void 0 : store.getState().error) === null || _a === void 0 ? void 0 : _a.code;
        if (!code) {
            store.dispatch(setErrorAction(error));
        }
    }
}
