import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useAppSelector } from '@hooks/useStore';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Alert, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { LoginForm } from './LoginForm';
import { Social } from '@components/content/social/Social';
import { Router } from '@Router';
import useBreakpoints from '@hooks/useBreakpoints';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import { API } from '@api/API';
import { RedirectComponent } from '../redirect/RedirectComponent';
const Login = () => {
    var _a;
    const loginStrings = strings.screens.auth.login;
    const location = useLocation();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const authState = useAppSelector((state) => state.auth.authState);
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall;
    const isMobile = breakpoints.isMobile;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const to = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.to;
    if (isLogged) {
        return _jsx(RedirectComponent, { to: to });
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: loginStrings.browserTitle }), _jsx("meta", { name: "description", content: loginStrings.browserDescription })] }), authState === 'unauth.expired' ? (_jsx(Row, { justify: "center", align: "middle", children: _jsx(Alert, { message: loginStrings.authExpired, type: "warning", showIcon: true, closable: true }) })) : undefined, _jsx(Row, { justify: "center", align: "middle", style: { minHeight: '80vh' }, children: _jsx(Col, { xs: 24, md: 18, lg: 12, style: { maxWidth: 600, marginTop: 24, marginBottom: 24 }, children: _jsxs(Card, { bodyStyle: { padding: isSmall ? 16 : 24 }, children: [_jsx(GoBackButton, { type: "default", showText: false, style: { marginBottom: 24 } }), _jsxs("div", { style: { maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }, children: [_jsxs(Paragraph, { style: {
                                                textAlign: 'center',
                                                fontWeight: 500,
                                                fontSize: isMobile ? 24 : 30,
                                                lineHeight: isMobile ? '32px' : '38px',
                                                marginBottom: 40,
                                            }, children: [loginStrings.greetings, _jsx("br", {}), loginStrings.goLogin] }), _jsx(Social, { kind: "login" }), _jsx(Divider, { plain: true, children: strings.general.email_option }), _jsx(LoginForm, {}), _jsx(Divider, { style: { margin: '16px 0' } }), _jsxs("div", { style: { width: '100%', textAlign: 'center' }, children: [loginStrings.goSignup.action != '' && (_jsxs(_Fragment, { children: [_jsx("span", { style: { marginRight: 3 }, children: loginStrings.goSignup.ask }), _jsx(Link, { onClick: () => {
                                                                void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: 'main.login' } });
                                                            }, to: { pathname: Router.Path.signup({}), state: location.state }, children: loginStrings.goSignup.action })] })), loginStrings.goSignup.action == '' && (_jsx(_Fragment, { children: _jsx(Link, { onClick: () => {
                                                            void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: 'main.login' } });
                                                        }, to: { pathname: Router.Path.signup({}), state: location.state }, children: loginStrings.goSignup.ask }) }))] })] })] }) }) })] }));
    }
};
export { Login };
