import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAppSelector } from '@hooks/useStore';
export const SocketContext = createContext(null);
export const NotificationContext = createContext(null);
const SocketComponent = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const currentToken = useAppSelector((state) => state.auth.authToken);
    const deviceId = useAppSelector((state) => state.auth.analyticsId);
    const general = useAppSelector((state) => state.notification.general);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const notifications = ((_c = (_b = (_a = general.aggregate) === null || _a === void 0 ? void 0 : _a[currentCommunity]) === null || _b === void 0 ? void 0 : _b.notifications) === null || _c === void 0 ? void 0 : _c.newly) + ((_f = (_e = (_d = general.aggregate) === null || _d === void 0 ? void 0 : _d[currentCommunity]) === null || _e === void 0 ? void 0 : _e.messages) === null || _f === void 0 ? void 0 : _f.newly);
    const socket = useWebSocket(WS_DOMAIN, {
        queryParams: {
            auth: currentToken,
            device: deviceId,
        },
        onOpen: () => { },
        onMessage: () => { },
        onError: () => { },
        shouldReconnect: () => {
            return true;
        },
        share: true,
        retryOnError: true,
        reconnectAttempts: 5,
        reconnectInterval: 15000,
    });
    return (_jsx(SocketContext.Provider, { value: socket, children: _jsx(NotificationContext.Provider, { value: notifications, children: props.children }) }));
};
export { SocketComponent };
