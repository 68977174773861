var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { communityChatExists } from '@state/actions';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { API } from '@api/API';
import { v4 } from 'uuid';
import { UserListItem } from '@components/content/entity/user_list_item/UserListItem';
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton';
import useBreakpoints from '@hooks/useBreakpoints';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
export const ChatModal = () => {
    const chatModal = useAppSelector((state) => state.modal.chat);
    const cm_pk = useAppSelector((state) => state.community.currentCommunity);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState(undefined);
    const [timer, setTimer] = useState();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const closeModal = () => {
        dispatch(modalAction({ modal: 'chat', state: false }));
    };
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const fetchFirstPage = () => {
        setLoading(true);
        void API.communityDirectory({
            urlParams: { cm_pk },
        }).then((response) => {
            if (response.data.length > 0) {
                const data = response.data;
                setLoading(false);
                setSuggestions(data);
            }
            else {
                setLoading(false);
                setSuggestions([]);
            }
        });
    };
    useEffect(() => {
        if (chatModal) {
            fetchFirstPage();
        }
        else {
            setSuggestions(undefined);
        }
    }, [chatModal]);
    const callAPI = (wordText) => {
        if (wordText.length >= 3) {
            setLoading(true);
            void API.userChattable({
                urlParams: { cm_pk },
                searchParams: {
                    term: wordText,
                },
            }).then((response) => {
                if (response.data.length > 0) {
                    const data = response.data;
                    setLoading(false);
                    setSuggestions(data);
                }
                else {
                    setLoading(false);
                    setSuggestions([]);
                }
            });
        }
        else {
            fetchFirstPage();
        }
    };
    const onChange = (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        setLoading(true);
        const timerRef = setTimeout(() => callAPI(e.target.defaultValue), 1100);
        setTimer(timerRef);
    };
    const afterCreate = (result) => {
        history.push(Router.Path.community_chat({ cm_pk }), { roomPk: result.data.publicKey });
        closeModal();
    };
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const efterError = (e, params) => __awaiter(void 0, void 0, void 0, function* () {
        if (e.error == 'application.chat.room.notfound') {
            yield delay(100);
            closeModal();
            history.push(Router.Path.community_chat({ cm_pk }), { roomPk: params.extraParams.localId });
        }
    });
    const onClick = (user) => {
        dispatch(communityChatExists({
            urlParams: {
                cm_pk,
            },
            bodyParams: { users: [user.publicKey] },
            extraParams: {
                localId: v4(),
                users: [
                    Object.assign(Object.assign({}, user), { roomAccess: 'member', roomState: 'invited' }),
                    Object.assign(Object.assign({}, currentEntity), { roomAccess: 'owner', roomState: 'present' }),
                ],
            },
            options: {
                then: {
                    action: afterCreate,
                    error: efterError,
                },
            },
        }));
    };
    return (_jsxs(TuringoModal, { bodyStyle: { padding: 0 }, open: chatModal, onCancel: closeModal, width: 650, footer: null, children: [_jsxs("div", { style: { padding: isMobile ? 16 : '16px 24px' }, children: [_jsx(Typography.Paragraph, { strong: true, style: { fontSize: isMobile ? 18 : 16, marginBottom: 16, marginRight: 24 }, children: strings.screens.chat.room.create.title }), _jsx("p", { children: strings.screens.chat.room.create.description }), _jsx(Input.Search, { autoFocus: true, onChange: onChange, placeholder: strings.screens.chat.search })] }), suggestions && (_jsxs("div", { style: { maxHeight: 320, overflow: 'auto' }, className: "scrollStyle", children: [suggestions && suggestions.length > 0 && !loading && (_jsx("div", { style: { display: 'grid', gridTemplateColumns: '1fr', gap: 8, paddingBottom: 16 }, children: suggestions.map((user) => {
                            return _jsx(UserListItem, { item: user, onClick: () => onClick(user) }, user.publicKey);
                        }) })), suggestions && suggestions.length == 0 && !loading && (_jsx("div", { style: { padding: isMobile ? 16 : '16px 24px' }, children: _jsx(AlertDashed, { message: strings.general.noResults, showIcon: false }) }))] })), loading && (_jsx("div", { style: { padding: 16 }, children: _jsx(ChatListSkeleton, { nElements: 5 }) }))] }));
};
