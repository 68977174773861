import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Dropdown } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { MoreOutlined } from '@ant-design/icons';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
const MoreButton = (props) => {
    const { items, type = 'default', size = 'medium', style } = props;
    const isMobile = useBreakpoints().isMobile;
    return (_jsx(Dropdown, { menu: { items }, trigger: ['click'], children: _jsx(TuringoButton, { onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
            }, shape: "square", icon: _jsx(MoreOutlined, {}), type: type, size: size, style: Object.assign({ zIndex: 3 }, style) }) }));
};
export { MoreButton };
