const digestName = (name, limit = 10000) => {
    if (!name || typeof name !== 'string')
        return 420;
    let accumulator = 0;
    for (let index = 0; index < name.length; index++) {
        accumulator = (accumulator + name.charCodeAt(index) * index) % limit;
    }
    return accumulator;
};
const ColorTable = Object.freeze([
    { banner: '#57B7FF', avatar: '#008BF2' },
    { banner: '#99DBDA', avatar: '#4DC1BE' },
    { banner: '#FDB05E', avatar: '#F27F03' },
    { banner: '#FF8AD8', avatar: '#EF009F' },
    { banner: '#6D6DD5', avatar: '#3131AA' },
    { banner: '#FF9EB3', avatar: '#FF3862' },
    { banner: '#FDB05E', avatar: '#F27F03' },
    { banner: '#6D6DD5', avatar: '#3131AA' },
    { banner: '#FF8AD8', avatar: '#EF009F' },
]);
export const entityColor = (name) => {
    return ColorTable[digestName(name) % ColorTable.length];
};
