import Localization from '@resources/localization';
export var HTTP;
(function (HTTP) {
    let MimeType;
    (function (MimeType) {
        MimeType["json"] = "application/json; charset=UTF-8";
        MimeType["urlEncoded"] = "application/x-www-form-urlencoded; charset=UTF-8";
    })(MimeType = HTTP.MimeType || (HTTP.MimeType = {}));
    function safeEncodingForMethod(method, parameters, encoding = 'body.json') {
        if (Object.keys(parameters.bodyParameters).length === 0 &&
            Object.keys(parameters.searchParameters).length === 0 &&
            Object.keys(parameters.urlParameters).length === 0) {
            return 'ignore';
        }
        switch (method) {
            case 'GET':
                return 'url.encoded';
            default:
                return encoding;
        }
    }
    HTTP.safeEncodingForMethod = safeEncodingForMethod;
    function acceptCurrentLanguage(request) {
        request.headers.set('Accept-Language', Localization.getLanguage());
    }
    HTTP.acceptCurrentLanguage = acceptCurrentLanguage;
    function pageRequest(request, pageHeaders) {
        if (!pageHeaders.page || pageHeaders.page == 'first') {
            return;
        }
        request.headers.set('X-Turingo-Pagination-Page', `${pageHeaders.page}`);
        if (!pageHeaders.pageCount) {
            return;
        }
        request.headers.set('X-Turingo-Pagination-Page-Count', `${pageHeaders.pageCount}`);
    }
    HTTP.pageRequest = pageRequest;
    function presignRequest(request, token) {
        if (!token) {
            return;
        }
        request.headers.set('X-Turingo-Auth-Presign-Id', `${token}`);
    }
    HTTP.presignRequest = presignRequest;
    function signRequest(request, token) {
        if (!token) {
            return;
        }
        request.headers.set('Authorization', `Bearer ${token}`);
    }
    HTTP.signRequest = signRequest;
    function parametrizePath(pathRaw, parameters = {}, strict = false) {
        const matched = {};
        const unmatched = Object.assign({}, parameters);
        const path = pathRaw.trim().replace(/\/:(\w)+/gi, (match) => {
            const parameterName = match.slice(2);
            if (parameters[parameterName] === undefined) {
                if (strict)
                    throw `HTTP Path Parametrization failed: No parameter '${parameterName}' found on paramters`;
                return '';
            }
            const parameter = parameters[parameterName];
            matched[parameterName] = parameter;
            delete unmatched[parameterName];
            return '/' + parameter.toString();
        });
        return { path, matched, unmatched };
    }
    HTTP.parametrizePath = parametrizePath;
})(HTTP || (HTTP = {}));
