import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Skeleton, Card, Row, Col } from 'antd';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import useBreakpoints from '@hooks/useBreakpoints';
import { PostSkeleton } from './components/PostSkeleton/PostSkeleton';
export const PostScreenSkeleton = () => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    return (_jsx("div", { className: "listings-skeleton", children: _jsx(ContentLayout, { left: _jsx("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24 }, children: _jsx(PostSkeleton, {}) }), right: _jsxs(Row, { gutter: [isSmall ? 0 : 16, isMobile ? 16 : 24], children: [_jsx(Col, { span: 24, children: _jsx(Card, { bordered: false, title: _jsx(Skeleton, { title: true, active: true, paragraph: { rows: 0 } }), children: _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 3 } }) }) }), _jsx(Col, { span: 24, children: _jsx(Card, { bordered: false, title: _jsx(Skeleton, { title: true, active: true, paragraph: { rows: 0 } }), children: _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 3 } }) }) }), _jsx(Col, { span: 24, children: _jsx(Card, { bordered: false, title: _jsx(Skeleton, { title: true, active: true, paragraph: { rows: 0 } }), children: _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 3 } }) }) })] }) }) }));
};
