import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Skeleton, Tabs } from 'antd';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { useHistory, useParams } from 'react-router';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { Router } from '@Router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TuringoList } from '@components/list/TuringoList';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { postReactionList } from '@state/actions';
import { getMoniker } from './PostAuthor';
const { TabPane } = Tabs;
const PostViewReadModal = (props) => {
    var _a, _b, _c;
    const { cm_pk, b_pk: br_pk, p_pk } = useParams();
    const isFeed = !br_pk;
    const { target, open, onCancel } = props;
    const isMobile = useBreakpoints().isMobile;
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isWhatsAppActive = ((_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.plugins) === null || _b === void 0 ? void 0 : _b.whatsapp) === null || _c === void 0 ? void 0 : _c.state) == 'active';
    const [readState, setReadState] = useState({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    });
    const [communityState, setCommunityState] = useState({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    });
    const [whatsappState, setWhatsappState] = useState({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    });
    const [emailState, setEmailState] = useState({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    });
    const [kind, setKind] = useState(props.kind);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const afterAction = (result, page, kind, content) => {
        if (kind == 'pin_view') {
            if (page == 'first') {
                if (content == 'email')
                    setEmailState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 });
                if (content == 'whatsapp')
                    setWhatsappState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 });
                if (content == 'none')
                    setCommunityState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 });
            }
            else {
                if (content == 'email')
                    setEmailState({ data: [...emailState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 });
                if (content == 'whatsapp')
                    setWhatsappState({ data: [...whatsappState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 });
                if (content == 'none')
                    setCommunityState({
                        data: [...communityState.data, ...result.data],
                        loading: false,
                        pagination: result.pagination,
                        total: result.total || 0,
                    });
            }
        }
        else {
            if (page == 'first') {
                setReadState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 });
            }
            else {
                setReadState({ data: [...readState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 });
            }
        }
    };
    const fetchReactions = (content, page) => {
        if (kind == 'pin_view') {
            if (content == 'email')
                setEmailState(Object.assign(Object.assign({}, emailState), { loading: true }));
            if (content == 'whatsapp')
                setWhatsappState(Object.assign(Object.assign({}, whatsappState), { loading: true }));
            if (content == 'none')
                setCommunityState(Object.assign(Object.assign({}, communityState), { loading: true }));
        }
        else {
            setReadState(Object.assign(Object.assign({}, readState), { loading: true }));
        }
        void dispatch(postReactionList({
            urlParams: {
                cm_pk,
                b_pk: target.boardPk,
                target_pk: target.publicKey,
            },
            extraParams: {
                feed: isFeed,
            },
            headerParams: {
                page: page == 'first' ? null : page,
            },
            searchParams: Object.assign({ kind: kind }, (content && { content: content })),
            options: {
                then: {
                    action: (result) => afterAction(result, page, kind, content),
                },
            },
        }));
    };
    useEffect(() => {
        const myDiv2 = document.getElementById('pin_read');
        if (myDiv2) {
            myDiv2.scrollTop = 0;
        }
        const myDiv = document.getElementById('pin_view');
        if (myDiv) {
            myDiv.scrollTop = 0;
        }
        if (kind == 'pin_view') {
            void fetchReactions('none', 'first');
            void fetchReactions('whatsapp', 'first');
            void fetchReactions('email', 'first');
        }
        else {
            void fetchReactions(undefined, 'first');
        }
    }, [kind]);
    const moniker = useCallback((user, maxWidth) => {
        return getMoniker(user, maxWidth);
    }, [communityState, whatsappState, emailState, readState]);
    const listComponent = (content, target) => {
        var _a;
        const reactionState = content ? (content == 'none' ? communityState : content == 'whatsapp' ? whatsappState : emailState) : readState;
        return (_jsx(InfiniteScroll, { scrollableTarget: target, style: { marginTop: ((_a = reactionState === null || reactionState === void 0 ? void 0 : reactionState.data) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 16 : 0, maxHeight: 'inherit' }, dataLength: reactionState.loading && reactionState.pagination == 'first' ? 0 : reactionState.data.length, next: () => {
                void fetchReactions(content, reactionState.pagination);
            }, hasMore: reactionState.pagination && reactionState.pagination != 'first', loader: _jsxs("div", { style: { padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }, children: [_jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null }), _jsx(Skeleton, { active: true, title: true, paragraph: null })] }), children: _jsx(TuringoList, { emptyText: " ", showDivider: false, showArrow: false, itemRender: (item) => (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(AvatarTuringo, { size: 24, style: {
                                height: 48,
                                width: 48,
                                lineHeight: '48px',
                                marginRight: 8,
                            }, name: item.name, avatar: item.avatarUrl }), _jsxs("div", { style: { lineHeight: '16px', width: '100%' }, children: [_jsx("div", { className: "turingo-text", style: { display: 'flex', alignItems: 'center', fontWeight: 500 }, children: item.name }), moniker(item)] })] }, item.publicKey)), items: reactionState.data.map((item) => {
                    return { item: item.author, publicKey: item.author.publicKey };
                }), onClick: (value) => history.push(Router.Path.user_details({ u_pk: value, cm_pk })) }) }));
    };
    const getTitle = () => {
        if (props.kind == 'react') {
            return `${strings.general.like} (${target.totalReactions})`;
        }
        if (props.kind == 'vote') {
            return strings.general.votes.title;
        }
        else {
            return strings.screens.markasread.details.title;
        }
    };
    return (_jsx(TuringoModal, { width: 572, open: open, scrolleable: false, onCancel: onCancel, footer: null, closable: true, customTitle: _jsxs("div", { className: "turingo-card-header-padding", children: [_jsx(Paragraph, { style: { marginBottom: 16 }, className: "turingo-title", strong: true, children: getTitle() }), _jsx(Paragraph, { className: "turingo-text", children: strings.screens.markasread.details.description })] }), bodyStyle: { padding: 0, minHeight: 500 }, children: (kind == 'pin_read' || kind == 'pin_view') && (_jsxs(Tabs, { className: "turingo-card-tabs", onChange: (key) => {
                setKind(key);
            }, children: [_jsx(TabPane, { tab: _jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }, children: [strings.screens.markasread.details.viewers.title, " ", _jsx("div", { className: 'tag', children: target.totalViews })] }), children: _jsxs("div", { id: 'pin_view', className: "scrollStyle", style: {
                            maxHeight: 'calc(90vh - 154px)',
                            overflowY: 'auto',
                        }, children: [_jsxs("div", { style: {
                                    marginLeft: isMobile ? 16 : 24,
                                    marginTop: 16,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }, children: [strings.screens.markasread.details.medium.community, " ", _jsx("div", { className: 'tag', children: communityState.total })] }), listComponent('none', 'pin_view'), isWhatsAppActive && (_jsx("div", { className: "lateral-padding", children: _jsx(Divider, {}) })), isWhatsAppActive && (_jsxs("div", { style: {
                                    marginLeft: isMobile ? 16 : 24,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }, children: [strings.screens.markasread.details.medium.whatsapp, " ", _jsx("div", { className: 'tag', children: whatsappState.total })] })), isWhatsAppActive && listComponent('whatsapp', 'pin_view'), _jsx("div", { className: "lateral-padding", children: _jsx(Divider, {}) }), _jsxs("div", { style: {
                                    marginLeft: isMobile ? 16 : 24,
                                    marginBottom: 16,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }, children: [strings.screens.markasread.details.medium.email, " ", _jsx("div", { className: 'tag', children: emailState.total })] }), listComponent('email', 'pin_view')] }) }, 'pin_view'), _jsx(TabPane, { disabled: target.totalReads == 0, tab: _jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }, children: [strings.screens.markasread.details.readers.title, " ", _jsx("div", { className: 'tag', children: target.totalReads })] }), children: _jsx("div", { id: 'pin_read', className: "scrollStyle", style: {
                            maxHeight: 'calc(90vh - 154px)',
                            overflowY: 'auto',
                        }, children: listComponent(undefined, 'pin_read') }) }, 'pin_read')] })) }));
};
export { PostViewReadModal };
