const LANGUAGES_LIST = {
    aa: { en: 'Afar', nativeName: 'Afaraf' },
    ab: { en: 'Abkhaz', nativeName: 'аҧсуа бызшәа' },
    ae: { en: 'Avestan', nativeName: 'avesta' },
    af: { en: 'Afrikaans', nativeName: 'Afrikaans' },
    ak: { en: 'Akan', nativeName: 'Akan' },
    am: { en: 'Amharic', nativeName: 'አማርኛ' },
    an: { en: 'Aragonese', nativeName: 'aragonés' },
    ar: { en: 'Arabic', nativeName: 'اللغة العربية' },
    as: { en: 'Assamese', nativeName: 'অসমীয়া' },
    av: { en: 'Avaric', nativeName: 'авар мацӀ' },
    ay: { en: 'Aymara', nativeName: 'aymar aru' },
    az: { en: 'Azerbaijani', nativeName: 'azərbaycan dili' },
    ba: { en: 'Bashkir', nativeName: 'башҡорт теле' },
    be: { en: 'Belarusian', nativeName: 'беларуская мова' },
    bg: { en: 'Bulgarian', nativeName: 'български език' },
    bh: { en: 'Bihari', nativeName: 'भोजपुरी' },
    bi: { en: 'Bislama', nativeName: 'Bislama' },
    bm: { en: 'Bambara', nativeName: 'bamanankan' },
    bn: { en: 'Bengali', nativeName: 'বাংলা' },
    bo: { en: 'Tibetan Standard', nativeName: 'བོད་ཡིག' },
    br: { en: 'Breton', nativeName: 'brezhoneg' },
    bs: { en: 'Bosnian', nativeName: 'bosanski jezik' },
    ca: { en: 'Catalan', nativeName: 'català' },
    ce: { en: 'Chechen', nativeName: 'нохчийн мотт' },
    ch: { en: 'Chamorro', nativeName: 'Chamoru' },
    co: { en: 'Corsican', nativeName: 'corsu' },
    cr: { en: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
    cs: { en: 'Czech', nativeName: 'čeština' },
    cu: { en: 'Old Church Slavonic', nativeName: 'ѩзыкъ словѣньскъ' },
    cv: { en: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
    cy: { en: 'Welsh', nativeName: 'Cymraeg' },
    da: { en: 'Danish', nativeName: 'dansk' },
    de: { en: 'German', nativeName: 'Deutsch' },
    dv: { en: 'Divehi', nativeName: 'Dhivehi' },
    dz: { en: 'Dzongkha', nativeName: 'རྫོང་ཁ' },
    ee: { en: 'Ewe', nativeName: 'Eʋegbe' },
    el: { en: 'Greek', nativeName: 'Ελληνικά' },
    en: { en: 'English', es: 'Inglés', nativeName: 'English' },
    eo: { en: 'Esperanto', nativeName: 'Esperanto' },
    es: { en: 'Spanish', es: 'Español', nativeName: 'Español' },
    et: { en: 'Estonian', nativeName: 'eesti' },
    eu: { en: 'Basque', nativeName: 'euskara' },
    fa: { en: 'Persian', nativeName: 'فارسی' },
    ff: { en: 'Fula', nativeName: 'Fulfulde' },
    fi: { en: 'Finnish', nativeName: 'suomi' },
    fj: { en: 'Fijian', nativeName: 'Vakaviti' },
    fo: { en: 'Faroese', nativeName: 'føroyskt' },
    fr: { en: 'French', nativeName: 'Français' },
    fy: { en: 'Western Frisian', nativeName: 'Frysk' },
    ga: { en: 'Irish', nativeName: 'Gaeilge' },
    gd: { en: 'Scottish Gaelic', nativeName: 'Gàidhlig' },
    gl: { en: 'Galician', nativeName: 'galego' },
    gn: { en: 'Guaraní', nativeName: "Avañe'ẽ" },
    gu: { en: 'Gujarati', nativeName: 'ગુજરાતી' },
    gv: { en: 'Manx', nativeName: 'Gaelg' },
    ha: { en: 'Hausa', nativeName: 'هَوُسَ' },
    he: { en: 'Hebrew', nativeName: 'עברית' },
    hi: { en: 'Hindi', nativeName: 'हिन्दी' },
    ho: { en: 'Hiri Motu', nativeName: 'Hiri Motu' },
    hr: { en: 'Croatian', nativeName: 'hrvatski jezik' },
    ht: { en: 'Haitian', nativeName: 'Kreyòl ayisyen' },
    hu: { en: 'Hungarian', nativeName: 'magyar' },
    hy: { en: 'Armenian', nativeName: 'Հայերեն' },
    hz: { en: 'Herero', nativeName: 'Otjiherero' },
    ia: { en: 'Interlingua', nativeName: 'Interlingua' },
    id: { en: 'Indonesian', nativeName: 'Indonesian' },
    ie: { en: 'Interlingue', nativeName: 'Interlingue' },
    ig: { en: 'Igbo', nativeName: 'Asụsụ Igbo' },
    ii: { en: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
    ik: { en: 'Inupiaq', nativeName: 'Iñupiaq' },
    io: { en: 'Ido', nativeName: 'Ido' },
    is: { en: 'Icelandic', nativeName: 'Íslenska' },
    it: { en: 'Italian', nativeName: 'Italiano' },
    iu: { en: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
    ja: { en: 'Japanese', nativeName: '日本語' },
    jv: { en: 'Javanese', nativeName: 'basa Jawa' },
    ka: { en: 'Georgian', nativeName: 'ქართული' },
    kg: { en: 'Kongo', nativeName: 'Kikongo' },
    ki: { en: 'Kikuyu', nativeName: 'Gĩkũyũ' },
    kj: { en: 'Kwanyama', nativeName: 'Kuanyama' },
    kk: { en: 'Kazakh', nativeName: 'қазақ тілі' },
    kl: { en: 'Kalaallisut', nativeName: 'kalaallisut' },
    km: { en: 'Khmer', nativeName: 'ខេមរភាសា' },
    kn: { en: 'Kannada', nativeName: 'ಕನ್ನಡ' },
    ko: { en: 'Korean', nativeName: '한국어' },
    kr: { en: 'Kanuri', nativeName: 'Kanuri' },
    ks: { en: 'Kashmiri', nativeName: 'कश्मीरी' },
    ku: { en: 'Kurdish', nativeName: 'Kurdî' },
    kv: { en: 'Komi', nativeName: 'коми кыв' },
    kw: { en: 'Cornish', nativeName: 'Kernewek' },
    ky: { en: 'Kyrgyz', nativeName: 'Кыргызча' },
    la: { en: 'Latin', nativeName: 'latine' },
    lb: { en: 'Luxembourgish', nativeName: 'Lëtzebuergesch' },
    lg: { en: 'Ganda', nativeName: 'Luganda' },
    li: { en: 'Limburgish', nativeName: 'Limburgs' },
    ln: { en: 'Lingala', nativeName: 'Lingála' },
    lo: { en: 'Lao', nativeName: 'ພາສາ' },
    lt: { en: 'Lithuanian', nativeName: 'lietuvių kalba' },
    lu: { en: 'Luba-Katanga', nativeName: 'Tshiluba' },
    lv: { en: 'Latvian', nativeName: 'latviešu valoda' },
    mg: { en: 'Malagasy', nativeName: 'fiteny malagasy' },
    mh: { en: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
    mi: { en: 'Māori', nativeName: 'te reo Māori' },
    mk: { en: 'Macedonian', nativeName: 'македонски јазик' },
    ml: { en: 'Malayalam', nativeName: 'മലയാളം' },
    mn: { en: 'Mongolian', nativeName: 'Монгол хэл' },
    mr: { en: 'Marathi', nativeName: 'मराठी' },
    ms: { en: 'Malay', nativeName: 'هاس ملايو‎' },
    mt: { en: 'Maltese', nativeName: 'Malti' },
    my: { en: 'Burmese', nativeName: 'ဗမာစာ' },
    na: { en: 'Nauru', nativeName: 'Ekakairũ Naoero' },
    nb: { en: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
    nd: { en: 'Northern Ndebele', nativeName: 'isiNdebele' },
    ne: { en: 'Nepali', nativeName: 'नेपाली' },
    ng: { en: 'Ndonga', nativeName: 'Owambo' },
    nl: { en: 'Dutch', nativeName: 'Nederlands' },
    nn: { en: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
    no: { en: 'Norwegian', nativeName: 'Norsk' },
    nr: { en: 'Southern Ndebele', nativeName: 'isiNdebele' },
    nv: { en: 'Navajo', nativeName: 'Diné bizaad' },
    ny: { en: 'Chichewa', nativeName: 'chiCheŵa' },
    oc: { en: 'Occitan', nativeName: 'occitan' },
    oj: { en: 'Ojibwe', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
    om: { en: 'Oromo', nativeName: 'Afaan Oromoo' },
    or: { en: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
    os: { en: 'Ossetian', nativeName: 'ирон æвзаг' },
    pa: { en: 'Panjabi', nativeName: 'ਪੰਜਾਬੀ' },
    pi: { en: 'Pāli', nativeName: 'पाऴि' },
    pl: { en: 'Polish', nativeName: 'język polski' },
    ps: { en: 'Pashto', nativeName: 'پښتو' },
    pt: { en: 'Portuguese', es: 'Portugués', nativeName: 'Português' },
    qu: { en: 'Quechua', nativeName: 'Runa Simi' },
    rm: { en: 'Romansh', nativeName: 'rumantsch grischun' },
    rn: { en: 'Kirundi', nativeName: 'Ikirundi' },
    ro: { en: 'Romanian', nativeName: 'Română' },
    ru: { en: 'Russian', nativeName: 'Русский' },
    rw: { en: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
    sa: { en: 'Sanskrit', nativeName: 'संस्कृतम्' },
    sc: { en: 'Sardinian', nativeName: 'sardu' },
    sd: { en: 'Sindhi', nativeName: 'सिन्धी' },
    se: { en: 'Northern Sami', nativeName: 'Davvisámegiella' },
    sg: { en: 'Sango', nativeName: 'yângâ tî sängö' },
    si: { en: 'Sinhala', nativeName: 'සිංහල' },
    sk: { en: 'Slovak', nativeName: 'slovenčina' },
    sl: { en: 'Slovene', nativeName: 'slovenski jezik' },
    sm: { en: 'Samoan', nativeName: "gagana fa'a Samoa" },
    sn: { en: 'Shona', nativeName: 'chiShona' },
    so: { en: 'Somali', nativeName: 'Soomaaliga' },
    sq: { en: 'Albanian', nativeName: 'Shqip' },
    sr: { en: 'Serbian', nativeName: 'српски језик' },
    ss: { en: 'Swati', nativeName: 'SiSwati' },
    st: { en: 'Southern Sotho', nativeName: 'Sesotho' },
    su: { en: 'Sundanese', nativeName: 'Basa Sunda' },
    sv: { en: 'Swedish', nativeName: 'svenska' },
    sw: { en: 'Swahili', nativeName: 'Kiswahili' },
    ta: { en: 'Tamil', nativeName: 'தமிழ்' },
    te: { en: 'Telugu', nativeName: 'తెలుగు' },
    tg: { en: 'Tajik', nativeName: 'тоҷикӣ' },
    th: { en: 'Thai', nativeName: 'ไทย' },
    ti: { en: 'Tigrinya', nativeName: 'ትግርኛ' },
    tk: { en: 'Turkmen', nativeName: 'Türkmen' },
    tl: { en: 'Tagalog', nativeName: 'Wikang Tagalog' },
    tn: { en: 'Tswana', nativeName: 'Setswana' },
    to: { en: 'Tonga', nativeName: 'faka Tonga' },
    tr: { en: 'Turkish', nativeName: 'Türkçe' },
    ts: { en: 'Tsonga', nativeName: 'Xitsonga' },
    tt: { en: 'Tatar', nativeName: 'татар теле' },
    tw: { en: 'Twi', nativeName: 'Twi' },
    ty: { en: 'Tahitian', nativeName: 'Reo Tahiti' },
    ug: { en: 'Uyghur', nativeName: 'ئۇيغۇرچە‎' },
    uk: { en: 'Ukrainian', nativeName: 'Українська' },
    ur: { en: 'Urdu', nativeName: 'اردو' },
    uz: { en: 'Uzbek', nativeName: 'Ўзбек' },
    ve: { en: 'Venda', nativeName: 'Tshivenḓa' },
    vi: { en: 'Vietnamese', nativeName: 'Tiếng Việt' },
    vo: { en: 'Volapük', nativeName: 'Volapük' },
    wa: { en: 'Walloon', nativeName: 'walon' },
    wo: { en: 'Wolof', nativeName: 'Wollof' },
    xh: { en: 'Xhosa', nativeName: 'isiXhosa' },
    yi: { en: 'Yiddish', nativeName: 'ייִדיש' },
    yo: { en: 'Yoruba', nativeName: 'Yorùbá' },
    za: { en: 'Zhuang', nativeName: 'Saɯ cueŋƅ' },
    zh: { en: 'Chinese', nativeName: '中文' },
    zu: { en: 'Zulu', nativeName: 'isiZulu' },
};
class ISO6391 {
    static getName(code, language) {
        return LANGUAGES_LIST[code][language];
    }
    static getAllNames() {
        return Object.values(LANGUAGES_LIST).map((l) => l.en);
    }
    static getNativeName(code) {
        if (ISO6391.validate(code)) {
            return LANGUAGES_LIST[code].nativeName;
        }
        return undefined;
    }
    static getAllNativeNames() {
        return Object.values(LANGUAGES_LIST).map((l) => l.nativeName);
    }
    static getCode(name) {
        return (Object.keys(LANGUAGES_LIST).find((code) => {
            const language = LANGUAGES_LIST[code];
            return language.en.toLowerCase() === name.toLowerCase() || language.nativeName.toLowerCase() === name.toLowerCase();
        }) || '');
    }
    static getAllCodes() {
        return Object.keys(LANGUAGES_LIST);
    }
    static validate(code) {
        return LANGUAGES_LIST.hasOwnProperty(code);
    }
}
export default ISO6391;
