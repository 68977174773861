export const isLoading = (states) => {
    states.forEach((element) => {
        if (!element)
            return true;
        if (element.loading)
            return true;
    });
    return false;
};
export const hasError = (states) => {
    let error = undefined;
    states.forEach((element) => {
        var _a;
        if (!element)
            return undefined;
        error = ((_a = element.error) === null || _a === void 0 ? void 0 : _a.error) || error;
    });
    return error;
};
