import { arraySimilarity } from '@util/array/equality';
import { trim } from '@util/string/Functions';
const buildCommunityEditRequest = (form, profile, color) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    let count = 0;
    const body = {};
    if (form.name != (profile === null || profile === void 0 ? void 0 : profile.name)) {
        body.name = trim(form.name);
        count++;
    }
    if (((_a = form.profile) === null || _a === void 0 ? void 0 : _a.bio) != ((_b = profile === null || profile === void 0 ? void 0 : profile.profile) === null || _b === void 0 ? void 0 : _b.bio)) {
        body.biography = trim((_c = form.profile) === null || _c === void 0 ? void 0 : _c.bio);
        if (body.biography == '')
            body.biography = null;
        count++;
    }
    if (form.email != ((_d = profile === null || profile === void 0 ? void 0 : profile.contact) === null || _d === void 0 ? void 0 : _d.email)) {
        body.email = trim(form.email);
        if (body.email == '')
            body.email = null;
        count++;
    }
    if (form.twitter != ((_e = profile === null || profile === void 0 ? void 0 : profile.contact) === null || _e === void 0 ? void 0 : _e.twitter)) {
        body.twitter = trim(form.twitter);
        if (body.twitter == '')
            body.twitter = null;
        count++;
    }
    if (form.linkedin != ((_f = profile.contact) === null || _f === void 0 ? void 0 : _f.linkedin)) {
        body.linkedin = trim(form.linkedin);
        if (body.linkedin == '')
            body.linkedin = null;
        count++;
    }
    if (form.facebook != ((_g = profile.contact) === null || _g === void 0 ? void 0 : _g.facebook)) {
        body.facebook = trim(form.facebook);
        if (body.facebook == '')
            body.facebook = null;
        count++;
    }
    if (color != ((_j = (_h = profile === null || profile === void 0 ? void 0 : profile.ui) === null || _h === void 0 ? void 0 : _h.style) === null || _j === void 0 ? void 0 : _j.color)) {
        body.uiColorPrimary = color;
        count++;
    }
    if (form.youtube != ((_k = profile === null || profile === void 0 ? void 0 : profile.contact) === null || _k === void 0 ? void 0 : _k.youtube)) {
        body.youtube = trim(form.youtube);
        if (body.youtube == '')
            body.youtube = null;
        count++;
    }
    if (form.instagram != ((_l = profile === null || profile === void 0 ? void 0 : profile.contact) === null || _l === void 0 ? void 0 : _l.instagram)) {
        body.instagram = trim(form.instagram);
        if (body.instagram == '')
            body.instagram = null;
        count++;
    }
    if (form.profile.url != ((_m = profile === null || profile === void 0 ? void 0 : profile.profile) === null || _m === void 0 ? void 0 : _m.url)) {
        body.url = trim(form.profile.url);
        if (body.url == '')
            body.url = null;
        count++;
    }
    if (form.rules && !arraySimilarity(form.rules, profile === null || profile === void 0 ? void 0 : profile.rule)) {
        body.rules = (_o = form.rules) === null || _o === void 0 ? void 0 : _o.map((rule, index) => {
            return {
                description: rule.description,
                title: rule.title,
                order: index,
            };
        });
        count++;
    }
    return { body, count };
};
export { buildCommunityEditRequest };
