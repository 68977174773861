import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Button, Dropdown, Menu, Space } from 'antd';
import { CaretRightFilled, PauseOutlined, SoundFilled } from '@ant-design/icons';
import { FullScreen, OffScreen } from '@icon-park/react';
import style from './Controls.scss';
import { forwardRef, useEffect, useRef, useState } from 'react';
const Controls = forwardRef((props, ref) => {
    const { onSeek, onSeekMouseDown, onSeekMouseUp, onPlayPause, onToggleFullScreen, playing, played, elapsedTime, totalDuration, fullscreen, setSelectedLanguage, subtitleTracks, onVolumeSeekDown, onVolumeChange, volume, muted, } = props;
    const onBlur = () => {
        console.log('focus');
    };
    const video = document.getElementById('lessonPlayer');
    const sliderRef = useRef();
    const [classSlider, setClassSlider] = useState('');
    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.addEventListener('mouseenter', function (event) {
                setClassSlider('mouseSlider');
            });
            sliderRef.current.addEventListener('mouseleave', function (event) {
                setClassSlider('');
            });
        }
    }, []);
    const [subtitleSelected, setSubtitleSelected] = useState();
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        if (subtitleTracks && subtitleTracks.length > 0) {
            const menuItems = [
                {
                    label: 'No',
                    key: -1,
                    onClick: () => {
                        for (let j = 0; j < subtitleTracks.length; j++) {
                            subtitleTracks[j].mode = 'disabled';
                        }
                        setSubtitleSelected(undefined);
                        console.log(subtitleTracks);
                    },
                },
            ];
            for (let i = 0; i < subtitleTracks.length; i++) {
                menuItems.push({
                    label: subtitleTracks[i].label,
                    key: i,
                    onClick: () => {
                        for (let j = 0; j < subtitleTracks.length; j++) {
                            const trackElement = subtitleTracks[j];
                            if (trackElement.language == subtitleTracks[i].language) {
                                trackElement.mode = 'showing';
                                setSubtitleSelected(trackElement.language);
                            }
                            else {
                                trackElement.mode = 'disabled';
                            }
                        }
                    },
                });
            }
            setMenuItems(menuItems);
        }
    }, [subtitleTracks]);
    const menu = _jsx(Menu, { items: menuItems });
    return (_jsx("div", { ref: ref, className: style.controlsWrapper, children: _jsx("div", { style: { display: 'grid', alignItems: 'end', height: '100%', padding: 10 }, children: _jsxs("div", { children: [_jsx("div", { ref: sliderRef, style: { cursor: 'pointer' }, children: _jsx(Slider, { className: classSlider, style: { margin: '10px 0' }, min: 0, max: 1000, value: played * 1000, onBeforeChange: onSeekMouseDown, onAfterChange: onSeekMouseUp, onBlur: onBlur, onChange: onSeek, trackStyle: { backgroundColor: '#de3559', height: 4 }, handleStyle: {
                                borderColor: '#de3559',
                                height: 16,
                                transform: 'scale(0)',
                                width: 16,
                                marginLeft: 0,
                                marginTop: -6,
                                backgroundColor: '#de3559',
                            }, railStyle: { backgroundColor: 'rgba(255,255,255,0.6)', height: 4 } }) }), _jsxs("div", { style: { justifyContent: 'space-between', display: 'flex' }, children: [_jsxs("div", { style: {
                                    display: 'grid',
                                    columnGap: 10,
                                    gridTemplateColumns: 'auto 1fr 1fr',
                                    alignItems: 'center',
                                }, children: [_jsx(Button, { type: "primary", shape: "circle", icon: playing ? _jsx(PauseOutlined, {}) : _jsx(CaretRightFilled, {}), onClick: onPlayPause }), _jsxs("span", { style: { color: 'white' }, children: [elapsedTime, " / ", totalDuration] }), _jsxs("span", { style: { display: 'flex' }, children: [_jsx(SoundFilled, { style: { fontSize: 18, marginRight: 4, color: 'white' } }), _jsx(Slider, { min: 0, max: 100, value: muted ? 0 : volume * 100, onChange: onVolumeChange, onAfterChange: onVolumeSeekDown, trackStyle: { backgroundColor: '#de3559', height: 4 }, handleStyle: {
                                                    borderColor: '#de3559',
                                                    height: 16,
                                                    transform: 'scale(0)',
                                                    width: 16,
                                                    marginLeft: 0,
                                                    marginTop: -6,
                                                    backgroundColor: '#de3559',
                                                }, railStyle: { backgroundColor: 'rgba(255,255,255,0.6)', height: 4 } })] })] }), _jsxs("div", { children: [menuItems.length > 0 && (_jsx("div", { style: { marginRight: 8, display: 'inline' }, children: _jsx(Dropdown, { placement: "top", overlay: menu, trigger: ['click'], children: _jsx("a", { onClick: (e) => e.preventDefault(), children: _jsxs(Space, { children: ["CC: ", subtitleSelected ? subtitleSelected : 'No'] }) }) }) })), _jsx(Button, { style: { color: 'white' }, type: "text", icon: fullscreen ? _jsx(OffScreen, {}) : _jsx(FullScreen, {}), onClick: onToggleFullScreen })] })] })] }) }) }));
});
export default Controls;
