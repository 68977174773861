import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Link, useParams } from 'react-router-dom';
import { Form, List, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { API } from '@api/API';
import strings from '@resources/localization';
import { commentDelete, commentEdit } from '@state/actions';
import { AvatarTuringo } from '@components/avatar/Avatar';
export const CommentForm = (props) => {
    const { form, onFinish, b_pk, p_pk, comment, type, parents } = props;
    const dispatch = useAppDispatch();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [buttonState, setButtonState] = useState(false);
    const [text, setText] = useState('');
    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams();
    const isFeed = !br_pk;
    const scope = pr_pk ? 'detail' : 'all';
    const onChange = (e) => {
        const text = e.target.value;
        const lines = text.split('\n');
        if (e.target.value) {
            if (lines.length <= 12 && text.trim() != '') {
                setButtonState(true);
            }
            else {
                setButtonState(false);
            }
        }
        else {
            setButtonState(false);
        }
    };
    const submit = (values) => {
        const { content } = values;
        if (type == 'edit' && comment) {
            dispatch(commentEdit({
                urlParams: { cm_pk, b_pk, p_pk: parents.length == 1 ? parents[0] : parents[1], c_pk: comment.publicKey },
                bodyParams: {
                    content: content === null || content === void 0 ? void 0 : content.trim(),
                },
                extraParams: {
                    parents: parents,
                    scope: scope,
                    feed: isFeed,
                },
                options: {
                    alerts: {
                        success: strings.alerts.comment.edit.success,
                        error: strings.alerts.comment.edit.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
            }));
        }
        else if (type == 'delete' && comment) {
            dispatch(commentDelete({
                urlParams: { cm_pk, b_pk, p_pk: parents.length == 1 ? parents[0] : parents[1], c_pk: comment.publicKey },
                bodyParams: { postRootPk: parents[0] },
                extraParams: {
                    parents: parents,
                    scope: scope,
                    feed: isFeed,
                },
                options: {
                    alerts: {
                        success: strings.alerts.comment.delete.success,
                        error: strings.alerts.comment.delete.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
            }));
        }
    };
    return (_jsx(List.Item.Meta, { avatar: type != 'delete' && _jsx(AvatarTuringo, { size: 24, style: { height: 64, width: 64 }, name: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.name, avatar: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl }), title: _jsxs(Form, { onFinish: submit, form: form, layout: 'vertical', initialValues: comment ? { content: comment.content } : undefined, children: [type != 'delete' && (_jsx(Form.Item, { name: ['content'], children: _jsx(TextArea, { onChange: onChange, autoSize: { minRows: 3, maxRows: 7 }, showCount: true, maxLength: 100, placeholder: strings.comments.form.placeholder, style: { marginBottom: 5 } }) })), type != 'delete' && (_jsxs(Row, { children: [_jsx("span", { style: { fontWeight: 400, marginRight: 5 }, children: strings.comments.form.help }), _jsx(Link, { onClick: () => {
                                void API.analyticsAction({ bodyParams: { action: 'support.contact', sender: 'course.comment.create.form' } });
                            }, target: "_blank", to: {
                                pathname: '/help',
                                search: '?page=contact',
                            }, children: strings.comments.form.contactUs })] }))] }) }));
};
