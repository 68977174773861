import { jsx as _jsx } from "react/jsx-runtime";
import { Canvas } from '@components/content/canvas/Canvas';
import ImageSignatureBernardo from '@bundle/assets/certificate/signature/scan-signature-bernardo.png';
import ImageSignatureIvanVera from '@bundle/assets/certificate/signature/scan-signature-ivan.png';
import RobotoFont from '@bundle/fonts/Roboto.ttf';
import strings from '@resources/localization';
import { CertificateConstants as Constants } from '@resources/constants/CertificateConstants';
import { useAppSelector } from '@hooks/useStore';
const Color = Object.freeze({
    background: '#ffffff',
    primary: '#000000',
    secondary: '#666666',
    placeholder: '#999999',
});
const fontWeight = (style) => {
    switch (style) {
        case 'content':
        case 'name':
        case 'sign-name':
        case 'multiline-content':
            return '500';
        case 'title':
            return '400';
        case 'footer':
        case 'sign-desc':
            return '400';
    }
};
const fontForStyle = (style) => {
    const w = fontWeight(style);
    switch (style) {
        case 'content':
        case 'multiline-content':
            return `${w} ${40}px Roboto, Arial`;
        case 'name':
            return `${w} ${60}px Roboto, Arial`;
        case 'title':
            return `${w} ${30}px Roboto, Arial`;
        case 'footer':
            return `${w} ${30}px Roboto, Arial`;
        case 'sign-name':
            return `${w} ${30}px Roboto, Arial`;
        case 'sign-desc':
            return `${w} ${24}px Roboto, Arial`;
    }
};
const alignmentForStyle = (style) => {
    switch (style) {
        case 'content':
            return 'bottom';
        case 'name':
            return 'bottom';
        case 'title':
            return 'middle';
        case 'multiline-content':
            return 'bottom';
        case 'footer':
            return 'middle';
        case 'sign-name':
            return 'bottom';
        case 'sign-desc':
            return 'bottom';
    }
};
export const drawText = (ctx, labelStyle, label, left, top, align = 'left', color = Color.primary) => {
    ctx.textBaseline = alignmentForStyle(labelStyle);
    ctx.fillStyle = color;
    ctx.textAlign = align;
    ctx.font = fontForStyle(labelStyle);
    ctx.fillText(label, left, top);
};
export function getLines(ctx, text, maxWidth) {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];
    for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = ctx.measureText(currentLine + ' ' + word).width;
        if (width < maxWidth) {
            currentLine += ' ' + word;
        }
        else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);
    return lines;
}
export const drawSignature = (ctx, signature, index, x = Constants.width * 0.13, y = Constants.height * 0.75, size = Constants.width * 0.18, height = Constants.height * 0.1, gutter = Constants.width * 0.1) => {
    if (signature.image) {
        ctx.lineWidth = 1.0;
        ctx.strokeStyle = Color.placeholder;
        ctx.setLineDash([]);
        const ratio = signature.image.width / signature.image.height;
        const WIDTH = ratio * height > size ? size : ratio * height;
        const sbn = { x: x + (size + gutter) * index, y: y, w: WIDTH, h: height };
        ctx.drawImage(signature.image, sbn.x + size / 2 - WIDTH / 2 + 30, sbn.y, sbn.w, sbn.h);
        if (!signature.notLine) {
            ctx.beginPath();
            ctx.moveTo(x + (size + gutter) * index, y + height - 10 + 0.5);
            ctx.lineTo(x + size + (size + gutter) * index + 60, y + height - 10 + 0.5);
            ctx.closePath();
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 1.0;
            ctx.stroke();
        }
        drawText(ctx, 'sign-name', signature.name, sbn.x + size / 2 + 30, y + height + 50, 'center');
        drawText(ctx, 'sign-desc', signature.position, sbn.x + size / 2 + 30, y + height + 80, 'center');
    }
};
export const parseDate = (date) => {
    const d = new Date(date);
    return strings.formatString(strings.general.monthYear, strings.general.months[d.getMonth()], d.getFullYear().toString());
};
export const handleMentors = (mentors) => {
    const processedMentors = [];
    let currentMentors = '';
    mentors === null || mentors === void 0 ? void 0 : mentors.forEach((mentor, index) => {
        currentMentors += mentor;
        if (index + 1 < mentors.length) {
            currentMentors += index + 2 == mentors.length ? ', ' : ', ';
            if (currentMentors.length > 50) {
                processedMentors.push(currentMentors);
                currentMentors = '';
            }
        }
        else {
            processedMentors.push(currentMentors);
        }
    });
    return processedMentors;
};
export const CustomCertificate = (props) => {
    var _a, _b, _c, _d;
    const { granteeName, courseTitle, mentors, grantDate, onRenderComplete } = props;
    const certificateStrings = strings.certificate;
    const handledMentors = handleMentors(mentors);
    const labels = [
        { style: 'title', text: certificateStrings.preamblePerson },
        { style: 'name', text: granteeName },
        { style: 'title', text: certificateStrings.preambleCourse },
        { style: 'content', text: courseTitle },
        ...(handledMentors && handledMentors.length > 0 ? [{ style: 'title', text: certificateStrings.preambleTeacher }] : []),
    ];
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    handledMentors.forEach((line) => {
        labels.push({ style: handledMentors.length > 1 ? 'multiline-content' : 'content', text: line });
    });
    labels.push({ style: 'footer', text: parseDate(grantDate) });
    const ibn = { x: 0.1, y: 0.1 };
    const lbn = { x: 0.13, y: 0.3, w: 0, h: handledMentors.length > 0 ? 0.43 : 0.3 };
    const bbn = { cx: 0.9, cy: 0.85, s: 1.2 };
    const preloadImages = {
        signatureIvan: ImageSignatureIvanVera,
        signatureBernardo: ImageSignatureBernardo,
        logo: props.logo ? props.logo : ((_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.logoUrl) ? (_b = community === null || community === void 0 ? void 0 : community.item) === null || _b === void 0 ? void 0 : _b.logoUrl : undefined,
        bgLogo: props.background ? props.background : undefined,
        signature0: props.signer ? (_c = props.signer[0]) === null || _c === void 0 ? void 0 : _c.signatureUrl : undefined,
        signature1: props.signer ? (_d = props.signer[1]) === null || _d === void 0 ? void 0 : _d.signatureUrl : undefined,
    };
    return (_jsx(Canvas, { preloadImages: preloadImages, preloadFonts: { Roboto: RobotoFont }, width: Constants.width * Constants.scale, height: Constants.height * Constants.scale, onDrawComplete: onRenderComplete, onDraw: (ctx, dimensions, images) => {
            const { width: w, height: h } = dimensions;
            ctx.fillStyle = '#f0f5ff';
            ctx.fillRect(0, 0, w, h);
            let border = 45;
            const radius = 10;
            ctx.fillStyle = '#061178';
            ctx.beginPath();
            ctx.moveTo(w - border, h - border);
            ctx.arcTo(border, h - border, border, border, radius);
            ctx.arcTo(border, border, w - border, border, radius);
            ctx.arcTo(w - border, border, w - border, h - border, radius);
            ctx.arcTo(w - border, h - border, border, h - border, radius);
            ctx.fill();
            border = 52;
            ctx.fillStyle = Color.background;
            ctx.beginPath();
            ctx.moveTo(w - border, h - border);
            ctx.arcTo(border, h - border, border, border, radius);
            ctx.arcTo(border, border, w - border, border, radius);
            ctx.arcTo(w - border, border, w - border, h - border, radius);
            ctx.arcTo(w - border, h - border, border, h - border, radius);
            ctx.fill();
            if (images.bgLogo) {
                const ratio = images.bgLogo.width / images.bgLogo.height;
                const WIDTH = ratio * h > w ? w : ratio * h;
                ctx.drawImage(images.bgLogo, 0.5, 0.5, WIDTH, h);
            }
            if (images.logo) {
                const ratio = images.logo.width / images.logo.height;
                const HEIGHT = Constants.width * 0.06;
                const WIDTH = ratio * HEIGHT;
                ctx.drawImage(images.logo, Constants.width * 0.13, Constants.height * 0.12, WIDTH, HEIGHT);
            }
            labels.forEach((label, index) => {
                if (label.style == 'content') {
                    const newLines = getLines(ctx, label.text, Constants.width * 0.6);
                    labels.splice(index, 1);
                    newLines.forEach((line, i) => {
                        labels.splice(index + i, 0, Object.assign(Object.assign({}, label), { text: line }));
                    });
                }
                if (label.style == 'footer') {
                    const vOffset = lbn.y + (lbn.h * index) / labels.length - (index === 0 ? 0.01 : 0);
                    const hOffset = lbn.x + lbn.w / 2.0;
                    drawText(ctx, label.style, label.text, w * hOffset, h * vOffset + 38);
                }
            });
            labels.forEach((label, index) => {
                if (label.style != 'footer') {
                    const vOffset = lbn.y + (lbn.h * index) / labels.length - (index === 0 ? 0.01 : 0);
                    const hOffset = lbn.x + lbn.w / 2.0;
                    drawText(ctx, label.style, label.text, w * hOffset, h * vOffset - 20);
                }
            });
            let signatures = [];
            if (props.signer) {
                signatures = props.signer.map((sign, index) => {
                    return { name: sign.name, image: index == 0 ? images.signature0 : images.signature1, position: sign.position };
                });
            }
            else {
                signatures = [
                    {
                        name: strings.screens.boards.course.certificate.signatures.bernardo.name,
                        image: images.signatureBernardo,
                        position: strings.screens.boards.course.certificate.signatures.bernardo.position,
                    },
                    {
                        name: strings.screens.boards.course.certificate.signatures.ivan.name,
                        image: images.signatureIvan,
                        position: strings.screens.boards.course.certificate.signatures.ivan.position,
                    },
                ];
            }
            signatures.forEach((signature, index) => {
                drawSignature(ctx, signature, index);
            });
        } }));
};
