import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Button } from 'antd';
const LinkButton = (props) => {
    const { children, href, width, className, style, btnStyle, externalHref } = props;
    const ButtonElement = (_jsx(Button, Object.assign({}, props, { href: undefined, className: undefined, style: Object.assign({ width }, btnStyle), children: children })));
    if (externalHref) {
        return (_jsx("a", { href: externalHref, target: '_blank', rel: "noreferrer", style: Object.assign(Object.assign({}, style), { width }), className: className, children: ButtonElement }));
    }
    if (!href)
        return ButtonElement;
    return (_jsx(Link, { to: href, style: Object.assign(Object.assign({}, style), { width }), className: className, children: ButtonElement }));
};
export { LinkButton };
