import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Close } from '@icon-park/react';
import useBreakpoints from '@hooks/useBreakpoints';
import style from './style.scss';
export const TopicDeleteButton = (props) => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: { marginRight: 8, marginBottom: 8 }, className: isMobile ? style.itemMobile : style.item, children: [props.title, " ", _jsx(Close, { onClick: props.onClick, className: style.closeBtn, style: { marginLeft: isMobile ? 4 : 3, display: 'flex' }, theme: "outline", size: isMobile ? 14 : 10 })] }));
};
