import { communityEdit, communityTeamCreate, communityTeamDelete, communityTeamMemberAdd, communityTeamMemberDelete, communityBoardCreate, communityBoardDelete, communityTeamEdit, communityBoardIndex, communityBoardEdit, communityMemberDelete, communityInvitationDelete, communityMemberTeamEdit, authLogout, communityPluginGet, communityPluginDisconnect, communityPluginConnect, communityEventList, communityEventDetails, communityEventAttendanceAccept, communityEventAttendanceReject, communityEventAttendanceList, communityInvitationCreate, communityInvitationEdit, communityDirectory, communityChatList, communityChatCreate, communityChatMessages, communityChatExists, communityChatGet, communityChatAccept, communityChatSilence, postReaction, communityCertificateList, communityCertificateUpdate, communityCertificateCreate, communityCertificateDelete, communityBoardPropertyEdit, communityBoardPropertyDelete, communityBoardPropertyOrder, communityBoardPropertyCreate, communityBoardMentorCreate, communityBoardMentorEdit, communityBoardMentorDelete, communityBoardMentorOrder, boardOrder, analyticsProgress, communityInvitationLink, communityInvitationEmail, userEdit, authLogin, } from './../../actions/index';
import { createSlice } from '@reduxjs/toolkit';
import { communityDetails, rateList, rateCreate, rateDelete, rateEdit, boardList, communityMembers, boardDetails, boardMembers, communityTeams, communityTeamDetail, communityTeamMembers, } from '@state/actions';
const defaultState = {
    currentCommunity: 'turingo',
    items: {},
    createState: {
        loading: false,
    },
    listState: {
        loading: false,
    },
};
const communitySlice = createSlice({
    name: 'community',
    initialState: defaultState,
    reducers: {
        addPin: (state, { payload }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            if ((_a = state.items[payload]) === null || _a === void 0 ? void 0 : _a.item) {
                state.items[payload].item.pinneds = Object.assign(Object.assign(Object.assign({}, (_b = state.items[payload]) === null || _b === void 0 ? void 0 : _b.item.pinneds), (((_e = (_d = (_c = state.items[payload]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.pinneds) === null || _e === void 0 ? void 0 : _e.readMandatory) && { readMandatory: ((_h = (_g = (_f = state.items[payload]) === null || _f === void 0 ? void 0 : _f.item) === null || _g === void 0 ? void 0 : _g.pinneds) === null || _h === void 0 ? void 0 : _h.readMandatory) + 1 })), (!((_l = (_k = (_j = state.items[payload]) === null || _j === void 0 ? void 0 : _j.item) === null || _k === void 0 ? void 0 : _k.pinneds) === null || _l === void 0 ? void 0 : _l.readMandatory) && { readMandatory: 1 }));
            }
        },
        changeCurrentCommunity: (state, { payload }) => {
            state.currentCommunity = payload;
        },
        deleteMessage: (state, { payload }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            if ((_f = (_e = (_d = (_c = (_b = (_a = state.items[payload.communityPk]) === null || _a === void 0 ? void 0 : _a.messages) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[payload.roomPk]) === null || _d === void 0 ? void 0 : _d.messages) === null || _e === void 0 ? void 0 : _e.items) === null || _f === void 0 ? void 0 : _f[payload.messageId]) {
                state.items[payload.communityPk].messages.items[payload.roomPk].messages.items[payload.messageId].item = Object.assign(Object.assign({}, (_g = state.items[payload.communityPk].messages.items[payload.roomPk].messages) === null || _g === void 0 ? void 0 : _g.items[payload.messageId].item), { isDeleted: true, content: '[REDACTED]' });
            }
            if (((_o = (_m = (_l = (_k = (_j = (_h = state.items[payload.communityPk]) === null || _h === void 0 ? void 0 : _h.chats) === null || _j === void 0 ? void 0 : _j.items) === null || _k === void 0 ? void 0 : _k[payload.roomPk]) === null || _l === void 0 ? void 0 : _l.item) === null || _m === void 0 ? void 0 : _m.messageLast) === null || _o === void 0 ? void 0 : _o.publicKey) == payload.messageId) {
                state.items[payload.communityPk].chats.items[payload.roomPk].item.messageLast = Object.assign(Object.assign({}, state.items[payload.communityPk].chats.items[payload.roomPk].item.messageLast), { isDeleted: true, content: '[REDACTED]' });
            }
        },
        addMessage: (state, { payload }) => {
            var _a, _b;
            if (state.items[payload.message.communityPk].messages.items[payload.message.roomPk]) {
                if (!payload.localId)
                    (_a = state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages) === null || _a === void 0 ? true : delete _a.items[payload.message.localId];
                const messages = Object.assign({}, state.items[payload.message.communityPk].messages.items);
                delete messages[payload.message.roomPk];
                state.items[payload.message.communityPk].messages = Object.assign(Object.assign({}, state.items[payload.message.communityPk].messages), { items: Object.assign({ [payload.message.roomPk]: Object.assign(Object.assign({}, state.items[payload.message.communityPk].messages.items[payload.message.roomPk]), { item: Object.assign(Object.assign({}, state.items[payload.message.communityPk].messages.items[payload.message.roomPk].item), { messageCount: state.items[payload.message.communityPk].messages.items[payload.message.roomPk].item.messageCount + 1, messageLast: payload.message }), messages: Object.assign(Object.assign({}, state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages), { items: Object.assign(Object.assign({}, (_b = state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages) === null || _b === void 0 ? void 0 : _b.items), { [payload.message.publicKey]: {
                                        item: payload.message,
                                        state: { loading: false },
                                    } }) }) }) }, messages) });
                state.items[payload.message.communityPk].chats = Object.assign(Object.assign({}, state.items[payload.message.communityPk].chats), { items: Object.assign(Object.assign({}, state.items[payload.message.communityPk].chats.items), { [payload.message.roomPk]: Object.assign(Object.assign({}, state.items[payload.message.communityPk].chats.items[payload.message.roomPk]), { item: Object.assign(Object.assign({}, state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item), { messageCount: state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item.messageCount + 1, messageLast: payload.message }) }) }) });
            }
            else {
                state.items[payload.message.communityPk].messages = Object.assign(Object.assign({}, state.items[payload.message.communityPk].messages), { items: Object.assign({ [payload.message.roomPk]: {
                            item: Object.assign(Object.assign({}, state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item), { messageCount: 1, messageLast: payload.message }),
                            state: { loading: false },
                            messages: {
                                items: {
                                    [payload.message.publicKey]: {
                                        item: payload.message,
                                        state: { loading: false },
                                    },
                                },
                                listState: {
                                    loading: false,
                                },
                            },
                        } }, state.items[payload.message.communityPk].messages.items) });
            }
        },
        addNewPost: (state, action) => {
            var _a, _b, _c, _d, _e, _f;
            if ((_b = (_a = state.items) === null || _a === void 0 ? void 0 : _a[action.payload.communityPk]) === null || _b === void 0 ? void 0 : _b.item) {
                const index = (_c = state.items[action.payload.communityPk].item.boards) === null || _c === void 0 ? void 0 : _c.findIndex((loopVariable) => loopVariable.publicKey === action.payload.boardPk);
                if ((_f = (_e = (_d = state.items[action.payload.communityPk].item) === null || _d === void 0 ? void 0 : _d.boards) === null || _e === void 0 ? void 0 : _e[index]) === null || _f === void 0 ? void 0 : _f.postCount) {
                    state.items[action.payload.communityPk].item.boards[index].postCount += 1;
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authLogin.fulfilled, (state, action) => {
            state.items = {};
        });
        builder.addCase(authLogout, (state) => {
            state.currentCommunity = 'turingo';
        });
        builder.addCase(rateList.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: true } }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { rates: { items: {}, listState: { loading: true } } });
            }
        });
        builder.addCase(rateList.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: true, error: payload } }) });
        });
        builder.addCase(rateList.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { rates: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(boardMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members.listState), { loading: true }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { members: { items: {}, listState: { loading: true } } });
            }
        });
        builder.addCase(boardMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members.listState), { loading: false, error: payload }) }) });
        });
        builder.addCase(boardMembers.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { members: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityInvitationDelete.pending, (state, { meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.kind]), { createState: { loading: false, editing: true } }) }) });
        });
        builder.addCase(communityInvitationDelete.rejected, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.kind]), { createState: { loading: false, error: payload } }) }) });
        });
        builder.addCase(communityInvitationDelete.fulfilled, (state, { meta }) => {
            var _a;
            const invState = state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk].item.invState;
            delete state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk];
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), (meta.arg.extraParams.kind == 'email' && {
                item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations), { email: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations.email), { [invState]: state.items[meta.arg.urlParams.cm_pk].item.invitations.email[invState] - 1 }) }) }),
            })), (meta.arg.extraParams.kind == 'link' && {
                item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations), { link: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations.link), { [invState]: state.items[meta.arg.urlParams.cm_pk].item.invitations.link[invState] - 1 }) }) }),
            })), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.extraParams.kind]), { createState: { loading: false } }) }) });
        });
        builder.addCase(communityInvitationCreate.pending, (state, { meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.bodyParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.bodyParams.kind]), { createState: { loading: false, editing: true } }) }) });
        });
        builder.addCase(communityInvitationCreate.rejected, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.bodyParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.bodyParams.kind]), { createState: { loading: false, error: payload } }) }) });
        });
        builder.addCase(communityInvitationCreate.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), (meta.arg.bodyParams.kind == 'email' && {
                item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { invitations: Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations), (meta.arg.bodyParams.saveMessage && { message: meta.arg.bodyParams.message })), { email: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations.email), { sent: state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent + meta.arg.bodyParams.users.length }) }) }),
            })), (meta.arg.bodyParams.kind == 'link' && {
                item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations), { link: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations.link), { active: state.items[meta.arg.urlParams.cm_pk].item.invitations.link.active + 1 }) }) }),
            })), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.bodyParams.kind]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a[meta.arg.bodyParams.kind]), { items: Object.assign(Object.assign({}, payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})), (_c = (_b = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _b === void 0 ? void 0 : _b[meta.arg.bodyParams.kind]) === null || _c === void 0 ? void 0 : _c.items), createState: { loading: false } }) }) });
        });
        builder.addCase(communityInvitationEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind]), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items), { [meta.arg.urlParams.i_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk]), { state: {
                                    loading: false,
                                    editing: true,
                                } }) }) }) }) });
        });
        builder.addCase(communityInvitationEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind]), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items), { [meta.arg.urlParams.i_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk]), { state: {
                                    loading: false,
                                    editing: false,
                                } }) }) }) }) });
        });
        builder.addCase(communityInvitationEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), (meta.arg.extraParams.kind == 'email' && {
                item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations), { email: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.invitations.email), { resent: meta.arg.bodyParams.resendEmail && meta.arg.extraParams.state != 'resent'
                                ? state.items[meta.arg.urlParams.cm_pk].item.invitations.email.resent + 1
                                : state.items[meta.arg.urlParams.cm_pk].item.invitations.email.resent, sent: meta.arg.bodyParams.resendEmail && meta.arg.extraParams.state != 'resent'
                                ? state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent - 1
                                : state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent }) }) }),
            })), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { [meta.arg.extraParams.kind]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind]), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items), { [payload.data.publicKey]: {
                                item: payload.data,
                                state: {
                                    loading: false,
                                    editing: false,
                                },
                            } }) }) }) });
        });
        builder.addCase(communityMemberTeamEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items), { [meta.arg.urlParams.u_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk]), { state: {
                                loading: false,
                                editing: true,
                            } }) }) }) });
        });
        builder.addCase(communityMemberTeamEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items), { [meta.arg.urlParams.u_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk]), { state: {
                                loading: false,
                                editing: false,
                            } }) }) }) });
        });
        builder.addCase(communityMemberTeamEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items), { [meta.arg.urlParams.u_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk].item), { teams: payload.data }), state: {
                                loading: false,
                                editing: false,
                            } }) }) }) });
        });
        builder.addCase(communityMemberDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { createState: { loading: false, editing: true } }) });
        });
        builder.addCase(communityMemberDelete.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { createState: { loading: false, error: payload } }) });
        });
        builder.addCase(communityMemberDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk];
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { createState: { loading: false } }) });
        });
        builder.addCase(communityMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.listState), { loading: true }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: { total: undefined, items: {}, listState: { loading: true } } });
            }
        });
        builder.addCase(communityMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.listState), { loading: false, error: payload }) }) });
        });
        builder.addCase(communityMembers.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: {
                    total: meta.arg.headerParams.page == 'first'
                        ? payload.total
                            ? payload.total
                            : (_a = state.items[meta.arg.urlParams.cm_pk].members) === null || _a === void 0 ? void 0 : _a.total
                        : (_b = state.items[meta.arg.urlParams.cm_pk].members) === null || _b === void 0 ? void 0 : _b.total,
                    items: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].members) === null || _c === void 0 ? void 0 : _c.items), payload.data.reduce((a, v) => {
                        if (v.user.publicKey)
                            return Object.assign(Object.assign({}, a), { [v.user.publicKey]: { item: v, loading: false } });
                        return Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } });
                    }, {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityInvitationLink.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { link: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.link), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.link.listState), { loading: true }) }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { link: { total: undefined, items: {}, listState: { loading: true } } }) });
            }
        });
        builder.addCase(communityInvitationLink.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { link: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.link), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.link.listState), { loading: false, error: payload }) }) }) });
        });
        builder.addCase(communityInvitationLink.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c, _d, _e, _f;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { link: {
                        total: meta.arg.headerParams.page == 'first'
                            ? payload.total
                                ? payload.total
                                : (_b = (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a.link) === null || _b === void 0 ? void 0 : _b.total
                            : (_d = (_c = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _c === void 0 ? void 0 : _c.link) === null || _d === void 0 ? void 0 : _d.total,
                        items: Object.assign(Object.assign({}, (_f = (_e = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _e === void 0 ? void 0 : _e.link) === null || _f === void 0 ? void 0 : _f.items), payload.data.reduce((a, v) => {
                            return Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } });
                        }, {})),
                        listState: { loading: false, pagination: payload.pagination },
                    } }) });
        });
        builder.addCase(communityInvitationEmail.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { email: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.email), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.email.listState), { loading: true }) }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { email: { total: undefined, items: {}, listState: { loading: true } } }) });
            }
        });
        builder.addCase(communityInvitationEmail.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { email: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.email), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations.email.listState), { loading: false, error: payload }) }) }) });
        });
        builder.addCase(communityInvitationEmail.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c, _d, _e, _f;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { invitations: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].invitations), { email: {
                        total: meta.arg.headerParams.page == 'first'
                            ? payload.total
                                ? payload.total
                                : (_b = (_a = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _a === void 0 ? void 0 : _a.email) === null || _b === void 0 ? void 0 : _b.total
                            : (_d = (_c = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.total,
                        items: Object.assign(Object.assign({}, (_f = (_e = state.items[meta.arg.urlParams.cm_pk].invitations) === null || _e === void 0 ? void 0 : _e.email) === null || _f === void 0 ? void 0 : _f.items), payload.data.reduce((a, v) => {
                            return Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } });
                        }, {})),
                        listState: { loading: false, pagination: payload.pagination },
                    } }) });
        });
        builder.addCase(analyticsProgress.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { analytics: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics.listState), { loading: true }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { analytics: { items: {}, listState: { loading: true } } });
            }
        });
        builder.addCase(analyticsProgress.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { analytics: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics.listState), { loading: false, error: payload }) }) });
        });
        builder.addCase(analyticsProgress.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { analytics: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => {
                        if (v.user.publicKey)
                            return Object.assign(Object.assign({}, a), { [v.user.publicKey]: { item: v, loading: false } });
                        return Object.assign(Object.assign({}, a), { [v.user.publicKey]: { item: v, loading: false } });
                    }, {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityDirectory.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.listState), { loading: true }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: { total: undefined, items: {}, listState: { loading: true } } });
            }
        });
        builder.addCase(communityDirectory.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].members.listState), { loading: false, error: payload }) }) });
        });
        builder.addCase(communityDirectory.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { members: {
                    total: meta.arg.headerParams.page == 'first'
                        ? payload.total
                            ? payload.total
                            : (_a = state.items[meta.arg.urlParams.cm_pk].members) === null || _a === void 0 ? void 0 : _a.total
                        : (_b = state.items[meta.arg.urlParams.cm_pk].members) === null || _b === void 0 ? void 0 : _b.total,
                    items: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].members) === null || _c === void 0 ? void 0 : _c.items), payload.data.reduce((a, v) => {
                        if (v.publicKey)
                            return Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } });
                        return Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } });
                    }, {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityTeams.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: undefined });
        });
        builder.addCase(communityTeams.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: undefined });
        });
        builder.addCase(communityTeams.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityEventList.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: {
                    items: ((_a = meta.arg.headerParams) === null || _a === void 0 ? void 0 : _a.page) && ((_b = meta.arg.headerParams) === null || _b === void 0 ? void 0 : _b.page) != 'first'
                        ? Object.assign({}, state.items[meta.arg.urlParams.cm_pk].events.items) : {},
                    listState: { loading: true },
                } });
        });
        builder.addCase(communityEventList.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: {
                    items: {},
                    listState: { loading: false },
                } });
        });
        builder.addCase(communityEventList.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: {
                            item: v,
                            loading: false,
                            attendance: {
                                accepted: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                rejected: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                invited: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                            },
                        } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityCertificateList.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: {
                    items: ((_a = meta.arg.headerParams) === null || _a === void 0 ? void 0 : _a.page) && ((_b = meta.arg.headerParams) === null || _b === void 0 ? void 0 : _b.page) != 'first'
                        ? Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items) : {},
                    listState: { loading: true },
                } });
        });
        builder.addCase(communityCertificateList.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: {
                    items: {},
                    listState: { loading: false },
                } });
        });
        builder.addCase(communityCertificateList.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: {
                    items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].certificates) === null || _a === void 0 ? void 0 : _a.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: {
                            item: v,
                            loading: false,
                        } })), {})),
                    listState: { loading: false, pagination: payload.pagination },
                } });
        });
        builder.addCase(communityCertificateUpdate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.listState), { loading: true }) }) });
        });
        builder.addCase(communityCertificateUpdate.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.bodyParams.boardPk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item), { merits: [
                                    ...(((_a = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item) === null || _a === void 0 ? void 0 : _a.merits)
                                        ? (_c = (_b = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item) === null || _b === void 0 ? void 0 : _b.merits) === null || _c === void 0 ? void 0 : _c.map((m) => {
                                            return Object.assign(Object.assign({}, m), { merit: payload.data });
                                        })
                                        : [
                                            {
                                                merit: payload.data,
                                            },
                                        ]),
                                ] }) }) }) }), certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { items: Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items), { [meta.arg.urlParams.cert_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk].item), payload.data) }) }), (meta.arg.extraParams.oldCertPk && {
                        [meta.arg.extraParams.oldCertPk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk].item), { boards: state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk].item.boards.filter((b) => b.publicKey != meta.arg.extraParams.b_pk) }) }),
                    })), listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.listState), { loading: false }) }) });
        });
        builder.addCase(communityCertificateCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.createState), { loading: true }) }) });
        });
        builder.addCase(communityCertificateCreate.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: { loading: false },
                        } }), createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.createState), { loading: false }) }) });
        });
        builder.addCase(communityCertificateDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.listState), { loading: true }) }) });
        });
        builder.addCase(communityCertificateDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk];
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.extraParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.extraParams.b_pk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.extraParams.b_pk].item), { merits: undefined }) }) }) }), certificates: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates), { createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].certificates.listState), { loading: false }) }) });
        });
        builder.addCase(communityEventAttendanceAccept.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].events), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].events) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.e_pk]), { item: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].events) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.e_pk].item), { derivedAttendants: ((_d = state.items[meta.arg.urlParams.cm_pk].events) === null || _d === void 0 ? void 0 : _d.items[meta.arg.urlParams.e_pk].item.derivedAttendants) + 1, derivedRejectAttendants: ((_e = state.items[meta.arg.urlParams.cm_pk].events) === null || _e === void 0 ? void 0 : _e.items[meta.arg.urlParams.e_pk].item.attendance.self) == 'rejected'
                                    ? ((_f = state.items[meta.arg.urlParams.cm_pk].events) === null || _f === void 0 ? void 0 : _f.items[meta.arg.urlParams.e_pk].item.derivedRejectAttendants) - 1
                                    : (_g = state.items[meta.arg.urlParams.cm_pk].events) === null || _g === void 0 ? void 0 : _g.items[meta.arg.urlParams.e_pk].item.derivedAttendants, attendance: Object.assign(Object.assign({}, (_h = state.items[meta.arg.urlParams.cm_pk].events) === null || _h === void 0 ? void 0 : _h.items[meta.arg.urlParams.e_pk].item.attendance), { self: 'accepted' }) }) }) }) }) });
        });
        builder.addCase(communityEventAttendanceReject.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].events), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].events) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.e_pk]), { item: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].events) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.e_pk].item), { derivedAttendants: ((_d = state.items[meta.arg.urlParams.cm_pk].events) === null || _d === void 0 ? void 0 : _d.items[meta.arg.urlParams.e_pk].item.attendance.self) == 'accepted'
                                    ? ((_e = state.items[meta.arg.urlParams.cm_pk].events) === null || _e === void 0 ? void 0 : _e.items[meta.arg.urlParams.e_pk].item.derivedAttendants) - 1
                                    : (_f = state.items[meta.arg.urlParams.cm_pk].events) === null || _f === void 0 ? void 0 : _f.items[meta.arg.urlParams.e_pk].item.derivedAttendants, derivedRejectAttendants: ((_g = state.items[meta.arg.urlParams.cm_pk].events) === null || _g === void 0 ? void 0 : _g.items[meta.arg.urlParams.e_pk].item.derivedRejectAttendants) + 1, attendance: Object.assign(Object.assign({}, (_h = state.items[meta.arg.urlParams.cm_pk].events) === null || _h === void 0 ? void 0 : _h.items[meta.arg.urlParams.e_pk].item.attendance), { self: 'rejected' }) }) }) }) }) });
        });
        builder.addCase(communityEventDetails.pending, (state, { meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                            },
                            attendance: {
                                accepted: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                rejected: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                invited: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                            },
                        } }) }) });
        });
        builder.addCase(communityEventDetails.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                            attendance: {
                                accepted: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                rejected: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                invited: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                            },
                        } }) }) });
        });
        builder.addCase(communityEventAttendanceList.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].events) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.e_pk]), { attendance: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].events) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.e_pk].attendance), { [meta.arg.searchParams.kind]: Object.assign(Object.assign({}, (_d = state.items[meta.arg.urlParams.cm_pk].events) === null || _d === void 0 ? void 0 : _d.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind]), { listState: Object.assign(Object.assign({}, (_e = state.items[meta.arg.urlParams.cm_pk].events) === null || _e === void 0 ? void 0 : _e.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind].listState), { loading: true }) }) }) }) }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_f = state.items[meta.arg.urlParams.cm_pk].events) === null || _f === void 0 ? void 0 : _f.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_g = state.items[meta.arg.urlParams.cm_pk].events) === null || _g === void 0 ? void 0 : _g.items[meta.arg.urlParams.e_pk]), { attendance: Object.assign(Object.assign({}, (_h = state.items[meta.arg.urlParams.cm_pk].events) === null || _h === void 0 ? void 0 : _h.items[meta.arg.urlParams.e_pk].attendance), { [meta.arg.searchParams.kind]: {
                                        items: {},
                                        listState: Object.assign(Object.assign({}, (_j = state.items[meta.arg.urlParams.cm_pk].events) === null || _j === void 0 ? void 0 : _j.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind].listState), { loading: true }),
                                    } }) }) }) }) });
            }
        });
        builder.addCase(communityEventAttendanceList.rejected, (state, { meta, payload }) => {
            var _a, _b, _c, _d;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].events) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.e_pk]), { attendance: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].events) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.e_pk].attendance), { [meta.arg.searchParams.kind]: {
                                    items: {},
                                    listState: Object.assign(Object.assign({}, (_d = state.items[meta.arg.urlParams.cm_pk].events) === null || _d === void 0 ? void 0 : _d.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind].listState), { error: payload, loading: false }),
                                } }) }) }) }) });
        });
        builder.addCase(communityEventAttendanceList.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c, _d;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { events: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].events) === null || _a === void 0 ? void 0 : _a.items), { [meta.arg.urlParams.e_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].events) === null || _b === void 0 ? void 0 : _b.items[meta.arg.urlParams.e_pk]), { attendance: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].events) === null || _c === void 0 ? void 0 : _c.items[meta.arg.urlParams.e_pk].attendance), { [meta.arg.searchParams.kind]: {
                                    items: Object.assign(Object.assign({}, (_d = state.items[meta.arg.urlParams.cm_pk].events) === null || _d === void 0 ? void 0 : _d.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind].items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                                    listState: { loading: false, pagination: payload.pagination },
                                } }) }) }) }) });
        });
        builder.addCase(communityTeamMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].listState), { loading: true }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                    total: undefined,
                    items: {},
                    listState: { loading: true },
                };
            }
        });
        builder.addCase(communityTeamMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].listState), { loading: false, error: payload }) });
        });
        builder.addCase(communityTeamMembers.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { total: meta.arg.headerParams.page == 'first'
                    ? payload.total
                        ? payload.total
                        : state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].total
                    : state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].total, items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})), listState: { loading: false, pagination: payload.pagination } });
        });
        builder.addCase(communityTeamMemberAdd.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { createState: {
                    loading: true,
                } });
        });
        builder.addCase(communityTeamMemberAdd.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { createState: {
                    loading: false,
                } });
        });
        builder.addCase(communityTeamMemberAdd.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { items: Object.assign(Object.assign({}, payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})), state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].items), createState: {
                    loading: false,
                } });
        });
        builder.addCase(communityTeamMemberDelete.pending, (state, { meta }) => {
            var _a, _b;
            (_a = state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]) === null || _a === void 0 ? true : delete _a.items[(_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.target_pk];
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { createState: {
                    loading: true,
                } });
        });
        builder.addCase(communityTeamMemberDelete.rejected, (state, { meta }) => {
            var _a, _b;
            (_a = state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]) === null || _a === void 0 ? true : delete _a.items[(_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.target_pk];
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { createState: {
                    loading: false,
                } });
        });
        builder.addCase(communityTeamMemberDelete.fulfilled, (state, { meta }) => {
            var _a, _b;
            (_a = state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]) === null || _a === void 0 ? true : delete _a.items[(_b = meta.arg.extraParams) === null || _b === void 0 ? void 0 : _b.target_pk];
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]), { createState: {
                    loading: false,
                } });
        });
        builder.addCase(communityTeamDelete.pending, (state, { meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk].teams.items[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items[meta.arg.urlParams.t_pk]), { state: {
                    loading: false,
                    editing: true,
                } });
        });
        builder.addCase(communityTeamDelete.rejected, (state, { meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk].teams.items[meta.arg.urlParams.t_pk] = Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items[meta.arg.urlParams.t_pk]), { state: {
                    loading: false,
                } });
        });
        builder.addCase(communityTeamDelete.fulfilled, (state, { meta }) => {
            var _a;
            (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? true : delete _a.items[meta.arg.urlParams.t_pk];
        });
        builder.addCase(communityTeamCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { createState: {
                        loading: true,
                    } }) });
        });
        builder.addCase(communityTeamCreate.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { createState: {
                        loading: false,
                    } }) });
        });
        builder.addCase(communityTeamCreate.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }), createState: {
                        loading: false,
                    } }) });
        });
        builder.addCase(communityTeamEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { createState: {
                        loading: true,
                    } }) });
        });
        builder.addCase(communityTeamEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { createState: {
                        loading: false,
                    } }) });
        });
        builder.addCase(communityTeamEdit.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }), createState: {
                        loading: false,
                    } }) });
        });
        builder.addCase(communityTeamDetail.fulfilled, (state, { meta, payload }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { teams: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].teams), { items: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk].teams) === null || _a === void 0 ? void 0 : _a.items), { [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }) }) });
        });
        builder.addCase(communityChatMessages.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [meta.arg.urlParams.r_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk]), { messages: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages), { listState: { loading: true } }) }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [meta.arg.urlParams.r_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk]), { messages: {
                                items: {},
                                listState: { loading: true },
                            } }) }) });
            }
        });
        builder.addCase(communityChatMessages.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [meta.arg.urlParams.r_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk]), { messages: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages), { listState: { loading: false, error: payload } }) }) }) });
        });
        builder.addCase(communityChatMessages.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [meta.arg.urlParams.r_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk]), { messages: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages), { items: Object.assign(Object.assign({}, payload.data.reverse().reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, state: { loading: false } } })), {})), state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages.items), listState: { loading: false, pagination: payload.pagination } }) }) }) });
        });
        builder.addCase(communityChatSilence.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].chats.items[meta.arg.urlParams.r_pk].item = payload.data;
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [meta.arg.urlParams.r_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk]), { item: payload.data }) }) });
        });
        builder.addCase(communityChatExists.fulfilled, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { listState: {
                    loading: false,
                }, items: Object.assign({ [payload.data.publicKey]: {
                        item: payload.data,
                        state: {
                            local: false,
                            loading: false,
                        },
                    } }, (_b = (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.chats) === null || _b === void 0 ? void 0 : _b.items) });
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { listState: {
                    loading: false,
                }, items: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].messages) === null || _c === void 0 ? void 0 : _c.items), { [payload.data.publicKey]: {
                        item: payload.data,
                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            local: false,
                            loading: false,
                        },
                    } }) });
        });
        builder.addCase(communityChatExists.rejected, (state, { meta }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { listState: {
                    loading: false,
                }, items: Object.assign({ [meta.arg.extraParams.localId]: {
                        item: {
                            publicKey: meta.arg.extraParams.localId,
                            users: meta.arg.extraParams.users,
                            title: 'string',
                            messageCount: 0,
                        },
                        state: {
                            local: true,
                            loading: false,
                        },
                    } }, (_b = (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.chats) === null || _b === void 0 ? void 0 : _b.items) });
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { listState: {
                    loading: false,
                }, items: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].messages) === null || _c === void 0 ? void 0 : _c.items), { [meta.arg.extraParams.localId]: {
                        item: {
                            publicKey: meta.arg.extraParams.localId,
                            users: meta.arg.extraParams.users,
                            title: 'string',
                            messageCount: 0,
                        },
                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            local: true,
                            loading: false,
                        },
                    } }) });
        });
        builder.addCase(communityChatGet.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { listState: { loading: true } });
        });
        builder.addCase(communityChatGet.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { listState: { loading: false } });
        });
        builder.addCase(communityChatGet.fulfilled, (state, { meta, payload }) => {
            var _a;
            if ((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.isFirstTime) {
                state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { listState: { loading: false }, items: Object.assign({ [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        } }, state.items[meta.arg.urlParams.cm_pk].chats.items) });
            }
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { listState: { loading: false }, items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [payload.data.publicKey]: {
                        item: payload.data,
                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            loading: false,
                        },
                    } }) });
        });
        builder.addCase(communityChatList.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f;
            if (meta.arg.headerParams.page != 'first') {
                if (state.items[meta.arg.urlParams.cm_pk]) {
                    state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { listState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats.listState), { loading: true }) }), messages: {
                            items: Object.assign({}, (((_a = state.items[meta.arg.urlParams.cm_pk].messages) === null || _a === void 0 ? void 0 : _a.items) ? (_b = state.items[meta.arg.urlParams.cm_pk].messages) === null || _b === void 0 ? void 0 : _b.items : {})),
                            listState: { loading: false },
                        } });
                }
                else {
                    state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: {
                            items: {},
                            listState: { loading: true },
                        }, messages: {
                            items: Object.assign({}, (((_c = state.items[meta.arg.urlParams.cm_pk].messages) === null || _c === void 0 ? void 0 : _c.items) ? (_d = state.items[meta.arg.urlParams.cm_pk].messages) === null || _d === void 0 ? void 0 : _d.items : {})),
                            listState: { loading: false },
                        } });
                }
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: {
                        items: {},
                        listState: { loading: true },
                    }, messages: {
                        items: Object.assign({}, (((_e = state.items[meta.arg.urlParams.cm_pk].messages) === null || _e === void 0 ? void 0 : _e.items) ? (_f = state.items[meta.arg.urlParams.cm_pk].messages) === null || _f === void 0 ? void 0 : _f.items : {})),
                        listState: { loading: false },
                    } });
            }
        });
        builder.addCase(communityChatList.rejected, (state, { meta, payload }) => {
            if (state.items[meta.arg.urlParams.cm_pk]) {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { listState: {
                            loading: false,
                            error: payload,
                        } }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: {
                        items: {},
                        listState: {
                            loading: false,
                            error: payload,
                        },
                    } });
            }
        });
        builder.addCase(communityChatList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { chats: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, messages: { items: {} }, state: { loading: false } } })), {})), listState: { loading: false, pagination: payload.pagination } }) });
        });
        builder.addCase(communityChatCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats.createState), { loading: true }) });
        });
        builder.addCase(communityChatCreate.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { createState: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats.createState), { loading: false, error: payload }) });
        });
        builder.addCase(communityChatCreate.fulfilled, (state, { meta, payload }) => {
            delete state.items[meta.arg.urlParams.cm_pk].chats.items[meta.arg.extraParams.localId];
            state.items[meta.arg.urlParams.cm_pk].chats = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].chats), { items: Object.assign({ [payload.data.publicKey]: { item: payload.data, state: { loading: false } } }, state.items[meta.arg.urlParams.cm_pk].chats.items), createState: { loading: false } });
        });
        builder.addCase(communityChatAccept.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items), { [payload.data.publicKey]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].messages.items[payload.data.publicKey]), { item: payload.data }) }), createState: { loading: false } });
        });
        builder.addCase(communityDetails.pending, (state, { meta }) => {
            var _a, _b;
            state.createState.loading = true;
            state.currentCommunity = meta.arg.urlParams.cm_pk;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: undefined, state: {
                    loading: true,
                }, team_members: {}, invitations: {}, boards: {
                    all: {
                        discussion: {
                            items: {},
                        },
                        catalog: {
                            items: {},
                        },
                        course: {
                            items: {},
                        },
                        forum: {
                            items: {},
                        },
                        story: {
                            items: {},
                        },
                    },
                    detail: Object.assign({}, (_b = (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.detail),
                } });
        });
        builder.addCase(communityDetails.rejected, (state, { meta, payload }) => {
            var _a;
            state.createState.loading = false;
            state.currentCommunity = 'turingo';
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: undefined, state: {
                    loading: false,
                    error: payload,
                }, team_members: {}, boards: Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) });
        });
        builder.addCase(communityDetails.fulfilled, (state, { payload, meta }) => {
            state.createState.loading = false;
            state.currentCommunity = payload.data.publicKey;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: payload.data, state: {
                    loading: false,
                    error: undefined,
                }, team_members: {} });
            if (payload.data.boards) {
                for (const board of payload.data.boards) {
                    if (!state.items[meta.arg.urlParams.cm_pk].boards.detail[board.publicKey]) {
                        state.items[meta.arg.urlParams.cm_pk].boards.detail[board.publicKey] = {
                            item: board,
                            state: { loading: false },
                        };
                    }
                }
            }
        });
        builder.addCase(communityBoardIndex.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.searchParams.kind]: {
                            items: ((_a = meta.arg.headerParams) === null || _a === void 0 ? void 0 : _a.page) && ((_b = meta.arg.headerParams) === null || _b === void 0 ? void 0 : _b.page) != 'first'
                                ? Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.searchParams.kind].items) : {},
                            listState: {
                                loading: true,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardIndex.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.searchParams.kind]: {
                            items: {},
                            listState: {
                                loading: false,
                                error: 'true',
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardIndex.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.searchParams.kind]: {
                            items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.searchParams.kind].items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                            listState: {
                                loading: false,
                                pagination: payload.pagination,
                            },
                        } }) }) });
        });
        builder.addCase(boardList.pending, (state, { meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards), { all: Object.assign(Object.assign({}, (_c = (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards) === null || _c === void 0 ? void 0 : _c.all), { [meta.arg.searchParams.kind]: {
                            items: ((_d = meta.arg.headerParams) === null || _d === void 0 ? void 0 : _d.page) && ((_e = meta.arg.headerParams) === null || _e === void 0 ? void 0 : _e.page) != 'first'
                                ? Object.assign({}, (_j = (_h = (_g = (_f = state.items[meta.arg.urlParams.cm_pk]) === null || _f === void 0 ? void 0 : _f.boards) === null || _g === void 0 ? void 0 : _g.all) === null || _h === void 0 ? void 0 : _h[meta.arg.searchParams.kind]) === null || _j === void 0 ? void 0 : _j.items) : {},
                            listState: {
                                loading: true,
                            },
                        } }) }) });
        });
        builder.addCase(boardList.rejected, (state, { meta }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards), { all: Object.assign(Object.assign({}, (_c = (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards) === null || _c === void 0 ? void 0 : _c.all), { [meta.arg.searchParams.kind]: {
                            items: {},
                            listState: {
                                loading: false,
                                error: 'true',
                            },
                        } }) }) });
        });
        builder.addCase(boardList.fulfilled, (state, { payload, meta }) => {
            var _a, _b, _c, _d, _e, _f, _g;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards), { all: Object.assign(Object.assign({}, (_c = (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards) === null || _c === void 0 ? void 0 : _c.all), { [meta.arg.searchParams.kind]: {
                            items: Object.assign(Object.assign({}, (_g = (_f = (_e = (_d = state.items[meta.arg.urlParams.cm_pk]) === null || _d === void 0 ? void 0 : _d.boards) === null || _e === void 0 ? void 0 : _e.all) === null || _f === void 0 ? void 0 : _f[meta.arg.searchParams.kind]) === null || _g === void 0 ? void 0 : _g.items), payload.data.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: { item: v, loading: false } })), {})),
                            listState: {
                                loading: false,
                                pagination: payload.pagination,
                            },
                        } }) }) });
        });
        builder.addCase(boardDetails.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_b = (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail) === null || _b === void 0 ? void 0 : _b[meta.arg.urlParams.b_pk]), { item: undefined, state: {
                                loading: true,
                            } }) }) }) });
        });
        builder.addCase(boardDetails.rejected, (state, { meta, payload }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, (((_b = (_a = state.items) === null || _a === void 0 ? void 0 : _a[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards) || {
                    all: {},
                })), { detail: Object.assign(Object.assign({}, (((_e = (_d = (_c = state.items) === null || _c === void 0 ? void 0 : _c[meta.arg.urlParams.cm_pk]) === null || _d === void 0 ? void 0 : _d.boards) === null || _e === void 0 ? void 0 : _e.detail) || {})), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (((_j = (_h = (_g = (_f = state.items) === null || _f === void 0 ? void 0 : _f[meta.arg.urlParams.cm_pk]) === null || _g === void 0 ? void 0 : _g.boards) === null || _h === void 0 ? void 0 : _h.detail) === null || _j === void 0 ? void 0 : _j[meta.arg.urlParams.b_pk]) || {})), { item: undefined, state: {
                                loading: false,
                                error: payload,
                            } }) }) }) });
        });
        builder.addCase(boardDetails.fulfilled, (state, { payload, meta }) => {
            var _a;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { item: payload.data, state: {
                                loading: false,
                            } }) }) }) });
        });
        builder.addCase(communityEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { state: {
                    loading: false,
                    editing: true,
                } });
        });
        builder.addCase(communityEdit.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { state: {
                    loading: false,
                    editing: false,
                    error: payload,
                } });
        });
        builder.addCase(communityEdit.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), payload.data), { invitations: state.items[meta.arg.urlParams.cm_pk].item.invitations, logoUrl: payload.data.logoUrl ? payload.data.logoUrl : undefined, bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined, boards: state.items[meta.arg.urlParams.cm_pk].item.boards, boardCount: state.items[meta.arg.urlParams.cm_pk].item.boardCount }), state: {
                    loading: false,
                    editing: false,
                } });
        });
        builder.addCase(communityBoardPropertyOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardPropertyOrder.fulfilled, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property] =
                meta.arg.bodyParams.properties;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardPropertyDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardPropertyDelete.fulfilled, (state, { meta }) => {
            const index = parseInt(meta.arg.bodyParams.propertyId) - 1;
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property].splice(index, 1);
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardPropertyEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardPropertyEdit.fulfilled, (state, { meta }) => {
            const index = parseInt(meta.arg.bodyParams.propertyId) - 1;
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property][index] =
                meta.arg.bodyParams.value;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardPropertyCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardPropertyCreate.fulfilled, (state, { meta }) => {
            if (state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property]) {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property].push(meta.arg.bodyParams.value);
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property] = [
                    meta.arg.bodyParams.value,
                ];
            }
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardMentorCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardMentorCreate.fulfilled, (state, { payload, meta }) => {
            var _a, _b;
            if ((_b = (_a = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item) === null || _a === void 0 ? void 0 : _a.partners) === null || _b === void 0 ? void 0 : _b.mentor) {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.push(payload.data);
            }
            else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners), { mentor: [payload.data] });
            }
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardMentorEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardMentorEdit.fulfilled, (state, { payload, meta }) => {
            const index = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.findIndex((x) => x.publicKey === payload.data.publicKey);
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor[index] = payload.data;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardMentorDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardMentorDelete.fulfilled, (state, { meta }) => {
            const index = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.findIndex((x) => x.publicKey === meta.arg.urlParams.m_pk);
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.splice(index, 1);
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardMentorOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: true } }) }) }) });
        });
        builder.addCase(communityBoardMentorOrder.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor = payload.data;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]), { state: { loading: false } }) }) }) });
        });
        builder.addCase(communityBoardEdit.fulfilled, (state, { payload, meta }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
            if (((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.scope) != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [payload.data.publicKey]: {
                                item: Object.assign(Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk].boards.detail[payload.data.publicKey]) === null || _b === void 0 ? void 0 : _b.item), payload.data), { bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined }),
                                state: { loading: false },
                            } }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: Object.assign(Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { boardCount: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item.boardCount), (payload.data.kind === 'course' &&
                            meta.arg.bodyParams.hidden === true &&
                            ((_d = (_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.boardCount.course) <= 0
                            ? { course: 1 }
                            : meta.arg.bodyParams.hidden === false && ((_f = (_e = state.items[meta.arg.urlParams.cm_pk]) === null || _e === void 0 ? void 0 : _e.item) === null || _f === void 0 ? void 0 : _f.boardCount.course) > 0
                                ? { course: state.items[meta.arg.urlParams.cm_pk].item.boardCount.course - 1 }
                                : {})), boards: (_h = (_g = state.items[meta.arg.urlParams.cm_pk]) === null || _g === void 0 ? void 0 : _g.item) === null || _h === void 0 ? void 0 : _h.boards.map((board) => board.publicKey === meta.arg.urlParams.b_pk ? Object.assign(Object.assign({}, board), { hidden: meta.arg.bodyParams.hidden }) : board) }), (!((_k = (_j = state.items[meta.arg.urlParams.cm_pk]) === null || _j === void 0 ? void 0 : _j.item) === null || _k === void 0 ? void 0 : _k.boards.find((board) => board.publicKey === meta.arg.urlParams.b_pk)) &&
                        meta.arg.bodyParams.hidden === false && {
                        boards: [...state.items[meta.arg.urlParams.cm_pk].item.boards, payload.data],
                    })), boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                                items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items), { [payload.data.publicKey]: {
                                        item: Object.assign(Object.assign(Object.assign({}, (_l = state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[payload.data.publicKey]) === null || _l === void 0 ? void 0 : _l.item), payload.data), { bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined }),
                                        state: { loading: false },
                                    } }),
                                createState: {
                                    loading: false,
                                },
                            } }) }) });
            }
            const index = (_o = (_m = state.items[meta.arg.urlParams.cm_pk].item) === null || _m === void 0 ? void 0 : _m.boards) === null || _o === void 0 ? void 0 : _o.findIndex((item) => item.publicKey == payload.data.publicKey);
            if (index >= 0 && payload.data.hidden === false) {
                (_p = state.items[meta.arg.urlParams.cm_pk].item) === null || _p === void 0 ? void 0 : _p.boards.splice(index, 1);
            }
            if (index < 0 && payload.data.hidden === true) {
                (_q = state.items[meta.arg.urlParams.cm_pk].item) === null || _q === void 0 ? void 0 : _q.boards.push(payload.data);
            }
            if (payload.data.hidden === false && payload.data.kind == 'course' && ((_s = (_r = state.items[meta.arg.urlParams.cm_pk]) === null || _r === void 0 ? void 0 : _r.item) === null || _s === void 0 ? void 0 : _s.boardCount.course) <= 0) {
                state.items[meta.arg.urlParams.cm_pk].item.boardCount.course = 1;
            }
            if (payload.data.hidden === true && payload.data.kind == 'course' && ((_u = (_t = state.items[meta.arg.urlParams.cm_pk]) === null || _t === void 0 ? void 0 : _t.item) === null || _u === void 0 ? void 0 : _u.boardCount.course) > 0) {
                state.items[meta.arg.urlParams.cm_pk].item.boardCount.course = state.items[meta.arg.urlParams.cm_pk].item.boardCount.course - 1;
            }
        });
        builder.addCase(communityBoardEdit.rejected, (state, { meta }) => {
            var _a;
            if (((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.scope) != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: {
                                item: state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item,
                                state: { loading: false },
                            } }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                                items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk].item), { state: { loading: false } }) }),
                                createState: {
                                    loading: false,
                                },
                            } }) }) });
            }
        });
        builder.addCase(communityBoardEdit.pending, (state, { meta }) => {
            var _a, _b, _c;
            if (((_a = meta.arg.extraParams) === null || _a === void 0 ? void 0 : _a.scope) != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: {
                                item: (_b = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]) === null || _b === void 0 ? void 0 : _b.item,
                                state: { loading: true },
                            } }) }) });
            }
            else {
                state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                                items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk]) === null || _c === void 0 ? void 0 : _c.item), { state: { loading: true } }) }),
                                createState: {
                                    loading: false,
                                },
                            } }) }) });
            }
        });
        builder.addCase(communityBoardCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.bodyParams.kind]: {
                            items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items),
                            createState: {
                                loading: true,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardCreate.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.bodyParams.kind]: {
                            items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items),
                            createState: {
                                loading: false,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardCreate.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { boards: [...state.items[meta.arg.urlParams.cm_pk].item.boards, payload.data] }), boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.bodyParams.kind]: {
                            items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items), { [payload.data.publicKey]: { item: payload.data, state: { loading: false } } }),
                            createState: {
                                loading: false,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardDelete.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                            items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items),
                            createState: {
                                loading: false,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                            items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items),
                            createState: {
                                loading: true,
                            },
                        } }) }) });
        });
        builder.addCase(communityBoardDelete.fulfilled, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { item: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { boards: state.items[meta.arg.urlParams.cm_pk].item.boards.filter(function (value) {
                        return value.publicKey != meta.arg.urlParams.b_pk;
                    }) }), boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { all: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all), { [meta.arg.extraParams.kind]: {
                            items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items),
                            createState: {
                                loading: false,
                            },
                        } }) }) });
            delete state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk];
        });
        builder.addCase(rateCreate.pending, (state, { meta }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (((_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk]) && Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.boards.detail[meta.arg.urlParams.b_pk].rates))), { createState: { loading: true } }) }) }) }) });
        });
        builder.addCase(rateCreate.rejected, (state, { meta, payload }) => {
            var _a, _b, _c;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (((_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk]) && Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.boards.detail[meta.arg.urlParams.b_pk].rates))), { createState: { loading: false, error: payload } }) }) }) }) });
        });
        builder.addCase(rateCreate.fulfilled, (state, { payload, meta }) => {
            var _a, _b, _c, _d, _e, _f, _g;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { item: Object.assign(Object.assign({}, (((_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk]) && Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.boards.detail[meta.arg.urlParams.b_pk].item))), { userRated: meta.arg.bodyParams.rate }), rates: Object.assign(Object.assign({}, (((_d = state.items[meta.arg.urlParams.cm_pk]) === null || _d === void 0 ? void 0 : _d.boards.detail[meta.arg.urlParams.b_pk]) && Object.assign({}, (_e = state.items[meta.arg.urlParams.cm_pk]) === null || _e === void 0 ? void 0 : _e.boards.detail[meta.arg.urlParams.b_pk].rates))), { items: Object.assign({ [payload.data.publicKey]: { item: payload.data, state: { loading: false } } }, (((_f = state.items[meta.arg.urlParams.cm_pk]) === null || _f === void 0 ? void 0 : _f.boards.detail[meta.arg.urlParams.b_pk]) && Object.assign({}, (_g = state.items[meta.arg.urlParams.cm_pk]) === null || _g === void 0 ? void 0 : _g.boards.detail[meta.arg.urlParams.b_pk].rates.items))), createState: { loading: false } }) }) }) }) });
        });
        builder.addCase(rateDelete.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: true } }) }) }) }) });
        });
        builder.addCase(rateDelete.rejected, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: false } }) }) }) }) });
        });
        builder.addCase(rateDelete.fulfilled, (state, { meta }) => {
            var _a, _b, _c, _d;
            (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? true : delete _a.boards.detail[meta.arg.urlParams.b_pk].rates.items[meta.arg.urlParams.target_pk];
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk]), { item: Object.assign(Object.assign({}, (_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.boards.detail[meta.arg.urlParams.b_pk].item), { userRated: undefined }), rates: Object.assign(Object.assign({}, (_d = state.items[meta.arg.urlParams.cm_pk]) === null || _d === void 0 ? void 0 : _d.boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: false } }) }) }) }) });
        });
        builder.addCase(rateEdit.pending, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: true } }) }) }) }) });
        });
        builder.addCase(rateEdit.rejected, (state, { meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk].rates), { listState: { loading: false } }) }) }) }) });
        });
        builder.addCase(rateEdit.fulfilled, (state, { payload, meta }) => {
            var _a, _b;
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { boards: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards), { detail: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.detail), { [meta.arg.urlParams.b_pk]: Object.assign(Object.assign({}, (_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[meta.arg.urlParams.b_pk]), { rates: {
                                items: Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.boards.detail[meta.arg.urlParams.b_pk].rates.items), { [meta.arg.urlParams.p_pk]: {
                                        item: payload.data,
                                        state: {
                                            loading: false,
                                        },
                                    } }),
                                listState: { loading: false },
                            } }) }) }) });
        });
        builder.addCase(communityPluginGet.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { listState: { loading: true } }) });
        });
        builder.addCase(communityPluginGet.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { listState: { loading: false, error: payload } }) });
        });
        builder.addCase(communityPluginGet.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins.items), { [payload.data.plugin]: payload.data }), listState: { loading: false } }) });
        });
        builder.addCase(communityPluginConnect.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { createState: { loading: true } }) });
        });
        builder.addCase(communityPluginConnect.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { createState: { loading: false, error: payload } }) });
        });
        builder.addCase(communityPluginConnect.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { items: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins.items), { [payload.data.plugin]: payload.data }), createState: { loading: false } }) });
        });
        builder.addCase(communityPluginDisconnect.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { listState: { loading: false, editing: true } }) });
        });
        builder.addCase(communityPluginDisconnect.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { listState: { loading: false, error: payload } }) });
        });
        builder.addCase(communityPluginDisconnect.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].plugins.items[meta.arg.urlParams.plugin];
            state.items[meta.arg.urlParams.cm_pk] = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk]), { plugins: Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].plugins), { listState: { loading: false } }) });
        });
        builder.addCase(postReaction.fulfilled, (state, { meta }) => {
            var _a, _b, _c;
            if (meta.arg.bodyParams.kind == 'pin_read' && ((_a = state.items[meta.arg.urlParams.cm_pk]) === null || _a === void 0 ? void 0 : _a.item)) {
                state.items[meta.arg.urlParams.cm_pk].item.pinneds = Object.assign(Object.assign({}, (_b = state.items[meta.arg.urlParams.cm_pk]) === null || _b === void 0 ? void 0 : _b.item.pinneds), { read: ((_c = state.items[meta.arg.urlParams.cm_pk]) === null || _c === void 0 ? void 0 : _c.item.pinneds.read) + 1 });
            }
        });
        builder.addCase(boardOrder.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: Object.assign({}, payload.data.boards.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.publicKey]: {
                        item: state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items[v.publicKey].item,
                        loading: false,
                    } })), {})),
                listState: {
                    loading: false,
                },
            };
        });
        builder.addCase(boardOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items),
                listState: {
                    loading: false,
                },
            };
        });
        builder.addCase(boardOrder.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: Object.assign({}, state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items),
                listState: {
                    loading: false,
                    error: payload,
                },
            };
        });
        builder.addCase(userEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].item = Object.assign(Object.assign({}, state.items[meta.arg.urlParams.cm_pk].item), { onboardingCompleted: payload.data.onboardingCompleted });
        });
    },
});
export const { changeCurrentCommunity, addMessage, deleteMessage, addNewPost, addPin } = communitySlice.actions;
export { communitySlice };
