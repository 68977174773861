import { communityEdit } from './../../actions/index';
import { createSlice } from '@reduxjs/toolkit';
import { authLogout, communityDetails } from '@state/actions';
export const tableColor = {
    blue: '#1890ff',
    red: '#FF3862',
    purple: '#722ed1',
    cyan: '#13c2c2',
    green: '#52c41a',
    magenta: '#eb2f96',
    pink: '#eb2f96',
    yellow: '#fadb14',
    volcano: '#fa541c',
    geekblue: '#2f54eb',
    gold: '#faad14',
    lime: '#a0d911',
    orange: '#fa8c16',
};
export const tableColorBanner = {
    blue: '#57B7FF',
    red: '#FF3862',
    purple: '#722ed1',
    cyan: '#13c2c2',
    green: '#52c41a',
    magenta: '#eb2f96',
    pink: '#eb2f96',
    yellow: '#fadb14',
    volcano: '#fa541c',
    geekblue: '#2f54eb',
    gold: '#faad14',
    lime: '#a0d911',
    orange: '#fa8c16',
};
export const LanguagesNamed = {
    es: { name: 'Español', key: 'es' },
    en: { name: 'English', key: 'en' },
    pt: { name: 'Portuguese', key: 'pt' },
};
const lang = navigator.language;
const defaultState = {
    collapsed: false,
    manual_collapsed: false,
    language: (lang === null || lang === void 0 ? void 0 : lang.startsWith('en')) ? 'en' : 'es',
    theme: 'light',
    color: 'turingo_color',
    validationBanner: false,
    sound: true,
};
const uiSlice = createSlice({
    name: 'ui',
    initialState: defaultState,
    reducers: {
        validationBanner: (state, action) => {
            state.validationBanner = action.payload;
        },
        lastPage: (state, action) => {
            state.lastPageVisited = action.payload;
        },
        collapseSidebar: (state, action) => {
            state.collapsed = action.payload;
        },
        manualCollapseSidebar: (state, action) => {
            state.manual_collapsed = action.payload;
        },
        setCurrentLanguage: (state, action) => {
            state.language = action.payload;
        },
        setCurrentTheme: (state, action) => {
            state.theme = action.payload;
        },
        setCurrentColor: (state, action) => {
            state.color = action.payload;
        },
        setSound: (state, action) => {
            state.sound = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(communityDetails.fulfilled, (state, { payload }) => {
            var _a, _b, _c, _d;
            if ((_b = (_a = payload.data.ui) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.color) {
                state.color = (_d = (_c = payload.data.ui) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.color;
            }
            else {
                state.color = 'turingo_color';
            }
        });
        builder.addCase(communityEdit.fulfilled, (state, { payload }) => {
            var _a, _b, _c, _d;
            if ((_b = (_a = payload.data.ui) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.color) {
                state.color = (_d = (_c = payload.data.ui) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.color;
            }
            else {
                state.color = 'turingo_color';
            }
        });
        builder.addCase(authLogout, (state) => {
            state.color = 'turingo_color';
        });
    },
});
export const { lastPage, collapseSidebar, setCurrentLanguage, setCurrentTheme, setCurrentColor, manualCollapseSidebar, validationBanner, setSound } = uiSlice.actions;
export { uiSlice };
