import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Form } from 'antd';
import { CreatePostContext } from '../../../PostCreateProvider';
import { TopicAddButton } from '../../../topics/TopicAddButton';
import { TopicDeleteButton } from '../../../topics/TopicDeleteButton';
import strings from '@resources/localization';
const PostCreationTopics = (props) => {
    var _a, _b;
    const { style, innerRef } = props;
    const { form, boards, setScreen } = useContext(CreatePostContext);
    const board = Form.useWatch(['form', 'turingo', 'board'], form);
    const topics = Form.useWatch(['form', 'turingo', 'topics'], form);
    const board_item = boards.find((value) => value.publicKey == board);
    return (_jsxs("div", { ref: innerRef, style: Object.assign({ display: 'flex', flexFlow: 'wrap' }, style), children: [topics === null || topics === void 0 ? void 0 : topics.map((topicRemove) => {
                var _a;
                return (_jsx(TopicDeleteButton, { title: (_a = board_item === null || board_item === void 0 ? void 0 : board_item.topics) === null || _a === void 0 ? void 0 : _a.find((value) => value.publicKey == topicRemove).name, onClick: () => form.setFieldValue(['form', 'turingo', 'topics'], topics.filter((topic) => topicRemove != topic)) }, topicRemove));
            }), _jsx(TopicAddButton, { disabled: !board || ((_b = (_a = boards.find((b) => b.publicKey == board)) === null || _a === void 0 ? void 0 : _a.topics) === null || _b === void 0 ? void 0 : _b.length) == 0, title: strings.screens.boards.discussion.post.topic.action, onClick: () => setScreen('topic_selection') })] }));
};
export { PostCreationTopics };
