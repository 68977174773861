import { jsx as _jsx } from "react/jsx-runtime";
import strings from '@resources/localization';
import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
export const FormPasswordItem = (props) => {
    const { name, label, validations, style, className, help } = props;
    const [error, setError] = useState(help);
    return (_jsx(Form.Item, { style: style, className: className, name: [name], label: label, help: error, rules: [
            ...(validations.includes('required')
                ? [
                    () => ({
                        validator(_, value) {
                            if (!value) {
                                setError(strings.forms.password.validations.required);
                                return Promise.reject(new Error());
                            }
                            setError(help);
                            return Promise.resolve();
                        },
                    }),
                ]
                : []),
            ...(validations.includes('required') ? [{ required: true }] : []),
            ...(validations.includes('short')
                ? [
                    () => ({
                        validator(_, value) {
                            if (value.length < 8) {
                                setError(strings.forms.password.validations.short);
                                return Promise.reject(new Error());
                            }
                            setError(help);
                            return Promise.resolve();
                        },
                    }),
                ]
                : []),
            ...(validations.includes('long')
                ? [
                    () => ({
                        validator(_, value) {
                            if (value.length > 20) {
                                setError(strings.forms.password.validations.long);
                                return Promise.reject(new Error());
                            }
                            setError(help);
                            return Promise.resolve();
                        },
                    }),
                ]
                : []),
            ...(validations.includes('confirm')
                ? [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]
                : []),
        ], children: _jsx(Input.Password, { autoComplete: "current-password", placeholder: strings.forms.password.placeholder, iconRender: (visible) => (visible ? _jsx(EyeTwoTone, { twoToneColor: "#FF3862" }) : _jsx(EyeInvisibleOutlined, { twoToneColor: "#FF3862" })) }) }));
};
