import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from './Notification.scss';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Badge, Popover, notification } from 'antd';
import { NotificationItem } from './NotificationItem';
import { BellOutlined, BellFilled } from '@ant-design/icons';
import { communityChatGet, getLastNotifications, markNotificationsAsSeen } from '@state/actions';
import { addChatNotification, addNotification, addUpdate, markChatAsRead } from '@state/reducers/Notifications/NotificationReducer';
import { useHistory, useLocation } from 'react-router';
import { Router } from '@Router';
import { NotificationPanel } from './NotificationPanel';
import { addMessage, addNewPost, addPin } from '@state/reducers/Community/CommunityReducer';
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton';
import { SocketContext } from 'src/SocketComponent';
import useSound from 'use-sound';
import notificationSound from '@bundle/audio/message.mp3';
export const NotificationList = () => {
    var _a, _b, _c;
    const language = useAppSelector((state) => state.ui.language);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const { general } = useAppSelector((state) => state.notification);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const roomSelected = (_a = location.state) === null || _a === void 0 ? void 0 : _a.roomPk;
    const isNotificationCenter = location.pathname.startsWith('/notifications');
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const chats = useAppSelector((state) => state.community.items[currentCommunity].chats);
    const [visibleNotifications, setVisibleNotifications] = useState(false);
    const soundSetting = useAppSelector((state) => state.ui.sound);
    const socket = useContext(SocketContext);
    const [play] = useSound(notificationSound, {
        volume: 0.6,
        interrupt: true,
    });
    const showNotification = (notificationData) => {
        var _a;
        if (!isMobile && currentCommunity == ((_a = notificationData.predicate) === null || _a === void 0 ? void 0 : _a.communityPk)) {
            notification.destroy();
            notification.open({
                message: (_jsx(NotificationItem, { onClose: () => {
                        void dispatch(markNotificationsAsSeen({
                            urlParams: {
                                notificationId: notificationData.publicKey,
                            },
                            bodyParams: {
                                communityPk: currentCommunity,
                            },
                        }));
                        setVisibleNotifications(false);
                    }, isPopup: true, language: language, dispatch: dispatch, history: history, compact: true, item: notificationData })),
                placement: 'topRight',
                top: 88,
            });
        }
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (((_b = (_a = socket.lastJsonMessage) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.id) == 'notification') {
            const notification = socket.lastJsonMessage;
            if (notification.body.kind == 'usr.community.post' && ((_c = notification.body.predicate) === null || _c === void 0 ? void 0 : _c.kind) == 'post') {
                dispatch(addUpdate({ publicKey: (_d = notification.body.predicate) === null || _d === void 0 ? void 0 : _d.communityPk }));
            }
            if (notification.body.kind == 'usr.chat.message') {
                if (roomSelected != notification.body.predicate.roomPk) {
                    dispatch(addChatNotification(notification.body));
                }
            }
            else {
                if (notification.body.kind == 'com.post.pinned.mandatory') {
                    dispatch(addPin(notification.body.predicate.communityPk));
                }
                dispatch(addNotification(notification.body));
                showNotification(notification.body);
            }
        }
        if (((_f = (_e = socket.lastJsonMessage) === null || _e === void 0 ? void 0 : _e.channel) === null || _f === void 0 ? void 0 : _f.id) == 'update') {
            const notification = socket.lastJsonMessage;
            dispatch(addNewPost({ communityPk: notification.body.communityPk, boardPk: notification.body.boardPk }));
        }
        if (((_h = (_g = socket.lastJsonMessage) === null || _g === void 0 ? void 0 : _g.channel) === null || _h === void 0 ? void 0 : _h.id) == 'chat') {
            const notification = socket.lastJsonMessage;
            if ((chats === null || chats === void 0 ? void 0 : chats.items) && chats.items[notification.body.roomPk]) {
                dispatch(addMessage({ message: notification.body }));
            }
            else {
                void dispatch(communityChatGet({
                    urlParams: {
                        cm_pk: notification.body.communityPk,
                        r_pk: notification.body.roomPk,
                    },
                    extraParams: {
                        isFirstTime: true,
                    },
                }));
            }
            if (((_j = notification.body) === null || _j === void 0 ? void 0 : _j.roomPk) == roomSelected) {
                const message = {
                    channel: 'chat.ctl',
                    body: {
                        action: 'readability.read',
                        communityPk: currentCommunity,
                        roomPk: roomSelected,
                        userPk: currentEntity.publicKey,
                    },
                };
                dispatch(markChatAsRead({ cm_pk: currentCommunity, r_pk: roomSelected }));
                socket.sendMessage(JSON.stringify(message));
            }
            if (((_k = notification.body) === null || _k === void 0 ? void 0 : _k.userPk) != currentEntity.publicKey && soundSetting) {
                play();
            }
        }
    }, [socket.lastJsonMessage]);
    const handleMenuClick = (open) => {
        if (!open) {
            void dispatch(markNotificationsAsSeen({
                urlParams: {
                    notificationId: 'all',
                },
                bodyParams: {
                    communityPk: currentCommunity,
                },
            }));
        }
        if (!isNotificationCenter && !isMobile) {
            setVisibleNotifications(open);
        }
        else if (isMobile) {
            history.push(Router.Path.notifications({}));
        }
    };
    useEffect(() => {
        void dispatch(getLastNotifications({}));
    }, []);
    return (_jsx(Popover, { style: { padding: '0px 12px' }, content: visibleNotifications ? (_jsx(NotificationPanel, { onClose: () => setVisibleNotifications(false) })) : (_jsx("div", { style: { width: 424, padding: 16, paddingBottom: 32 }, children: _jsx(ChatListSkeleton, { nElements: 2 }) })), title: _jsx("div", { style: { fontSize: 16 }, children: strings.notifications.title }), trigger: "click", placement: "bottomRight", open: visibleNotifications, onOpenChange: handleMenuClick, arrowPointAtCenter: true, getPopupContainer: (trigger) => trigger.parentElement, children: _jsx(Badge, { count: (_c = (_b = general.aggregate[currentCommunity]) === null || _b === void 0 ? void 0 : _b.notifications) === null || _c === void 0 ? void 0 : _c.newly, color: 'var(--notification-dot)', offset: [-14, 14], children: _jsxs("div", { className: visibleNotifications || isNotificationCenter ? style.iconActive : style.icon, children: [!visibleNotifications && !isNotificationCenter && _jsx(BellOutlined, { style: { fontSize: 24 } }), (visibleNotifications || isNotificationCenter) && _jsx(BellFilled, { style: { fontSize: 24 } })] }) }) }));
};
