import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@hooks/useStore';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton';
import heroDark from '@bundle/assets/landing/heroDark.png';
import heroLight from '@bundle/assets/landing/heroLight.png';
import { API } from '@api/API';
export const Hero = () => {
    const ref = useRef(null);
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const breakpoints = useBreakpoints();
    const isMedium = breakpoints.md || breakpoints.mdl;
    const isLarge = breakpoints.lg || breakpoints.xl || breakpoints.xxl;
    const paddingSize = isMedium ? 16 : isLarge ? 24 : 16;
    const isMobile = breakpoints.isMobile;
    const color = isDark ? '#58D1C9' : '#13C2C2';
    const bgColor = isDark ? '#1D1D1D' : '#FFFFFF';
    const isMLarge = breakpoints.md || breakpoints.mdl || breakpoints.lg;
    const columns = breakpoints.xxl ? 529 : breakpoints.xl ? 529 : breakpoints.lg ? 349 : 0;
    return (_jsx("div", { style: {
            paddingTop: 24,
            maxWidth: 1308,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: paddingSize,
            paddingRight: paddingSize,
        }, children: _jsxs("div", { style: { display: 'grid', gap: 24, gridTemplateColumns: isMobile ? '1fr' : `minmax(auto, ${columns}px) 1fr`, alignItems: 'center' }, children: [_jsxs("div", { style: Object.assign(Object.assign(Object.assign({ borderRadius: '100%' }, (isMobile && { textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' })), (!isMobile && !isMLarge && { maxWidth: 538 })), (!isMobile && isMLarge && { maxWidth: 349 })), children: [_jsx(Title, { style: Object.assign({ color: color, marginBottom: 16, fontWeight: 500, fontSize: 38, lineHeight: '46px' }, (isMedium && { maxWidth: 565, margin: 'auto' })), children: strings.screens.landing.headline }), _jsx(Paragraph, { style: { fontWeight: 400, fontSize: isMobile ? 18 : 16, lineHeight: '24px', marginBottom: 24 }, children: strings.screens.landing.subheadline }), _jsx(Link, { to: "/signup", onClick: () => {
                                void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: 'main.landing.hero' } });
                            }, children: _jsx(ExpandableButton, { size: 'large', type: "primary", children: strings.screens.auth.signup.action }) })] }), _jsx("div", { style: { gridRow: isMobile ? 1 : 'unset' }, children: _jsx("img", { alt: "example", style: { objectFit: 'cover', width: '100%', height: '100%' }, src: isDark ? heroDark : heroLight, loading: "eager" }) })] }) }));
};
