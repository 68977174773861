import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { LanguageMiddleware } from '@state/middlewares/language';
import { AuthenticationMiddleware } from '@state/middlewares/authentication';
import { Migration } from './Manifest';
import { RootReducer } from '@state/reducers/Root';
import logger from 'redux-logger';
const debugMiddlewares = [];
if (APP_DEBUG) {
    debugMiddlewares.push(logger);
}
const config = {
    key: 'core',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 2,
    migrate: createMigrate(Migration, { debug: false }),
    blacklist: ['modal', 'error'],
};
const store = configureStore({
    reducer: persistReducer(config, RootReducer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: true,
    }).concat(LanguageMiddleware, AuthenticationMiddleware, ...debugMiddlewares),
});
const persistor = persistStore(store);
export { store, persistor };
