import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { useAppSelector } from '@hooks/useStore';
import { BellOutlined, BellFilled } from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import { AppstoreOutlined, AppstoreFilled, LineChartOutlined } from '@ant-design/icons';
import { Bookmark, DegreeHat, SettingConfig } from '@icon-park/react';
import styles from './AdminSidebarItems.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { Router } from '@Router';
import { MembersIcon } from '@components/icons/MembersIcon';
import ClassNames from '@util/style/ClassNames';
import { TeamsIcon } from '@components/icons/TeamsIcon';
import { BackIcon } from '@components/icons/BackIcon';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CatalogueIcon } from '@components/icons/CataloguesIcon';
import { IntegrationsIcon } from '@components/icons/IntegrationsIcon';
import React from 'react';
const AdminSidebarItems = (props) => {
    var _a;
    const { mode: menuMode, hideCollapse, collapsed = false, afterClick, isDrawer } = props;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const manual_collapsed = useAppSelector((state) => state.ui.manual_collapsed);
    const breakpoints = useBreakpoints();
    const history = useHistory();
    const isCollapsed = manual_collapsed || collapsed;
    const isInline = menuMode == 'inline';
    const match = useRouteMatch('/:cm_pk/settings/:section');
    const section = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.section;
    const inlineItems = currentCommunity
        ? [
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'general' }) }, children: strings.screens.admin.general.title })),
                key: 'general',
                icon: _jsx(SettingConfig, { className: styles.menuIcon, theme: section == 'general' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } }),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: _jsx("span", { children: strings.general.peoples }),
                key: 'people',
                icon: (_jsx(MembersIcon, { className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })),
                className: ClassNames(styles.menuItemExpanded),
                children: [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'members' }) }, children: strings.screens.admin.members.title })),
                        key: 'members',
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'invitations' }) }, children: strings.screens.admin.invitations.title })),
                        key: 'invitations',
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'profiles' }) }, children: strings.screens.admin.profiles.title })),
                        key: 'profiles',
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ],
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'teams' }) }, children: strings.screens.admin.teams.title })),
                key: 'teams',
                icon: (_jsx(TeamsIcon, { color: section == 'teams' ? 'var(--ant-primary-color)' : undefined, filled: section == 'teams', className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })),
                className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'boards' }) }, children: strings.screens.admin.boards.title })),
                key: 'boards',
                icon: React.createElement(section == 'boards' ? AppstoreFilled : AppstoreOutlined, {
                    className: styles.menuIcon,
                    style: { fontSize: 24, marginRight: 8 },
                }),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'catalogues' }) }, children: strings.screens.admin.catalogues.title })),
                key: 'catalogues',
                icon: (_jsx(CatalogueIcon, { color: section == 'catalogues' ? 'var(--ant-primary-color)' : undefined, filled: section == 'catalogues', className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })),
                className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            ...(currentCommunity != 'turingo'
                ? [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'notifications' }) }, children: strings.screens.admin.notifications.title })),
                        key: 'notifications',
                        icon: section == 'notifications' ? (_jsx(BellFilled, { className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })) : (_jsx(BellOutlined, { className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })),
                        className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'integrations' }) }, children: strings.screens.admin.integrations.title })),
                key: 'integrations',
                icon: (_jsx(IntegrationsIcon, { color: section == 'integrations' ? 'var(--ant-primary-color)' : undefined, filled: section == 'integrations', className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } })),
                className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'onboarding' }) }, children: strings.screens.admin.onboarding.title })),
                key: 'onboarding',
                icon: _jsx(Bookmark, { className: styles.menuIcon, theme: section == 'onboarding' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } }),
                className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'courses' }) }, children: strings.screens.admin.courses.title })),
                key: 'courses',
                icon: _jsx(DegreeHat, { className: styles.menuIcon, theme: section == 'courses' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } }),
                className: ClassNames(styles.menuItemExpanded),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'analytics' }) }, children: strings.screens.admin.analytics.title })),
                key: 'analytics',
                icon: _jsx(LineChartOutlined, { className: styles.menuIcon, style: { fontSize: 24, marginRight: 8 } }),
                className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
        ]
        : [];
    return (_jsxs("div", { style: { paddingTop: !hideCollapse && isInline ? 27 : 0 }, children: [_jsxs("div", { onClick: () => history.push(Router.Path.community_home({ cm_pk: currentCommunity })), className: "hover-svg", style: { display: 'flex', alignItems: 'center', padding: '8px 16px', marginBottom: 16, cursor: 'pointer' }, children: [_jsx(BackIcon, { style: { marginRight: 8 } }), ((!breakpoints.isMobile && !breakpoints.lg) || isDrawer) && (_jsx(Paragraph, { style: { fontSize: 16 }, children: strings.screens.admin.actions.back }))] }), isDrawer && _jsx(Divider, {}), ((!breakpoints.isMobile && !breakpoints.lg) || isDrawer) && (_jsx(Paragraph, { style: { padding: '8px 16px' }, strong: true, className: "turingo-sidebar-text", children: strings.screens.admin.actions.adminPanel })), _jsx(Menu, { triggerSubMenuAction: 'click', getPopupContainer: (trigger) => trigger.parentElement, className: 'sidebar-admin', theme: 'light', selectedKeys: [match.params.section], defaultOpenKeys: isCollapsed ? [] : ['people'], mode: menuMode, items: inlineItems })] }));
};
export { AdminSidebarItems };
