import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Skeleton, Card } from 'antd';
export const HeaderSkeleton = ({ breadcrumb = false }) => {
    return (_jsx(Card, { bordered: false, bodyStyle: {
            padding: '24px 24px',
        }, cover: _jsxs(_Fragment, { children: [breadcrumb && _jsx("div", { style: { padding: '16px 24px' }, children: _jsx(Skeleton, { title: true, active: true, paragraph: null, style: { maxWidth: 250 } }) }), _jsx("div", { style: { paddingTop: '40%', position: 'relative', overflow: 'hidden' }, children: _jsx("div", { className: "turingo-skeleton", style: {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        } }) })] }), children: _jsx(Skeleton, { title: true, active: true, paragraph: null, style: { maxWidth: 200 } }) }));
};
