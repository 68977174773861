import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Spin } from 'antd';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import strings from '@resources/localization';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { billingCartCreate } from '@state/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { Router } from '@Router';
import { Redirect, useHistory, useParams } from 'react-router';
import { API } from '@api/API';
const LoadingIcon = _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true });
const BuyButton = (props) => {
    var _a, _b, _c, _d;
    const { board, size = 'middle', showPrice, position } = props;
    const { cm_pk = 'turingo' } = useParams();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const cart = useAppSelector((state) => state.billing.cart);
    const onClickAddToCart = (productPk) => {
        void dispatch(billingCartCreate({
            bodyParams: {
                products: [
                    {
                        publicKey: productPk,
                        quantity: 1,
                    },
                ],
            },
        }));
    };
    if (cart && cart.state === 'created' && cart.products && cart.products.find((p) => { var _a; return p.publicKey === ((_a = board.product) === null || _a === void 0 ? void 0 : _a.publicKey); })) {
        return _jsx(Redirect, { to: { pathname: Router.Path.billing_cart_details([]), state: { entityPk: board.publicKey } } });
    }
    let analyticsSender = 'course.detail.card';
    switch (props.position) {
        case 'card':
            analyticsSender = 'course.card';
            break;
        case 'landing_mobile_bottom':
            analyticsSender = 'course.detail.mobile.fixed';
            break;
        case 'landing_sidebar':
            analyticsSender = 'course.detail.sidebar';
            break;
        case 'landing_primary':
            analyticsSender = 'course.detail.card';
            break;
    }
    if (cart && cart.state === 'pending') {
        return (_jsx(LinkButton, { width: '100%', block: true, type: "primary", size: size, children: _jsx(Spin, { indicator: LoadingIcon, size: 'large' }) }, `cb#1`));
    }
    else {
        return (_jsx(LinkButton, { href: { pathname: Router.Path.billing_cart_details([]), state: { entityPk: board.publicKey } }, block: true, width: '100%', type: "primary", size: size, onClick: () => {
                if (isLogged && position) {
                    void API.analyticsAction({
                        bodyParams: {
                            action: 'billing.purchase',
                            sender: analyticsSender,
                            referenceBoard: board.publicKey,
                            referenceCommunity: cm_pk,
                        },
                    });
                }
                onClickAddToCart(board.product.publicKey);
            }, children: !showPrice ? (strings.general.buy) : ((_a = board.product) === null || _a === void 0 ? void 0 : _a.priceOriginal) ? (_jsxs(_Fragment, { children: [strings.general.buy, ' ', new Intl.NumberFormat('es-CL', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                    }).format((_b = board.product) === null || _b === void 0 ? void 0 : _b.price), ' ', _jsx("s", { style: { opacity: 0.8 }, children: new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format((_c = board === null || board === void 0 ? void 0 : board.product) === null || _c === void 0 ? void 0 : _c.priceOriginal) })] })) : (strings.general.buy +
                ' ' +
                new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format((_d = board.product) === null || _d === void 0 ? void 0 : _d.price)) }, `cb#1`));
    }
};
export { BuyButton };
