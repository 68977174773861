import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import strings from '@resources/localization';
import { InformationModalBody } from './InformationModalBody';
import { FooterModal } from '@components/modals/components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
export const InformationModal = (props) => {
    const { open, onCancel, kind, title, description, onClick, onClickText, subdescription, children, onCancelText, onClickDisabled } = props;
    return (_jsxs(TuringoModal, { open: open, onCancel: onCancel, onOk: onClick, width: 600, footer: [
            _jsx(FooterModal, { primary: {
                    action: onClick,
                    disabled: onClickDisabled,
                    customText: onClickText ? onClickText : 'OK',
                }, secondary: {
                    action: onCancel,
                    customText: onCancelText ? onCancelText : strings.general.cancel,
                } }, 'footer_modal'),
        ], children: [_jsx(InformationModalBody, { forceLeftTextAlign: true, kind: kind, title: title, description: description, subdescription: subdescription }), children] }));
};
