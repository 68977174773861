import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Divider } from 'antd';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import { LanguageSelector } from '../LanguageSelector';
import strings from '@resources/localization';
const LanguageScreen = (props) => {
    const history = useHistory();
    return (_jsxs("div", { className: "lateral-padding", style: { paddingBottom: 16 }, children: [_jsxs("div", { style: { marginTop: 16, display: 'flex', alignItems: 'center' }, children: [_jsx(GoBackButton, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }, type: "default", showText: false, onClick: () => history.push(Router.Path.settings({})) }), _jsx(Paragraph, { strong: true, className: "turingo-text", children: strings.screens.settings.sections.language.title })] }), _jsx(Divider, {}), _jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.settings.sections.language.description }), _jsx(LanguageSelector, {})] }));
};
export { LanguageScreen };
