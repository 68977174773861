import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from './Messages.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Badge, Button, Popover } from 'antd';
import { MessageFilled, MessageOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { Router } from '@Router';
import { Plus } from '@icon-park/react';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { MessagesPanel } from './MessagesPanel';
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton';
import strings from '@resources/localization';
import { useState } from 'react';
export const MessagesList = () => {
    var _a, _b, _c;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const { general } = useAppSelector((state) => state.notification);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const roomSelected = (_a = location.state) === null || _a === void 0 ? void 0 : _a.roomPk;
    const isChatScreen = location.pathname.includes('/messages');
    const [visibleMessages, setVisibleMessages] = useState(false);
    const newChat = () => {
        dispatch(modalAction({ modal: 'chat', state: true }));
        setVisibleMessages(false);
    };
    const handleMenuClick = (open) => {
        if (!isChatScreen && !isMobile) {
            setVisibleMessages(open);
        }
        else if (isMobile) {
            history.push({ pathname: Router.Path.community_chat({ cm_pk: currentCommunity }), state: { roomPk: roomSelected } });
        }
    };
    return (_jsx(Popover, { style: { padding: '0px 12px' }, content: visibleMessages ? (_jsx(MessagesPanel, { onClose: () => setVisibleMessages(false) })) : (_jsx("div", { style: { width: 424, padding: 16, paddingBottom: 32 }, children: _jsx(ChatListSkeleton, { nElements: 2 }) })), title: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [_jsx("div", { style: { fontSize: 16 }, children: strings.screens.chat.title }), ' ', _jsx(Button, { onClick: newChat, type: "default", icon: _jsx(Plus, { theme: "outline", size: "14", style: { display: 'flex', justifyContent: 'center' } }), style: { height: 38, width: 38 } })] }), trigger: "click", placement: "bottomRight", open: visibleMessages, onOpenChange: handleMenuClick, arrowPointAtCenter: true, getPopupContainer: (trigger) => trigger.parentElement, children: _jsx(Badge, { count: (_c = (_b = general.aggregate[currentCommunity]) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.newly, color: 'var(--notification-dot)', offset: [-14, 14], children: _jsxs("div", { className: visibleMessages || isChatScreen ? style.iconActive : style.icon, children: [!visibleMessages && !isChatScreen && _jsx(MessageOutlined, { style: { fontSize: 24 } }), (visibleMessages || isChatScreen) && _jsx(MessageFilled, { style: { fontSize: 24 } })] }) }) }));
};
