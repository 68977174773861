import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Alert, Button, Affix } from 'antd';
import { authRevalidate, authValidateStatus } from '@state/actions';
import { useRequestState } from '@api/requests/AsyncRequests';
import strings from '@resources/localization';
import { validationBanner } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
export const Banner = (props) => {
    var _a;
    const { offsetTop = 48 } = props;
    const [onlineStatus, setOnlineStatus] = useState(true);
    useEffect(() => {
        window.addEventListener('offline', () => {
            setOnlineStatus(false);
        });
        window.addEventListener('online', () => {
            setOnlineStatus(true);
        });
        return () => {
            window.removeEventListener('offline', () => {
                setOnlineStatus(false);
            });
            window.removeEventListener('online', () => {
                setOnlineStatus(true);
            });
        };
    }, []);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const validation = useAppSelector((state) => state.auth.authValidation);
    const hideBanner = location.pathname.startsWith('/auth') ||
        ((_a = location.pathname) === null || _a === void 0 ? void 0 : _a.split('/')[2]) == 'onboarding' ||
        !isLogged ||
        location.pathname.startsWith('/billing') ||
        location.pathname.startsWith('/blocked') ||
        (location.pathname.startsWith('/help') && validation == 'blocked');
    const isValidated = useAppSelector((state) => state.auth.authValidation == 'valid');
    const [isSended, setIsSended] = useState(false);
    const [resendStatus, setResendStatus] = useRequestState(false, false);
    useEffect(() => {
        dispatch(validationBanner(!isValidated && !hideBanner));
    }, [hideBanner, isValidated]);
    const dispatch = useAppDispatch();
    const handleok = () => {
        setIsSended(true);
    };
    const handleUpdate = () => {
        window.location.reload();
    };
    const handleClose = () => {
        dispatch(validationBanner(false));
    };
    const resendEmail = (value) => {
        if (value.authValidation != 'valid') {
            void dispatch(authRevalidate({
                options: {
                    redux: {
                        stateHandler: setResendStatus,
                    },
                    then: { action: handleok },
                    alerts: {
                        error: 'Ha sucedido un error al reenviar el email de validación',
                    },
                },
            }));
        }
    };
    const getValidationStatus = () => {
        void dispatch(authValidateStatus({
            options: {
                then: { action: resendEmail },
            },
        }));
    };
    if (isValidated && onlineStatus) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Affix, { offsetTop: offsetTop, style: { zIndex: 8 }, children: [!isValidated && !hideBanner && !isSended && onlineStatus && (_jsx("div", { style: { backgroundColor: 'var(--yellow)' }, children: _jsx(Alert, { showIcon: true, banner: true, onClose: handleClose, style: { maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto', display: 'flex' }, message: _jsxs(_Fragment, { children: [_jsxs("span", { style: { marginRight: 4 }, children: [strings.screens.landing.bannerValidate.title, " ", strings.screens.landing.bannerValidate.description, ' '] }), _jsx(Button, { className: 'underline', type: "text", size: "small", style: { fontWeight: 500, padding: 0, height: 'unset' }, onClick: getValidationStatus, loading: resendStatus.editing, children: strings.screens.landing.bannerValidate.action })] }), type: "warning", closable: true }) })), !isValidated && !hideBanner && isSended && onlineStatus && (_jsx("div", { style: { backgroundColor: 'var(--green)' }, children: _jsx(Alert, { style: { maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto' }, showIcon: true, banner: true, message: strings.screens.landing.bannerValidate.sended, type: "success", closable: true }) })), !onlineStatus && (_jsx("div", { style: { backgroundColor: 'var(--red)' }, children: _jsx(Alert, { showIcon: true, banner: true, style: { maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto' }, message: _jsxs(_Fragment, { children: [_jsx("span", { style: { marginRight: 4 }, children: 'Conexión a internet débil. Te recomendamos revisar tu conexión.' }), _jsx(Button, { className: 'underline', type: "text", size: "small", style: { fontWeight: 'bold', padding: 0 }, onClick: handleUpdate, children: 'Actualizar Página' })] }), type: "error" }) }))] }));
};
