import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { Down } from '@icon-park/react';
import style from './ButtonSelect.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import ClassNames from '@util/style/ClassNames';
import { PostContext } from '@components/content/posts/Post';
const ButtonSelect = (props) => {
    var _a;
    const { onClick, icon, shape = 'circle' } = props;
    const context = useContext(PostContext);
    const mask = (_a = context === null || context === void 0 ? void 0 : context.mask) === null || _a === void 0 ? void 0 : _a.get;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { className: ClassNames(style.button_select, 'tgo-hover-button'), onClick: onClick, style: {
            padding: icon ? (isMobile ? '8px' : '7px') : isMobile ? '1px 7px 1px 3px' : '2px 7px 2px 3px',
            borderRadius: shape == 'circle' ? '100px' : '4px',
        }, children: [!icon && (_jsx(AvatarTuringo, { size: 16, style: {
                    height: isMobile ? 40 : 32,
                    width: isMobile ? 40 : 32,
                    marginRight: 4,
                    lineHeight: isMobile ? '40px' : '32px',
                    minWidth: isMobile ? 40 : 32,
                    border: '1px solid var(--border)',
                }, name: mask === null || mask === void 0 ? void 0 : mask.name, avatar: mask === null || mask === void 0 ? void 0 : mask.avatarUrl })), icon && _jsx("div", { style: { display: 'flex', alignItems: 'center', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }, children: icon }), _jsx(Down, { style: { display: 'flex' }, theme: "outline", size: isMobile ? (icon ? 16 : 18) : icon ? 14 : 16, strokeLinejoin: "bevel" })] }));
};
export { ButtonSelect };
