const KindNamePlural = {
    discussion: 'discussions',
    course: 'courses',
    forum: 'talks',
    story: 'stories',
    community: 'communities',
    catalog: 'catalogue',
    organization: 'organizations',
    user: 'users',
};
const KindNameSingular = {
    discussion: 'discussion',
    course: 'course',
    forum: 'talk',
    story: 'story',
    community: 'community',
    catalog: 'catalogue',
    organization: 'organization',
    user: 'user',
};
export { KindNameSingular, KindNamePlural };
