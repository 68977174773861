import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { Card } from 'antd';
import { useEffect } from 'react';
import { FooterModal } from './components/FooterModal';
import { InformationModalBody } from './information_modal/InformationModalBody';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { setErrorAction } from '@state/actions';
import { useLocation } from 'react-use';
import { useIsMount } from '@hooks/useIsMount';
import { Router } from '@Router';
export const SystemError = (props) => {
    const breakpoints = useBreakpoints();
    const { error } = props;
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const communities = useAppSelector((state) => { var _a; return (_a = state.auth) === null || _a === void 0 ? void 0 : _a.communities; });
    const isMobile = breakpoints.isMobile;
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const isMount = useIsMount();
    useEffect(() => {
        if (!isMount) {
            dispatch(setErrorAction());
        }
    }, [location.pathname]);
    const onAction = () => {
        dispatch(setErrorAction());
        const community = location.pathname.split('/')[1];
        history.push(Router.Path.community_home({ cm_pk: currentCommunity }), { to: location.pathname });
    };
    const onBack = () => {
        dispatch(setErrorAction());
        const community = location.pathname.split('/')[1];
        history.push(Router.Path.community_home({ cm_pk: community }), { to: location.pathname });
    };
    if (error == 'access.notallowed') {
        if (isLogged) {
            return (_jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    marginTop: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 24,
                }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'warning', title: strings.system.access_error.title, description: strings.system.access_error.description }), _jsx(FooterModal, { primary: {
                                action: onAction,
                                customText: strings.system.access_error.action,
                            } })] }) }));
        }
    }
    if (error == 'application.community.notfound') {
        return (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                alignItems: 'center',
                marginTop: isMobile ? 16 : 24,
                marginBottom: isMobile ? 16 : 24,
            }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'warning', title: strings.system.not_found.title, description: strings.system.not_found.description }), _jsx(FooterModal, { primary: {
                            action: () => {
                                dispatch(setErrorAction());
                                history.push(Router.Path.root({}));
                            },
                            customText: strings.system.not_found.action,
                        } })] }) }));
    }
    if (error == 'application.post.notfound' || error == 'application.board.notfound') {
        return (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                alignItems: 'center',
                marginTop: isMobile ? 16 : 24,
                marginBottom: isMobile ? 16 : 24,
            }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'warning', title: strings.system.not_found.title, description: strings.system.not_found.description }), _jsx(FooterModal, { primary: {
                            action: onBack,
                            customText: strings.system.not_found.action,
                        } })] }) }));
    }
    return (_jsx("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            marginTop: isMobile ? 16 : 24,
            marginBottom: isMobile ? 16 : 24,
        }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'error', title: strings.system.api_error.title, description: strings.system.api_error.description }), _jsx(FooterModal, { primary: {
                        action: onAction,
                        customText: strings.system.api_error.action,
                    } })] }) }));
};
