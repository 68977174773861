import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { authLogout } from '@state/actions';
import { FooterModal } from './components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
const AuthExpiredModal = () => {
    const logoutModal = useAppSelector((state) => state.auth.authExpiredModal);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const logoutAction = () => {
        dispatch(authLogout({ reason: 'auto.expiration' }));
        localStorage.setItem('logout', Date.now().toString());
        history.push('/login');
    };
    return (_jsxs(TuringoModal, { open: logoutModal, width: 600, closable: false, onCancel: null, footer: [
            _jsx(FooterModal, { primary: {
                    action: logoutAction,
                    customText: strings.screens.auth.expiration.actions.accept,
                } }, 'footer_modal'),
        ], children: [_jsx(ExclamationCircleOutlined, { style: { color: '#FAAD14', fontSize: 56, marginBottom: 20 } }), _jsx(Paragraph, { strong: true, children: strings.screens.auth.expiration.title }), _jsx(Paragraph, { children: strings.screens.auth.expiration.description })] }));
};
export { AuthExpiredModal };
