import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AvatarTuringo } from '@components/avatar/Avatar';
import style from './UserListItem.scss';
const UserListItem = (props) => {
    var _a, _b;
    const { item, onClick } = props;
    return (_jsxs("div", { className: style.item, style: { display: 'flex', alignItems: 'center', padding: '8px 16px' }, onClick: onClick, children: [_jsx(AvatarTuringo, { size: 16, style: {
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    lineHeight: '40px',
                    marginRight: 8,
                }, name: item.name, avatar: item.avatarUrl }), _jsxs("div", { style: { lineHeight: '22px' }, children: [_jsx("div", { className: "turingo-text bold", children: item.name }), ((_a = item.profile) === null || _a === void 0 ? void 0 : _a.job) && _jsx("div", { className: "turingo-subtext", children: (_b = item.profile) === null || _b === void 0 ? void 0 : _b.job })] })] }, item.publicKey));
};
export { UserListItem };
