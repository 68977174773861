import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import strings from '@resources/localization';
import ImageFacebookIcon from '@bundle/assets/social/small/logo-facebook-white.svg';
import ImageLinkedinIcon from '@bundle/assets/social/small/logo-linkedin-white.svg';
import ImageGoogleIcon from '@bundle/assets/social/small/logo-google.svg';
import style from './SocialButton.scss';
import SVG from '@components/assets/SVG';
const SocialButton = (props) => {
    const Providers = {
        facebook: {
            className: style.facebook,
            color: 'white',
            icon: ImageFacebookIcon,
        },
        google: {
            className: style.google,
            color: 'black',
            icon: ImageGoogleIcon,
        },
        linkedin: {
            className: style.linkedin,
            color: 'white',
            icon: ImageLinkedinIcon,
        },
        zoom: {
            className: style.linkedin,
            color: 'white',
            icon: ImageLinkedinIcon,
        },
    };
    const { onClick, provider, kind } = props;
    const { className, color, icon } = Providers[props.provider];
    return (_jsxs("button", { style: { color: color }, className: className, onClick: onClick, children: [_jsx(SVG, { style: { width: 24, height: 24, marginRight: 8 }, content: icon }), strings.screens.auth[kind].social[provider]] }));
};
export { SocialButton };
