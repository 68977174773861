import { createSlice } from '@reduxjs/toolkit';
import { billingCartCreate, billingDLocalGoPaymentCancel, billingDLocalGoPaymentConfirm, billingDLocalPaymentComplete, billingPaypalPaymentComplete, billingRedeem, } from '@state/actions';
const defaultState = {
    paymentResult: {
        redeemal: 'initial',
        payment: undefined,
        products: [],
        invoice: undefined,
    },
};
const billingSlice = createSlice({
    name: 'billing',
    initialState: defaultState,
    reducers: {
        cartCheckoutStart: (state) => {
            if (state.cart && state.cart.state === 'created') {
                state.cart = Object.assign(Object.assign({}, state.cart), { state: 'checking' });
            }
        },
        cartCheckoutExpire: (state) => {
            if (state.cart && state.cart.state !== 'paid') {
                state.cart = Object.assign(Object.assign({}, state.cart), { state: 'expired' });
            }
        },
        billingDLocalGoPaymentConfirm: (state) => {
            state.paymentResult = { redeemal: 'starting' };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(billingCartCreate.rejected, (state, action) => {
            state.cart = Object.assign(Object.assign({}, state.cart), { state: undefined, dateCreated: undefined });
        });
        builder.addCase(billingCartCreate.pending, (state, action) => {
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'pending', dateCreated: Date() });
            state.paymentResult = undefined;
        });
        builder.addCase(billingCartCreate.fulfilled, (state, action) => {
            state.cart = Object.assign(Object.assign(Object.assign({}, state.cart), { state: 'created', dateCreated: Date() }), action.payload.data);
        });
        builder.addCase(billingPaypalPaymentComplete.fulfilled, (state, action) => {
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'paid' });
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'required', payment: action.payload.data.payment, products: action.payload.data.products, invoice: action.payload.data.invoice });
        });
        builder.addCase(billingDLocalPaymentComplete.fulfilled, (state, action) => {
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'paid' });
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'required', payment: action.payload.data.payment, products: action.payload.data.products, invoice: action.payload.data.invoice });
        });
        builder.addCase(billingDLocalGoPaymentConfirm.pending, (state, action) => {
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'progress' });
        });
        builder.addCase(billingDLocalGoPaymentConfirm.fulfilled, (state, action) => {
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'required', payment: action.payload.data.payment, products: action.payload.data.products, invoice: action.payload.data.invoice });
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'paid' });
        });
        builder.addCase(billingDLocalGoPaymentConfirm.rejected, (state, action) => {
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'failed', payment: undefined, products: [], invoice: undefined });
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'checking' });
        });
        builder.addCase(billingDLocalGoPaymentCancel.fulfilled, (state, action) => {
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'cancelled', payment: undefined, products: [], invoice: undefined });
            state.cart = Object.assign(Object.assign({}, state.cart), { state: 'checking' });
        });
        builder.addCase(billingRedeem.pending, (state, action) => {
            state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'progress' });
        });
        builder.addCase(billingRedeem.fulfilled, (state, { payload }) => {
            const redeemedProduct = state.paymentResult.products.find((p) => p.license === payload.data.license);
            if (redeemedProduct) {
                state.paymentResult = Object.assign(Object.assign({}, state.paymentResult), { redeemal: 'redeemed' });
            }
        });
    },
});
export const { cartCheckoutStart, cartCheckoutExpire } = billingSlice.actions;
export { billingSlice };
