let lastScrollTop = 0;
export const lockScroll = () => {
    lastScrollTop = window.pageYOffset;
    const doc = document.getElementById('root');
    doc.style.position = 'relative';
    doc.style.top = `-${lastScrollTop}px`;
    document.body.classList.add('noscroll');
};
export const unlockScroll = () => {
    document.body.classList.remove('noscroll');
    const doc = document.getElementById('root');
    doc.style.position = '';
    doc.style.top = '';
    window.scroll(0, lastScrollTop);
};
export const checkBlockedScroll = () => {
    return document.body.classList.contains('noscroll');
};
