import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import Loadable from '@loadable/component';
import { BackTop } from 'antd';
import strings from '@resources/localization';
import { cleanAuth } from '@state/reducers/Auth/AuthReducer';
import { Hero } from './components/Hero/Hero';
const LandingBody = Loadable(() => import('./LandingBody'));
const Landing = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const userState = useAppSelector((state) => state.auth.authState);
    const dispatch = useAppDispatch();
    if (userState === 'unauth.closed' || userState === 'unauth.expired' || userState === 'unauth.rejected') {
        dispatch(cleanAuth());
    }
    return (_jsxs("div", { style: { width: '100%', backgroundColor: isDark ? "#000000" : '#FAFAFA' }, children: [_jsx(BackTop, { style: { zIndex: 100 } }), _jsxs(Meta, { children: [_jsx("title", { children: strings.screens.landing.browserTitle }), _jsx("meta", { name: "description", content: strings.screens.landing.browserDescription })] }), _jsxs("div", { children: [_jsx("div", { style: { width: '100%' }, children: _jsx(Hero, {}) }, "a"), _jsx(LandingBody, { theme: theme })] })] }));
};
export { Landing };
