import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory } from 'react-router';
import strings from '@resources/localization';
import { authLogout } from '@state/actions';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { API } from '@api/API';
import { FooterModal } from './components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { InformationModalBody } from './information_modal/InformationModalBody';
import { changeCurrentCommunity } from '@state/reducers/Community/CommunityReducer';
const LogoutModal = () => {
    const logoutStrings = strings.screens.auth.logout;
    const logoutModal = useAppSelector((state) => state.modal.logout);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const closeModal = () => {
        void API.analyticsModal({ bodyParams: { modal: 'modal.main.logout', state: 'close' } });
        dispatch(modalAction({ modal: 'logout', state: false }));
    };
    const logoutAction = () => {
        void API.analyticsAction({ bodyParams: { action: 'account.logout', sender: 'modal.main.logout' } });
        localStorage.setItem('logout', Date.now().toString());
        dispatch(authLogout({ reason: 'manual' }));
        dispatch(changeCurrentCommunity('turingo'));
        closeModal();
        history.push('/');
    };
    return (_jsx(TuringoModal, { open: logoutModal, width: 600, onCancel: closeModal, footer: [
            _jsx(FooterModal, { primary: {
                    action: logoutAction,
                    customText: logoutStrings.actions.accept,
                }, secondary: {
                    action: closeModal,
                    customText: logoutStrings.actions.cancel,
                } }, 'footer_modal'),
        ], children: _jsx(InformationModalBody, { kind: 'warning', title: logoutStrings.title, description: logoutStrings.description }) }));
};
export { LogoutModal };
