import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CollapseStyled } from '@components/collapse/CollapseStyled';
import strings from '@resources/localization';
import { Router } from '@Router';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { Colors } from '@util/color/Colors';
import { Card, Collapse } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory, useParams } from 'react-router';
export const CommunityRules = (props) => {
    const { isCompact, rules } = props;
    const { cm_pk } = useParams();
    const history = useHistory();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const onClick = () => {
        if (isLogged) {
            history.push(Router.Path.community_about({ cm_pk }));
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    return (_jsx(Card, { title: strings.screens.boards.discussion.details.rules.title, bordered: false, bodyStyle: { padding: 16 }, children: _jsx(CollapseStyled, { ghost: true, children: rules &&
                rules.map((rule, index) => {
                    const isLastItem = index === rules.length - 1;
                    return (_jsx(_Fragment, { children: _jsx(Collapse.Panel, { style: {
                                padding: '4px 0px',
                                margin: !isLastItem ? '0px 0px 16px 0px' : '0px',
                                borderBottom: !isLastItem ? `1px solid ${Colors('DIVIDER')}` : 'unset',
                            }, header: _jsx("span", { className: "ant-collapse-header-title", children: `${rule.title}` }), children: _jsx("p", { style: { marginBottom: 0 }, children: rule.description }) }, `rulesPanel#${index}`) }));
                }) }) }));
};
