import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { parse, STATES } from './utils';
import { Spin } from 'antd';
export function LinkedInCallback() {
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const params = parse(window.location.search);
        if (params.state !== localStorage.getItem(STATES['linkedin'])) {
            setErrorMessage('State does not match');
        }
        else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.';
            window.opener &&
                window.opener.postMessage({
                    error: params.error,
                    state: params.state,
                    errorMessage,
                    from: 'Turingo',
                }, window.location.origin);
            if (params.error === 'user_cancelled_login') {
                window.close();
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin);
        }
    }, []);
    return _jsx(Spin, {});
}
export function GoogleCallback() {
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const params = parse(window.location.search);
        if (params.state !== localStorage.getItem(STATES['google'])) {
            setErrorMessage('State does not match');
        }
        else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.';
            window.opener &&
                window.opener.postMessage({
                    error: params.error,
                    state: params.state,
                    errorMessage,
                    from: 'Turingo',
                }, window.location.origin);
            if (params.error === 'user_cancelled_login') {
                window.close();
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin);
        }
    }, []);
    return _jsx(Spin, {});
}
export function FacebookCallback() {
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const params = parse(window.location.search);
        if (params.state !== localStorage.getItem(STATES['facebook'])) {
            setErrorMessage('State does not match');
        }
        else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.';
            window.opener &&
                window.opener.postMessage({
                    error: params.error,
                    state: params.state,
                    errorMessage,
                    from: 'Turingo',
                }, window.location.origin);
            if (params.error === 'user_cancelled_login') {
                window.close();
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin);
        }
    }, []);
    return _jsx(Spin, {});
}
export function ZoomCallback() {
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const params = parse(window.location.search);
        if (params.state !== localStorage.getItem(STATES['zoom'])) {
            setErrorMessage('State does not match');
        }
        else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.';
            window.opener &&
                window.opener.postMessage({
                    error: params.error,
                    state: params.state,
                    errorMessage,
                    from: 'Turingo',
                }, window.location.origin);
            if (params.error === 'user_cancelled_login') {
                window.close();
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin);
        }
    }, []);
    return _jsx(Spin, {});
}
