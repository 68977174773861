var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const createDynamicLibrary = (id, load) => {
    const chunkLibrary = {
        instance: undefined,
        identifier: id,
        observers: { onLoad: [] },
        loadPromise() {
            return __awaiter(this, void 0, void 0, function* () {
                if (this.instance)
                    return this.instance;
                const library = yield load();
                this.instance = library;
                this.observers.onLoad.forEach((o) => o(library));
                this.observers.onLoad = [];
                return library;
            });
        },
        load(observer) {
            if (this.instance) {
                if (observer)
                    observer(this.instance);
                return this;
            }
            if (observer)
                this.observers.onLoad.push(observer);
            void load()
                .then((library) => {
                this.instance = library;
                this.observers.onLoad.forEach((o) => o(library));
                this.observers.onLoad = [];
            })
                .catch((error) => {
                console.error('Failed to load library', this.identifier, 'due to', error);
            });
            return this;
        },
        onLoad(observer) {
            if (this.instance) {
                observer(this.instance);
                return this;
            }
            this.observers.onLoad.push(observer);
            return this;
        },
        get isLoaded() {
            return this.instance !== undefined;
        },
        get library() {
            if (!this.instance)
                throw `Library ${this.identifier} not loaded`;
            return this.instance;
        },
        get libraryOptional() {
            return this.instance;
        },
        discard() {
            this.instance = undefined;
            return this;
        },
    };
    return chunkLibrary;
};
export { createDynamicLibrary };
