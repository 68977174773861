import { store } from '@state/persistance/Storage';
const ColorsValues = {
    light: {
        TURINGO_1: '#FFF0F3',
        TURINGO_2: '#FFC7CE',
        TURINGO_3: '#FF9EAD',
        TURINGO_4: '#FF758E',
        TURINGO_5: '#FF4D6E',
        TURINGO_6: '#FF3862',
        TURINGO_7: '#CF133C',
        TURINGO_8: '#A8072A',
        TURINGO_9: '#82001C',
        TURINGO_10: '#5C0014',
        NEUTRAL_1: '#FFFFFF',
        NEUTRAL_2: '#FAFAFA',
        NEUTRAL_3: '#F5F5F5',
        NEUTRAL_4: '#F0F0F0',
        NEUTRAL_5: '#D9D9D9',
        NEUTRAL_6: '#BFBFBF',
        NEUTRAL_7: '#8C8C8C',
        NEUTRAL_8: '#595959',
        NEUTRAL_9: '#434343',
        NEUTRAL_10: '#262626',
        NEUTRAL_11: '#1F1F1F',
        NEUTRAL_12: '#141414',
        NEUTRAL_13: '#000000',
        BACKGROUND: '#FFFFFF',
        CYAN_HIGHLIGHT: '#006D75',
        CYAN_BACKGROUND: '#B5F5EC',
        PURPLE_HIGHLIGHT: '#391085',
        PURPLE_BACKGROUND: '#EFDBFF',
        VOLCANO_HIGHLIGHT: '#AD2102',
        VOLCANO_BACKGROUND: '#FFD8BF',
        BLUE_HIGHLIGHT: '#0050B3',
        BLUE_BACKGROUND: '#BAE7FF',
        DARK_BLUE_BACKGROUND: '#E6F7FF',
        FORM_INPUT_AREA_BACKGROUND: '#F5F5F5',
        CREATE_POLL_BACKGROUND: '#F5F5F5',
        POSTED_POLL_BACKGROUND: '#FAFAFA',
        OPTION_POLL_BACKGROUND: '#FFFFFF',
        OPTION_POLL_BORDER: '#D9D9D9',
        ICON: '#8C8C8C',
        DIVIDER: '#F0F0F0',
        MORE_POST_BACKGROUND: '#F0F0F0',
        INPUT_ERROR: '#ff4d6e',
        INPUT_COLOR: 'rgba(0, 0, 0, 0.85)',
        ALERT_BLUE_BACKGROUND: '#E6F7FF',
        ALERT_BLUE_BORDER: '#91D5FF',
        SKELETON: '#F2F2F2',
    },
    dark: {
        TURINGO_1: '#2A1213',
        TURINGO_2: '#43141F',
        TURINGO_3: '#581827',
        TURINGO_4: '#791A2F',
        TURINGO_5: '#A61D3B',
        TURINGO_6: '#E82E56',
        TURINGO_7: '#E84767',
        TURINGO_8: '#F3708C',
        TURINGO_9: '#F89AAE',
        TURINGO_10: '#FAC3CF',
        NEUTRAL_1: '#000000',
        NEUTRAL_2: '#141414',
        NEUTRAL_3: '#1F1F1F',
        NEUTRAL_4: '#262626',
        NEUTRAL_5: '#434343',
        NEUTRAL_6: '#595959',
        NEUTRAL_7: '#8C8C8C',
        NEUTRAL_8: '#BFBFBF',
        NEUTRAL_9: '#F0F0F0',
        NEUTRAL_10: '#F5F5F5',
        NEUTRAL_11: '#FAFAFA',
        NEUTRAL_12: '#FFFFFF',
        NEUTRAL_13: '#FFFFFF',
        BACKGROUND: '#262626',
        SKELETON: '#303030',
        CYAN_HIGHLIGHT: '#58D1C9',
        CYAN_BACKGROUND: '#144848',
        PURPLE_HIGHLIGHT: '#AB7AE0',
        PURPLE_BACKGROUND: '#3E2069',
        VOLCANO_HIGHLIGHT: '#F3956A',
        VOLCANO_BACKGROUND: '#592716',
        BLUE_HIGHLIGHT: '#65B7F3',
        BLUE_BACKGROUND: '#164C7E',
        DARK_BLUE_BACKGROUND: '#111D2C',
        FORM_INPUT_AREA_BACKGROUND: '#434343',
        CREATE_POLL_BACKGROUND: '#434343',
        POSTED_POLL_BACKGROUND: '#434343',
        OPTION_POLL_BACKGROUND: '#434343',
        OPTION_POLL_BORDER: '#8C8C8C',
        ICON: '#BFBFBF',
        DIVIDER: '#595959',
        MORE_POST_BACKGROUND: '#434343',
        INPUT_ERROR: '#f55875',
        INPUT_COLOR: 'rgba(255, 255, 255, 0.85)',
        ALERT_BLUE_BACKGROUND: '#111D2C',
        ALERT_BLUE_BORDER: '#65B7F3',
    },
};
export function Colors(color) {
    var _a;
    const state = store === null || store === void 0 ? void 0 : store.getState();
    if (!state)
        return '#000000';
    return ColorsValues[(_a = state === null || state === void 0 ? void 0 : state.ui) === null || _a === void 0 ? void 0 : _a.theme][color];
}
