export const LINKEDIN_OAUTH2_STATE = 'linkedin_oauth2_state';
export const STATES = {
    linkedin: 'linkedin_oauth2_state',
    facebook: 'facebook_oauth2_state',
    google: 'google_oauth2_state',
    zoom: 'zoom_oauth2_state',
};
export const SCOPES = {
    linkedin: 'r_liteprofile r_emailaddress',
    facebook: 'email',
    google: 'openid profile email',
    zoom: 'user:zak_read meeting:write meeting:read',
};
export function authURL(provider, clientId, redirectUri, generatedState) {
    const scopeParam = `&scope=${encodeURI(SCOPES[provider])}`;
    if (provider == 'facebook')
        return `https://www.facebook.com/v12.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${generatedState}${scopeParam}`;
    if (provider == 'linkedin')
        return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${generatedState}`;
    if (provider == 'google')
        return `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline${scopeParam}&include_granted_scopes=true&response_type=code&state=${generatedState}&redirect_uri=${redirectUri}&client_id=${clientId}`;
    if (provider == 'zoom')
        return `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${generatedState}${scopeParam}`;
}
export function parse(search) {
    const query = search.substring(1);
    const vars = query.split('&');
    const parsed = {};
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair.length > 1) {
            parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
    }
    return parsed;
}
