var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Slider } from 'antd';
import { useCallback, useState } from 'react';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../canvasUtils';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { v4 } from 'uuid';
const AssetOptions = {
    '1:1': {
        size: {
            width: 320,
            height: 320,
        },
        aspectRatio: 1,
        fileSize: 0.128,
        cropShape: 'round',
    },
    '5:2': {
        size: {
            width: 1800,
            height: 720,
        },
        fileSize: 5,
        aspectRatio: 5 / 2,
        cropShape: 'rect',
    },
    '16:9': {
        size: {
            width: 1280,
            height: 720,
        },
        fileSize: 5,
        aspectRatio: 16 / 9,
        cropShape: 'rect',
    },
    '16:10': {
        size: {
            width: 1280,
            height: 800,
        },
        fileSize: 5,
        aspectRatio: 16 / 10,
        cropShape: 'rect',
    },
    'letter': {
        size: {
            width: 2004,
            height: 1548,
        },
        fileSize: 5,
        aspectRatio: 1.294 / 1,
        cropShape: 'rect',
    },
};
export const EditImage = (props) => {
    const { open, onCancel, file, setFile, afterEdit, aspect_ratio, title, restrictPosition = true } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    const [scale, setScale] = useState(1);
    function onChangeScale(value) {
        setScale(value);
    }
    const onClickSave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const croppedImage = yield getCroppedImg(file, croppedAreaPixels, AssetOptions[aspect_ratio].size);
            const croppedFile = new File([croppedImage.blob], 'Avatar', { type: 'image/png' });
            const uploadCropped = {
                file: croppedFile,
                status: 'initial',
                percent: 0,
                preview: croppedImage.imagePreview
            };
            setFile({
                [v4()]: uploadCropped
            });
            onCancel();
            setScale(1);
            if (afterEdit) {
                afterEdit();
            }
        }
        catch (e) {
            console.error(e);
        }
    });
    const onCancelCrop = () => {
        try {
            setFile({});
            onCancel();
            setScale(1);
        }
        catch (e) {
            console.error(e);
        }
    };
    return (_jsxs(TuringoModal, { title: title, open: open, onOk: () => { }, onCancel: onCancel, width: 572, style: { top: 30 }, footer: [
            _jsx(FooterModal, { primary: {
                    action: onClickSave,
                    customText: 'Guardar',
                }, secondary: {
                    action: onCancelCrop,
                    customText: 'Cancelar',
                } }, 'footer_modal'),
        ], children: [file && (_jsx("div", { style: { position: 'relative', width: '100%', height: '300px' }, children: _jsx(Cropper, { showGrid: false, image: file, crop: crop, zoom: scale, restrictPosition: restrictPosition, minZoom: -0.5, aspect: AssetOptions[aspect_ratio].aspectRatio, onCropChange: setCrop, onRotationChange: setRotation, onCropComplete: onCropComplete, onZoomChange: setZoom, cropShape: AssetOptions[aspect_ratio].cropShape }) })), _jsxs(Row, { justify: 'center', style: { alignItems: 'center' }, children: [_jsx(Col, { children: _jsx(ZoomOutOutlined, { style: { margin: 16 } }) }), _jsx(Col, { flex: "1 1 auto", children: _jsx(Slider, { tooltip: { open: false }, min: 0.5, step: 0.01, max: 2, value: scale, onChange: onChangeScale }) }), _jsx(Col, { children: _jsx(ZoomInOutlined, { style: { margin: 16 } }) })] })] }));
};
