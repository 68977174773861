import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { LinkedinFilled, YoutubeFilled, FacebookFilled, InstagramFilled, MailFilled } from '@ant-design/icons';
import { API } from '@api/API';
import useBreakpoints from '@hooks/useBreakpoints';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { XIcon } from '@components/icons/XIcon';
const FooterSocialIcons = (props) => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs("div", { style: {
            display: 'grid',
            gridAutoFlow: 'column',
            rowGap: 10,
            columnGap: 16,
            justifyContent: isMobile ? 'center' : 'space-between',
        }, children: [_jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, externalHref: APP_MANIFEST.sites.social.linkedin.url, title: APP_MANIFEST.sites.social.linkedin.network, target: '_blank', rel: "noreferrer", children: _jsx(LinkedinFilled, { style: { fontSize: isMobile ? 24 : 22 } }) }), _jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, externalHref: APP_MANIFEST.sites.social.twitter.url, title: APP_MANIFEST.sites.social.twitter.network, target: '_blank', rel: "noreferrer", children: _jsx(XIcon, { height: isMobile ? 24 : 22, width: isMobile ? 24 : 22 }) }), _jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, externalHref: APP_MANIFEST.sites.social.youtube.url, title: APP_MANIFEST.sites.social.youtube.network, target: '_blank', rel: "noreferrer", children: _jsx(YoutubeFilled, { style: { fontSize: isMobile ? 24 : 22 } }) }), _jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, externalHref: APP_MANIFEST.sites.social.facebook.url, title: APP_MANIFEST.sites.social.facebook.network, target: '_blank', rel: "noreferrer", children: _jsx(FacebookFilled, { style: { fontSize: isMobile ? 24 : 22 } }) }), _jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, externalHref: APP_MANIFEST.sites.social.instagram.url, title: APP_MANIFEST.sites.social.instagram.network, target: '_blank', rel: "noreferrer", children: _jsx(InstagramFilled, { style: { fontSize: isMobile ? 24 : 22 } }) }), !props.error && (_jsx(LinkButton, { type: "text", btnStyle: { padding: isMobile ? 10 : 8 }, onClick: () => {
                    void API.analyticsAction({ bodyParams: { action: 'support.contact', sender: 'main.nav.menu' } });
                }, href: {
                    pathname: '/help',
                    search: '?page=contact',
                }, children: _jsx(MailFilled, { style: { fontSize: isMobile ? 24 : 22 } }) }))] }));
};
export { FooterSocialIcons };
