import { jsx as _jsx } from "react/jsx-runtime";
import { byteArrayMapBits } from '@util/array/bytearray';
import { useAppSelector } from '@hooks/useStore';
const ProgressBar = (props) => {
    const { progressBitmap, fullfillment } = props;
    const theme = useAppSelector((state) => state.ui.theme);
    let array = [];
    for (const character of byteArrayMapBits(progressBitmap)) {
        array.push(character.bit);
    }
    if (fullfillment == 100)
        array = array.map((val, i) => 1);
    return (_jsx("div", { style: { display: 'grid', gridAutoColumns: 'minmax(0, 1fr)', gridAutoFlow: 'column', width: '100%' }, children: array.map((bit, index) => {
            return (_jsx("div", { style: {
                    borderRadius: index == 0 ? '5px 0 0 5px' : index == array.length - 1 ? '0 5px 5px 0' : undefined,
                    height: 6,
                    backgroundColor: fullfillment == 100 ? '#73d03f' : bit === 1 ? '#FA8C16' : theme != 'light' ? '#222222' : '#F0F0F0',
                }, children: "\u00A0\u00A0\u00A0" }, `pregress${index}`));
        }) }));
};
export { ProgressBar };
