var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTP } from './HTTP';
import { isEndpointError } from './Error';
import { declareError } from '@typings/error/Error';
export const ErrorAuthDenied = declareError('api', 'auth.denied', 'Authentication has no access to this property');
export const ErrorAuthTokenMissing = declareError('api', 'auth.missing', 'Authentication token is required');
export const ErrorAuthTokenExpired = declareError('api', 'auth.expired', 'Authentication token has been marked as expired');
export const ErrorAccessNotAllowed = declareError('api', 'access.notallowed', 'You had no permissions to see this');
export const ErrorAPI = declareError('api', 'generic', 'API Error');
export const ErrorMissingParamsAPI = declareError('api', 'missing params', 'API Error');
export const ErrorEmailAlreadyExistAPI = declareError('api', 'email exists', 'API Error');
export const ErrorTooManyRequests = declareError('api', 'many request', 'API Error');
class Client {
    constructor(baseUrl, authTokenProvider) {
        this.analyticsIdRetrieve = (throwOnNil) => {
            const analyticsId = this.authTokenProvider.getAnalyticsId();
            if (!analyticsId && throwOnNil) {
                throw ErrorAuthTokenMissing();
            }
            return analyticsId;
        };
        this.authTokenRetrieve = (throwOnNil) => {
            const authToken = this.authTokenProvider.getAuthToken();
            if (!authToken && throwOnNil) {
                throw ErrorAuthTokenMissing();
            }
            return authToken;
        };
        this.authTokenSave = (token) => {
            this.authTokenProvider.saveAuthToken(token);
        };
        this.absoluteUrl = (relative) => {
            if (relative.startsWith('/')) {
                relative = relative.slice(1);
            }
            return new URL(relative, new URL(this.baseUrl));
        };
        this.serialize = (relativeUrl, options, urlParameters, bodyParameters, searchParameters) => {
            if (options.useColonParams) {
                const { path, unmatched } = HTTP.parametrizePath(relativeUrl, urlParameters);
                urlParameters = unmatched;
                relativeUrl = path;
            }
            const url = this.absoluteUrl(relativeUrl);
            options.encoding = HTTP.safeEncodingForMethod(options.method, { urlParameters, bodyParameters, searchParameters }, options.encoding);
            const requestOptions = {
                method: options.method,
                headers: {},
            };
            switch (options.encoding) {
                case 'ignore':
                    requestOptions.body = undefined;
                    break;
                case 'body.json':
                    requestOptions.body = JSON.stringify(bodyParameters);
                    requestOptions.headers['Content-Type'] = HTTP.MimeType.json;
                    break;
                case 'url.encoded':
                    Object.keys(searchParameters).forEach((key) => { var _a; return url.searchParams.append(key, (_a = searchParameters[key]) === null || _a === void 0 ? void 0 : _a.toString()); });
                    requestOptions.headers['Content-Type'] = HTTP.MimeType.urlEncoded;
                    break;
                case 'body.url.encoded':
                    Object.keys(bodyParameters).forEach((key) => { var _a; return url.searchParams.append(key, (_a = bodyParameters[key]) === null || _a === void 0 ? void 0 : _a.toString()); });
                    requestOptions.body = url.search.substr(1);
                    requestOptions.headers['Content-Type'] = HTTP.MimeType.urlEncoded;
                    url.search = '';
                    break;
            }
            if (options.appendUTM && location.search) {
                const windowParams = new URLSearchParams(location.search);
                windowParams.forEach((value, key) => {
                    key = key.toLowerCase();
                    if (!key.startsWith('utm_'))
                        return;
                    url.searchParams.append(key, value);
                });
                url.search;
            }
            return new Request(url.href, requestOptions);
        };
        this.deserialize = (response) => __awaiter(this, void 0, void 0, function* () {
            if (response.status === 204) {
                return {};
            }
            if (response.status === 429) {
                return { error: 'application.many.requests', message: 'Too many requests' };
            }
            const bodyJson = (yield response.json());
            return bodyJson;
        });
        this.extractPagination = (response) => {
            const pagination = response.headers.get('X-Turingo-Pagination-Next-Page');
            if (!pagination || pagination == 'undefined' || pagination == 'first')
                return undefined;
            return pagination;
        };
        this.extractTotal = (response) => {
            const total = response.headers.get('X-Turingo-Pagination-Page-Total');
            if (!total || total == 'undefined')
                return undefined;
            return Number(total);
        };
        this.extractPresignId = (response) => {
            const presignId = response.headers.get('X-Turingo-Auth-Presign-Id');
            if (!presignId || presignId == 'undefined')
                return undefined;
            return presignId;
        };
        this.hookExtractAuthToken = (request, response) => {
            if (!response || response.authToken !== undefined) {
                this.authTokenSave(response.authToken);
            }
            else {
                console.warn('API: Unknown object for auth token extraction');
            }
        };
        this.hookPreventUnauthorized = (request, response) => {
            if (!response)
                return;
            if (isEndpointError(response) && (response.error === 'access.header.auth.expired' || response.error === 'application.auth.bearer')) {
                this.authTokenProvider.invalidateAuthToken();
                throw ErrorAuthTokenExpired();
            }
        };
        this.hookLogger = (request, response) => {
            if (isEndpointError(response)) {
                console.error(`API @${request.url} Error: ${response.error}`);
                if (response.content) {
                    console.groupCollapsed(response.content);
                    console.groupEnd();
                }
            }
            else {
                console.log(`API @${request.url} Succeeded`);
            }
        };
        this.hookCatch = (request, response) => {
            if (!response || !isEndpointError(response))
                return;
            switch (response.error) {
                case 'access.header.auth.expired': {
                    this.authTokenProvider.invalidateAuthToken();
                    throw response;
                }
                case 'access.notallowed': {
                    this.authTokenProvider.showError('access.notallowed');
                    throw response;
                }
                case 'application.community.notfound': {
                    this.authTokenProvider.showError('application.community.notfound');
                    throw response;
                }
                case 'application.board.notfound': {
                    this.authTokenProvider.showError('application.board.notfound');
                    throw response;
                }
                case 'application.post.notfound': {
                    this.authTokenProvider.showError('application.post.notfound');
                    throw response;
                }
                case 'application.auth.bearer': {
                    this.authTokenProvider.invalidateAuthToken();
                    throw response;
                }
                case 'routing.arguments': {
                    throw response;
                }
                case 'application.account.email.used': {
                    throw response;
                }
                default: {
                    throw response;
                }
            }
        };
        this.handleErrors = (response) => {
            if (!response) {
                throw Error(response.statusText);
            }
            return response;
        };
        this.perform = (clientRelativeUrl, options, urlParameters, bodyParameters, searchParameters) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const request = this.serialize(clientRelativeUrl, options, urlParameters, bodyParameters, searchParameters);
            let authToken;
            const postHooks = [this.hookLogger, this.hookCatch];
            request.headers.set('X-Turingo-Device-Id', `${this.analyticsIdRetrieve(true)}`);
            switch (options.authentication) {
                case 'none':
                    break;
                case 'provider.authtoken':
                    postHooks.push(this.hookExtractAuthToken);
                    break;
                case 'required.header.bearer':
                    authToken = this.authTokenRetrieve(true);
                    postHooks.push(this.hookPreventUnauthorized);
                    break;
                case 'optional.header.bearer':
                    authToken = this.authTokenRetrieve(false);
                    break;
            }
            if ((_a = options.headers) === null || _a === void 0 ? void 0 : _a.presignId) {
                HTTP.presignRequest(request, (_b = options.headers) === null || _b === void 0 ? void 0 : _b.presignId);
            }
            HTTP.signRequest(request, authToken);
            HTTP.pageRequest(request, options.headers);
            HTTP.acceptCurrentLanguage(request);
            const response = yield fetch(request)
                .then(this.handleErrors)
                .then((response) => {
                return response;
            })
                .catch((error) => {
                this.authTokenProvider.showError(error);
            });
            if (!response) {
                throw Error();
            }
            const deserialized = yield this.deserialize(response);
            const pagination = this.extractPagination(response);
            const total = this.extractTotal(response);
            const presignId = this.extractPresignId(response);
            const data = { data: deserialized, pagination: pagination, total: total, presignId: presignId };
            postHooks.forEach((c) => c(request, deserialized));
            return data;
        });
        if (!baseUrl.endsWith('/'))
            baseUrl += '/';
        this.baseUrl = baseUrl.toLowerCase().trim();
        this.authTokenProvider = authTokenProvider;
    }
}
export { Client };
