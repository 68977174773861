import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import FooterDark from '@bundle/assets/landing_b2b/footer.dark.png';
import FooterLight from '@bundle/assets/landing_b2b/footer.light.png';
import FooterMobileDark from '@bundle/assets/landing_b2b/footer.mobile.dark.png';
import FooterMobileLight from '@bundle/assets/landing_b2b/footer.mobile.light.png';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
import { useHistory } from 'react-router';
import { API } from '@api/API';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
const Footer = (props) => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.xxs || breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.mdl;
    const history = useHistory();
    return (_jsx("div", { style: { width: '100%', backgroundColor: isDark ? '#1f1f1f' : '#f5f5f5' }, children: _jsx("div", { className: "cover", style: {
                background: isMobile ? 'unset' : `url(${isDark ? FooterDark : FooterLight})`,
                height: isMobile ? 'inherit' : 320,
                backgroundSize: 'cover',
                maxWidth: 1600,
                paddingBottom: 16,
                paddingLeft: isMobile ? 0 : 16,
                paddingRight: isMobile ? 0 : 16,
                marginLeft: 'auto',
                marginRight: 'auto',
            }, children: _jsx("div", { style: { maxWidth: 1380, display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end', height: isMobile ? 'unset' : '100%' }, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'center', flexDirection: 'column' }, children: [!isMobile && (_jsxs("div", { style: { fontSize: 38, lineHeight: '46px', fontWeight: 500, maxWidth: 434, alignSelf: 'center', marginBottom: 16 }, children: [strings.screens.landingb2b.footer[0], _jsx("div", { style: { color: '#4cc1be', display: 'inline' }, children: strings.screens.landingb2b.footer[1] }), ' ', strings.screens.landingb2b.footer[2]] })), isMobile && (_jsx("img", { src: isDark ? FooterMobileDark : FooterMobileLight, alt: "", style: { height: 217, objectFit: 'cover', marginBottom: 16, width: '100%' } })), _jsxs("div", { style: Object.assign({}, (isMobile
                                ? {
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    display: 'flex',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                }
                                : {})), children: [isMobile && (_jsxs("div", { style: { fontSize: 30, lineHeight: '40px', fontWeight: 500, alignSelf: 'center', marginBottom: 16 }, children: [strings.screens.landingb2b.footer[0], ' ', _jsxs("div", { style: { color: '#4cc1be', display: 'inline' }, children: [strings.screens.landingb2b.footer[1], " "] }), ' ', strings.screens.landingb2b.footer[2]] })), _jsx(LinkButton, { style: Object.assign({}, (!isMobile && { width: 160 })), externalHref: 'https://calendly.com/turingo/demo-request', onClick: () => {
                                        void API.analyticsAction({ bodyParams: { action: 'btn.demo', sender: 'main.nav.footer' } });
                                    }, type: "default", children: strings.screens.landingb2b.action })] })] }) }) }) }));
};
export { Footer };
